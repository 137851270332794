import React from 'react';
import {Button, Card, List, message} from 'antd';
import {useModel} from '@@/plugin-model/useModel';
import EditEmailVIew from '@/pages/user/profile/components/components/EditEmailView';
import ChangePasswdView from '@/pages/user/profile/components/components/ChangePasswdView';

const Index: React.FC = () => {
  const {initialState} = useModel('@@initialState');
  const currentUser = initialState?.currentUser;
  const passwordStrengthMap = {
    'STRONG': 'strong',
    'MEDIUM_STRENGTH': 'medium strength',
    'WEAK': 'weak'
  }

  const data = [
    {
      title: 'Email',
      description: 'Binded mailbox: ' + currentUser?.email,
      action: [<EditEmailVIew/>],
    },
    {
      title: 'Password',
      description: 'Current password strength: ' + passwordStrengthMap[currentUser?.passwordStrength!],
      action: [<ChangePasswdView/>],
    },
  ];

  return (
    <Card
      title={<div className="text-xl">Security Setting</div>}
      bordered={false}
    >
      <List
        itemLayout="horizontal"
        dataSource={data}
        renderItem={(item) => (
          <List.Item actions={item.action}>
            <List.Item.Meta title={item.title} description={item.description}/>
          </List.Item>
        )}
      />
    </Card>
  );
};

export default Index;
