// @ts-nocheck

  import DashboardOutlined from '@ant-design/icons/es/icons/DashboardOutlined';
import DatabaseOutlined from '@ant-design/icons/es/icons/DatabaseOutlined';
import PartitionOutlined from '@ant-design/icons/es/icons/PartitionOutlined';
import FundOutlined from '@ant-design/icons/es/icons/FundOutlined';
import ForkOutlined from '@ant-design/icons/es/icons/ForkOutlined';
import AlertOutlined from '@ant-design/icons/es/icons/AlertOutlined';
import UnorderedListOutlined from '@ant-design/icons/es/icons/UnorderedListOutlined';
import InsertRowAboveOutlined from '@ant-design/icons/es/icons/InsertRowAboveOutlined';
import ExperimentOutlined from '@ant-design/icons/es/icons/ExperimentOutlined';
import UserOutlined from '@ant-design/icons/es/icons/UserOutlined';
import GlobalOutlined from '@ant-design/icons/es/icons/GlobalOutlined'
  export default {
    DashboardOutlined,
DatabaseOutlined,
PartitionOutlined,
FundOutlined,
ForkOutlined,
AlertOutlined,
UnorderedListOutlined,
InsertRowAboveOutlined,
ExperimentOutlined,
UserOutlined,
GlobalOutlined
  }