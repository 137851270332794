import React, { useRef } from 'react';
import { ClientType, PostAdminMegaDataClients } from '@/service/admin/megaData';
import { ProColumns, ProTable } from '@ant-design/pro-table';
import { Button, message, Modal, Space } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { PostAdminStationGrantAdd, PostAdminStationGrantDelete } from '@/service/admin/stationGrant';
import ProForm, { ModalForm, ProFormSelect } from '@ant-design/pro-form';
import { PostAdminStationChoices } from '@/service/admin/station';

const Clients = () => {
  const columns: ProColumns<ClientType>[] = [
    {
      title: 'userId',
      width: 120,
      dataIndex: 'userId',
      render: (_) => <a>{_}</a>,
    },
    {
      title: 'description',
      width: 480,
      dataIndex: 'userDescription',
      render: (_) => <a>{_}</a>,
    },
    {
      title: 'operation',
      valueType: 'option',
      readonly: true,
      render: (dom, entity, index, action) => {
        return (
          <div>
            <ModalForm
              title={<>New Auth</>}
              trigger={<Button type="link">Add</Button>}
              onFinish={async (formData) => {
                await PostAdminStationGrantAdd({
                  stationId: formData.station,
                  userId: entity.userId,
                  period: [],
                  description: '',
                });
                message.success('submit successfully');
                return true;
              }}
            >
              <ProForm.Group>
                <ProFormSelect
                  label="Station"
                  name="station"
                  showSearch
                  width="md"
                  request={async ({ keyWords = '' }) => {
                    const { data } = await PostAdminStationChoices(keyWords);
                    return data.map((it) => ({ label: it.stationId, value: it.stationId }));
                  }}
                  placeholder="Please select station"
                  rules={[{ required: true, message: 'Required' }]}
                />
              </ProForm.Group>
            </ModalForm>
          </div>
        );
      },
    },
  ];

  const expandedRowRender = (item: ClientType) => {
    let data = [];
    data = item.stations;
    return (
      <ProTable
        columns={[
          {
            title: 'stationId',
            dataIndex: 'stationId',
            key: 'stationId',
            render: (it) => {
              if (it == '*') return 'ALL';
              else return it;
            },
          },
          { title: 'networkId', dataIndex: 'networkId', key: 'networkId' },
          {
            title: 'operation',
            valueType: 'option',
            readonly: true,
            render: (dom, entity, index, action) => {
              return (
                <div>
                  <Button
                    danger
                    type="link"
                    onClick={() => {
                      Modal.confirm({
                        title: 'Confirm',
                        icon: <ExclamationCircleOutlined />,
                        content: (
                          <div>
                            <div>Are you sure to delete auth</div>
                            <div>{` ${item.userId} - ${entity.stationId} ?`}</div>
                          </div>
                        ),
                        okText: 'Delete',
                        closable: false,
                        cancelText: 'Cancel',
                        okType: 'danger',
                        onOk() {
                          PostAdminStationGrantDelete(entity.id)
                            .then(() => {
                              message.success(`Auth ${item.userId} - ${entity.stationId} deleted! Please refresh the list`).then(() => {});
                            })
                            .catch(() => {});
                        },
                      });
                    }}
                  >
                    Delete
                  </Button>
                </div>
              );
            },
          },
        ]}
        headerTitle={false}
        search={false}
        options={false}
        dataSource={data}
        pagination={false}
      />
    );
  };
  return (
    <ProTable<ClientType>
      expandable={{ expandedRowRender }}
      rowKey="userId"
      columns={columns}
      request={async (params) => {
        const { data } = await PostAdminMegaDataClients(params.pageSize ?? 10, params.current ?? 0, params.query ?? '');
        return {
          data: data.values,
          total: data.total,
          success: true,
        };
      }}
      pagination={{
        defaultPageSize: 7,
        showSizeChanger: true,
        showQuickJumper: true,
      }}
      search={false}
      options={{
        search: {
          name: 'query',
          placeholder: 'Search by userId',
        },
        setting: false,
      }}
      size="small"
    />
  );
};

export default Clients;
