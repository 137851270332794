import React from 'react';
import { PageContainer } from '@ant-design/pro-layout';
import ProTable, { ConfigProvider } from '@ant-design/pro-table';
import { PostStationGetLast, StationItem } from '@/service/map/station';
import { enUSIntl } from '@ant-design/pro-provider';
import { Tag } from 'antd';

const Index: React.FC = () => {
  const columns = [
    {
      title: 'station ID',
      dataIndex: 'stationId',
      key: 'stationId',
    },
    {
      title: 'status',
      dataIndex: 'status',
      key: 'status',
      render: (_: any, record: StationItem) => {
        const colors = ['#23ac4c', '#ffc000', '#f50', '#808080'];
        const statusList = ['NORMAL', 'UNSTABLE', 'ERROR', 'STOP'];
        return <Tag color={colors[record.status]}>{statusList[record.status]}</Tag>;
      },
    },
    {
      title: 'longitude',
      dataIndex: 'longitude',
      key: 'longitude',
    },
    {
      title: 'latitude',
      dataIndex: 'latitude',
      key: 'latitude',
    },
    {
      title: 'depthMsl',
      dataIndex: 'depthMSL',
      key: 'depthMSL',
      render: (_: any, record: StationItem) => {
        return `${record.depthMSL?.toFixed(3)}`;
      },
    },
    {
      title: 'battery',
      dataIndex: 'battery',
      key: 'battery',
      render: (_: any, record: StationItem) => {
        return record.battery * 100 + '%';
      },
    },
    {
      title: 'signalLTE',
      dataIndex: 'signalLTE',
      key: 'signalLTE',
      render: (_: any, record: StationItem) => {
        return record.signalLTE * 100 + '%';
      },
    },
    {
      title: 'signalWIFI',
      dataIndex: 'signalWIFI',
      key: 'signalWIFI',
      render: (_: any, record: StationItem) => {
        if (typeof record.signelWiFi === 'string') {
          return '- %';
        }
        return record.signelWiFi * 100 + '%';
      },
    },
    {
      title: 'temperature',
      dataIndex: 'temperature',
      key: 'temperature',
      render: (_: any, record: StationItem) => {
        let cpu: number | string = record.temeprature_cpu;
        if (typeof cpu === 'string') {
          cpu = '-';
        } else {
          cpu = cpu.toFixed(1);
        }

        let sensor: number | string = record.temeprature_sensor;
        if (typeof sensor === 'string') {
          sensor = '-';
        } else {
          sensor = sensor.toFixed(1);
        }
        return `cpu:${cpu}℃ sensor:${sensor}℃`;
      },
    },
  ];

  return (
    <ConfigProvider value={{ intl: enUSIntl, valueTypeMap: {} }}>
      <PageContainer>
        <ProTable
          headerTitle="Station List"
          search={false}
          columns={columns}
          request={async (params) => {
            const res = await PostStationGetLast();
            return {
              data: res.data,
              success: true,
              total: res.data.length,
            };
          }}
          rowKey="id"
        ></ProTable>
      </PageContainer>
    </ConfigProvider>
  );
};

export default Index;
