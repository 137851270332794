import React, { useState } from 'react';
import ProForm, { ModalForm, ProFormSelect, ProFormText } from '@ant-design/pro-form';
import { Button, Form, Input, message } from 'antd';
import { PostAdminUserAdd, PostAdminUserQuickAdd, PostAuthoritiesOptions } from '@/service/user/list';
import { TextField } from '@mui/material';

type FormDataType = {
  id?: string;
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  type: string;
  authorities: string[];
};

const NewUserModal: React.FC = ({}) => {
  const [id, setId] = useState('');
  const [quickAddLoading, setQuickAddLoading] = useState(false);
  return (
    <ModalForm
      title={<>New User</>}
      trigger={<Button type="primary">New User</Button>}
      onFinish={async (formData: FormDataType) => {
        formData.id = id || '';
        if (formData.authorities == null) formData.authorities = [];
        // @ts-ignore
        const res = await PostAdminUserAdd({
          ...formData,
        });
        message.success('submit successfully');
        return true;
      }}
    >
      <ProForm.Group>
        <Form.Item label="Id">
          <Input
            width="md"
            id="id"
            name="id"
            value={id}
            onChange={(res) => {
              setId(res.target.value);
            }}
          />
        </Form.Item>
        <Form.Item label={<div></div>}>
          <Button
            type="primary"
            loading={quickAddLoading}
            onClick={() => {
              if (id == '') {
                message.warn('Id can not be empty when using quick new');
              } else {
                setQuickAddLoading(true);
                PostAdminUserQuickAdd(id).then((res) => {
                  setQuickAddLoading(false);
                  setId('');
                  message.success('Submit successfully! You can add new users again');
                });
              }
            }}
          >
            Quick New
          </Button>
        </Form.Item>
      </ProForm.Group>
      <ProForm.Group>
        <ProFormText width="md" id="email" name="email" label="Email" required />
      </ProForm.Group>
      <ProForm.Group>
        <ProFormText width="md" id="firstName" name="firstName" label="First Name" />
        <ProFormText width="md" id="lastName" name="lastName" label="Last Name" />
      </ProForm.Group>
      <ProForm.Group>
        <ProFormSelect
          width="md"
          required
          initialValue="User"
          options={[
            {
              value: 'Admin',
              label: 'Admin',
            },
            {
              value: 'User',
              label: 'Common User',
            },
          ]}
          name="type"
          label="User Type"
        />
      </ProForm.Group>
      <ProForm.Group>
        <ProFormSelect
          request={async () => {
            const res = await PostAuthoritiesOptions();
            return res.data.map((item) => {
              return {
                value: item.name,
                label: item.description,
              };
            });
          }}
          mode="tags"
          width="xl"
          name="authorities"
          label="Authorities"
        />
      </ProForm.Group>
      <ProForm.Group>
        <ProFormText.Password
          rules={[{ required: true, message: 'new password is required' }]}
          width="md"
          id="newPassword"
          name="password"
          label="New Password"
        />
        <ProFormText.Password
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('password not match'));
              },
            }),
          ]}
          width="md"
          id="confirmPassword"
          name="rePassword"
          required
          label="Confirm Password"
          placeholder="confirm password"
        />
      </ProForm.Group>
    </ModalForm>
  );
};

export default NewUserModal;
