import React, { useEffect, useState } from 'react';
import { history, useParams } from 'umi';
import { PageContainer } from '@ant-design/pro-layout';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Card, DatePicker, Divider, Radio, Row, Space, Spin } from 'antd';
import { StatisticCard } from '@ant-design/pro-card';
import { DatItem, PostCore2DatList, PostCore2DatListByTime } from '@/service/core2/dat';
import { PostCore2StaLatest, PostCore2StaList, PostCore2StaListByTime, StaItem } from '@/service/core2/sta';
import Bettary from '@/pages/station/status/charts/Bettary';
import RcResizeObserver from 'rc-resize-observer';
import { TemperatureCpu } from '@/pages/station/status/charts/TemperatureCpu';
import { SignalLTE } from '@/pages/station/status/charts/SignalLTE';
import { SignelWiFi } from '@/pages/station/status/charts/signelWiFi';
import { TemperatureSensor } from '@/pages/station/status/charts/TemperatureSensor';
import { Azimuth } from '@/pages/station/status/charts/Azimuth';
import { Inclination } from '@/pages/station/status/charts/Inclination';
import { Noise } from '@/pages/station/status/charts/Noise';
import { Status } from '@/pages/station/status/charts/Status';
import { IndexChart } from '@/pages/station/status/charts/IndexChart';
import moment, { Moment } from 'moment';
import { GpsQuality } from '@/pages/station/status/charts/GpsQuality';
import { SampleRate } from '@/pages/station/status/charts/SampleRate';
import { MqttSent } from '@/pages/station/status/charts/MqttSent';
import { TimeSlider } from '@/pages/station/status/TimeSlider';
import { ProFormDateTimeRangePicker } from '@ant-design/pro-form';
import { useMount } from 'ahooks';

const { RangePicker } = DatePicker;

type TimeLengthType = '30D' | '20D' | '10D' | '7D' | '3D' | '2D' | '24H' | '12H' | '6H' | '3H' | '2H' | '1H' | 'Custom';
const timeLengthMap = {
  '30D': 24 * 30,
  '20D': 24 * 20,
  '10D': 24 * 10,
  '7D': 24 * 7,
  '3D': 24 * 3,
  '2D': 24 * 2,
  '24H': 24,
  '12H': 12,
  '6H': 6,
  '3H': 3,
  '2H': 2,
  '1H': 1,
  Custom: 0,
};

const Index: React.FC = () => {
  // userId为废弃属性
  const { stationId, from } = useParams<{
    stationId: string;
    from: string;
  }>();
  const [timeLength, setTimeLength] = React.useState<TimeLengthType>('6H');
  const [dat, setDat] = React.useState<DatItem[]>([]);
  const [sta, setSta] = React.useState<StaItem[]>([]);

  const [loading1, setLoading1] = React.useState(false);
  const [loading2, setLoading2] = React.useState(false);
  const [chartWidth, setChartWidth] = React.useState(0);
  const [min, setMin] = React.useState(0);
  const [max, setMax] = React.useState(0);

  const [timeSliderMin, setTimeSliderMin] = React.useState(0);
  const [timeSliderMax, setTimeSliderMax] = React.useState(0);
  const [customDate, setCustomDate] = React.useState<Moment[]>([moment(), moment()]);

  const [firstFourData, setFirstFourData] = React.useState<StaItem>();
  const [mqttVersion, setMqttVersion] = React.useState('');

  useEffect(() => {
    setLoading1(true);
    setLoading2(true);

    // 设置时间轴的最小值和最大值
    if (timeLength == 'Custom') {
      console.log('Custom');
      PostCore2DatListByTime(stationId, customDate[0], customDate[1]).then((res) => {
        res.data.sort((a: { tpack: number }, b: { tpack: number }) => b.tpack - a.tpack);
        setDat(res.data);
        setLoading1(false);
      });

      PostCore2StaListByTime(stationId, customDate[0], customDate[1]).then((res) => {
        res.data.sort((a, b) => b.tbeat - a.tbeat);
        setSta(res.data);
        setLoading2(false);
      });

      setMin(customDate[0].valueOf());
      setMax(customDate[1].valueOf());
      setTimeSliderMin(customDate[0].valueOf());
      setTimeSliderMax(customDate[1].valueOf());
    } else {
      PostCore2DatList(stationId, timeLengthMap[timeLength]).then((res) => {
        res.data.sort((a: { tpack: number }, b: { tpack: number }) => b.tpack - a.tpack);
        setDat(res.data);
        setLoading1(false);
      });

      PostCore2StaList(stationId, timeLengthMap[timeLength]).then((res) => {
        res.data.sort((a, b) => b.tbeat - a.tbeat);
        setSta(res.data);
        setLoading2(false);
      });

      setMin(moment().subtract(timeLengthMap[timeLength], 'hours').valueOf());
      setMax(moment().valueOf());
      setTimeSliderMin(moment().subtract(timeLengthMap[timeLength], 'hours').valueOf());
      setTimeSliderMax(moment().valueOf());
    }
  }, [timeLength, customDate]);

  //前四个card数据获取
  useMount(() => {
    PostCore2StaLatest(stationId).then((res) => {
      setFirstFourData(res.data);

      if (res.data) {
        const s = res.data?.mqttVersion.replace('"', '');
        setMqttVersion(s[0] + s[1] + '-' + s[2] + s[3] + '-' + s[4] + s[5] + '-' + s[6] + s[7]);
      } else {
        setMqttVersion('no data');
      }
    });
  });
  // @ts-ignore
  // @ts-ignore
  return (
    <div>
      <PageContainer
        title={
          <span>
            <ArrowLeftOutlined onClick={() => history.goBack()} className="mr-2" /> {stationId}
          </span>
        }
      >
        <RcResizeObserver
          key="resize-observer"
          onResize={(offset) => {
            setChartWidth(offset.width);
          }}
        >
          <Card>
            <Space direction="vertical" className="w-full">
              <Row>
                <Radio.Group onChange={(res) => setTimeLength(res.target.value as TimeLengthType)} defaultValue={timeLength}>
                  <Radio.Button value="30D">30D</Radio.Button>
                  <Radio.Button value="20D">20D</Radio.Button>
                  <Radio.Button value="10D">10D</Radio.Button>
                  <Radio.Button value="7D">7D</Radio.Button>
                  <Radio.Button value="3D">3D</Radio.Button>
                  <Radio.Button value="2D">2D</Radio.Button>
                  <Radio.Button value="24H">24H</Radio.Button>
                  <Radio.Button value="12H">12H</Radio.Button>
                  <Radio.Button value="6H">6H</Radio.Button>
                  <Radio.Button value="3H">3H</Radio.Button>
                  <Radio.Button value="2H">2H</Radio.Button>
                  <Radio.Button value="1H">1H</Radio.Button>
                  <Radio.Button value="Custom">Custom</Radio.Button>
                </Radio.Group>
                <RangePicker
                  format={'MM/DD/YYYY HH:mm:ss'}
                  onChange={(date) => {
                    if (date != null) {
                    }
                    setCustomDate(date);
                  }}
                  showTime
                />
              </Row>

              <div className="w-full center mt-4" key="f-1">
                <div style={{ textAlign: 'center' }}>
                  <span className="font-bold pt-2">
                    {moment(min).utc().format('yyyy-MM-DD HH:mm:ss')} - {moment(max).utc().format('yyyy-MM-DD HH:mm:ss')}
                  </span>
                </div>
              </div>
              <TimeSlider
                width={chartWidth}
                min={timeSliderMin}
                max={timeSliderMax}
                onChange={(min, max) => {
                  setMin(min);
                  setMax(max);
                }}
              />
              <Divider />
              <Spin spinning={loading1 || loading2}>
                <Row>
                  <StatisticCard.Group direction="row">
                    <StatisticCard
                      title="Coordinate"
                      statistic={{
                        value: `[${firstFourData?.latitude ?? 'no data'}, ${firstFourData?.longitude ?? 'no data'}]`,
                      }}
                    />
                    <StatisticCard title="DepthMSL" statistic={{ value: firstFourData?.depthMSL?.toFixed(1) ?? 'no data' }} />
                    <StatisticCard title="Firm Version" statistic={{ value: firstFourData?.firmVersion ?? 'no data' }} />
                    <StatisticCard title="MQTT Version" statistic={{ value: mqttVersion }} />
                  </StatisticCard.Group>
                </Row>
                <Divider />
                <IndexChart dat={dat} width={chartWidth} min={min} max={max} />
                <Divider />
                <Status sta={sta} width={chartWidth} min={min} max={max} />
                <Divider />
                <SampleRate dat={dat} width={chartWidth} min={min} max={max} />
                <Divider />
                <MqttSent sta={sta} width={chartWidth} min={min} max={max} />
                {/*<Divider />*/}
                {/*<DepthMSL sta={sta} width={chartWidth} min={min} max={max} />*/}
                <Divider />
                <Bettary sta={sta} width={chartWidth} min={min} max={max} />
                <Divider />
                <TemperatureCpu sta={sta} width={chartWidth} min={min} max={max} />
                <Divider />
                <TemperatureSensor dat={dat} width={chartWidth} min={min} max={max} />
                <Divider />
                <SignalLTE sta={sta} width={chartWidth} min={min} max={max} />
                <Divider />
                <SignelWiFi sta={sta} width={chartWidth} min={min} max={max} />
                <Divider />
                <GpsQuality sta={sta} width={chartWidth} min={min} max={max} />
                <Divider />
                <Azimuth dat={dat} width={chartWidth} min={min} max={max} />
                <Divider />
                <Inclination dat={dat} width={chartWidth} min={min} max={max} />
                <Divider />
                <Noise title="IMU acc noise" suffix="(ug)" dat={dat} width={chartWidth} r1="std_ref1" k1="std_ax" k2="std_ay" k3="std_az" min={min} max={max} />
                <Divider />
                <Noise
                  title="IMU gyro noise"
                  suffix="(mdps)"
                  dat={dat}
                  width={chartWidth}
                  r1="std_ref2"
                  k1="std_gx"
                  k2="std_gy"
                  k3="std_gz"
                  min={min}
                  max={max}
                />
                <Divider />
                <Noise title="AS noise" suffix="(ug)" dat={dat} width={chartWidth} r1="std_ref3" k1="std_asx" k2="std_asy" k3="std_asz" min={min} max={max} />
              </Spin>
              <Divider />
            </Space>
          </Card>
        </RcResizeObserver>
      </PageContainer>
      <div className="h-16"></div>
    </div>
  );
};

//
// status, gps_quality, n_mqtt_sent,
//   index, sample_rate

export default Index;
