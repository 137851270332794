import React, { CSSProperties } from 'react';
import { Carousel } from 'antd';
import MapIllustration from './icons/MapIllustration';
import Trail from '@/components/Trail';
import DataIll from '@/pages/user/login/components/icons/DataIll';
import TheEarth from '@/pages/user/login/components/icons/TheEarth';

const Index: React.FC = () => {
  const contentStyle: CSSProperties = {
    height: '600px',
    textAlign: 'center',
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const [trailOpen, setTrailOpen] = React.useState(true);

  return (
    <>
      <div
        className="text-white text-3xl h-full p-12"
        style={{
          // backgroundColor: '#0093E9',
          // backgroundImage: 'linear-gradient(135deg, #0093E9 0%, #2b60c4 100%)',
          backgroundColor: '#A7DF6F',
          backgroundImage: 'linear-gradient(145deg, #A7DF6F 0%, #6e9249 100%)',
        }}
      >
        <h3
          className="text-white"
          style={{ textShadow: '2px 4px 8px rgba(255, 255, 255, 0.25)' }}
        >
          SeismicityAlert Portal
        </h3>
        <Carousel
          effect="fade"
          autoplay={true}
          beforeChange={() => {
            setTrailOpen(false);
          }}
          afterChange={() => {
            setTrailOpen(true);
          }}
        >
          <div>
            <h3 style={contentStyle}>
              <MapIllustration />
              <div className="h-8" />
              <div>
                <Trail trailsText="h-6 text-xl" open={trailOpen}>
                  <span>Connect your monitoring station</span>
                  <span>At any place</span>
                </Trail>
              </div>
            </h3>
          </div>
          <div>
            <h3 style={contentStyle}>
              <DataIll />
              <div className="h-8" />
              <div>
                <Trail trailsText="h-6 text-xl" open={trailOpen}>
                  <span>Reliable and stable </span>
                  <span>Cloud storage</span>
                  <span>Keep your data security</span>
                </Trail>
              </div>
            </h3>
          </div>
          <div>
            <h3 style={contentStyle}>
              <TheEarth />
              <div className="h-8" />
              <div>
                <Trail trailsText="h-6 text-xl" open={trailOpen}>
                  <span>Never miss any new event</span>
                  <span>Get new information </span>
                  <span>In a timely manner</span>
                </Trail>
              </div>
            </h3>
          </div>
        </Carousel>
      </div>
    </>
  );
};

export default Index;
