import React, { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import constant from './../../constant';
import MapStation from '@/pages/components/MapStation';
import MapEvent from '@/pages/components/MapEvent';
import { SyncOutlined } from '@ant-design/icons';
import { Popover, Button, Row, Col } from 'antd';
import { PostUserUpdateMapStatus } from '@/service/user/common';
import { useRequest } from 'ahooks';
import { useModel } from '@@/plugin-model/useModel';
import MapViewController from '@/pages/components/MapViewController';
import MapPublicStation from '@/pages/components/MapPublicStation';
import MapUsgsEvent from '@/pages/components/MapUsgsEvent';
import MapBoundary from '@/pages/components/MapBoundary';
import MapEventSeismogram from '@/pages/components/MapEventSeismogram';

mapboxgl.accessToken = constant.MAP_TOKEN;

export let mapInstance: mapboxgl.Map | undefined = undefined;

const MapView: React.FC = () => {
  const { initialState, refresh } = useModel('@@initialState');

  const initLatLng = {
    lat: initialState?.currentUser?.lastLat != 0 ? initialState?.currentUser?.lastLat : 30.41,
    lng: initialState?.currentUser?.lastLng != 0 ? initialState?.currentUser?.lastLng : -97.88,
    zoom: initialState?.currentUser?.lastZoom != 0 ? initialState?.currentUser?.lastZoom : 8,
  };

  const mapContainer = useRef(null);
  const map = useRef<mapboxgl.Map>();
  const [lng, setLng] = useState<number>(initLatLng.lng ?? -97.88);
  const [lat, setLat] = useState(initLatLng.lat ?? 30.41);
  const [zoom, setZoom] = useState(initLatLng.zoom ?? 8);

  // 用于辅助刷新
  const [updater, setUpdater] = useState(0);
  const forceUpdate = () => setTimeout(() => setUpdater(updater + 1), 0);
  const [currentStyle, setCurrentStyle] = useState([0, 0]);
  const mapStyles = [
    {
      name: 'Ourdoors',
      image: '/outdoors.png',
      // outdoors-2 [mercator,earth]
      link: ['mapbox://styles/taohanxu/cl4qpv1ln000n14l3p119na56', 'mapbox://styles/taohanxu/cl4qqv1dc000f14kzhe6toest'],
    },
    {
      name: 'Satellite',
      image: '/satellite.jpg',
      link: ['mapbox://styles/taohanxu/cl4qnd80m001a14nyy3lbzf4h', 'mapbox://styles/taohanxu/cl4qr2nve000w14p9g9jh4dw0'],
    },
  ];

  const projections = [
    {
      name: 'Mercator',
      image: '/mercator.png',
    },
    {
      name: 'Global',
      image: '/earth.png',
    },
  ];

  const updateMapStatus = async (params: { lat: number; lng: number; zoom: number }) => {
    PostUserUpdateMapStatus(params).then((r) => {});
  };

  const { data, loading, run } = useRequest(updateMapStatus, {
    debounceWait: 5000,
    manual: true,
  });

  useEffect(() => {
    if (map.current) return;
    map.current = new mapboxgl.Map({
      container: mapContainer.current!,
      style: mapStyles[currentStyle[0]].link[currentStyle[1]],
      // style: 'mapbox://styles/mapbox/satellite-v9',
      center: [lng, lat],
      zoom: zoom,
    });
    mapInstance = map.current;
    map.current.addControl(
      new mapboxgl.NavigationControl({
        visualizePitch: true,
      }),
      'bottom-left',
    );

    // 手动触发一次更新
    forceUpdate();
    // 添加天空和3D地形效果
    map.current.on('load', () => {
      map.current?.on('moveend', (lt) => {
        const center = map.current?.getCenter();
        if (center?.lng === undefined || center?.lat === undefined || map.current?.getZoom() === undefined) return;
        run({
          lat: center.lat,
          lng: center.lng,
          zoom: map.current?.getZoom(),
        });
      });
    });
  }, []);

  return (
    <div>
      <link href="https://api.tiles.mapbox.com/mapbox-gl-js/v2.7.0/mapbox-gl.css" rel="stylesheet" />
      <MapEvent map={map.current} userType={initialState?.currentUser?.type} />
      <MapUsgsEvent map={map.current} userType={initialState?.currentUser?.type} />
      <MapStation map={map.current} userType={initialState?.currentUser?.type} />
      <MapPublicStation map={map.current} />
      <MapViewController map={map.current} />
      <MapBoundary map={map.current} userType={initialState?.currentUser?.type} userId={initialState?.currentUser?.id} />
      <MapEventSeismogram map={map.current} userType={initialState?.currentUser?.type} clientId={initialState?.currentUser?.id}></MapEventSeismogram>
      <div ref={mapContainer} style={{ height: '100%', width: '100%', position: 'absolute' }} />
      <div style={{ position: 'fixed', bottom: '30px', right: '60px' }}>
        <Popover
          trigger="click"
          placement="topLeft"
          content={() => (
            <div>
              <div className="font-bold m-2">Style</div>
              <Row>
                {mapStyles.map((style, index) => (
                  <Col
                    key={index}
                    onClick={() => {
                      if (index === currentStyle[0]) return;
                      setCurrentStyle([index, currentStyle[1]]);
                      map.current?.setStyle(style.link[currentStyle[1]]);
                    }}
                  >
                    <div
                      className={
                        index == currentStyle[0]
                          ? 'border-solid p-2 rounded-lg border-4 border-blue-500 hover:bg-gray-100 duration-300 m-2'
                          : 'p-3 hover:bg-gray-100 rounded-lg duration-300 m-2'
                      }
                    >
                      <div
                        style={{
                          borderRadius: 5,
                          overflow: 'hidden',
                          marginRight: 6,
                        }}
                      >
                        <img src={style.image} width={60} height={60} alt="" />
                        <div className="font-semibold">{style.name}</div>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
              <div className="font-bold m-2">Projection</div>
              <Row>
                {projections.map((projection, index) => {
                  return (
                    <Col
                      key={index}
                      onClick={() => {
                        if (index === currentStyle[1]) return;
                        setCurrentStyle([currentStyle[0], index]);
                        map.current?.setStyle(mapStyles[currentStyle[0]].link[index]);
                      }}
                      className={`mt-2 ml-2 rounded-xl ${
                        currentStyle[1] === index
                          ? 'border-solid p-2 rounded-lg border-4 border-blue-500 hover:bg-gray-100 duration-300'
                          : 'p-3 hover:bg-gray-100 rounded-lg duration-300'
                      }`}
                    >
                      <img width={75} height={50} src={projection.image} alt="" />
                      <div className="font-semibold">{projection.name}</div>
                    </Col>
                  );
                })}
              </Row>
            </div>
          )}
          overlayStyle={{ borderRadius: 10 }}
        >
          <div style={{ padding: 5 }}>
            <div
              className={`border-solid rounded-lg border-4 border-white hover:border-blue-200 duration-500 overflow-hidden ${
                currentStyle[1] === 1 ? 'rounded-full' : ''
              }`}
            >
              <img src={mapStyles[currentStyle[0]].image} width={100} height={100} alt="" />
            </div>
          </div>
        </Popover>
      </div>
    </div>
  );
};

export default MapView;

/**
 * onClick={() => {
 *             setCurrentStyle((currentStyle + 1) % mapStyles.length);
 *             map.current?.setStyle(mapStyles[currentStyle]);
 *           }}
 */
/**
 *    这些代码是测试好的，放在这里备用
 *    // const element = (
 *     //   <div className='bg-blue' style={{transform: 'scale(2)'}}>
 *     //     <Magnitude level={2}/>
 *     //   </div>
 *     // );
 *     // const container = document.createElement('div');
 *     // ReactDOM.render(
 *     //   element,
 *     //   container
 *     // );
 *     // const marker = new mapboxgl.Marker({
 *     //   element: container,
 *     // }).setLngLat([-97.88, 30.41])
 *     // .setPopup(new mapboxgl.Popup({offset: 25}).setDOMContent(document.createElement('div')).setHTML('<h1>Hello</h1>'))
 *     // .setPopup(new mapboxgl.Popup({offset: 25}).setDOMContent(container))
 *     //   .addTo(map.current);
 *
 */
