import mapboxgl from 'mapbox-gl';
import { useMount } from 'ahooks';
import { useEffect } from 'react';

type MapBoundaryParamType = { map: mapboxgl.Map | undefined; userType: string | undefined; userId: string | undefined };

const MapBoundary = ({ map, userType, userId }: MapBoundaryParamType) => {
  useEffect(() => {
    // TODO 这里有段写死userId的代码
    if (userType == 'Admin' || userId == 'Shell_groundbirch') {
      // 没想好怎么在切换style的时候保持这个layer
      ['load'].forEach((trigger) => {
        map?.on(trigger, () => {
          // geojson
          map?.addSource('Shell_groundbirch1', {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: [
                {
                  type: 'Feature',
                  properties: {
                    GM_LAYER: 'Unknown Area Type',
                    GM_TYPE: 'Unknown Area Type',
                    LAYER: 'Unknown Area Type',
                    OBJECTID: 1,
                    ID: 0,
                    TYPE: null,
                    UPDATED: 'April 2021',
                    SHAPE_AREA: 2012974416.9400001,
                    SHAPE_LEN: 236772.276701,
                  },
                  geometry: {
                    type: 'Polygon',
                    coordinates: [
                      [
                        [-120.742082765142044, 56.246401170891836],
                        [-120.689385331972005, 56.246385210193644],
                        [-120.688478627805452, 56.159522739407961],
                        [-120.349237675411786, 56.159531117394387],
                        [-120.349225286952091, 56.116121412263404],
                        [-120.297090113127197, 56.116141421211125],
                        [-120.297086974693144, 56.043423587423185],
                        [-120.114418773177846, 56.043423620574174],
                        [-120.114411229462377, 55.883437159604526],
                        [-120.244868495949504, 55.883434456689685],
                        [-120.244866994778448, 55.825253114874208],
                        [-120.688476180266619, 55.825243465904272],
                        [-120.68847752421506, 55.854332755091455],
                        [-120.740688787713992, 55.854345562363328],
                        [-120.740690031448892, 55.897976442827527],
                        [-120.766796105356605, 55.897976508074528],
                        [-120.76679710600267, 55.941611322355286],
                        [-120.818933627308454, 55.941588443079823],
                        [-120.818934439887144, 55.970676286241684],
                        [-121.027720525348514, 55.970694351717285],
                        [-121.02772232331634, 56.028875837501658],
                        [-121.158185830911762, 56.028866547084242],
                        [-121.15818766315725, 56.145215695446737],
                        [-121.032050674783079, 56.145230075989268],
                        [-121.031936910853091, 56.173684500358156],
                        [-121.031936631685326, 56.246400002941094],
                        [-120.926514095928241, 56.246390054947298],
                        [-120.926517179331711, 56.304566121375473],
                        [-120.742088495302085, 56.304575512783465],
                        [-120.742082765142044, 56.246401170891836],
                      ],
                    ],
                  },
                },
              ],
            },
          });

          map?.addSource('Shell_groundbirch2', {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: [
                {
                  type: 'Feature',
                  properties: {
                    GM_LAYER: 'Unknown Area Type',
                    GM_TYPE: 'Unknown Area Type',
                    NAME: 'Shell 100%',
                    LAYER: 'Unknown Area Type',
                    OBJECTID: 0,
                    WORKING_IN: 'Shell 100%',
                    LABEL: 'Shell 100%',
                    ASSET: 'Groundbirch',
                    LAST_UPDAT: 'April 2021',
                    GIS_CONTAC: 'Sheena.Ryall@Shell.com',
                    LANDMAN_CO: 'Mark.McKay@shell.com',
                    COMMENTS: null,
                  },
                  geometry: {
                    type: 'Polygon',
                    coordinates: [
                      [
                        [-120.897293444831377, 55.854370848772575],
                        [-120.897293141752115, 55.8543708488072],
                        [-120.89729362683974, 55.849797069963628],
                        [-120.897293907009711, 55.847095688850622],
                        [-120.897294457797784, 55.839826771721114],
                        [-120.897294457930002, 55.839824971402592],
                        [-120.910347881995676, 55.839822768287888],
                        [-120.91443251075728, 55.839823166322496],
                        [-120.923401071870202, 55.839823572419853],
                        [-120.929177808140096, 55.839821568750274],
                        [-120.936454227911838, 55.839818658838936],
                        [-120.942671830074985, 55.839815878187906],
                        [-120.949506428255233, 55.839812458997073],
                        [-120.949507598996604, 55.839812458378866],
                        [-120.949507617048212, 55.84501054908683],
                        [-120.949507619550914, 55.847087647540569],
                        [-120.949507383076963, 55.849481233528223],
                        [-120.949506890090731, 55.854358393075515],
                        [-120.960726271356634, 55.85435120552485],
                        [-120.962529274370141, 55.854349955035119],
                        [-120.966214218326826, 55.854348631785143],
                        [-120.975550426509983, 55.854344785146367],
                        [-120.975550459454141, 55.85981029466668],
                        [-120.975550466295616, 55.861615487454891],
                        [-120.975550237814673, 55.868890742012958],
                        [-120.97555027736459, 55.876161176129202],
                        [-120.975550271590407, 55.880896658882506],
                        [-120.975550262822125, 55.883431933002676],
                        [-120.988603209606026, 55.883443760601402],
                        [-121.001656691632292, 55.883449860637562],
                        [-121.001657446418719, 55.890720360247869],
                        [-121.001657236988947, 55.897991073790408],
                        [-121.001656812370982, 55.905266071591974],
                        [-121.00165756679371, 55.912536517850278],
                        [-121.014710820523618, 55.912541141452252],
                        [-121.027763805477264, 55.912544266838673],
                        [-121.027763805337415, 55.912546904524504],
                        [-121.02776335523356, 55.919819224913219],
                        [-121.027763543219493, 55.922774782631237],
                        [-121.027763808309146, 55.927089624474519],
                        [-121.027763243211041, 55.934359969968028],
                        [-121.027763427854154, 55.94163486766665],
                        [-121.040816462817446, 55.941636817066588],
                        [-121.053869497918072, 55.941637213414488],
                        [-121.053869760921501, 55.94890737598574],
                        [-121.053869970562417, 55.956182304808273],
                        [-121.066923031923096, 55.956181286420225],
                        [-121.079976091597629, 55.95617898295648],
                        [-121.07997611246806, 55.963449445521995],
                        [-121.079976079110423, 55.970724136568037],
                        [-121.07997609855407, 55.976863352907834],
                        [-121.079976099735688, 55.977994545878914],
                        [-121.079976119827279, 55.984927585821616],
                        [-121.079976113797343, 55.98526923762995],
                        [-121.093029954630097, 55.985265468064227],
                        [-121.100278761909081, 55.985262656365293],
                        [-121.106082650897136, 55.985260097854294],
                        [-121.106082757017717, 55.985260097805003],
                        [-121.10608277236598, 55.992530301672467],
                        [-121.106082519420482, 55.999805271815347],
                        [-121.106083016319403, 56.00383144254225],
                        [-121.106083408537245, 56.00707539296198],
                        [-121.106083311095929, 56.008370236720353],
                        [-121.106082848522746, 56.014349683504321],
                        [-121.106082848496868, 56.014350010694045],
                        [-121.11910238196819, 56.01434267461287],
                        [-121.132121198577678, 56.014338556430154],
                        [-121.132121967516596, 56.014338556425521],
                        [-121.132121620384481, 56.021608679979238],
                        [-121.132121273121967, 56.028878809863208],
                        [-121.132121407568448, 56.036153436190915],
                        [-121.132121060057486, 56.043423565879827],
                        [-121.132121194254907, 56.050698192912108],
                        [-121.132120632451702, 56.057968321503623],
                        [-121.119101321080279, 56.057976914229222],
                        [-121.106082276254497, 56.057979937596592],
                        [-121.106081871841454, 56.050709604729228],
                        [-121.106081467507508, 56.043434986572912],
                        [-121.09302889839114, 56.043440535553792],
                        [-121.079975285795129, 56.043444539135656],
                        [-121.079974879373779, 56.036169929772484],
                        [-121.079975007738284, 56.02889960461242],
                        [-121.066921614315618, 56.028902026926907],
                        [-121.053868008778707, 56.028903218138247],
                        [-121.053869308425462, 56.021628608645891],
                        [-121.053868413342798, 56.014358229334036],
                        [-121.053868267281118, 56.007088117935261],
                        [-121.053868602222892, 55.999813293178647],
                        [-121.040815680227155, 55.999812985649484],
                        [-121.02776206316959, 55.999811178110377],
                        [-121.027762843428889, 55.992540780446916],
                        [-121.027762926742696, 55.985266150529796],
                        [-121.014709013096549, 55.985262876107392],
                        [-121.001655420655709, 55.985258369709918],
                        [-121.001656011849789, 55.977988001345885],
                        [-121.001656067109636, 55.970713135457082],
                        [-121.001656658053363, 55.96344282175378],
                        [-121.001656445061897, 55.956167955664782],
                        [-120.988602693755226, 55.956162059962736],
                        [-120.975549960594066, 55.956154879036482],
                        [-120.962527214318726, 55.956164728613444],
                        [-120.949504684214517, 55.956168793927105],
                        [-120.936452218647659, 55.956175376042026],
                        [-120.923398950314962, 55.956175959865007],
                        [-120.910345383464644, 55.956179791520455],
                        [-120.897291850703624, 55.956182057014558],
                        [-120.884238533225556, 55.956178646208329],
                        [-120.871185000738052, 55.956178181543443],
                        [-120.870985332583103, 55.956059055455718],
                        [-120.856900424014853, 55.956055138336012],
                        [-120.845078365507504, 55.956050743840628],
                        [-120.844878580427959, 55.956050659830289],
                        [-120.845079237969145, 55.934352804073882],
                        [-120.845079062363908, 55.927082058560877],
                        [-120.845079209260433, 55.919807083141293],
                        [-120.845079758572922, 55.912536467037604],
                        [-120.845079591476292, 55.90526557256338],
                        [-120.845079424441167, 55.897990447642663],
                        [-120.858132910567818, 55.897998061530316],
                        [-120.871186933015764, 55.898000159117338],
                        [-120.87118648220742, 55.890729300786404],
                        [-120.871186514401145, 55.883454104085395],
                        [-120.871187080492632, 55.876183459603325],
                        [-120.871187647372125, 55.868912547028266],
                        [-120.858133870078319, 55.868906200372244],
                        [-120.845079933557315, 55.868902852976369],
                        [-120.845080461806234, 55.861632225016166],
                        [-120.845081044098976, 55.854356832418887],
                        [-120.858133999524497, 55.854364660535069],
                        [-120.871186958423451, 55.85436670614974],
                        [-120.871187141891568, 55.854366706167866],
                        [-120.884240183660097, 55.854367304827676],
                        [-120.897293141752115, 55.8543708488072],
                        [-120.897293141833487, 55.854372071800938],
                        [-120.897293608639046, 55.86164151210518],
                        [-120.89729380739179, 55.868912442400593],
                        [-120.910346780181001, 55.868914558858002],
                        [-120.923400004318651, 55.86891084026157],
                        [-120.923400753390908, 55.861640162434007],
                        [-120.923400538420182, 55.854364984328221],
                        [-120.910347331332304, 55.854368664218661],
                        [-120.897293444831377, 55.854370848772575],
                      ],
                      [
                        [-120.89729254414847, 55.919820669862744],
                        [-120.897292470180133, 55.927091190984477],
                        [-120.91034598353491, 55.927093326749819],
                        [-120.923399749967786, 55.92708945835215],
                        [-120.936452529893558, 55.927084347910061],
                        [-120.949505255780238, 55.927082504664931],
                        [-120.949505585810243, 55.919807452490559],
                        [-120.949505655068123, 55.912536767129922],
                        [-120.949505903454025, 55.90526634522957],
                        [-120.949506151881778, 55.897991103792961],
                        [-120.936452918235091, 55.897997524325874],
                        [-120.923399951793925, 55.898002658880031],
                        [-120.91034642663378, 55.89800179373848],
                        [-120.897293421839152, 55.898004194365399],
                        [-120.897293673953698, 55.905274856178565],
                        [-120.897292372608987, 55.912550016243479],
                        [-120.89729254414847, 55.919820669862744],
                      ],
                    ],
                  },
                },
                {
                  type: 'Feature',
                  properties: {
                    GM_LAYER: 'Unknown Area Type',
                    GM_TYPE: 'Unknown Area Type',
                    NAME: 'Shell 100%',
                    LAYER: 'Unknown Area Type',
                    OBJECTID: 0,
                    WORKING_IN: 'Shell 100%',
                    LABEL: 'Shell 100%',
                    ASSET: 'Groundbirch',
                    LAST_UPDAT: 'April 2021',
                    GIS_CONTAC: 'Sheena.Ryall@Shell.com',
                    LANDMAN_CO: 'Mark.McKay@shell.com',
                    COMMENTS: null,
                  },
                  geometry: {
                    type: 'Polygon',
                    coordinates: [
                      [
                        [-120.987811015029365, 55.737770076015593],
                        [-121.000747927059678, 55.737774630661733],
                        [-121.000748336724541, 55.744575758442465],
                        [-121.000748363980904, 55.745045449022371],
                        [-121.000748301167619, 55.745631063658337],
                        [-121.000747519860852, 55.752320696378639],
                        [-121.000747787788768, 55.757967950172699],
                        [-121.000747861235695, 55.759591411725964],
                        [-121.000747613724869, 55.761521521110375],
                        [-121.000746916321134, 55.766866679326576],
                        [-121.000746065780788, 55.766866679071654],
                        [-120.987810215801957, 55.766862121596603],
                        [-120.976951262544688, 55.766857307461201],
                        [-120.974873569343245, 55.766856276989266],
                        [-120.961936172943297, 55.766848988119861],
                        [-120.948999257670394, 55.76684491077318],
                        [-120.94899925771675, 55.766844416216301],
                        [-120.948999922615926, 55.759569430981699],
                        [-120.94899940900072, 55.752970101056341],
                        [-120.948999355234207, 55.75229871478416],
                        [-120.948999449982168, 55.751357357695476],
                        [-120.948999995578205, 55.745023421230449],
                        [-120.949000063595946, 55.73775354025004],
                        [-120.949000063604032, 55.737752399607039],
                        [-120.961793800877345, 55.737761147222869],
                        [-120.961936975368545, 55.737761233283756],
                        [-120.961937300014682, 55.737761231326829],
                        [-120.974874103088808, 55.737764024045717],
                        [-120.987811015029365, 55.737770076015593],
                      ],
                    ],
                  },
                },
                {
                  type: 'Feature',
                  properties: {
                    GM_LAYER: 'Unknown Area Type',
                    GM_TYPE: 'Unknown Area Type',
                    NAME: 'Shell 100%',
                    LAYER: 'Unknown Area Type',
                    OBJECTID: 0,
                    WORKING_IN: 'Shell 100%',
                    LABEL: 'Shell 100%',
                    ASSET: 'Groundbirch',
                    LAST_UPDAT: 'April 2021',
                    GIS_CONTAC: 'Sheena.Ryall@Shell.com',
                    LANDMAN_CO: 'Mark.McKay@shell.com',
                    COMMENTS: null,
                  },
                  geometry: {
                    type: 'Polygon',
                    coordinates: [
                      [
                        [-120.713995291287119, 55.579136390765434],
                        [-120.713995265696354, 55.574969625859389],
                        [-120.707744920875285, 55.574969856545088],
                        [-120.70149463003284, 55.574969766183095],
                        [-120.695244338304448, 55.574969996891618],
                        [-120.688994045695551, 55.574969906552475],
                        [-120.682743754898425, 55.574970137284069],
                        [-120.676493410160361, 55.574970046967849],
                        [-120.670243118495833, 55.574970277722471],
                        [-120.663992851425306, 55.574970212755794],
                        [-120.663992846528657, 55.570803435663336],
                        [-120.66399284159327, 55.566636659437989],
                        [-120.663992837517654, 55.562469937140143],
                        [-120.663992832504704, 55.558303159950952],
                        [-120.670243091962163, 55.558303279836828],
                        [-120.676493372096019, 55.55830305460568],
                        [-120.682743707101864, 55.558303151344667],
                        [-120.688993987267466, 55.558302927035882],
                        [-120.695244269244967, 55.558303023797848],
                        [-120.701494549442472, 55.558302799512035],
                        [-120.707744830552855, 55.558302896296837],
                        [-120.713995164742286, 55.558302672033967],
                        [-120.713995139856507, 55.554135906967126],
                        [-120.71399511403267, 55.549969140968848],
                        [-120.713995088169668, 55.54580242979717],
                        [-120.713995009207693, 55.541635664633951],
                        [-120.720245332779314, 55.54163576786425],
                        [-120.726495603308422, 55.541635549147955],
                        [-120.732745819892145, 55.541635653300467],
                        [-120.738996143513745, 55.541635434607223],
                        [-120.738996107840848, 55.537468728894538],
                        [-120.738996071230147, 55.533301970989072],
                        [-120.73899603548, 55.529135211252751],
                        [-120.738995998791538, 55.524968505443596],
                        [-120.745246204775967, 55.524968616041612],
                        [-120.751496518022165, 55.524968403828744],
                        [-120.757746806772616, 55.52496851785186],
                        [-120.763997040680579, 55.524968310828321],
                        [-120.763997137829335, 55.529135067405257],
                        [-120.763997180979516, 55.533301769990594],
                        [-120.763997224989993, 55.537468524704465],
                        [-120.763997268961973, 55.541635227225356],
                        [-120.763997311995738, 55.545801983673471],
                        [-120.76399735589014, 55.549968686129894],
                        [-120.763997452806322, 55.55413544071493],
                        [-120.763997442662898, 55.55830219706634],
                        [-120.770247791995871, 55.558302311140402],
                        [-120.776498087385704, 55.558302104167531],
                        [-120.782748383690347, 55.558302164305005],
                        [-120.788998680011574, 55.558302010415204],
                        [-120.788998727084461, 55.562468712789006],
                        [-120.788998775017888, 55.566635468190633],
                        [-120.788998875973093, 55.570802170500066],
                        [-120.788998869868578, 55.574968925837197],
                        [-120.788998970746022, 55.579135682041588],
                        [-120.788999017092124, 55.583302383675374],
                        [-120.788998876114704, 55.587469242034544],
                        [-120.788998734198699, 55.59163610036164],
                        [-120.788998646205073, 55.595802903797377],
                        [-120.788998505110328, 55.599969762060262],
                        [-120.788998417037305, 55.604136620291001],
                        [-120.788998221904976, 55.608303425429135],
                        [-120.788998133753751, 55.612470282696265],
                        [-120.788997991602997, 55.616637140830775],
                        [-120.78899785031345, 55.620803945872467],
                        [-120.78899770808431, 55.62497080394278],
                        [-120.782747376190471, 55.624971024257157],
                        [-120.776497098280387, 55.624970923521005],
                        [-120.770246713357693, 55.624971143858609],
                        [-120.763996382419194, 55.624971043145308],
                        [-120.757746049690397, 55.624971263505877],
                        [-120.751495718784469, 55.624971162815406],
                        [-120.746899383790151, 55.624971373442108],
                        [-120.74524536300504, 55.624971407152621],
                        [-120.741935096840749, 55.624971406301242],
                        [-120.738995007067999, 55.624971330620873],
                        [-120.737524128679823, 55.624971416830839],
                        [-120.732744598076778, 55.624971575163578],
                        [-120.726494243071897, 55.624971498654673],
                        [-120.720243887173751, 55.624971743220051],
                        [-120.713993478241051, 55.624971666734368],
                        [-120.713993344239142, 55.624971666743001],
                        [-120.707743122374865, 55.624971910423469],
                        [-120.701492767435298, 55.624971834859643],
                        [-120.695242411598741, 55.624972133431349],
                        [-120.688992001831949, 55.624972056991304],
                        [-120.688992221263916, 55.620805180199845],
                        [-120.688992437958646, 55.616638303376412],
                        [-120.688992657309825, 55.6124714795816],
                        [-120.688992821764316, 55.608304603593389],
                        [-120.688993038341181, 55.604137726673557],
                        [-120.688993257576797, 55.599970849721586],
                        [-120.688993474073129, 55.595804026697678],
                        [-120.688993638370746, 55.591637150580993],
                        [-120.695243948064032, 55.591637235575867],
                        [-120.701494309947094, 55.591636999518727],
                        [-120.707744618773688, 55.591637084536444],
                        [-120.713994926728276, 55.591636848502525],
                        [-120.713995121440561, 55.587469974832118],
                        [-120.713995315214362, 55.583303102029006],
                        [-120.713995291287119, 55.579136390765434],
                      ],
                    ],
                  },
                },
                {
                  type: 'Feature',
                  properties: {
                    GM_LAYER: 'Unknown Area Type',
                    GM_TYPE: 'Unknown Area Type',
                    NAME: 'Shell 100%',
                    LAYER: 'Unknown Area Type',
                    OBJECTID: 0,
                    WORKING_IN: 'Shell 100%',
                    LABEL: 'Shell 100%',
                    ASSET: 'Groundbirch',
                    LAST_UPDAT: 'April 2021',
                    GIS_CONTAC: 'Sheena.Ryall@Shell.com',
                    LANDMAN_CO: 'Mark.McKay@shell.com',
                    COMMENTS: null,
                  },
                  geometry: {
                    type: 'Polygon',
                    coordinates: [
                      [
                        [-120.780778318747437, 55.737731516513129],
                        [-120.793730607063594, 55.737731188971573],
                        [-120.793730165697738, 55.745002240861588],
                        [-120.793729779554695, 55.752273263532814],
                        [-120.793728845119333, 55.752273263946016],
                        [-120.780778689927786, 55.752278307687071],
                        [-120.767826371442908, 55.752281906700105],
                        [-120.76782682964587, 55.745006113144321],
                        [-120.767827046934372, 55.737823012703473],
                        [-120.767827050276736, 55.737735057401494],
                        [-120.780778318747437, 55.737731516513129],
                      ],
                    ],
                  },
                },
                {
                  type: 'Feature',
                  properties: {
                    GM_LAYER: 'Unknown Area Type',
                    GM_TYPE: 'Unknown Area Type',
                    NAME: 'Shell 100%',
                    LAYER: 'Unknown Area Type',
                    OBJECTID: 0,
                    WORKING_IN: 'Shell 100%',
                    LABEL: 'Shell 100%',
                    ASSET: 'Groundbirch',
                    LAST_UPDAT: 'April 2021',
                    GIS_CONTAC: 'Sheena.Ryall@Shell.com',
                    LANDMAN_CO: 'Mark.McKay@shell.com',
                    COMMENTS: null,
                  },
                  geometry: {
                    type: 'Polygon',
                    coordinates: [
                      [
                        [-121.311294740825943, 55.774152306531363],
                        [-121.311295425390171, 55.766877526699233],
                        [-121.298360162009814, 55.766874144320653],
                        [-121.285424146958292, 55.766869475038398],
                        [-121.285424679530678, 55.759594683501383],
                        [-121.285425961943659, 55.752324390312708],
                        [-121.285425436318647, 55.745049380635777],
                        [-121.285424596892611, 55.737779161634812],
                        [-121.272489160095972, 55.73777298719169],
                        [-121.259553722406352, 55.737765527679834],
                        [-121.259552835062692, 55.730490603033289],
                        [-121.259551678798957, 55.723220391606951],
                        [-121.272487432195291, 55.723227790286565],
                        [-121.285422918510903, 55.723233956953734],
                        [-121.298358672833444, 55.723238891613342],
                        [-121.311294372330039, 55.723242328060792],
                        [-121.324230126729319, 55.723244478534546],
                        [-121.337165903759995, 55.723245380233713],
                        [-121.33716616738559, 55.730520111763461],
                        [-121.337166430892154, 55.737790346595347],
                        [-121.350102542206841, 55.737789402069566],
                        [-121.363038439560782, 55.73778749262123],
                        [-121.363038688305835, 55.745061957781715],
                        [-121.363038972130155, 55.75233221219743],
                        [-121.363038255233974, 55.759607007091617],
                        [-121.363038502126486, 55.766877303455978],
                        [-121.363037731008717, 55.774151884110289],
                        [-121.363038245650017, 55.781422181178918],
                        [-121.350102424181699, 55.78142430644133],
                        [-121.337165904858736, 55.781425147503526],
                        [-121.324230454562496, 55.781424513457424],
                        [-121.311294324080777, 55.781422588741201],
                        [-121.311294740825943, 55.774152306531363],
                      ],
                    ],
                  },
                },
                {
                  type: 'Feature',
                  properties: {
                    GM_LAYER: 'Unknown Area Type',
                    GM_TYPE: 'Unknown Area Type',
                    NAME: 'Shell 80% / PetroChina 20%',
                    LAYER: 'Unknown Area Type',
                    OBJECTID: 0,
                    WORKING_IN: 'Shell 80% / PetroChina 20%',
                    LABEL: 'Shell 80% / PetroChina 20%',
                    ASSET: 'Groundbirch',
                    LAST_UPDAT: 'Aug 5, 2021',
                    GIS_CONTAC: 'Sheena.Ryall@Shell.com',
                    LANDMAN_CO: 'Mark.McKay@shell.com',
                    COMMENTS: null,
                  },
                  geometry: {
                    type: 'Polygon',
                    coordinates: [
                      [
                        [-121.327842050101182, 56.014354259608325],
                        [-121.340895549649318, 56.014359888403774],
                        [-121.340895321373964, 56.021629714174267],
                        [-121.340895092832611, 56.028904039058141],
                        [-121.327841574134666, 56.02889845715903],
                        [-121.314788237835245, 56.028895902879519],
                        [-121.314788497378501, 56.021621586429639],
                        [-121.314788488638285, 56.014351716028514],
                        [-121.327842050101182, 56.014354259608325],
                      ],
                    ],
                  },
                },
                {
                  type: 'Feature',
                  properties: {
                    GM_LAYER: 'Unknown Area Type',
                    GM_TYPE: 'Unknown Area Type',
                    NAME: 'Shell 80% / PetroChina 20%',
                    LAYER: 'Unknown Area Type',
                    OBJECTID: 0,
                    WORKING_IN: 'Shell 80% / PetroChina 20%',
                    LABEL: 'Shell 80% / PetroChina 20%',
                    ASSET: 'Groundbirch',
                    LAST_UPDAT: 'Aug 5, 2021',
                    GIS_CONTAC: 'Sheena.Ryall@Shell.com',
                    LANDMAN_CO: 'Mark.McKay@shell.com',
                    COMMENTS: null,
                  },
                  geometry: {
                    type: 'Polygon',
                    coordinates: [
                      [
                        [-120.71462274397436, 55.985267050126915],
                        [-120.714622330130297, 55.977991896090295],
                        [-120.714622931298393, 55.970721240154816],
                        [-120.71462311813319, 55.956175430691808],
                        [-120.727676712814358, 55.956178684630068],
                        [-120.74072928913435, 55.956180654458791],
                        [-120.740729145542176, 55.94890546480174],
                        [-120.740729483658882, 55.941634773263516],
                        [-120.727676636149141, 55.941632839555041],
                        [-120.714622769621414, 55.941634119036301],
                        [-120.71462310353823, 55.934363248582656],
                        [-120.714623437532353, 55.927088039906693],
                        [-120.71462377119569, 55.919817384184419],
                        [-120.714622806527146, 55.912546747655647],
                        [-120.714623234270348, 55.905271269233417],
                        [-120.714623233671162, 55.898000554238138],
                        [-120.714623178990507, 55.89072984004504],
                        [-120.714622910155441, 55.883458858667687],
                        [-120.714624087448854, 55.876183646039983],
                        [-120.714624086371003, 55.868912717524019],
                        [-120.714623763204301, 55.861642003841773],
                        [-120.714623762017197, 55.85436652294139],
                        [-120.714624456654207, 55.847095808159509],
                        [-120.714624187092269, 55.839824825297242],
                        [-120.714623853584115, 55.83255408313871],
                        [-120.714623743401177, 55.825278383824362],
                        [-120.72767743251876, 55.825282084298543],
                        [-120.740730369950853, 55.825279787260591],
                        [-120.753783077211594, 55.825280689915431],
                        [-120.766836972269274, 55.82528035186894],
                        [-120.779890385478708, 55.825273695273239],
                        [-120.792943530707802, 55.825270304159297],
                        [-120.805958653375583, 55.82526493226819],
                        [-120.818974098267176, 55.825253831793979],
                        [-120.818973893463095, 55.83252927488369],
                        [-120.818973600462996, 55.839800138294549],
                        [-120.818974529301627, 55.847070991152997],
                        [-120.818973957732283, 55.854346074248156],
                        [-120.832028060623983, 55.854352025626341],
                        [-120.845081044098976, 55.854356832418887],
                        [-120.845080461806234, 55.861632225016166],
                        [-120.845079933557315, 55.868902852976369],
                        [-120.858133870078319, 55.868906200372244],
                        [-120.871187647372125, 55.868912547028266],
                        [-120.871187080492632, 55.876183459603325],
                        [-120.871186514401145, 55.883454104085395],
                        [-120.87118648220742, 55.890729300786404],
                        [-120.871186933015764, 55.898000159117338],
                        [-120.858132910567818, 55.897998061530316],
                        [-120.845079424441167, 55.897990447642663],
                        [-120.845079591476292, 55.90526557256338],
                        [-120.845079758572922, 55.912536467037604],
                        [-120.845079209260433, 55.919807083141293],
                        [-120.845079062363908, 55.927082058560877],
                        [-120.845079237969145, 55.934352804073882],
                        [-120.845078340550401, 55.941623282386061],
                        [-120.8450782467946, 55.948898312369934],
                        [-120.845078365507504, 55.956050743840628],
                        [-120.845078367186218, 55.956168790050938],
                        [-120.845078273411886, 55.963439267631337],
                        [-120.845078179288336, 55.970714297322772],
                        [-120.845078084361347, 55.977984934781944],
                        [-120.845078031294733, 55.977985041796629],
                        [-120.845078031283975, 55.977986061802568],
                        [-120.845077935757445, 55.985255519783486],
                        [-120.832024897372364, 55.985251046757888],
                        [-120.818971806365809, 55.985245065131664],
                        [-120.818971698448351, 55.985245010273005],
                        [-120.805956039875397, 55.985252042126326],
                        [-120.792940329295277, 55.985257520050254],
                        [-120.779887666124665, 55.985261017073334],
                        [-120.7668352166924, 55.985263014136471],
                        [-120.753782286485929, 55.985268277443275],
                        [-120.74072868345074, 55.985267648574677],
                        [-120.727676329335608, 55.985265876304254],
                        [-120.71462274397436, 55.985267050126915],
                      ],
                    ],
                  },
                },
                {
                  type: 'Feature',
                  properties: {
                    GM_LAYER: 'Unknown Area Type',
                    GM_TYPE: 'Unknown Area Type',
                    NAME: 'Shell 80% / PetroChina 20%',
                    LAYER: 'Unknown Area Type',
                    OBJECTID: 0,
                    WORKING_IN: 'Shell 80% / PetroChina 20%',
                    LABEL: 'Shell 80% / PetroChina 20%',
                    ASSET: 'Groundbirch',
                    LAST_UPDAT: 'Aug 5, 2021',
                    GIS_CONTAC: 'Sheena.Ryall@Shell.com',
                    LANDMAN_CO: 'Mark.McKay@shell.com',
                    COMMENTS: null,
                  },
                  geometry: {
                    type: 'MultiPolygon',
                    coordinates: [
                      [
                        [
                          [-120.76782884551757, 55.650461449381091],
                          [-120.754892918643293, 55.65046910829107],
                          [-120.741955020380317, 55.650469172823215],
                          [-120.729019007473781, 55.650473063870407],
                          [-120.716081086752624, 55.650473140452718],
                          [-120.716081329843789, 55.643202215483356],
                          [-120.716081573922054, 55.635931288528006],
                          [-120.716075920480691, 55.633875134853632],
                          [-120.713993041724891, 55.633886590579131],
                          [-120.713993062715986, 55.633306747260896],
                          [-120.713993202885376, 55.629141032615742],
                          [-120.713993345008333, 55.624971666350824],
                          [-120.713993345008646, 55.624971502764737],
                          [-120.72024387154373, 55.624971472791955],
                          [-120.726494397821156, 55.624971442251741],
                          [-120.732744923867457, 55.624971411145822],
                          [-120.737524128761493, 55.624971417006527],
                          [-120.738995451192835, 55.624971381301108],
                          [-120.741935096069938, 55.624971406607735],
                          [-120.745244070516733, 55.624971350876621],
                          [-120.746899383059812, 55.624971372983723],
                          [-120.751494595747204, 55.624971320155289],
                          [-120.757745098402495, 55.624971313760909],
                          [-120.763995600910064, 55.62497130770754],
                          [-120.763995500836558, 55.629137013075734],
                          [-120.763995400139407, 55.633306533232705],
                          [-120.765673894426413, 55.633588821851284],
                          [-120.767836719726589, 55.633573560787653],
                          [-120.767836872304272, 55.633573559969292],
                          [-120.76783109486766, 55.635915815105768],
                          [-120.767830923380075, 55.643190539466332],
                          [-120.767830612559393, 55.644283112161709],
                          [-120.76782884551757, 55.650461449381091],
                        ],
                      ],
                      [
                        [
                          [-121.052484775796131, 55.803320921619481],
                          [-121.052496541310219, 55.795954732634542],
                          [-121.065434358114715, 55.795950965667252],
                          [-121.07837026646213, 55.795947198082168],
                          [-121.078364475775629, 55.802933146525262],
                          [-121.078364159144101, 55.803313401926395],
                          [-121.07835614662585, 55.810681392398159],
                          [-121.078356143946976, 55.810683419853227],
                          [-121.06692522001461, 55.810687180177155],
                          [-121.065414576836346, 55.810687172856987],
                          [-121.054940012128, 55.810687168613107],
                          [-121.053871117935685, 55.810687117595329],
                          [-121.05247110125822, 55.810687111065334],
                          [-121.052484775796131, 55.803320921619481],
                        ],
                      ],
                      [
                        [
                          [-121.158679271225452, 55.970563609449577],
                          [-121.158227593300651, 55.970721997400943],
                          [-121.158227600118892, 55.970565917454572],
                          [-121.158227600306091, 55.970563959256147],
                          [-121.158679271225452, 55.970563609449577],
                        ],
                      ],
                      [
                        [
                          [-121.158227593300651, 55.970721997400943],
                          [-121.159031903837814, 55.977973705065281],
                          [-121.160295540078906, 55.980066812160359],
                          [-121.163171707113477, 55.982326915428487],
                          [-121.171893070030748, 55.984935797905322],
                          [-121.18433531252019, 55.98527142483524],
                          [-121.184335573693289, 55.978000637440807],
                          [-121.184335567482563, 55.977971377717445],
                          [-121.185034153411436, 55.97797132693173],
                          [-121.185034153555193, 55.977973888128673],
                          [-121.197343695451224, 55.978013709868186],
                          [-121.203782298121951, 55.977924280960671],
                          [-121.203782297734122, 55.981526690401843],
                          [-121.210243597547077, 55.981551282782334],
                          [-121.210441573574315, 55.98527545211428],
                          [-121.223495658795485, 55.985271742727022],
                          [-121.236547837130004, 55.985271848995325],
                          [-121.236547637124175, 55.992546474758448],
                          [-121.236549345230188, 55.999813471455091],
                          [-121.236547080470956, 56.007087928077695],
                          [-121.236546720395097, 56.01435856648807],
                          [-121.223494513691193, 56.014362287588852],
                          [-121.210440400879094, 56.014366009256428],
                          [-121.21044195169496, 56.021636672929809],
                          [-121.210441594778132, 56.028903522246708],
                          [-121.210441238768738, 56.036178000320646],
                          [-121.210440880962878, 56.043448663854051],
                          [-121.210440524210043, 56.050723141416519],
                          [-121.210440165700717, 56.057993804448842],
                          [-121.197386065200874, 56.057993787952029],
                          [-121.1843338745701, 56.057993771586169],
                          [-121.184334227071815, 56.050719267823595],
                          [-121.184334580742473, 56.043448579944467],
                          [-121.184334933265987, 56.036174075732433],
                          [-121.184333378740291, 56.028903387168477],
                          [-121.184335639459249, 56.021628883643757],
                          [-121.184334084551224, 56.014362009379703],
                          [-121.171281879457993, 56.014358101976939],
                          [-121.158227698525351, 56.014350327440035],
                          [-121.145175402897067, 56.014342538052368],
                          [-121.13212196828573, 56.01433855635414],
                          [-121.13212119819984, 56.01433855636251],
                          [-121.119101328527734, 56.014342216068449],
                          [-121.106082848965357, 56.014349683169733],
                          [-121.106081457937123, 56.014349684254952],
                          [-121.106083310918379, 56.008370237004918],
                          [-121.106083709648345, 56.007075187973022],
                          [-121.106083016331212, 56.003831442247204],
                          [-121.106082145447957, 55.999804504958874],
                          [-121.106082398671305, 55.992529923601843],
                          [-121.106082651157095, 55.985260097421111],
                          [-121.106082650618262, 55.985259158296209],
                          [-121.100278761523768, 55.985262656127709],
                          [-121.093030339754065, 55.985266641279694],
                          [-121.079976107156, 55.985270263217942],
                          [-121.079976113127657, 55.985269237917258],
                          [-121.079976120168254, 55.984927585948597],
                          [-121.079976354631924, 55.977995692228717],
                          [-121.079976099180541, 55.976863352850799],
                          [-121.079974695620606, 55.970724937275975],
                          [-121.079974943175088, 55.963450366023594],
                          [-121.079975189704712, 55.956179610881158],
                          [-121.066922889231634, 55.95618321157685],
                          [-121.053868681885731, 55.95618299777378],
                          [-121.053868954553849, 55.948908458992534],
                          [-121.053869226593022, 55.941637732771305],
                          [-121.040815044418622, 55.941637550890746],
                          [-121.027762768513469, 55.941637368306502],
                          [-121.027763066562329, 55.934359046598246],
                          [-121.027763363623507, 55.927092166458152],
                          [-121.027763543155132, 55.922774782907908],
                          [-121.027763660679682, 55.919821473550492],
                          [-121.027763894565894, 55.912546904192631],
                          [-121.027763804938374, 55.912546904553587],
                          [-121.014709762255933, 55.912542963225647],
                          [-121.001657538446196, 55.912539023194938],
                          [-121.001655839469151, 55.905268228783811],
                          [-121.001656049909016, 55.897993621089611],
                          [-121.001656258604555, 55.890722826992935],
                          [-121.001656468219409, 55.883452033306881],
                          [-120.988602375781184, 55.883444302046897],
                          [-120.975550188127329, 55.883432756557021],
                          [-120.975550272074742, 55.880896658592391],
                          [-120.975550416399841, 55.876161974979496],
                          [-120.975550645201466, 55.868891192933624],
                          [-120.97555087336643, 55.861616595331753],
                          [-120.975550459040946, 55.859810294380296],
                          [-120.975549193796681, 55.854345813264814],
                          [-120.966214218254493, 55.854348631771238],
                          [-120.962529471622318, 55.85434954980672],
                          [-120.960726271120507, 55.854351205661857],
                          [-120.949505932903946, 55.854360916161347],
                          [-120.949507382474252, 55.84948123365222],
                          [-120.949508088174483, 55.847090145335827],
                          [-120.949507616649285, 55.845010548632366],
                          [-120.949506428864254, 55.839812459454777],
                          [-120.949506428175624, 55.839811744955227],
                          [-120.942671829391017, 55.839815878254569],
                          [-120.936454298236313, 55.839819308359999],
                          [-120.929177808859649, 55.839821568813605],
                          [-120.923400262032914, 55.839823057204299],
                          [-120.91443251073683, 55.839823165953604],
                          [-120.910348101797126, 55.839823002740459],
                          [-120.897294458014343, 55.839826771899332],
                          [-120.897294010818584, 55.839826771442588],
                          [-120.897293727823723, 55.847097514666473],
                          [-120.897293627170868, 55.849797070411896],
                          [-120.89729344459036, 55.854370848537435],
                          [-120.897293445569574, 55.854372071729514],
                          [-120.89729314197379, 55.854372071931358],
                          [-120.88423934054741, 55.854368193575816],
                          [-120.871187142627107, 55.854368129593894],
                          [-120.871187142256517, 55.854366705989221],
                          [-120.87118744044524, 55.847097404960017],
                          [-120.871185831043888, 55.839822865052966],
                          [-120.871188027395519, 55.832552135012769],
                          [-120.871186324758739, 55.825281365942182],
                          [-120.871186529501912, 55.817983892723177],
                          [-120.871188642488889, 55.810682605485127],
                          [-120.871213173001436, 55.809568644802233],
                          [-120.871350976388513, 55.803308838378399],
                          [-120.87135118357206, 55.795935071932263],
                          [-120.858415395646659, 55.795935069276659],
                          [-120.848976513252552, 55.795932418697724],
                          [-120.845478077679005, 55.795931252251243],
                          [-120.84547770276815, 55.795931251970991],
                          [-120.845477922335448, 55.78865668068164],
                          [-120.845478141641053, 55.781385923351834],
                          [-120.840946110974016, 55.781384746162786],
                          [-120.832540398529332, 55.781382132556928],
                          [-120.819604260182317, 55.781378406570973],
                          [-120.819604465222355, 55.774103833535563],
                          [-120.819604668677798, 55.76683307449705],
                          [-120.806666586657641, 55.766829336237947],
                          [-120.793730413242827, 55.766821782480321],
                          [-120.793728676006992, 55.759551012954368],
                          [-120.793728845253014, 55.752273264250476],
                          [-120.793728845358714, 55.752272613541898],
                          [-120.767945275198983, 55.752161948414276],
                          [-120.767827047248687, 55.737823012605787],
                          [-120.767826320283277, 55.737734849552595],
                          [-120.767826540359593, 55.73046398052935],
                          [-120.767826758642514, 55.723189297635052],
                          [-120.767826758785745, 55.72318817953915],
                          [-120.767826979020143, 55.715918429891119],
                          [-120.767827198008504, 55.708647561860879],
                          [-120.754891113726046, 55.708647613606175],
                          [-120.741953213565097, 55.708655290722163],
                          [-120.741953450117833, 55.701376795730383],
                          [-120.741953685366411, 55.694105929500182],
                          [-120.741953920378108, 55.686835063032639],
                          [-120.741954155606692, 55.679560381810731],
                          [-120.754892082644929, 55.679560316520366],
                          [-120.767829980141244, 55.679552643224852],
                          [-120.767828292661534, 55.672281774248681],
                          [-120.767828500801158, 55.665010896709589],
                          [-120.767830580148498, 55.657736172822133],
                          [-120.76782884551757, 55.650461449381091],
                          [-120.767830055193755, 55.650461448920694],
                          [-120.770214914425011, 55.650461546327612],
                          [-120.780781936642967, 55.650461418767897],
                          [-120.791803260214579, 55.650454987446473],
                          [-120.793733117431771, 55.65045375951297],
                          [-120.793733118202894, 55.650454226004079],
                          [-120.793732971901576, 55.657732288675192],
                          [-120.793732820775631, 55.665003187525592],
                          [-120.806670675209375, 55.665010778032304],
                          [-120.819606621728468, 55.66501455295942],
                          [-120.819606495270008, 55.672289235142621],
                          [-120.81960636905518, 55.679563910755014],
                          [-120.832542359317102, 55.679567681609953],
                          [-120.845480027112089, 55.679571494585986],
                          [-120.858415736810812, 55.679571500792719],
                          [-120.871353355371767, 55.679571507166273],
                          [-120.884290974187309, 55.679571514361008],
                          [-120.89722668439029, 55.679567705873367],
                          [-120.910164303210948, 55.679563897902398],
                          [-120.923100028184933, 55.679560098642],
                          [-120.936051036065422, 55.679567760388693],
                          [-120.949000137790989, 55.679571607223032],
                          [-120.949000204968442, 55.672296957878331],
                          [-120.949002185104547, 55.665026117741419],
                          [-120.949002286942502, 55.657755254508913],
                          [-120.949002387885159, 55.6504805773409],
                          [-120.961938163709107, 55.650488224858869],
                          [-120.974875847511782, 55.650492057904813],
                          [-120.974875713806767, 55.657766742620296],
                          [-120.974875580351778, 55.665037612510744],
                          [-120.974875507621576, 55.67231227418641],
                          [-120.974875452217546, 55.679583114751317],
                          [-120.987813107869655, 55.679590775585879],
                          [-121.000750765867068, 55.679590807873062],
                          [-121.00074881392932, 55.686865468643404],
                          [-121.000748770455772, 55.694136316046894],
                          [-121.000750633783042, 55.701410976797526],
                          [-121.000748680492634, 55.70868182260196],
                          [-121.013686544693158, 55.708685659992277],
                          [-121.026626328690242, 55.708685680780683],
                          [-121.02662442478308, 55.715960335031731],
                          [-121.026624427129221, 55.723231176379798],
                          [-121.026624429360538, 55.730505831405253],
                          [-121.026624430975801, 55.737776671645712],
                          [-121.026624434000794, 55.745051326480187],
                          [-121.026624350771598, 55.752322155446649],
                          [-121.026624211148814, 55.756431816835409],
                          [-121.02662409575521, 55.759596778971058],
                          [-121.026623839774757, 55.766866937060499],
                          [-121.026623840349856, 55.766867587747029],
                          [-121.013685899602777, 55.766867567609886],
                          [-121.000746064769373, 55.766867544658858],
                          [-121.000746066055768, 55.766866679203844],
                          [-121.000747612988462, 55.761521520917285],
                          [-121.000748168316179, 55.759592934067598],
                          [-121.000747787888358, 55.75796795034168],
                          [-121.000746454375218, 55.752322138839936],
                          [-121.00074830141557, 55.745631063964581],
                          [-121.000748456691397, 55.74504749459259],
                          [-121.0007483365073, 55.744575758709324],
                          [-121.000746594205367, 55.73777664947395],
                          [-120.987810798806834, 55.737772779779384],
                          [-120.974873095174928, 55.737765095486552],
                          [-120.961937300706921, 55.7377612312427],
                          [-120.961936975638551, 55.737761233037297],
                          [-120.961793801634627, 55.737761147513389],
                          [-120.949000064258499, 55.737753539974278],
                          [-120.948999597488992, 55.737753540266517],
                          [-120.948999528948519, 55.745024374652644],
                          [-120.948999450204525, 55.75135735733658],
                          [-120.94899942388119, 55.752299010509439],
                          [-120.948999408678617, 55.752970101284255],
                          [-120.948999242501046, 55.759569805859634],
                          [-120.948999058337932, 55.766844415851729],
                          [-120.948999257580397, 55.766844416482186],
                          [-120.961934856746367, 55.766848290399011],
                          [-120.97487256267155, 55.766855980268495],
                          [-120.97695126309803, 55.766857307532192],
                          [-120.987810267758519, 55.766863669015962],
                          [-121.000746064769373, 55.766867544658858],
                          [-121.000745870883264, 55.774138339254336],
                          [-121.000747583499532, 55.781409135598722],
                          [-121.013683542678592, 55.78141298508082],
                          [-121.02662142110816, 55.781413019563075],
                          [-121.03955929934834, 55.781413053291224],
                          [-121.052497178249354, 55.781409273916786],
                          [-121.052496859373676, 55.788683910636983],
                          [-121.052496541310219, 55.795954732634542],
                          [-121.039558725875665, 55.795954684112388],
                          [-121.026620910271177, 55.795958451990749],
                          [-121.026616835572582, 55.803320812327989],
                          [-121.026612761494491, 55.810683172847668],
                          [-121.02776481774228, 55.810686993560381],
                          [-121.027764558892045, 55.817984504321537],
                          [-121.027764297942028, 55.825274386138325],
                          [-121.027764883860684, 55.825274386551179],
                          [-121.033297435641543, 55.82527620477461],
                          [-121.040816430809514, 55.8252782769408],
                          [-121.053870472449674, 55.825278353404613],
                          [-121.053870149502941, 55.832552990365542],
                          [-121.053869971646989, 55.839823801135893],
                          [-121.066923987533286, 55.839823888333669],
                          [-121.079976097462065, 55.839820160468314],
                          [-121.079975958597927, 55.847094793291305],
                          [-121.079975819980433, 55.854365611419773],
                          [-121.079977587145279, 55.86164024315503],
                          [-121.079975541597008, 55.8689110617889],
                          [-121.09302966450808, 55.868907346915648],
                          [-121.106083823706868, 55.868903630034474],
                          [-121.106083928994352, 55.861632806298871],
                          [-121.106084035614359, 55.854358167887447],
                          [-121.113246341663967, 55.854356290062789],
                          [-121.119101919055453, 55.854354445027305],
                          [-121.132121709698495, 55.854346902900566],
                          [-121.132121639732475, 55.861617737053635],
                          [-121.132121567219343, 55.868892381890888],
                          [-121.132122974525529, 55.874453129315683],
                          [-121.132123404312452, 55.876163211369658],
                          [-121.132123359853722, 55.881035449762379],
                          [-121.132123331818633, 55.883437855816723],
                          [-121.132123312095104, 55.88583865518072],
                          [-121.132123260171227, 55.890708685587384],
                          [-121.132123188957564, 55.897983329668946],
                          [-121.132123163979216, 55.900920644266854],
                          [-121.132123117078493, 55.905254158162357],
                          [-121.132121136566312, 55.912528802539029],
                          [-121.145175401893965, 55.91253273343078],
                          [-121.158229666007671, 55.912540478912121],
                          [-121.17128194438034, 55.91254820898218],
                          [-121.184335883390744, 55.912552075579491],
                          [-121.195522549224535, 55.912548934275897],
                          [-121.197387913627551, 55.912548312040002],
                          [-121.199472624987408, 55.912549022313343],
                          [-121.210441852727556, 55.912552179205811],
                          [-121.223493883177809, 55.912552230663927],
                          [-121.236547820300842, 55.912552282646338],
                          [-121.236547719815135, 55.919819287393452],
                          [-121.236548991415049, 55.925222824762542],
                          [-121.236549429199727, 55.927093913858585],
                          [-121.236549059872445, 55.92837281569998],
                          [-121.236547323055248, 55.934364725824338],
                          [-121.236547124872899, 55.94163553832589],
                          [-121.223495039070784, 55.941643096270788],
                          [-121.210441048154436, 55.941643025472679],
                          [-121.210440818776249, 55.948910010289474],
                          [-121.210442489083746, 55.956156246689197],
                          [-121.210395914380186, 55.956156243025738],
                          [-121.206040596464888, 55.956155877559972],
                          [-121.197342737966565, 55.95615457872411],
                          [-121.197342685098363, 55.956154578848981],
                          [-121.188631679240757, 55.956152871506461],
                          [-121.184289667877451, 55.956151736806639],
                          [-121.184289560540378, 55.956151737021521],
                          [-121.179945975186385, 55.956151942959494],
                          [-121.175620165496198, 55.956151941824551],
                          [-121.171236223283003, 55.956150695532045],
                          [-121.158923280211269, 55.956146361826214],
                          [-121.158924747691017, 55.956059515486523],
                          [-121.158183156698911, 55.956059166405879],
                          [-121.158183154422602, 55.956146057132294],
                          [-121.158183154145036, 55.956146164070034],
                          [-121.158183155382389, 55.956176602095674],
                          [-121.145175810814678, 55.956168863063489],
                          [-121.132121570537095, 55.95616109977189],
                          [-121.132121834374558, 55.948890327388582],
                          [-121.130122924812923, 55.946153759179772],
                          [-121.125540041556022, 55.943669249058637],
                          [-121.118775263951719, 55.942023062426749],
                          [-121.106082257350153, 55.941626929779567],
                          [-121.105819494831309, 55.943206747456038],
                          [-121.106481214256405, 55.944218833920793],
                          [-121.107436454315192, 55.945021423306578],
                          [-121.110395247899405, 55.947507132794584],
                          [-121.114575321232095, 55.950919121641505],
                          [-121.119184999150519, 55.954883125872804],
                          [-121.122985680899731, 55.956136825417197],
                          [-121.132121570537095, 55.95616109977189],
                          [-121.132121305297716, 55.963435687279073],
                          [-121.137286363135985, 55.967916203106157],
                          [-121.139660119220906, 55.969321448621521],
                          [-121.145498945665111, 55.970684261839693],
                          [-121.158227593300651, 55.970721997400943],
                        ],
                      ],
                    ],
                  },
                },
                {
                  type: 'Feature',
                  properties: {
                    GM_LAYER: 'Unknown Area Type',
                    GM_TYPE: 'Unknown Area Type',
                    NAME: 'Shell 80% / PetroChina 20%',
                    LAYER: 'Unknown Area Type',
                    OBJECTID: 0,
                    WORKING_IN: 'Shell 80% / PetroChina 20%',
                    LABEL: 'Shell 80% / PetroChina 20%',
                    ASSET: 'Groundbirch',
                    LAST_UPDAT: 'Aug 5, 2021',
                    GIS_CONTAC: 'Sheena.Ryall@Shell.com',
                    LANDMAN_CO: 'Mark.McKay@shell.com',
                    COMMENTS: null,
                  },
                  geometry: {
                    type: 'Polygon',
                    coordinates: [
                      [
                        [-120.638991845148666, 55.491635286815644],
                        [-120.64524206370892, 55.491635404898496],
                        [-120.6514922813884, 55.491635201935509],
                        [-120.657742446021246, 55.49163532004129],
                        [-120.663992718591217, 55.491635117101332],
                        [-120.670242890404467, 55.49163523559524],
                        [-120.676493183038914, 55.491635033747556],
                        [-120.682743369566793, 55.491635152968549],
                        [-120.688993662233088, 55.49163495024456],
                        [-120.695243847893479, 55.491635123447637],
                        [-120.701494140591734, 55.491634921645854],
                        [-120.707744326284612, 55.491635040912691],
                        [-120.713994565954422, 55.491634839133674],
                        [-120.720244804739892, 55.491634958423575],
                        [-120.726495044441606, 55.491634756667473],
                        [-120.732745283259291, 55.49163487598026],
                        [-120.738995522992965, 55.491634674247166],
                        [-120.738995624847362, 55.495801366380874],
                        [-120.73899572658712, 55.499968058572307],
                        [-120.73899576350756, 55.504134816735167],
                        [-120.738995799490169, 55.508301522705239],
                        [-120.738995836333245, 55.512468281703072],
                        [-120.738995872238249, 55.516635041568115],
                        [-120.738995962064578, 55.520801746542318],
                        [-120.738995998791538, 55.524968505443596],
                        [-120.732745685801689, 55.524968717714096],
                        [-120.726495479849603, 55.524968608038286],
                        [-120.720245166891758, 55.524968819432473],
                        [-120.713994907012378, 55.524968709779358],
                        [-120.707744648046159, 55.52496892119661],
                        [-120.701494388199137, 55.524968811566303],
                        [-120.695244128365417, 55.524969023006548],
                        [-120.688993869449973, 55.524968913399064],
                        [-120.682743608748879, 55.524969124862274],
                        [-120.676493349865538, 55.52496901527757],
                        [-120.670243090095539, 55.524969226763893],
                        [-120.663992847662769, 55.524969107653625],
                        [-120.663992843200774, 55.520802331073718],
                        [-120.663992837800976, 55.516635554461629],
                        [-120.663992833261233, 55.512468830877559],
                        [-120.66399282778417, 55.508302054201003],
                        [-120.657742602657152, 55.508302254444466],
                        [-120.651492376650538, 55.508302133639759],
                        [-120.645242151555252, 55.50830233390618],
                        [-120.638991925580697, 55.508302213124459],
                        [-120.638991927482209, 55.50413543874857],
                        [-120.638991930244515, 55.499968666138926],
                        [-120.638991861242857, 55.495801976394901],
                        [-120.638991845148666, 55.491635286815644],
                      ],
                    ],
                  },
                },
                {
                  type: 'Feature',
                  properties: {
                    GM_LAYER: 'Unknown Area Type',
                    GM_TYPE: 'Unknown Area Type',
                    NAME: 'Shell 80% / PetroChina 20%',
                    LAYER: 'Unknown Area Type',
                    OBJECTID: 0,
                    WORKING_IN: 'Shell 80% / PetroChina 20%',
                    LABEL: 'Shell 80% / PetroChina 20%',
                    ASSET: 'Groundbirch',
                    LAST_UPDAT: 'Aug 5, 2021',
                    GIS_CONTAC: 'Sheena.Ryall@Shell.com',
                    LANDMAN_CO: 'Mark.McKay@shell.com',
                    COMMENTS: null,
                  },
                  geometry: {
                    type: 'Polygon',
                    coordinates: [
                      [
                        [-121.210243242483386, 55.883460186580429],
                        [-121.210442712125385, 55.883460204080954],
                        [-121.210442688424962, 55.890734972492965],
                        [-121.210442665515444, 55.898005241487745],
                        [-121.197390141474557, 55.898003632749891],
                        [-121.1843363332613, 55.898004862324619],
                        [-121.184337105146739, 55.89073009102669],
                        [-121.184336863355369, 55.883459818049396],
                        [-121.197389920809258, 55.883458377417803],
                        [-121.210243242483386, 55.883460186580429],
                      ],
                    ],
                  },
                },
                {
                  type: 'Feature',
                  properties: {
                    GM_LAYER: 'Unknown Area Type',
                    GM_TYPE: 'Unknown Area Type',
                    NAME: 'Shell 80% / PetroChina 20%',
                    LAYER: 'Unknown Area Type',
                    OBJECTID: 0,
                    WORKING_IN: 'Shell 80% / PetroChina 20%',
                    LABEL: 'Shell 80% / PetroChina 20%',
                    ASSET: 'Groundbirch',
                    LAST_UPDAT: 'Aug 5, 2021',
                    GIS_CONTAC: 'Sheena.Ryall@Shell.com',
                    LANDMAN_CO: 'Mark.McKay@shell.com',
                    COMMENTS: null,
                  },
                  geometry: {
                    type: 'Polygon',
                    coordinates: [
                      [
                        [-120.5889900670782, 55.508302531522297],
                        [-120.595240292025863, 55.50830265221191],
                        [-120.601490570954113, 55.508302451854043],
                        [-120.607740742873446, 55.508302572566578],
                        [-120.613991023631826, 55.508302372231576],
                        [-120.613990960315235, 55.51246914334294],
                        [-120.613990949121714, 55.516635858663868],
                        [-120.613990940586632, 55.520802629710879],
                        [-120.613990929315122, 55.524969399826219],
                        [-120.607740642938239, 55.524969597828267],
                        [-120.601490410541814, 55.524969473883452],
                        [-120.595240177256642, 55.524969671009188],
                        [-120.588989943992743, 55.524969547087345],
                        [-120.582739654884776, 55.524969750696123],
                        [-120.576489467021801, 55.524969646659123],
                        [-120.570239172147964, 55.524969917652314],
                        [-120.563988931256532, 55.524969812738881],
                        [-120.557738689475315, 55.524970030694902],
                        [-120.551488447716181, 55.524969926703527],
                        [-120.545238152906137, 55.524970143783364],
                        [-120.538987911178765, 55.524970039814882],
                        [-120.538988000784599, 55.520803260815178],
                        [-120.538988036392468, 55.516636482682706],
                        [-120.538988125920042, 55.512469758477749],
                        [-120.5389881632494, 55.508302979381618],
                        [-120.545238394518677, 55.508303089772959],
                        [-120.551488679771026, 55.508302826055676],
                        [-120.557738858011788, 55.508302936469768],
                        [-120.563989143294478, 55.508302724936549],
                        [-120.570239375526413, 55.508302836272826],
                        [-120.576489607780218, 55.508302624762614],
                        [-120.582739839144665, 55.508302735222351],
                        [-120.5889900670782, 55.508302531522297],
                      ],
                    ],
                  },
                },
                {
                  type: 'Feature',
                  properties: {
                    GM_LAYER: 'Unknown Area Type',
                    GM_TYPE: 'Unknown Area Type',
                    NAME: 'Shell 80% / PetroChina 20%',
                    LAYER: 'Unknown Area Type',
                    OBJECTID: 0,
                    WORKING_IN: 'Shell 80% / PetroChina 20%',
                    LABEL: 'Shell 80% / PetroChina 20%',
                    ASSET: 'Groundbirch',
                    LAST_UPDAT: 'Aug 5, 2021',
                    GIS_CONTAC: 'Sheena.Ryall@Shell.com',
                    LANDMAN_CO: 'Mark.McKay@shell.com',
                    COMMENTS: null,
                  },
                  geometry: {
                    type: 'Polygon',
                    coordinates: [
                      [
                        [-120.557738898139732, 55.474969212846787],
                        [-120.563989117671113, 55.474969008629714],
                        [-120.563989112561202, 55.479135749385819],
                        [-120.563989162272492, 55.483302436150673],
                        [-120.563989157086709, 55.487469121983757],
                        [-120.56398920582204, 55.491635808684109],
                        [-120.55773892796104, 55.491636015032853],
                        [-120.551488704079901, 55.491635900336185],
                        [-120.545238479310584, 55.491636106707922],
                        [-120.538988307621892, 55.491635992034169],
                        [-120.538988265055309, 55.487469303202104],
                        [-120.538988273711837, 55.483302615237179],
                        [-120.538988230169281, 55.479135926340746],
                        [-120.538988241447839, 55.474969183452941],
                        [-120.545238406967982, 55.474969300258316],
                        [-120.551488679527978, 55.474969096018285],
                        [-120.557738898139732, 55.474969212846787],
                      ],
                    ],
                  },
                },
                {
                  type: 'Feature',
                  properties: {
                    GM_LAYER: 'Unknown Area Type',
                    GM_TYPE: 'Unknown Area Type',
                    NAME: 'Shell 80% / PetroChina 20%',
                    LAYER: 'Unknown Area Type',
                    OBJECTID: 0,
                    WORKING_IN: 'Shell 80% / PetroChina 20%',
                    LABEL: 'Shell 80% / PetroChina 20%',
                    ASSET: 'Groundbirch',
                    LAST_UPDAT: 'Aug 5, 2021',
                    GIS_CONTAC: 'Sheena.Ryall@Shell.com',
                    LANDMAN_CO: 'Mark.McKay@shell.com',
                    COMMENTS: null,
                  },
                  geometry: {
                    type: 'Polygon',
                    coordinates: [
                      [
                        [-120.910347331332304, 55.854368664218661],
                        [-120.923400538420182, 55.854364984328221],
                        [-120.923400753390908, 55.861640162434007],
                        [-120.923400004318651, 55.86891084026157],
                        [-120.910346780181001, 55.868914558858002],
                        [-120.89729380739179, 55.868912442400593],
                        [-120.897293608639046, 55.86164151210518],
                        [-120.897293141833487, 55.854372071800938],
                        [-120.897293141752115, 55.8543708488072],
                        [-120.897293444831377, 55.854370848772575],
                        [-120.910347331332304, 55.854368664218661],
                      ],
                    ],
                  },
                },
                {
                  type: 'Feature',
                  properties: {
                    GM_LAYER: 'Unknown Area Type',
                    GM_TYPE: 'Unknown Area Type',
                    NAME: 'Shell 50% / Pacific Canbriam 50%',
                    LAYER: 'Unknown Area Type',
                    OBJECTID: 0,
                    WORKING_IN: 'Shell 50% / Pacific Canbriam 50%',
                    LABEL: 'Shell 50% / Pacific Canbriam 50%',
                    ASSET: 'Groundbirch',
                    LAST_UPDAT: 'April 2021',
                    GIS_CONTAC: 'Sheena.Ryall@Shell.com',
                    LANDMAN_CO: 'Mark.McKay@shell.com',
                    COMMENTS: null,
                  },
                  geometry: {
                    type: 'MultiPolygon',
                    coordinates: [
                      [
                        [
                          [-121.119102948913735, 55.839809488338418],
                          [-121.132122902293176, 55.839801327650328],
                          [-121.132122349708354, 55.847076361397995],
                          [-121.13212276019739, 55.854346897738814],
                          [-121.132121710435669, 55.854346903038021],
                          [-121.119103039945031, 55.85435505255262],
                          [-121.113246341580506, 55.854356289624384],
                          [-121.106084336604496, 55.854357423516134],
                          [-121.106083692595021, 55.847086947357113],
                          [-121.106084012732993, 55.839811919277132],
                          [-121.119102948913735, 55.839809488338418],
                        ],
                      ],
                      [
                        [
                          [-121.07835614653635, 55.810681392352258],
                          [-121.079980493367785, 55.810684452463661],
                          [-121.079979090664452, 55.817977431477011],
                          [-121.079978222680751, 55.825274963576483],
                          [-121.066925329345139, 55.825277136385552],
                          [-121.0538716871406, 55.825277809377233],
                          [-121.040817777059814, 55.825277251415848],
                          [-121.033297434988256, 55.825276204955351],
                          [-121.027764884112614, 55.825275141512499],
                          [-121.027764884141192, 55.825274386606814],
                          [-121.02776514398866, 55.817982189011772],
                          [-121.027765403915168, 55.810684417415352],
                          [-121.039542860468941, 55.810684259488447],
                          [-121.040819376818362, 55.810686514569213],
                          [-121.052472057379219, 55.810687534095784],
                          [-121.053872332815757, 55.810687325770687],
                          [-121.054940012928839, 55.81068716829239],
                          [-121.065414267813139, 55.810685131808597],
                          [-121.066926519970181, 55.810686639026422],
                          [-121.078355728218199, 55.810681391563129],
                          [-121.07835614653635, 55.810681392352258],
                        ],
                      ],
                    ],
                  },
                },
                {
                  type: 'Feature',
                  properties: {
                    GM_LAYER: 'Unknown Area Type',
                    GM_TYPE: 'Unknown Area Type',
                    NAME: 'Shell 70% / Pacific Canbriam 30%',
                    LAYER: 'Unknown Area Type',
                    OBJECTID: 0,
                    WORKING_IN: 'Shell 70% / Pacific Canbriam 30%',
                    LABEL: 'Shell 70% / Pacific Canbriam 30%',
                    ASSET: 'Groundbirch',
                    LAST_UPDAT: 'April 2021',
                    GIS_CONTAC: 'Sheena.Ryall@Shell.com',
                    LANDMAN_CO: 'Mark.McKay@shell.com',
                    COMMENTS: null,
                  },
                  geometry: {
                    type: 'Polygon',
                    coordinates: [
                      [
                        [-120.858131004107321, 55.985262989877235],
                        [-120.871184341771809, 55.985264729492442],
                        [-120.871184736764818, 55.9925397802036],
                        [-120.871184651974858, 55.999810279065464],
                        [-120.858131519780287, 55.999804019157651],
                        [-120.845077477923667, 55.99980102632783],
                        [-120.845077573263396, 55.992530494686463],
                        [-120.845077935757445, 55.985255519783486],
                        [-120.845077990213269, 55.985255519617844],
                        [-120.858131004107321, 55.985262989877235],
                      ],
                    ],
                  },
                },
                {
                  type: 'Feature',
                  properties: {
                    GM_LAYER: 'Unknown Area Type',
                    GM_TYPE: 'Unknown Area Type',
                    NAME: 'Shell 50% / Cutbank Ridge 50%',
                    LAYER: 'Unknown Area Type',
                    OBJECTID: 0,
                    WORKING_IN: 'Shell 50% / Cutbank Ridge 50%',
                    LABEL: 'Shell 50% / Cutbank Ridge 50%',
                    ASSET: 'Groundbirch',
                    LAST_UPDAT: 'April 2021',
                    GIS_CONTAC: 'Sheena.Ryall@Shell.com',
                    LANDMAN_CO: 'Mark.McKay@shell.com',
                    COMMENTS: null,
                  },
                  geometry: {
                    type: 'Polygon',
                    coordinates: [
                      [
                        [-120.663992855384492, 55.579136935856404],
                        [-120.663992851425306, 55.574970212755794],
                        [-120.65774254177154, 55.574970400070036],
                        [-120.651492286996657, 55.574970266336521],
                        [-120.645241978274029, 55.574970453673671],
                        [-120.638991722631872, 55.574970319963093],
                        [-120.632741467900601, 55.574970507323499],
                        [-120.62649121228975, 55.574970427595552],
                        [-120.620240903630503, 55.574970614978568],
                        [-120.613990647153031, 55.574970481314111],
                        [-120.613990657989262, 55.570803710686143],
                        [-120.613990667886554, 55.566636993985455],
                        [-120.613990731705556, 55.562470225092021],
                        [-120.613990688465918, 55.558303454367092],
                        [-120.62024099058705, 55.558303584799624],
                        [-120.626491291829439, 55.558303394184314],
                        [-120.632741486063196, 55.558303524639484],
                        [-120.638991787338099, 55.558303280986806],
                        [-120.63899179060175, 55.554136560957303],
                        [-120.638991792928209, 55.54996978693606],
                        [-120.638991796115363, 55.545803014681169],
                        [-120.638991852323272, 55.541636294555161],
                        [-120.645242092232408, 55.541636421801314],
                        [-120.651492333061057, 55.541636227999476],
                        [-120.657742573902311, 55.541636301308984],
                        [-120.663992813863416, 55.541636107530017],
                        [-120.663992862615771, 55.537469384139456],
                        [-120.663992858269893, 55.533302608555644],
                        [-120.663992852985913, 55.52913583114097],
                        [-120.663992847662769, 55.524969107653625],
                        [-120.670243090095539, 55.524969226763893],
                        [-120.676493349865538, 55.52496901527757],
                        [-120.682743608748879, 55.524969124862274],
                        [-120.688993869449973, 55.524968913399064],
                        [-120.695244128365417, 55.524969023006548],
                        [-120.701494388199137, 55.524968811566303],
                        [-120.707744648046159, 55.52496892119661],
                        [-120.713994907012378, 55.524968709779358],
                        [-120.720245166891758, 55.524968819432473],
                        [-120.726495479849603, 55.524968608038286],
                        [-120.732745685801689, 55.524968717714096],
                        [-120.738995998791538, 55.524968505443596],
                        [-120.73899603548, 55.529135211252751],
                        [-120.738996071230147, 55.533301970989072],
                        [-120.738996107840848, 55.537468728894538],
                        [-120.738996143513745, 55.541635434607223],
                        [-120.732745819892145, 55.541635653300467],
                        [-120.726495603308422, 55.541635549147955],
                        [-120.720245332779314, 55.54163576786425],
                        [-120.713995009207693, 55.541635664633951],
                        [-120.713995088169668, 55.54580242979717],
                        [-120.71399511403267, 55.549969140968848],
                        [-120.713995139856507, 55.554135906967126],
                        [-120.713995164742286, 55.558302672033967],
                        [-120.707744830552855, 55.558302896296837],
                        [-120.701494549442472, 55.558302799512035],
                        [-120.695244269244967, 55.558303023797848],
                        [-120.688993987267466, 55.558302927035882],
                        [-120.688994003281891, 55.562469645433168],
                        [-120.688994016559221, 55.566636416858515],
                        [-120.688994032495486, 55.570803188251674],
                        [-120.688994045695551, 55.574969906552475],
                        [-120.695244338304448, 55.574969996891618],
                        [-120.70149463003284, 55.574969766183095],
                        [-120.707744920875285, 55.574969856545088],
                        [-120.713995265696354, 55.574969625859389],
                        [-120.713995291287119, 55.579136390765434],
                        [-120.713995315214362, 55.583303102029006],
                        [-120.713995121440561, 55.587469974832118],
                        [-120.713994926728276, 55.591636848502525],
                        [-120.707744618773688, 55.591637084536444],
                        [-120.701494309947094, 55.591636999518727],
                        [-120.695243948064032, 55.591637235575867],
                        [-120.688993638370746, 55.591637150580993],
                        [-120.68274333047961, 55.591637386660899],
                        [-120.676493021718215, 55.591637301688991],
                        [-120.670242713858713, 55.591637537791918],
                        [-120.663992387087177, 55.591637420681003],
                        [-120.663992622783979, 55.58747059259462],
                        [-120.663992859343878, 55.583303711415141],
                        [-120.663992855384492, 55.579136935856404],
                      ],
                    ],
                  },
                },
                {
                  type: 'Feature',
                  properties: {
                    GM_LAYER: 'Unknown Area Type',
                    GM_TYPE: 'Unknown Area Type',
                    NAME: 'Shell 50% / Cutbank Ridge 50%',
                    LAYER: 'Unknown Area Type',
                    OBJECTID: 0,
                    WORKING_IN: 'Shell 50% / Cutbank Ridge 50%',
                    LABEL: 'Shell 50% / Cutbank Ridge 50%',
                    ASSET: 'Groundbirch',
                    LAST_UPDAT: 'April 2021',
                    GIS_CONTAC: 'Sheena.Ryall@Shell.com',
                    LANDMAN_CO: 'Mark.McKay@shell.com',
                    COMMENTS: null,
                  },
                  geometry: {
                    type: 'Polygon',
                    coordinates: [
                      [
                        [-120.763997040680579, 55.524968310828321],
                        [-120.770247327664705, 55.524968424874324],
                        [-120.77649766862568, 55.524968217873756],
                        [-120.782747903480711, 55.524968331942702],
                        [-120.788998244473845, 55.524968124965021],
                        [-120.788998291857055, 55.529134881555855],
                        [-120.788998340100989, 55.533301584155176],
                        [-120.788998387406693, 55.537468338882974],
                        [-120.788998435573021, 55.541635041417827],
                        [-120.788998482801205, 55.545801796980484],
                        [-120.788998530889842, 55.549968552510919],
                        [-120.788998632000542, 55.554135254949202],
                        [-120.788998680011574, 55.558302010415204],
                        [-120.782748383690347, 55.558302164305005],
                        [-120.776498087385704, 55.558302104167531],
                        [-120.770247791995871, 55.558302311140402],
                        [-120.763997442662898, 55.55830219706634],
                        [-120.763997452806322, 55.55413544071493],
                        [-120.76399735589014, 55.549968686129894],
                        [-120.763997311995738, 55.545801983673471],
                        [-120.763997268961973, 55.541635227225356],
                        [-120.763997224989993, 55.537468524704465],
                        [-120.763997180979516, 55.533301769990594],
                        [-120.763997137829335, 55.529135067405257],
                        [-120.763997040680579, 55.524968310828321],
                      ],
                    ],
                  },
                },
                {
                  type: 'Feature',
                  properties: {
                    GM_LAYER: 'Unknown Area Type',
                    GM_TYPE: 'Unknown Area Type',
                    NAME: 'Shell Land with No Montney Rights',
                    LAYER: 'Unknown Area Type',
                    OBJECTID: 0,
                    WORKING_IN: 'Shell Land with No Montney Rights',
                    LABEL: 'Shell Land with No Montney Rights',
                    ASSET: 'Groundbirch',
                    LAST_UPDAT: 'April 2021',
                    GIS_CONTAC: 'Sheena.Ryall@Shell.com',
                    LANDMAN_CO: 'Mark.McKay@shell.com',
                    COMMENTS: null,
                  },
                  geometry: {
                    type: 'Polygon',
                    coordinates: [
                      [
                        [-120.819604434905784, 55.795920550991205],
                        [-120.819604104714287, 55.788649606071736],
                        [-120.819603827463411, 55.78137866015458],
                        [-120.832541083087108, 55.781382801997694],
                        [-120.840946111063587, 55.781384746005671],
                        [-120.845478302346137, 55.781385562921301],
                        [-120.845478297351804, 55.788656507645392],
                        [-120.845478078082635, 55.79593125200828],
                        [-120.845478078059912, 55.795931950645752],
                        [-120.848976513907957, 55.795932418615585],
                        [-120.858414980726906, 55.795933184517864],
                        [-120.871351617299084, 55.795933187276923],
                        [-120.871351944650471, 55.803307391453167],
                        [-120.871213172681038, 55.809568644723548],
                        [-120.871188459146126, 55.810683362476816],
                        [-120.858429331888374, 55.810682061041717],
                        [-120.858135064623028, 55.810681310636632],
                        [-120.845507622444018, 55.810676314135868],
                        [-120.845081618058202, 55.810677812752033],
                        [-120.832541204936661, 55.810668555120117],
                        [-120.832028650762183, 55.810668559516778],
                        [-120.819574511263809, 55.810664167994602],
                        [-120.819588696917251, 55.803294742727971],
                        [-120.819604434905784, 55.795920550991205],
                      ],
                    ],
                  },
                },
                {
                  type: 'Feature',
                  properties: {
                    GM_LAYER: 'Unknown Area Type',
                    GM_TYPE: 'Unknown Area Type',
                    NAME: 'Shell Land with No Montney Rights',
                    LAYER: 'Unknown Area Type',
                    OBJECTID: 0,
                    WORKING_IN: 'Shell Land with No Montney Rights',
                    LABEL: 'Shell Land with No Montney Rights',
                    ASSET: 'Groundbirch',
                    LAST_UPDAT: 'April 2021',
                    GIS_CONTAC: 'Sheena.Ryall@Shell.com',
                    LANDMAN_CO: 'Mark.McKay@shell.com',
                    COMMENTS: null,
                  },
                  geometry: {
                    type: 'Polygon',
                    coordinates: [
                      [
                        [-120.767826759246717, 55.723188179725462],
                        [-120.767827704467308, 55.723188179178592],
                        [-120.767827270794939, 55.730463732661569],
                        [-120.767827050276736, 55.737735057401494],
                        [-120.767945259164634, 55.74355136454529],
                        [-120.767945274674318, 55.752161948751613],
                        [-120.767826371442908, 55.752281906700105],
                        [-120.754890063498308, 55.752284435498346],
                        [-120.741952995303365, 55.752289973641268],
                        [-120.741953190954902, 55.745014178628594],
                        [-120.741953427146711, 55.737742911396055],
                        [-120.74195339520891, 55.730471858106064],
                        [-120.741953631309755, 55.723196038276228],
                        [-120.754890994336336, 55.723194982563633],
                        [-120.767826759246717, 55.723188179725462],
                      ],
                    ],
                  },
                },
                {
                  type: 'Feature',
                  properties: {
                    GM_LAYER: 'Unknown Area Type',
                    GM_TYPE: 'Unknown Area Type',
                    NAME: 'Shell Land with No Montney Rights',
                    LAYER: 'Unknown Area Type',
                    OBJECTID: 0,
                    WORKING_IN: 'Shell Land with No Montney Rights',
                    LABEL: 'Shell Land with No Montney Rights',
                    ASSET: 'Groundbirch',
                    LAST_UPDAT: 'April 2021',
                    GIS_CONTAC: 'Sheena.Ryall@Shell.com',
                    LANDMAN_CO: 'Mark.McKay@shell.com',
                    COMMENTS: null,
                  },
                  geometry: {
                    type: 'Polygon',
                    coordinates: [
                      [
                        [-121.210243242483386, 55.883460186580429],
                        [-121.210242853396792, 55.883431806104802],
                        [-121.197656620443766, 55.883373781869501],
                        [-121.184336863355369, 55.883459818049396],
                        [-121.184336887206115, 55.876185045653656],
                        [-121.184336694632847, 55.868914505381852],
                        [-121.184336451588294, 55.861639732789449],
                        [-121.18433620660808, 55.854369459421491],
                        [-121.19739001533641, 55.854372524985351],
                        [-121.21044430619331, 55.854370129902428],
                        [-121.22349736593803, 55.854370679251431],
                        [-121.236550370901838, 55.854369728577531],
                        [-121.236550348880272, 55.861639994176457],
                        [-121.236550325372477, 55.868914758995089],
                        [-121.249603654009306, 55.868908073998128],
                        [-121.262656425811642, 55.868904144066022],
                        [-121.262656396142205, 55.876178900651041],
                        [-121.26265609926952, 55.883449158723657],
                        [-121.262655854416622, 55.89072391511263],
                        [-121.262655823048476, 55.897994172992163],
                        [-121.249603021079864, 55.897997857891284],
                        [-121.236548998515048, 55.898004816854694],
                        [-121.223496474319163, 55.898005778285885],
                        [-121.210442665515444, 55.898005241487745],
                        [-121.210243242483386, 55.883460186580429],
                      ],
                    ],
                  },
                },
                {
                  type: 'Feature',
                  properties: {
                    GM_LAYER: 'Unknown Area Type',
                    GM_TYPE: 'Unknown Area Type',
                    NAME: 'Shell Land with No Montney Rights',
                    LAYER: 'Unknown Area Type',
                    OBJECTID: 0,
                    WORKING_IN: 'Shell Land with No Montney Rights',
                    LABEL: 'Shell Land with No Montney Rights',
                    ASSET: 'Groundbirch',
                    LAST_UPDAT: 'April 2021',
                    GIS_CONTAC: 'Sheena.Ryall@Shell.com',
                    LANDMAN_CO: 'Mark.McKay@shell.com',
                    COMMENTS: null,
                  },
                  geometry: {
                    type: 'Polygon',
                    coordinates: [
                      [
                        [-120.701570272524677, 55.956175175009982],
                        [-120.71462311813319, 55.956175430691808],
                        [-120.714623264344525, 55.963450585019643],
                        [-120.714622931298393, 55.970721240154816],
                        [-120.70156933993259, 55.970716719950097],
                        [-120.688516767887421, 55.970715198002992],
                        [-120.68851731245114, 55.963439811021274],
                        [-120.688516678555516, 55.956169190099693],
                        [-120.701570272524677, 55.956175175009982],
                      ],
                    ],
                  },
                },
                {
                  type: 'Feature',
                  properties: {
                    GM_LAYER: 'Unknown Area Type',
                    GM_TYPE: 'Unknown Area Type',
                    NAME: 'Shell Land with No Montney Rights',
                    LAYER: 'Unknown Area Type',
                    OBJECTID: 0,
                    WORKING_IN: 'Shell Land with No Montney Rights',
                    LABEL: 'Shell Land with No Montney Rights',
                    ASSET: 'Groundbirch',
                    LAST_UPDAT: 'April 2021',
                    GIS_CONTAC: 'Sheena.Ryall@Shell.com',
                    LANDMAN_CO: 'Mark.McKay@shell.com',
                    COMMENTS: null,
                  },
                  geometry: {
                    type: 'Polygon',
                    coordinates: [
                      [
                        [-120.770524489445023, 55.633557851939244],
                        [-120.770246466083293, 55.633304813967904],
                        [-120.767214368163948, 55.63330476616256],
                        [-120.763996128392264, 55.63330471503032],
                        [-120.763996282404875, 55.629137905634195],
                        [-120.763996382419194, 55.624971043145308],
                        [-120.770246713357693, 55.624971143858609],
                        [-120.776497098280387, 55.624970923521005],
                        [-120.776496951058135, 55.629137783334947],
                        [-120.77649680289862, 55.633304590056042],
                        [-120.774231784682712, 55.63330467154772],
                        [-120.774482587448077, 55.633534829559188],
                        [-120.77762893986052, 55.633516528068036],
                        [-120.780775293163487, 55.633498227485013],
                        [-120.793714133705492, 55.633417077329973],
                        [-120.793733681448003, 55.63591156831334],
                        [-120.793733799014873, 55.64318292375382],
                        [-120.793733117875391, 55.650453759283621],
                        [-120.793733117848575, 55.650454226037411],
                        [-120.791803260127338, 55.650454987375305],
                        [-120.780782093328156, 55.650458753545621],
                        [-120.770214914962835, 55.65046154640693],
                        [-120.767830055335963, 55.650462050822298],
                        [-120.767830055391556, 55.650461449231848],
                        [-120.767830613191848, 55.644283112238526],
                        [-120.767830709104146, 55.64319068580545],
                        [-120.767830666767395, 55.635914876183669],
                        [-120.76783672040429, 55.63357356107992],
                        [-120.7678367205977, 55.633573486259642],
                        [-120.770524489445023, 55.633557851939244],
                      ],
                    ],
                  },
                },
                {
                  type: 'Feature',
                  properties: {
                    GM_LAYER: 'Unknown Area Type',
                    GM_TYPE: 'Unknown Area Type',
                    NAME: 'Shell Land with No Montney Rights',
                    LAYER: 'Unknown Area Type',
                    OBJECTID: 0,
                    WORKING_IN: 'Shell Land with No Montney Rights',
                    LABEL: 'Shell Land with No Montney Rights',
                    ASSET: 'Groundbirch',
                    LAST_UPDAT: 'April 2021',
                    GIS_CONTAC: 'Sheena.Ryall@Shell.com',
                    LANDMAN_CO: 'Mark.McKay@shell.com',
                    COMMENTS: null,
                  },
                  geometry: {
                    type: 'Polygon',
                    coordinates: [
                      [
                        [-120.858131970273035, 55.963447026311393],
                        [-120.871184864393115, 55.963448787665584],
                        [-120.871184565833389, 55.970723730978143],
                        [-120.871184427449208, 55.977994229889127],
                        [-120.871184341771809, 55.985264729492442],
                        [-120.858131004107321, 55.985262989877235],
                        [-120.845077990216197, 55.985255466027517],
                        [-120.845077990213269, 55.985255519617844],
                        [-120.845077573263396, 55.992530494686463],
                        [-120.845077477923667, 55.99980102632783],
                        [-120.832024434619768, 55.999796480237691],
                        [-120.818971725946909, 55.999786257664546],
                        [-120.818971873234531, 55.992515741935257],
                        [-120.818971698448351, 55.985245010273005],
                        [-120.832024842518351, 55.985250939741391],
                        [-120.844877892826901, 55.985138770415986],
                        [-120.844877982738026, 55.982233208024788],
                        [-120.845078031283975, 55.977986061802568],
                        [-120.845078084361347, 55.977984934781944],
                        [-120.845078179288336, 55.970714297322772],
                        [-120.845078380428475, 55.963439374648644],
                        [-120.858131970273035, 55.963447026311393],
                      ],
                    ],
                  },
                },
                {
                  type: 'Feature',
                  properties: {
                    GM_LAYER: 'Unknown Area Type',
                    GM_TYPE: 'Unknown Area Type',
                    NAME: 'Shell Land with No Montney Rights',
                    LAYER: 'Unknown Area Type',
                    OBJECTID: 0,
                    WORKING_IN: 'Shell Land with No Montney Rights',
                    LABEL: 'Shell Land with No Montney Rights',
                    ASSET: 'Groundbirch',
                    LAST_UPDAT: 'April 2021',
                    GIS_CONTAC: 'Sheena.Ryall@Shell.com',
                    LANDMAN_CO: 'Mark.McKay@shell.com',
                    COMMENTS: null,
                  },
                  geometry: {
                    type: 'Polygon',
                    coordinates: [
                      [
                        [-120.71462274397436, 55.985267050126915],
                        [-120.727676329335608, 55.985265876304254],
                        [-120.74072868345074, 55.985267648574677],
                        [-120.753782286485929, 55.985268277443275],
                        [-120.7668352166924, 55.985263014136471],
                        [-120.766835137886858, 55.992538223554277],
                        [-120.76683527457547, 55.999808666662041],
                        [-120.753781870537367, 55.999809421930699],
                        [-120.740728272068537, 55.999813528597436],
                        [-120.740728539587209, 56.007084354728498],
                        [-120.740728809250228, 56.014354969185064],
                        [-120.727675742021816, 56.014353119875274],
                        [-120.714622138764554, 56.014354536874869],
                        [-120.71462312265497, 56.007079198379856],
                        [-120.714622608078955, 55.999808361513999],
                        [-120.714622193823161, 55.992537706765631],
                        [-120.71462274397436, 55.985267050126915],
                      ],
                    ],
                  },
                },
                {
                  type: 'Feature',
                  properties: {
                    GM_LAYER: 'Unknown Area Type',
                    GM_TYPE: 'Unknown Area Type',
                    NAME: 'Shell Land with No Montney Rights',
                    LAYER: 'Unknown Area Type',
                    OBJECTID: 0,
                    WORKING_IN: 'Shell Land with No Montney Rights',
                    LABEL: 'Shell Land with No Montney Rights',
                    ASSET: 'Groundbirch',
                    LAST_UPDAT: 'April 2021',
                    GIS_CONTAC: 'Sheena.Ryall@Shell.com',
                    LANDMAN_CO: 'Mark.McKay@shell.com',
                    COMMENTS: null,
                  },
                  geometry: {
                    type: 'Polygon',
                    coordinates: [
                      [
                        [-120.727675027424837, 56.028899285666157],
                        [-120.740728383982429, 56.028901123270273],
                        [-120.740728438338493, 56.036171683475537],
                        [-120.740728707504033, 56.043442511575989],
                        [-120.727675330385651, 56.043445183980957],
                        [-120.714622434657954, 56.043442126089388],
                        [-120.71462341903468, 56.036167056873552],
                        [-120.714622903066669, 56.0288962160719],
                        [-120.727675027424837, 56.028899285666157],
                      ],
                    ],
                  },
                },
                {
                  type: 'Feature',
                  properties: {
                    GM_LAYER: 'Unknown Area Type',
                    GM_TYPE: 'Unknown Area Type',
                    NAME: 'Shell Land with No Montney Rights',
                    LAYER: 'Unknown Area Type',
                    OBJECTID: 0,
                    WORKING_IN: 'Shell Land with No Montney Rights',
                    LABEL: 'Shell Land with No Montney Rights',
                    ASSET: 'Groundbirch',
                    LAST_UPDAT: 'April 2021',
                    GIS_CONTAC: 'Sheena.Ryall@Shell.com',
                    LANDMAN_CO: 'Mark.McKay@shell.com',
                    COMMENTS: null,
                  },
                  geometry: {
                    type: 'Polygon',
                    coordinates: [
                      [
                        [-120.754889559253328, 55.766831151535449],
                        [-120.767826849851986, 55.766824059680019],
                        [-120.767827197386978, 55.77409979449051],
                        [-120.767827062833859, 55.781370763719387],
                        [-120.767827678223711, 55.788641733744676],
                        [-120.767827061287733, 55.795917467369343],
                        [-120.754890322932269, 55.79592003097396],
                        [-120.741952545855796, 55.795925864282346],
                        [-120.741953191440544, 55.788650119578435],
                        [-120.741952284577991, 55.781379140273671],
                        [-120.741952394748594, 55.774107892871861],
                        [-120.741952772868657, 55.766832200935617],
                        [-120.754889559253328, 55.766831151535449],
                      ],
                    ],
                  },
                },
                {
                  type: 'Feature',
                  properties: {
                    GM_LAYER: 'Unknown Area Type',
                    GM_TYPE: 'Unknown Area Type',
                    NAME: 'Shell Land with No Montney Rights',
                    LAYER: 'Unknown Area Type',
                    OBJECTID: 0,
                    WORKING_IN: 'Shell Land with No Montney Rights',
                    LABEL: 'Shell Land with No Montney Rights',
                    ASSET: 'Groundbirch',
                    LAST_UPDAT: 'April 2021',
                    GIS_CONTAC: 'Sheena.Ryall@Shell.com',
                    LANDMAN_CO: 'Mark.McKay@shell.com',
                    COMMENTS: null,
                  },
                  geometry: {
                    type: 'Polygon',
                    coordinates: [
                      [
                        [-121.079980493367785, 55.810684452463661],
                        [-121.09130901260869, 55.81068094716553],
                        [-121.093034587640361, 55.810680948985798],
                        [-121.104261643186419, 55.810674696122405],
                        [-121.106087949806266, 55.81067598319553],
                        [-121.117213040705749, 55.810671496523533],
                        [-121.11910837811763, 55.810668981301632],
                        [-121.130163689710528, 55.810662513331572],
                        [-121.132127788658636, 55.810661016225787],
                        [-121.132126595260431, 55.817958363998081],
                        [-121.132124438531264, 55.825255979666693],
                        [-121.119105170985975, 55.825264168612193],
                        [-121.106085422947984, 55.825266358126576],
                        [-121.093032203228944, 55.825271279682113],
                        [-121.079978222680751, 55.825274963576483],
                        [-121.079979090664452, 55.817977431477011],
                        [-121.079980493367785, 55.810684452463661],
                      ],
                    ],
                  },
                },
                {
                  type: 'Feature',
                  properties: {
                    GM_LAYER: 'Unknown Area Type',
                    GM_TYPE: 'Unknown Area Type',
                    NAME: 'Shell 80% / PetroChina 20%',
                    LAYER: 'Unknown Area Type',
                    OBJECTID: 0,
                    WORKING_IN: 'Shell 80% / PetroChina 20%',
                    LABEL: 'Shell 80% / PetroChina 20%',
                    ASSET: 'Groundbirch',
                    LAST_UPDAT: 'Aug 5, 2021',
                    GIS_CONTAC: 'Sheena.Ryall@Shell.com',
                    LANDMAN_CO: 'Mark.McKay@shell.com',
                    COMMENTS: null,
                  },
                  geometry: {
                    type: 'MultiPolygon',
                    coordinates: [
                      [
                        [
                          [-121.130157475282203, 55.76686360786946],
                          [-121.130157475296414, 55.766863443268754],
                          [-121.130158842364295, 55.766863443731047],
                          [-121.132230722543184, 55.766864126908949],
                          [-121.143095844677731, 55.766867137732547],
                          [-121.149626837474543, 55.766867247164349],
                          [-121.156034215364599, 55.766867017267344],
                          [-121.156034215274687, 55.766867866202112],
                          [-121.156033658135087, 55.772055916451649],
                          [-121.156033429447149, 55.774145608957475],
                          [-121.156033009150264, 55.775285487732965],
                          [-121.15603073708408, 55.78141275555344],
                          [-121.150198492354889, 55.781412958323926],
                          [-121.143092508039345, 55.781412831420937],
                          [-121.130154280637839, 55.781405278280175],
                          [-121.130156321130229, 55.775596872154402],
                          [-121.130156831807739, 55.774134361267912],
                          [-121.130156891302008, 55.773476150188372],
                          [-121.130157475282203, 55.76686360786946],
                        ],
                      ],
                      [
                        [
                          [-121.130157475296414, 55.766863443268754],
                          [-121.12860054657996, 55.766862611830106],
                          [-121.117219105310269, 55.766855934810515],
                          [-121.109859295573244, 55.766851830294598],
                          [-121.104281166236603, 55.766848425821983],
                          [-121.104280736143366, 55.76684842554976],
                          [-121.104283145765564, 55.75957373885965],
                          [-121.104283647422648, 55.752302865245461],
                          [-121.104285182334166, 55.746685784885678],
                          [-121.104285561472651, 55.745028229258061],
                          [-121.104285329906787, 55.737757433006124],
                          [-121.117223730055798, 55.737764924373195],
                          [-121.130160223759191, 55.737772414867017],
                          [-121.143100531734632, 55.737776091341281],
                          [-121.156038932890269, 55.737775952990049],
                          [-121.156038932749496, 55.737776718330487],
                          [-121.156037578704314, 55.745054297689364],
                          [-121.156039600816783, 55.752321278548713],
                          [-121.143099180883539, 55.752321443635921],
                          [-121.130161145626602, 55.752317793928931],
                          [-121.130160669860075, 55.752317793769684],
                          [-121.130160099441909, 55.758782152245161],
                          [-121.130160026597949, 55.759588711969563],
                          [-121.130159921006822, 55.759891548759278],
                          [-121.130157475296414, 55.766863443268754],
                        ],
                      ],
                    ],
                  },
                },
                {
                  type: 'Feature',
                  properties: {
                    GM_LAYER: 'Unknown Area Type',
                    GM_TYPE: 'Unknown Area Type',
                    NAME: 'Shell Land with No Montney Rights',
                    LAYER: 'Unknown Area Type',
                    OBJECTID: 0,
                    WORKING_IN: 'Shell Land with No Montney Rights',
                    LABEL: 'Shell Land with No Montney Rights',
                    ASSET: 'Groundbirch',
                    LAST_UPDAT: 'April 2021',
                    GIS_CONTAC: 'Sheena.Ryall@Shell.com',
                    LANDMAN_CO: 'Mark.McKay@shell.com',
                    COMMENTS: null,
                  },
                  geometry: {
                    type: 'Polygon',
                    coordinates: [
                      [
                        [-121.156033658135087, 55.772055916451649],
                        [-121.156034749504229, 55.766867866239778],
                        [-121.156034215274687, 55.766867866202112],
                        [-121.149626837474543, 55.766867247164349],
                        [-121.143096635317121, 55.766866272562254],
                        [-121.132230722543184, 55.766864126908949],
                        [-121.130158840989296, 55.766863608733651],
                        [-121.130158842364295, 55.766863443731047],
                        [-121.130159921006822, 55.759891548759278],
                        [-121.130159967253761, 55.759588541096555],
                        [-121.130160099441909, 55.758782152245161],
                        [-121.130161145626602, 55.752317793928931],
                        [-121.130161145649836, 55.752317648970596],
                        [-121.155823185097177, 55.752206235880671],
                        [-121.156038866394283, 55.737776718303557],
                        [-121.156038932749496, 55.737776718330487],
                        [-121.168977107236898, 55.737781317524636],
                        [-121.181912662296185, 55.737780606730873],
                        [-121.181913425861154, 55.745050966988806],
                        [-121.181913819301116, 55.752325963476189],
                        [-121.181912326299482, 55.759596633572571],
                        [-121.181909813864692, 55.766871534054665],
                        [-121.181908320585976, 55.77414215000185],
                        [-121.181905592448658, 55.781417318297919],
                        [-121.168969242743842, 55.781418139940321],
                        [-121.156031892251207, 55.781413431850581],
                        [-121.156033009150264, 55.775285487732965],
                        [-121.156033214774865, 55.774143032348668],
                        [-121.156033658135087, 55.772055916451649],
                      ],
                    ],
                  },
                },
                {
                  type: 'Feature',
                  properties: {
                    GM_LAYER: 'Unknown Area Type',
                    GM_TYPE: 'Unknown Area Type',
                    NAME: 'Shell 80% / PetroChina 20%',
                    LAYER: 'Unknown Area Type',
                    OBJECTID: 0,
                    WORKING_IN: 'Shell 80% / PetroChina 20%',
                    LABEL: 'Shell 80% / PetroChina 20%',
                    ASSET: 'Groundbirch',
                    LAST_UPDAT: 'Aug 5, 2021',
                    GIS_CONTAC: 'Sheena.Ryall@Shell.com',
                    LANDMAN_CO: 'Mark.McKay@shell.com',
                    COMMENTS: null,
                  },
                  geometry: {
                    type: 'Polygon',
                    coordinates: [
                      [
                        [-120.936452918235091, 55.897997524325874],
                        [-120.949506151881778, 55.897991103792961],
                        [-120.949505903454025, 55.90526634522957],
                        [-120.949505655068123, 55.912536767129922],
                        [-120.949505585810243, 55.919807452490559],
                        [-120.949505255780238, 55.927082504664931],
                        [-120.936452529893558, 55.927084347910061],
                        [-120.923399749967786, 55.92708945835215],
                        [-120.91034598353491, 55.927093326749819],
                        [-120.897292470180133, 55.927091190984477],
                        [-120.89729254414847, 55.919820669862744],
                        [-120.897292372608987, 55.912550016243479],
                        [-120.897293673953698, 55.905274856178565],
                        [-120.897293421839152, 55.898004194365399],
                        [-120.91034642663378, 55.89800179373848],
                        [-120.923399951793925, 55.898002658880031],
                        [-120.936452918235091, 55.897997524325874],
                      ],
                    ],
                  },
                },
                {
                  type: 'Feature',
                  properties: {
                    GM_LAYER: 'Unknown Area Type',
                    GM_TYPE: 'Unknown Area Type',
                    NAME: 'Shell Land with No Montney Rights',
                    LAYER: 'Unknown Area Type',
                    OBJECTID: 0,
                    WORKING_IN: 'Shell Land with No Montney Rights',
                    LABEL: 'Shell Land with No Montney Rights',
                    ASSET: 'Groundbirch',
                    LAST_UPDAT: 'April 2021',
                    GIS_CONTAC: 'Sheena.Ryall@Shell.com',
                    LANDMAN_CO: 'Mark.McKay@shell.com',
                    COMMENTS: null,
                  },
                  geometry: {
                    type: 'Polygon',
                    coordinates: [
                      [
                        [-120.613990929315122, 55.524969399826219],
                        [-120.613990940586632, 55.520802629710879],
                        [-120.613990949121714, 55.516635858663868],
                        [-120.613990960315235, 55.51246914334294],
                        [-120.613991023631826, 55.508302372231576],
                        [-120.607740742873446, 55.508302572566578],
                        [-120.601490570954113, 55.508302451854043],
                        [-120.595240292025863, 55.50830265221191],
                        [-120.5889900670782, 55.508302531522297],
                        [-120.588990137773465, 55.504135764510345],
                        [-120.588990154470665, 55.499968997465956],
                        [-120.588990154254034, 55.495802312055517],
                        [-120.588990099926136, 55.491635626795023],
                        [-120.595240317459414, 55.491635744785867],
                        [-120.601490535911822, 55.491635541731334],
                        [-120.607740753476932, 55.491635659745199],
                        [-120.61399097286025, 55.491635456713588],
                        [-120.620241190457193, 55.491635574750326],
                        [-120.626491408972711, 55.491635371741644],
                        [-120.63274162750092, 55.491635489801482],
                        [-120.638991845148666, 55.491635286815644],
                        [-120.638991861242857, 55.495801976394901],
                        [-120.638991930244515, 55.499968666138926],
                        [-120.638991927482209, 55.50413543874857],
                        [-120.638991925580697, 55.508302213124459],
                        [-120.645242151555252, 55.50830233390618],
                        [-120.651492376650538, 55.508302133639759],
                        [-120.657742602657152, 55.508302254444466],
                        [-120.66399282778417, 55.508302054201003],
                        [-120.663992833261233, 55.512468830877559],
                        [-120.663992837800976, 55.516635554461629],
                        [-120.663992843200774, 55.520802331073718],
                        [-120.663992847662769, 55.524969107653625],
                        [-120.663992852985913, 55.52913583114097],
                        [-120.663992858269893, 55.533302608555644],
                        [-120.663992862615771, 55.537469384139456],
                        [-120.663992813863416, 55.541636107530017],
                        [-120.657742573902311, 55.541636301308984],
                        [-120.651492333061057, 55.541636227999476],
                        [-120.645242092232408, 55.541636421801314],
                        [-120.638991852323272, 55.541636294555161],
                        [-120.6389918545336, 55.537469520437156],
                        [-120.638991857604665, 55.533302747186276],
                        [-120.638991859736961, 55.529136027862698],
                        [-120.638991862730805, 55.524969253647967],
                        [-120.632741629350747, 55.524969450705072],
                        [-120.626491395991181, 55.524969326714142],
                        [-120.620241163542147, 55.524969524693503],
                        [-120.613990929315122, 55.524969399826219],
                      ],
                    ],
                  },
                },
                {
                  type: 'Feature',
                  properties: {
                    GM_LAYER: 'Unknown Area Type',
                    GM_TYPE: 'Unknown Area Type',
                    NAME: 'Shell 100%',
                    LAYER: 'Unknown Area Type',
                    OBJECTID: 0,
                    WORKING_IN: 'Shell 100%',
                    LABEL: 'Shell 100%',
                    ASSET: 'Groundbirch',
                    LAST_UPDAT: 'April 2021',
                    GIS_CONTAC: 'Sheena.Ryall@Shell.com',
                    LANDMAN_CO: 'Mark.McKay@shell.com',
                    COMMENTS: null,
                  },
                  geometry: {
                    type: 'Polygon',
                    coordinates: [
                      [
                        [-121.23654857972079, 55.919819836219737],
                        [-121.236548680813627, 55.912549577202256],
                        [-121.223495139656521, 55.912550811412025],
                        [-121.2104426166146, 55.912550277604872],
                        [-121.199472625082137, 55.912549022467012],
                        [-121.197389075613586, 55.912548673647109],
                        [-121.195522549001979, 55.912548934678156],
                        [-121.184336285612773, 55.91254990621173],
                        [-121.171282957907039, 55.912545571103728],
                        [-121.158230188379633, 55.912539472077825],
                        [-121.145176321489828, 55.91253212730421],
                        [-121.132122455576251, 55.912527994072747],
                        [-121.132123275948118, 55.90525322869339],
                        [-121.132123163266286, 55.900920644320486],
                        [-121.132123080791217, 55.897982747587278],
                        [-121.132122937715721, 55.89070798200401],
                        [-121.13212331193327, 55.88583865541549],
                        [-121.132123491466217, 55.883437446749156],
                        [-121.132123359265876, 55.881035449623745],
                        [-121.132123081039509, 55.876162680968228],
                        [-121.132122974710185, 55.874453129521953],
                        [-121.132122617388248, 55.868892145509989],
                        [-121.13212247562204, 55.861617165496632],
                        [-121.13212276019739, 55.854346897738814],
                        [-121.132122349708354, 55.847076361397995],
                        [-121.132122902293176, 55.839801327650328],
                        [-121.119102948913735, 55.839809488338418],
                        [-121.106084012732993, 55.839811919277132],
                        [-121.106084427876056, 55.832541383368145],
                        [-121.106085422947984, 55.825266358126576],
                        [-121.119105170985975, 55.825264168612193],
                        [-121.132124438531264, 55.825255979666693],
                        [-121.132126595260431, 55.817958363998081],
                        [-121.132127788658636, 55.810661016225787],
                        [-121.130163689710528, 55.810662513331572],
                        [-121.11910837811763, 55.810668981301632],
                        [-121.117213040705749, 55.810671496523533],
                        [-121.106087949806266, 55.81067598319553],
                        [-121.104261643186419, 55.810674696122405],
                        [-121.093034587640361, 55.810680948985798],
                        [-121.09130901260869, 55.81068094716553],
                        [-121.079980493367785, 55.810684452463661],
                        [-121.07835614653635, 55.810681392352258],
                        [-121.078355728218199, 55.810681391563129],
                        [-121.078364093424582, 55.803312156005013],
                        [-121.078364475136382, 55.802933146360289],
                        [-121.078371494398311, 55.795947364792852],
                        [-121.078373106015931, 55.788672325173188],
                        [-121.078373754096532, 55.781401568935806],
                        [-121.065435916526809, 55.781405283835134],
                        [-121.052498079112794, 55.781407766704113],
                        [-121.052499414957438, 55.774137024856337],
                        [-121.052499945956413, 55.766861998532356],
                        [-121.039561564273171, 55.766867493376978],
                        [-121.026623840345195, 55.766866936743703],
                        [-121.026623665870062, 55.766866936727041],
                        [-121.026623653235887, 55.759596208267183],
                        [-121.026624210723568, 55.756431816724024],
                        [-121.026624925807724, 55.752321195342418],
                        [-121.026624525859148, 55.745050445772108],
                        [-121.026624523738491, 55.73777518751298],
                        [-121.026625003548972, 55.730504374522035],
                        [-121.02662575124026, 55.723229116068282],
                        [-121.039564116320193, 55.723229880679042],
                        [-121.052502748571115, 55.72322887906472],
                        [-121.052613392230882, 55.723290422685281],
                        [-121.087764750536593, 55.723280589601963],
                        [-121.104071012455236, 55.723276216781336],
                        [-121.104285175644193, 55.745028496360746],
                        [-121.104285182334166, 55.746685784885678],
                        [-121.104284955092893, 55.752303467779491],
                        [-121.104283168228932, 55.759574046430068],
                        [-121.104281166236603, 55.766848425821983],
                        [-121.104281165983764, 55.766849337465779],
                        [-121.109859295573244, 55.766851830294598],
                        [-121.117220242220398, 55.766854732392837],
                        [-121.12860054657996, 55.766862611830106],
                        [-121.130157475282203, 55.76686360786946],
                        [-121.130158840989296, 55.766863608733651],
                        [-121.130156891302008, 55.773476150188372],
                        [-121.130156696013628, 55.774134231764648],
                        [-121.130156321130229, 55.775596872154402],
                        [-121.130154820301883, 55.781404800730165],
                        [-121.143093221320285, 55.781412007623345],
                        [-121.150198492354889, 55.781412958323926],
                        [-121.156031892251207, 55.781413431850581],
                        [-121.168969242743842, 55.781418139940321],
                        [-121.181905592448658, 55.781417318297919],
                        [-121.181904097558487, 55.788687720013385],
                        [-121.181902334504102, 55.795962888136216],
                        [-121.181906614843967, 55.803323257970881],
                        [-121.181911859159811, 55.810683894805813],
                        [-121.184343561220132, 55.810683704505223],
                        [-121.184341064497886, 55.81798136064247],
                        [-121.184338568547048, 55.825278748683182],
                        [-121.197392534425148, 55.825282108170903],
                        [-121.210445964894603, 55.825279683159621],
                        [-121.210444691406209, 55.832554544332844],
                        [-121.210443868842916, 55.83982482332312],
                        [-121.223497142802785, 55.839825376558345],
                        [-121.236551165977644, 55.839824697773459],
                        [-121.236550392815232, 55.847094964462883],
                        [-121.236550370901838, 55.854369728577531],
                        [-121.22349736593803, 55.854370679251431],
                        [-121.21044430619331, 55.854370129902428],
                        [-121.19739001533641, 55.854372524985351],
                        [-121.18433620660808, 55.854369459421491],
                        [-121.184336451588294, 55.861639732789449],
                        [-121.184336694632847, 55.868914505381852],
                        [-121.184336887206115, 55.876185045653656],
                        [-121.184336863355369, 55.883459818049396],
                        [-121.184337105146739, 55.89073009102669],
                        [-121.1843363332613, 55.898004862324619],
                        [-121.197390141474557, 55.898003632749891],
                        [-121.210442665515444, 55.898005241487745],
                        [-121.223496474319163, 55.898005778285885],
                        [-121.236548998515048, 55.898004816854694],
                        [-121.249603021079864, 55.897997857891284],
                        [-121.262655823048476, 55.897994172992163],
                        [-121.275669524906974, 55.89798850908133],
                        [-121.288683282166389, 55.897981345161078],
                        [-121.288682970208981, 55.905251592174835],
                        [-121.288682712062396, 55.912526068600563],
                        [-121.288682076939565, 55.919796315447272],
                        [-121.288682443161221, 55.927070794698636],
                        [-121.288683023401774, 55.934340827606519],
                        [-121.288682103340591, 55.941615574656126],
                        [-121.288682683335011, 55.948885554308745],
                        [-121.288682727098475, 55.956160300266916],
                        [-121.275668484648122, 55.956167495863099],
                        [-121.2626552603313, 55.956173243608248],
                        [-121.249601961491408, 55.956177165088206],
                        [-121.236548336531186, 55.956179598620693],
                        [-121.236548802472171, 55.948909617471976],
                        [-121.236548519277662, 55.941634868915941],
                        [-121.236548717876744, 55.934364619572271],
                        [-121.236549060665681, 55.928372815326703],
                        [-121.23654913128496, 55.927094585069689],
                        [-121.236548992064471, 55.925222824511096],
                        [-121.23654857972079, 55.919819836219737],
                      ],
                    ],
                  },
                },
              ],
            },
          });

          // layer
          map?.addLayer({
            id: 'geojson1',
            type: 'fill',
            source: 'Shell_groundbirch1',
            layout: {},
            paint: {
              'fill-color': '#8eb5ff',
              'fill-opacity': 0.4,
              // line color
            },
          });

          map?.addLayer({
            id: 'geojson2',
            type: 'fill',
            source: 'Shell_groundbirch2',
            layout: {},
            paint: {
              'fill-color': 'rgba(230,51,35,0.28)',
              'fill-opacity': 0.4,
              // line color
            },
          });

          map.addLayer({
            id: 'outline1',
            type: 'line',
            source: 'Shell_groundbirch1',
            layout: {},
            paint: {
              'line-color': 'rgba(50,118,255,0.47)',
              'line-width': 1,
            },
          });

          map.addLayer({
            id: 'outline2',
            type: 'line',
            source: 'Shell_groundbirch2',
            layout: {},
            paint: {
              'line-color': 'rgba(230,51,35,0.32)',
              'line-width': 1,
            },
          });
        });
      });
    }
  });
  return <div></div>;
};

export default MapBoundary;
