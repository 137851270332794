import React, { useEffect, useState } from 'react';
import { Space } from 'antd';
import { Statistic, StatisticCard } from '@ant-design/pro-card';
import { StaItem } from '@/service/core2/sta';
import { useMount } from 'ahooks';

import * as echarts from 'echarts/core';
import { TitleComponent, ToolboxComponent, TooltipComponent, GridComponent, DataZoomComponent } from 'echarts/components';
import { LineChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { useRandomDomId } from '@/utils/chart';
import { EllipsisOutlined } from '@ant-design/icons';
import { DatItem } from '@/service/core2/dat';
import { EChartsOption } from 'echarts';

echarts.use([TitleComponent, ToolboxComponent, TooltipComponent, GridComponent, DataZoomComponent, LineChart, CanvasRenderer, UniversalTransition]);

export function Noise(props: {
  dat: DatItem[];
  width: number;
  k1: string;
  k2: string;
  k3: string;
  r1: string;
  title: string;
  suffix?: string;
  min: number;
  max: number;
}) {
  const dat = props.dat;
  const width = props.width;
  const domId = useRandomDomId();

  const [globalChart, setGlobalChart] = useState<echarts.ECharts | null>(null);

  useEffect(() => {
    let chartDom: HTMLElement | null = null;
    let myChart: echarts.ECharts | null = null;

    chartDom = document.getElementById(domId);
    if (globalChart == null) {
      myChart = echarts.init(chartDom!);
      setGlobalChart(myChart);
    } else myChart = globalChart;

    let data = dat.map((item) => {
      let tpack = item.tpack < new Date('2000').getTime() ? item.tpack * 1000 : item.tpack;
      // @ts-ignore
      return [tpack, item[props.k1], item[props.k2], item[props.k3], item[props.r1]];
    });

    const option: EChartsOption = {
      title: {
        text: props.title,
      },
      tooltip: {
        trigger: 'axis',
      },
      useUTC: true,
      xAxis: {
        name: 'Time',
        type: 'time',
        boundaryGap: false,
        min: props.min,
        max: props.max,
      },
      yAxis: {
        type: 'value',
        scale: true,
        name: props.suffix,
      },
      legend: {
        data: [props.k1, props.k2, props.k3, props.r1],
      },
      series: [
        {
          name: props.k1,
          type: 'line',
          sampling: 'lttb',
          symbol: 'none',
          itemStyle: { color: '#00ff00' },
          dimensions: [
            {
              name: 'tpack',
              type: 'time',
              displayName: 'Time',
            },
            {
              name: 'noise1',
              displayName: 'ref',
            },
          ],
          data: data.map((item) => [item[0], item[1]]),
        },
        {
          name: props.k2,
          type: 'line',
          sampling: 'lttb',
          symbol: 'none',
          itemStyle: { color: '#0000ff' },
          dimensions: [
            {
              name: 'tpack',
              type: 'time',
              displayName: 'Time',
            },
            {
              name: 'noise2',
              displayName: 'ref',
            },
          ],
          data: data.map((item) => [item[0], item[2]]),
        },
        {
          name: props.k3,
          type: 'line',
          sampling: 'lttb',
          symbol: 'none',
          itemStyle: { color: '#666666' },
          dimensions: [
            {
              name: 'tpack',
              type: 'time',
              displayName: 'Time',
            },
            {
              name: 'noise2',
              displayName: 'ref',
            },
          ],
          data: data.map((item) => [item[0], item[3]]),
        },
        {
          name: props.r1,
          type: 'line',
          sampling: 'lttb',
          symbol: 'none',
          itemStyle: { color: '#cf1322' },
          dimensions: [
            {
              name: 'tpack',
              type: 'time',
              displayName: 'Time',
            },
            {
              name: 'noise2',
              displayName: 'ref',
            },
          ],
          data: data.map((item) => [item[0], item[4]]),
        },
      ],
    };
    option && myChart.setOption(option);
  });

  useEffect(() => {
    globalChart?.resize();
  }, [width]);

  return <div id={domId} style={{ height: '300px' }}></div>;
}
