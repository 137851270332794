import { PostQueryUsgsEventList, UsgsEventItem } from '@/service/map/event';
import mapboxgl from 'mapbox-gl';
import { Button, Descriptions, message } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import moment from 'moment/moment';
import React, { useEffect, useState } from 'react';
import { AdminDeleteEvent, AdminDeleteUsgsEvent } from '@/service/admin/event';
import ReactDOM from 'react-dom';
import Magnitude from '@/components/Magnitude';
import { connect } from 'umi';
import { setUsgs } from '@/pages/components/sideView/Events';
import { addMagnitudeMaker } from '@/pages/components/MapEvent';
import { useMount } from 'ahooks';

let _UserType: String | undefined = undefined;

const usgsEventDescriptions = (event: UsgsEventItem) => {
  return (
    <Descriptions title="Event" bordered={true} size="small" column={1} style={{ width: 400 }}>
      <Descriptions.Item label="Event ID ">{event.eventId}</Descriptions.Item>
      <Descriptions.Item label="Origin time (UTC) ">{moment.utc(event.t0Utc).format('YYYY-MM-DD HH:mm:ss.SSS')}</Descriptions.Item>
      <Descriptions.Item label="Latitude">{event.latitude}°</Descriptions.Item>
      <Descriptions.Item label="Longitude">{event.longitude}°</Descriptions.Item>
      <Descriptions.Item label="Depth (ground)">{event.depthGround}m</Descriptions.Item>
      <Descriptions.Item label="Magnitude">{event.magnitude}</Descriptions.Item>
      <Descriptions.Item label="Mode">{event.mode}</Descriptions.Item>
      {_UserType === 'Admin' && (
        <Descriptions.Item label="Operate">
          <Button
            danger={true}
            type="link"
            onClick={() => {
              AdminDeleteUsgsEvent(event.eventId).then(() => {
                message.success('Delete event successfully, please refresh the page');
              });
            }}
          >
            Delete
          </Button>
        </Descriptions.Item>
      )}
    </Descriptions>
  );
};

const usgsEventPopup = (event: UsgsEventItem, marker: mapboxgl.Marker) => {
  const element = (
    <div style={{ position: 'relative' }}>
      <div style={{ position: 'absolute', right: 0, top: 0 }}>
        <Button
          type="text"
          onClick={() => {
            marker.togglePopup();
          }}
        >
          <CloseOutlined />
        </Button>
      </div>
      {usgsEventDescriptions(event)}
    </div>
  ); // element
  const container = document.createElement('div');
  ReactDOM.render(element, container);
  return container;
};

export const addUsgsEventMaker = (map: mapboxgl.Map, lenLat: mapboxgl.LngLatLike, event: UsgsEventItem) => {
  const element = (
    <div style={{ transform: 'scale(2)' }}>
      <Magnitude magnitude={event.magnitude} shape="rect" />
    </div>
  );
  const container = document.createElement('div');
  ReactDOM.render(element, container);
  const marker = new mapboxgl.Marker({
    element: container,
  });
  marker.setLngLat(lenLat).setPopup(new mapboxgl.Popup({ offset: 25, closeButton: false }).setDOMContent(usgsEventPopup(event, marker)).setMaxWidth('800px'));
  return marker;
};

const usgsMarkerList: mapboxgl.Marker[] = [];

const Index: React.FC<{
  map?: mapboxgl.Map;
  userType: string | undefined;
  usgsEvent?: {
    usgsEventList: UsgsEventItem[];
    magnitudeRange: number[];
  };
}> = ({ map, usgsEvent, userType }) => {
  _UserType = userType;
  if (!map) return <div />;
  useEffect(() => {
    usgsMarkerList.forEach((it) => it.remove());
    while (usgsMarkerList.length > 0) {
      usgsMarkerList.pop();
    }
    usgsEvent?.usgsEventList.forEach((item) => {
      if (item.magnitude >= usgsEvent?.magnitudeRange[0] && item.magnitude <= usgsEvent?.magnitudeRange[1]) {
        const marker = addUsgsEventMaker(map, [item.longitude, item.latitude], item);
        usgsMarkerList.push(marker);
      }
    });
  });
  setUsgs((b) => {
    if (b) {
      usgsMarkerList.forEach((it) => it.addTo(map));
    } else {
      usgsMarkerList.forEach((it) => {
        it.remove();
      });
    }
  });

  return <div></div>;
};

export default connect((state) => {
  return state;
})(Index);
