import { PageContainer } from '@ant-design/pro-layout';
import { ActionType, ConfigProvider, EditableProTable, ProColumns } from '@ant-design/pro-table';
import { enUSIntl } from '@ant-design/pro-provider';
import { useRef } from 'react';
import { Network, PostAdminNetworkDelete, PostAdminNetworkPage, PostAdminNetworkUpdate } from '@/service/admin/network';
import NewNetworkModel from '@/pages/network/list/NewNetworkModel';
import { Button, message, Space } from 'antd';
import { action } from 'mobx';

const Index = () => {
  const ref = useRef<ActionType>();

  const columns: ProColumns<Network, 'text'>[] = [
    {
      title: 'id',
      dataIndex: 'id',
      valueType: 'text',
    },
    {
      title: 'name',
      dataIndex: 'name',
      valueType: 'text',
    },
    {
      title: 'description',
      valueType: 'text',
      dataIndex: 'description',
    },
    {
      title: 'station num',
      readonly: true,
      dataIndex: 'num',
    },
    {
      title: 'operation',
      valueType: 'option',
      readonly: true,
      render: (dom, entity, index, action) => {
        return (
          <Space>
            <Button
              onClick={() => {
                action?.startEditable?.(entity.id);
              }}
              type="link"
            >
              Edit
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <ConfigProvider value={{ intl: enUSIntl, valueTypeMap: {} }}>
      <PageContainer>
        <EditableProTable
          headerTitle="Network List"
          actionRef={ref}
          rowKey="id"
          options={{
            search: {
              name: 'query',
              placeholder: 'Search by email/name',
            },
            setting: false,
          }}
          columns={columns}
          search={false}
          request={async (params) => {
            const { data } = await PostAdminNetworkPage(params.pageSize ?? 10, params.current ?? 0, params.query ?? '');
            return {
              data: data.values,
              total: data.total,
              success: true,
            };
          }}
          pagination={{
            defaultPageSize: 10,
            showSizeChanger: true,
            showQuickJumper: true,
          }}
          toolbar={{
            actions: [<NewNetworkModel />],
          }}
          editable={{
            type: 'multiple',
            onSave: async (rowKey, data: Network) => {
              await PostAdminNetworkUpdate(`${rowKey}`, data);
            },
            onDelete: async (rowKey, row: Network) => {
              if (row.num > 0) {
                message.warn('The network cannot be deleted');
                return;
              }
              await PostAdminNetworkDelete(`${rowKey}`);
              ref.current?.reload();
            },
            // onChange: setEditableRowKeys,
          }}
          recordCreatorProps={{
            style: {
              display: 'none',
            },
          }}
        ></EditableProTable>
      </PageContainer>
    </ConfigProvider>
  );
};

export default Index;
