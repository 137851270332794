import { PageContainer } from '@ant-design/pro-layout';
import { Card, Tabs } from 'antd';
import Basic from '@/pages/user/profile/components/Basic';
import Security from '@/pages/user/profile/components/Security';
import React from 'react';
import FileTabView from '@/pages/experiment/FileTabView';
import { KeepAlive } from '@@/core/umiExports';
import MetaFileImportView from '@/pages/experiment/MetaFileImportView';

const { TabPane } = Tabs;

const Index = () => {
  return (
    <PageContainer>
      <Card>
        <Tabs defaultActiveKey="0" tabPosition="left" size="large" tabBarStyle={{ width: '180px' }}>
          <TabPane tab="Import" key="0">
            <MetaFileImportView />
          </TabPane>
          <TabPane tab="File" key="1">
            <FileTabView />
          </TabPane>
          <TabPane tab="Health" key="2"></TabPane>
          <TabPane tab="Api" key="3"></TabPane>
        </Tabs>
      </Card>
    </PageContainer>
  );
};

export default () => {
  return (
    <KeepAlive>
      <Index />
    </KeepAlive>
  );
};
