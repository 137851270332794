import React from 'react';
import { StationItem } from '@/service/map/station';
import { Box, Tabs, Tab, Button, Typography } from '@mui/material';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const Index: React.FC<{ station: StationItem | undefined }> = ({ station }) => {
  if (station == undefined) return <div />;
  const [value, setValue] = React.useState(0);
  return (
    // <div className='px-12 py-8 rounded-md' style={{width: '1000px', height: '700px', backgroundColor: 'white'}}>
    <div className="px-4">
      <Box>
        <Tabs
          value={value}
          onChange={(_, res) => {
            setValue(res);
          }}
          aria-label="basic tabs example"
        >
          <Tab label="OverView" />
          <Tab label="Battery" />
          <Tab label="Signal" />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        Item One
      </TabPanel>
      <TabPanel value={value} index={1}>
        Item Two
      </TabPanel>
      <TabPanel value={value} index={2}>
        Item Three
      </TabPanel>
      <div className="h-4" />
    </div>
  );
};

export default Index;
