import React, { useEffect, useState } from 'react';
import { Space } from 'antd';
import { Statistic, StatisticCard } from '@ant-design/pro-card';
import { StaItem } from '@/service/core2/sta';
import { useMount } from 'ahooks';

import * as echarts from 'echarts/core';
import { TitleComponent, ToolboxComponent, TooltipComponent, GridComponent, DataZoomComponent } from 'echarts/components';
import { LineChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { useRandomDomId } from '@/utils/chart';
import { EllipsisOutlined } from '@ant-design/icons';

echarts.use([TitleComponent, ToolboxComponent, TooltipComponent, GridComponent, DataZoomComponent, LineChart, CanvasRenderer, UniversalTransition]);

const Index: React.FC<{ sta: StaItem[]; width: number; min: number; max: number }> = ({ sta, width, min, max }) => {
  const domId = useRandomDomId();

  const [globalChart, setGlobalChart] = useState<echarts.ECharts | null>(null);

  useEffect(() => {
    let chartDom: HTMLElement | null = null;
    let myChart: echarts.ECharts | null = null;

    chartDom = document.getElementById(domId);
    if (globalChart == null) {
      myChart = echarts.init(chartDom!);
      setGlobalChart(myChart);
    } else myChart = globalChart;

    let data = sta.map((item) => {
      let tbeat = item.tbeat < new Date('2000').getTime() ? item.tbeat * 1000 : item.tbeat;
      return [tbeat, item.battery * 100];
    });

    const option = {
      title: {
        text: 'Battery',
      },
      useUTC: true,
      tooltip: {
        trigger: 'axis',
      },
      xAxis: {
        name: 'Time',
        type: 'time',
        boundaryGap: false,
        min,
        max,
      },
      yAxis: {
        type: 'value',
        name: '%',
        // boundaryGap: [0, '100%'],
        min: 0,
        max: 100,
      },
      series: [
        {
          name: 'battery',
          type: 'line',
          symbol: 'none',
          sampling: 'lttb',
          itemStyle: {
            color: 'rgb(255, 70, 131)',
          },
          // smooth: true,
          dimensions: [
            {
              name: 'tbeat',
              type: 'time',
            },
            {
              name: 'battery',
              type: 'value',
            },
          ],
          data: data,
        },
      ],
    };
    option && myChart.setOption(option);
  });

  useEffect(() => {
    globalChart?.resize();
  }, [width]);

  return <div id={domId} style={{ height: '300px' }}></div>;
};
export default Index;
