import { PostStationPublic, StationItem } from '@/service/map/station';
import { Effect, ImmerReducer, StationModelType } from '@@/plugin-dva/connect';

export interface PublicStationState {
  stationList: StationItem[];
}

export interface PublicStationModelType {
  namespace: 'publicStation';
  state: PublicStationState;
  reducers: {
    save: ImmerReducer<PublicStationState>;
  };
  effects: {
    fetchPublicStationList: Effect;
  };
}

const PublicStationModel: PublicStationModelType = {
  namespace: 'publicStation',
  state: {
    stationList: [],
  },
  reducers: {
    save(state, { payload }) {
      state.stationList = payload;
    },
  },
  effects: {
    *fetchPublicStationList({ payload }, { call, put }) {
      const response = yield call(PostStationPublic, payload);
      yield put({
        type: 'save',
        payload: response.data,
      });
    },
  },
};

export default PublicStationModel;
