import React from 'react';
import './404.less';
import dog from '/public/FIND.svg';
import { history } from 'umi';

const Page404 = () => {
  return (
    <div className="ErrorPage">
      <div className="ErrorPage-container">
        <div className="ErrorPage-text">
          <h1 className="ErrorPage-title">404</h1>
          <p className="ErrorPage-subtitle">the page you find seems to lost</p>
          <a
            onClick={() => history.push('/')}
            className="Button Button--primary Button--blue ErrorPage-primaryButton"
          >
            home
          </a>
          <div className="ErrorPage-otherButtonContainer">
            or
            <button
              className="Button Button--plain Button--blue ErrorPage-otherButton ErrorPage-goBackButton"
              onClick={() => history.go(-1)}
            >
              back
              <svg
                className="Zi Zi--ArrowRight"
                fill="currentColor"
                viewBox="0 0 24 24"
                width="24px"
                height="24px"
              >
                <path
                  d="M9.218 16.78a.737.737 0 0 0 1.052 0l4.512-4.249a.758.758 0 0 0 0-1.063L10.27 7.22a.737.737 0 0 0-1.052 0 .759.759 0 0 0-.001 1.063L13 12l-3.782 3.716a.758.758 0 0 0 0 1.063z"
                  fillRule="evenodd"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className="ErrorPage-errorImageContainer">
          <img className="ErrorPage-errorImage" src={dog} alt="page error" />
        </div>
      </div>
    </div>
  );
};

export default Page404;
