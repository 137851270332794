import React, { useEffect, useState } from 'react';
import { Space } from 'antd';
import { Statistic, StatisticCard } from '@ant-design/pro-card';
import { StaItem } from '@/service/core2/sta';
import { useMount } from 'ahooks';

import * as echarts from 'echarts/core';
import { TitleComponent, ToolboxComponent, TooltipComponent, GridComponent, DataZoomComponent } from 'echarts/components';
import { LineChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { useRandomDomId } from '@/utils/chart';
import { EllipsisOutlined } from '@ant-design/icons';
import { EChartsOption } from 'echarts';

echarts.use([TitleComponent, ToolboxComponent, TooltipComponent, GridComponent, DataZoomComponent, LineChart, CanvasRenderer, UniversalTransition]);

const statusMap = {
  0: 'Normal',
  1: 'Unstable',
  2: 'Error',
  3: 'Stop',
};

export function Status(props: { sta: StaItem[]; width: number; min: number; max: number }) {
  const sta = props.sta;
  const width = props.width;
  const domId = useRandomDomId();

  const [globalChart, setGlobalChart] = useState<echarts.ECharts | null>(null);

  useEffect(() => {
    let chartDom: HTMLElement | null = null;
    let myChart: echarts.ECharts | null = null;

    chartDom = document.getElementById(domId);
    if (globalChart == null) {
      myChart = echarts.init(chartDom!);
      setGlobalChart(myChart);
    } else myChart = globalChart;

    let data = sta.map((item) => {
      let tbeat = item.tbeat < new Date('2000').getTime() ? item.tbeat * 1000 : item.tbeat;
      // @ts-ignore
      return [tbeat, statusMap[item.status]];
    });

    const option: EChartsOption = {
      title: {
        text: 'Status',
      },
      tooltip: {
        trigger: 'item',
      },
      useUTC: true,
      xAxis: {
        name: 'Time',
        type: 'time',
        boundaryGap: false,
        min: props.min,
        max: props.max,
      },
      yAxis: {
        type: 'category',
        data: ['Normal', 'Unstable', 'Error', 'Stop'],
        splitLine: {
          show: true,
          // @ts-ignore
          alignWithLabel: true,
        },
      },
      series: [
        {
          type: 'scatter',
          itemStyle: {
            color: 'rgb(255, 70, 131)',
          },
          dimensions: [
            {
              name: 'tbeat',
              type: 'time',
            },
            {
              name: 'status',
            },
          ],
          data: data,
        },
      ],
    };
    option && myChart.setOption(option);
  });

  useEffect(() => {
    globalChart?.resize();
  }, [width]);

  return <div id={domId} style={{ height: '300px' }}></div>;
}
