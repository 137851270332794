import React from 'react';

const Index: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      width="300px"
      height="300px"
      viewBox="0 0 400 400"
      enableBackground="new 0 0 400 400"
    >
      <g>
        <g>
          <path
            fill="#CCE9FF"
            d="M247.9,13.187C167.396-6.721,79.468,28.552,35.41,104.863l0,0     c-79.425,137.569,31.093,307.559,188.487,285.473c86.658-12.1,153.051-80.806,165.107-165.035     C402.971,127.883,341.897,36.433,247.9,13.187z"
          />
          <path
            fill="#FFE3CA"
            d="M63.331,118.917C130.925,1.84,305.2,18.804,349.703,145.544l0,0c2.678,7.625,4.773,15.446,6.268,23.392     l0,0c14.521,77.201-29.643,152.648-103.004,178.405l0,0c-7.624,2.678-15.447,4.774-23.39,6.269l0,0     C97.222,378.504-4.079,235.676,63.331,118.917z"
          />
          <circle fill="#FFBE55" cx="229.225" cy="176.041" r="17.773" />
          <path
            fill="#FFBE55"
            d="M155.926,222.583c-2.817-1.082-5.878-1.675-9.076-1.675c-14.544,0-26.231,12.244-25.313,26.988     c0.786,12.625,11.059,22.9,23.684,23.69c6.731,0.42,12.937-1.786,17.697-5.693c4.87-3.994,11.476-5.27,17.285-2.831     c3.016,1.268,6.331,1.969,9.81,1.969c14.544,0,26.229-12.245,25.312-26.988c-0.784-12.624-11.058-22.898-23.682-23.688     c-6.731-0.421-12.938,1.786-17.697,5.691C168.917,224.172,161.999,224.91,155.926,222.583z"
          />
          <path
            fill="#FFBE55"
            d="M311.182,178.75c0.451-1.856,0.574-3.827,0.315-5.835c-1.182-9.134-9.815-15.479-19.001-13.708     c-12.681,2.448-17.502,18.452-7.946,27.447c2.905,2.734,4.24,6.779,3.182,10.624c-0.552,1.998-0.723,4.137-0.44,6.32     c1.181,9.133,9.816,15.479,19.001,13.707c7.863-1.518,13.482-8.803,12.955-16.794c-0.28-4.262-2.17-7.979-5.01-10.652     C311.24,187.038,310.212,182.751,311.182,178.75z"
          />
          <circle fill="#FFBE55" cx="159.314" cy="149.737" r="14.692" />
          <g>
            <circle fill="#FFBE55" cx="199.397" cy="108.88" r="8.123" />
          </g>
          <path
            fill="#FFBE55"
            d="M174.009,326.705c-8.116,0-14.694-6.575-14.694-14.692c0-8.115,6.578-14.69,14.694-14.69     c8.113,0,14.69,6.575,14.69,14.69C188.699,320.13,182.122,326.705,174.009,326.705z"
          />
          <path
            fill="#FFBE55"
            d="M324.657,296.197c11.273-14.269,20.043-30.413,25.857-47.714c-17.739-15.604-46.205-3.229-46.205,20.906     C304.31,282.167,312.923,292.925,324.657,296.197z"
          />
        </g>
      </g>
    </svg>
  );
};

export default Index;
