import React, { useRef, useState } from 'react';
import { ActionType, EditableProTable, ProColumns, ProTable } from '@ant-design/pro-table';
import { PostAdminMegaDataNetwork } from '@/service/admin/megaData';
import NewNetworkModel from '@/pages/network/list/NewNetworkModel';
import { Button, message, Space } from 'antd';
import { PostAdminNetworkDelete, PostAdminNetworkUpdate } from '@/service/admin/network';

type Network = {
  id: string;
  name: string;
  description: string;
};
const Networks = () => {
  const ref = useRef<ActionType>();
  const columns: ProColumns<Network>[] = [
    {
      title: 'NetworkId',
      width: 120,
      dataIndex: 'name',
      render: (_) => <a>{_}</a>,
    },
    {
      title: 'description',
      width: 480,
      dataIndex: 'description',
      render: (_) => <a>{_}</a>,
    },
    {
      title: 'operation',
      valueType: 'option',
      readonly: true,
      render: (dom, entity, index, action) => {
        return (
          <Space>
            <Button
              onClick={() => {
                action?.startEditable?.(entity.id);
              }}
              type="link"
            >
              Edit
            </Button>
          </Space>
        );
      },
    },
  ];

  const expandedRowRender = (item: any) => {
    let data = [];
    data = item.stations;
    if (data != null && data.length != 0)
      return (
        <ProTable
          columns={[{ title: 'stationId', dataIndex: 'stationId', key: 'stationId' }]}
          headerTitle={false}
          search={false}
          options={false}
          dataSource={data}
          pagination={false}
        />
      );
    else return <></>;
  };
  return (
    <EditableProTable<Network>
      columns={columns}
      rowKey="id"
      request={async (params) => {
        const { data } = await PostAdminMegaDataNetwork(params.pageSize ?? 10, params.current ?? 0, params.query ?? '');
        return {
          data: data.values,
          total: data.total,
          success: true,
        };
      }}
      pagination={{
        defaultPageSize: 7,
        showSizeChanger: true,
        showQuickJumper: true,
      }}
      recordCreatorProps={{
        style: {
          display: 'none',
        },
      }}
      search={false}
      options={{
        search: {
          name: 'query',
          placeholder: 'Search by networkId/description',
        },
        setting: false,
      }}
      toolbar={{
        actions: [<NewNetworkModel />],
      }}
      expandable={{ expandedRowRender }}
      size="small"
      editable={{
        type: 'multiple',
        onSave: async (rowKey, data: Network) => {
          await PostAdminNetworkUpdate(`${rowKey}`, data);
        },
        onDelete: async (rowKey, row: Network) => {
          await PostAdminNetworkDelete(`${rowKey}`);
          ref.current?.reload();
        },
      }}
    />
  );
};

export default Networks;
