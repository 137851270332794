import { KeepAlive } from '@@/core/umiExports';
import { PageContainer } from '@ant-design/pro-layout';
import { Card, Tabs } from 'antd';
import React, { useState } from 'react';
import Networks from './Networks';
import Stations from './Stations';
import Clients from '@/pages/megaData/Clients';

const { TabPane } = Tabs;

const MegaData = () => {
  const [tabKey, setTabKey] = useState('Networks');
  return (
    <PageContainer>
      <Card>
        <Tabs defaultActiveKey="Networks" onChange={(key) => setTabKey(key)}>
          <TabPane tab="Networks" key="Networks">
            {tabKey === 'Networks' && <Networks />}
          </TabPane>
          <TabPane tab="Stations" key="Stations">
            {tabKey === 'Stations' && <Stations />}
          </TabPane>
          <TabPane tab="Clients" key="Clients">
            {tabKey === 'Clients' && <Clients />}
          </TabPane>
        </Tabs>
      </Card>
    </PageContainer>
  );
};

export default () => {
  return (
    <KeepAlive>
      <MegaData />
    </KeepAlive>
  );
};
