import { StatisticCard } from '@ant-design/pro-card';
import React, { useState } from 'react';
import { Card } from 'antd';
import moment from 'moment-timezone';
import { history } from '@@/core/history';
import { StationItem } from '@/service/map/station';

const { Statistic } = StatisticCard;

const DatCard: React.FC<{
  station: StationItem;
}> = ({ station }) => {
  let color = '#fff';
  let lightText = false;

  const Title = (title: string) => {
    if (lightText) return <div className="text-white">{title}</div>;
    return <div>{title}</div>;
  };

  const Cell = ({ title, value }: any) => {
    return <Statistic value={value} title={Title(title)} layout="horizontal" valueStyle={{ color: lightText ? '#fff' : 'unset' }} />;
  };

  const maxTime = Math.max(station?.tbeat ?? 0, station?.tpack ?? 0);
  lightText = false;
  if (new Date().getTime() - maxTime < 3600000) {
    color = '#cbdfb8';
  } else if (new Date().getTime() - maxTime < 3600000 * 5) {
    color = '#e63323';
    lightText = true;
  } else {
    color = '#a6a6a6';
    lightText = true;
  }

  return (
    <div
      className="mt-2"
      onClick={() => {
        history.push(`/station/detail/status/${station.stationId}`);
      }}
    >
      <Card
        headStyle={{ backgroundColor: color }}
        bodyStyle={{ backgroundColor: color, color: '#fff' }}
        className="hover:shadow-xl shadow-md"
        title={
          <div className={lightText ? 'text-white' : ''}>
            <div className="font-bold">{station.stationId}</div>
            <div className="mt-2">Last update: {new Date(maxTime).toUTCString()}</div>
            <div className="mt-2">{moment(maxTime).startOf('seconds').fromNow()}</div>
          </div>
        }
      >
        <div>
          <Cell title="Index" value={station?.index ?? '-'} />
          <Cell title="Sample Rate" value={station?.sample_rate ?? '-'} />
          <Cell title="MQTT Sent" value={station?.n_mqtt_sent ?? '-'} />
          <Cell title="Temperature Sens" value={station?.temeprature_sensor ?? '-' + '℃'} />
          <Cell title="Temperature CPU" value={station?.temeprature_cpu ?? '-' + '℃'} />
          <Cell title="Battery" value={station?.battery == undefined ? '-' : station?.battery * 100 + '%'} />
          <Cell title="Signal LTE" value={station?.signalLTE ?? '-'} />
          <Cell title="Signal WiFi" value={station?.signelWiFi ?? '-'} />
          <Cell title="STD_a" value={`[${station?.std_ax ?? '-'},${station?.std_ay ?? '-'},${station?.std_az ?? '-'}]ug`} />
          <Cell title="STD_g" value={`[${station?.std_gx ?? '-'},${station?.std_gy ?? '-'},${station?.std_gz ?? '-'}]mdps`} />
          <Cell title="STD_as" value={`[${station?.std_asx ?? '-'},${station?.std_asy ?? '-'},${station?.std_asz ?? '-'}]ug`} />
        </div>
      </Card>
    </div>
  );
};

export default DatCard;
