import { UserStation } from '@/service/admin/userStation';
import { ResponseData } from '@/service/API';
import { request } from 'umi';

export async function PostUserStation() {
  return request<ResponseData<UserStation[]>>('/api/user/station/list', {
    method: 'POST',
  });
}

type StationGrantListAllItem = {
  id: string;
  stationId: string;
  userId: string;
};

export async function PostUserGrantStation() {
  return request<ResponseData<StationGrantListAllItem[]>>('/api/user/grant/listAll', {
    method: 'POST',
  });
}
