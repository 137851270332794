import React from 'react';
import { Button, message, Modal } from 'antd';
import { UserItem } from '@/service/user/common';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { PostUserDelete } from '@/service/user/list';
import { PostAdminStationGrantDelete, StationGrant } from '@/service/admin/stationGrant';

const DeleteStationGrant: React.FC<{
  entity: StationGrant;
  onDeleted: () => void;
}> = ({ children, entity, onDeleted }) => {
  const [loading, setLoading] = React.useState(false);
  return (
    <div>
      <Button
        danger
        onClick={() => {
          Modal.confirm({
            title: 'Confirm',
            icon: <ExclamationCircleOutlined />,
            content: (
              <div>
                <div>Are you sure to delete auth</div>
                <div>{` ${entity.stationId} - ${entity.userId} ?`}</div>
              </div>
            ),
            okText: 'Delete',
            closable: false,
            cancelText: 'Cancel',
            okType: 'danger',
            onOk() {
              setLoading(true);
              PostAdminStationGrantDelete(entity.id)
                .then(() => {
                  message.success(`Auth ${entity.stationId} - ${entity.userId} deleted`).then(() => {
                    onDeleted();
                    setLoading(false);
                  });
                })
                .catch(() => {
                  setLoading(false);
                });
            },
          });
        }}
        loading={loading}
      >
        Delete
      </Button>
    </div>
  );
};

export default DeleteStationGrant;
