import { IBestAFSRoute } from '@@/plugin-layout/layout/types';
import { CurrentUserInfo } from '@/service/user/common';

export default function (initialState: { currentUser?: CurrentUserInfo | undefined }) {
  const { currentUser } = initialState || {};
  const authorities = currentUser?.authorities || [];
  const res: any = {};
  authorities.forEach((it) => {
    res[it] = true;
  });
  return {
    ...authorities,
    routeAccess: (route: IBestAFSRoute) => {
      if (route.need !== undefined) {
        return authorities.some((it) => route.need?.includes(it));
      }
      if (route.userType === undefined) {
        return true;
      }
      return !(currentUser === undefined || currentUser.type !== route.userType);
    },
  };
}
