import React from 'react';
import { useModel } from '@@/plugin-model/useModel';
import { Avatar, Dropdown, Menu, Row, Space } from 'antd';
import { LogoutOutlined, PieChartOutlined, UndoOutlined, UserOutlined } from '@ant-design/icons';
import { PostUserLogout } from '@/service/user/common';
import { history } from '@@/core/history';
import Cookies from 'js-cookie';

const Index: React.FC = () => {
  const { initialState, refresh } = useModel('@@initialState');
  const currentUser = initialState?.currentUser;
  const otoken = Cookies.get('otoken');

  const action = {
    handleToDashboard: async () => {
      history.push('/dashboard');
    },
    handleLogout: async () => {
      await PostUserLogout();
      history.replace('/user/login');
    },

    handleToCenter: async () => {
      history.push('/user/profile');
    },

    switchBack: async () => {
      Cookies.set('xtoken', otoken as string);
      Cookies.remove('otoken');
      history.replace('/');
      window.location.reload();
    },
  };

  const menu = (
    <Menu>
      <Menu.Item key={3} icon={<PieChartOutlined />}>
        <div onClick={action.handleToDashboard}>Dashboard</div>
      </Menu.Item>
      <Menu.Item key={2} icon={<UserOutlined />}>
        <div onClick={action.handleToCenter}>User Profile</div>
      </Menu.Item>
      <Menu.Item key={1} icon={<LogoutOutlined />}>
        <div onClick={action.handleLogout}>Logout</div>
      </Menu.Item>
      {otoken && (
        <Menu.Item key={4} icon={<UndoOutlined />}>
          <div onClick={action.switchBack}>Switch Back</div>
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <Dropdown overlay={menu} placement="bottomRight">
      <div className="text-white font-semibold">
        <Space>
          <Avatar src="/api/user/avatar">{currentUser?.firstName?.at(0)}</Avatar>
          <div className="mr-4">
            {currentUser?.firstName} {currentUser?.lastName}
          </div>
        </Space>
      </div>
    </Dropdown>
  );
};
export default Index;
