import React from 'react';
import ProForm, {
  ModalForm,
  ProFormCaptcha,
  ProFormField,
  ProFormText,
} from '@ant-design/pro-form';
import {
  PostSendCaptchaEmail,
  PostUserModifyEmail,
  PostUserModifyPassword,
} from '@/service/user/common';
import { message } from 'antd';

const Index: React.FC<{}> = () => {
  return (
    <ModalForm
      title="Modify Password"
      trigger={<a>Modify</a>}
      onFinish={async (formData: Record<string, any>) => {
        const { oldPass, newPass } = formData;
        const { data, code } = await PostUserModifyPassword(oldPass, newPass);
        if (code === 200) {
          message.success('modify success');
          return true;
        } else {
          message.error(data.msg);
        }
      }}
    >
      <ProFormText.Password
        width="md"
        label="Old Password"
        name="oldPass"
        rules={[{ required: true, message: 'Please input your old Password!' }]}
      ></ProFormText.Password>
      <ProFormText.Password
        rules={[{ required: true, message: 'Please input your new Password!' }]}
        width="md"
        name="newPass"
        label="New Password"
        placeholder="Please enter"
      />
      <ProFormText.Password
        rules={[
          { required: true, message: 'Please input your new Password again!' },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('newPass') === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error('Two passwords that you enter is inconsistent!'),
              );
            },
          }),
        ]}
        width="md"
        name="rePassword"
        label="Confirm Password"
        placeholder="Please enter"
      />
    </ModalForm>
  );
};

export default Index;
