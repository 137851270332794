import { history } from 'umi';

export const goToLoginPage = () => {
  history.replace('/user/login');
};

export const goToLoginPageWithRedirect = (redirect: string) => {
  history.replace(`/user/login?redirect=${redirect}`);
};

export const isNotLoginPage = (): boolean => {
  return (
    history.location.pathname !== '/user/login' &&
    history.location.pathname !== '/user/recoveryPassword'
  );
};
