import React, { useState } from 'react';
import { PageContainer } from '@ant-design/pro-layout';
import RcResizeObserver from 'rc-resize-observer';
import { useMount, useRequest, useUnmount, useUpdateEffect } from 'ahooks';
import { Card, Col, Divider, Empty, Form, Row, Select, Spin } from 'antd';
import DatCard from '@/pages/station/status/DatCard';
import { ConfigProvider } from '@ant-design/pro-table';
import { enUSIntl } from '@ant-design/pro-provider';
import { PostAdminStationStatus } from '@/service/admin/station';
import { PostAdminUserChoices } from '@/service/admin/user';
import { UserItem } from '@/service/user/common';
import { StationItem } from '@/service/map/station';
import { KeepAlive } from '@@/core/umiExports';

const { Option } = Select;

const Page: React.FC = () => {
  const [data, setData] = useState<StationItem[]>([]);
  const [users, setUsers] = useState<UserItem[]>([]);
  const [responsive, setResponsive] = useState<number>(6);
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState<string | null>(null);

  const polling = async () => {
    const res = await PostAdminStationStatus(userId ?? '');
    setData(res.data);
    setLoading(false);
  };

  const { run, cancel } = useRequest(polling, {
    pollingInterval: 60000,
  });

  useUpdateEffect(() => {
    run();
  }, [userId]);

  useMount(async () => {
    const { data } = await PostAdminUserChoices('');
    setUsers(data);
    setUserId(data[0].id ?? '');
    run();
  });

  useUnmount(() => {
    cancel();
  });

  const userOptions = users.map((d) => (
    <Option key={d.id} value={d.id}>
      {`${d.id} ${d.firstName == '' ? '' : '-'} ${d.firstName ?? ''} ${d.lastName ?? ''}`}
    </Option>
  ));

  return (
    <ConfigProvider value={{ intl: enUSIntl, valueTypeMap: {} }}>
      <PageContainer>
        <RcResizeObserver
          key="resize-observer"
          onResize={(offset) => {
            if (offset.width < 600) setResponsive(24);
            else if (offset.width < 1200) setResponsive(12);
            else if (offset.width < 1500) setResponsive(8);
            else setResponsive(6);
          }}
        >
          <Card>
            <Form wrapperCol={{ span: 6 }}>
              <Form.Item label="Choose User">
                <Select
                  showSearch
                  defaultActiveFirstOption={false}
                  showArrow={false}
                  filterOption={false}
                  onSearch={async (keyWords) => {
                    if (keyWords) {
                      const { data } = await PostAdminUserChoices(keyWords);
                      setUsers(data);
                    } else {
                      const { data } = await PostAdminUserChoices('');
                      setUsers(data);
                    }
                  }}
                  value={userId}
                  onSelect={(value: string) => {
                    setUserId(value);
                    setLoading(true);
                  }}
                  onChange={(value: string) => {
                    setUserId(value);
                  }}
                  placeholder="Select user id/name/email"
                >
                  {userOptions}
                </Select>
              </Form.Item>
            </Form>
            <Divider></Divider>
            <Spin spinning={loading}>
              {data.length == 0 && <Empty />}
              <Row gutter={[32, 32]}>
                {data.map((item, index2) => {
                  return (
                    <Col key={`${item.stationId}-${index2}`} span={responsive} className="gutter-row">
                      <DatCard station={item} key={index2} />
                    </Col>
                  );
                })}
              </Row>
            </Spin>
          </Card>
        </RcResizeObserver>
      </PageContainer>
    </ConfigProvider>
  );
};

const Index = () => {
  return (
    <div>
      <KeepAlive saveScrollPosition={true}>
        <Page key="s-page"></Page>
      </KeepAlive>
    </div>
  );
};

export default Index;
