import React from 'react';
import { history } from 'umi';
import { useModel } from '@@/plugin-model/useModel';
import './index.less';

const Index: React.FC = () => {
  history.push('/dashboard');
  return <div />;
};

export default Index;
