import mapboxgl from 'mapbox-gl';

type ConstantType = {
  MAP_TOKEN: string;
  MAP_CENTER: mapboxgl.LngLatLike;
  [key: string]: any;
};

const constant: ConstantType = {
  MAP_TOKEN:
    'pk.eyJ1IjoidGFvaGFueHUiLCJhIjoiY2wwZmJwOHF4MHJmbzNtcHIwZGwzZm91bCJ9.oCKIGTMyU5iJhX5xJeH35g',
  // MAP_STYLE: 'mapbox://styles/taohanxu/ckwezms4g3h5614ocopizsjv9',
  MAP_STYLE: 'mapbox://styles/mapbox/minimal-v9',
  MAP_CENTER: [-95.18, 29.4],
  // MAP_CENTER: [-97.88, 30.41],
  // MAP_CENTER: [-122.4194, 37.7749],
};

export default constant;
