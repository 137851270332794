import { request } from 'umi';
import { PageData, ResponseData } from '@/service/API';

export type NetworkWithStation = {
  id: string;
  name: string;
  description: string;
  stations: [];
};

//获取NetWork
export function PostAdminMegaDataNetwork(current: number, size: number, query?: string) {
  return request<ResponseData<PageData<NetworkWithStation>>>('/api/admin/megaData/listNetwork', {
    method: 'POST',
    data: {
      current,
      size,
      query: query || '',
    },
  });
}

export type Station = {
  id: string;
  stationId: string;
  networkId: string;
};

//获取Station
export function PostAdminMegaDataStation(current: number, size: number, query?: string) {
  return request<ResponseData<PageData<Station>>>('/api/admin/megaData/listStation', {
    method: 'POST',
    data: {
      current,
      size,
      query: query || '',
    },
  });
}

type Stations = {
  stationId: String;
  networkId: String;
  id: String;
};

export type ClientType = {
  userId: String;
  stations: Array<Stations>;
};

//获取Clients
export function PostAdminMegaDataClients(current: number, size: number, query?: string) {
  return request<ResponseData<PageData<ClientType>>>('/api/admin/megaData/listClient', {
    method: 'POST',
    data: {
      current,
      size,
      query: query || '',
    },
  });
}
