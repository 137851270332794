import { useLocalStore } from 'mobx-react';
import { message, Steps, Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { observable } from 'mobx';

const { Step } = Steps;
const { Dragger } = Upload;

class MetaFileImportViewModel {
  @observable
  currentStep = 0;
}

const ViewModel = new MetaFileImportViewModel();

const MetaFileImportView = () => {
  const viewModel = useLocalStore(() => ViewModel);

  const props: UploadProps = {
    name: 'file',
    accept: '.xls, .xlsx',
    multiple: true,
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  return (
    <div>
      <div className="text-xl font-bold">Import Excel File</div>
      <div className="m-8">
        <Steps current={viewModel.currentStep}>
          <Step title="Preparation" description="Select and upload excel file" />
          <Step title="Pre-Analysis" description="Check the file format" />
          <Step title="Cloud Processing" description="Parsing and save the file" />
        </Steps>
      </div>
      <Dragger {...props}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Click or drag the excel file to this area to upload</p>
      </Dragger>
    </div>
  );
};

export default MetaFileImportView;
