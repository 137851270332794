import { Col, Row } from 'antd';
import Sider from '@/pages/components/Sider';
import MapView from '@/pages/components/MapView';
import './index.less';

const Map = () => {
  return (
    <Row className="fixed w-full" style={{ height: '99%' }} wrap={false}>
      <Col flex="490px">
        <Sider />
      </Col>
      <Col flex="auto">
        <MapView />
      </Col>
    </Row>
  );
};

export default Map;
