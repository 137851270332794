import { request } from 'umi';
import { ResponseData } from '@/service/API';
import { UserBoundary, UserItem } from '@/service/user/common';

export function PostAdminGetUser(userId: string) {
  return request<ResponseData<UserItem>>(`/api/admin/user/getUser/${userId}`, {
    method: 'POST',
  });
}

export function PostAdminUpdateBoundary({ userId, boundary }: { userId: string; boundary: UserBoundary }) {
  return request<ResponseData<UserItem>>(`/api/admin/user/updateBoundary`, {
    method: 'POST',
    data: {
      userId,
      boundary,
    },
  });
}

export function PostAdminUserChoices(keywords: string) {
  return request<ResponseData<UserItem[]>>(`/api/admin/user/choices`, {
    method: 'POST',
    data: {
      keywords,
    },
  });
}

export function PostAdminUserSwitch(id: string) {
  return request('/api/admin/user/switch/' + id, {
    method: 'POST',
  });
}
