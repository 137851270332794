import { request } from '@@/plugin-request/request';
import { ResponseData } from '@/service/API';

export type StationItem = {
  networkId: string;
  user: string[];
  index: string;
  id: string;
  createdTime: number;
  updatedTime: number;
  n_mqtt_sent: number;
  deleted: number;

  sample_rate: number;
  temeprature_sensor: number;
  temeprature_cpu: number;
  tpack: number;
  tbeat: number;
  status: number;
  ipAddress: string;
  stationId: string;
  latitude: number;
  longitude: number;
  depthMSL: number;
  depthGround: number;
  battery: number;
  signalLTE: number;
  signelWiFi: number;
  std_ax: number;
  std_ay: number;
  std_az: number;
  std_gx: number;
  std_gy: number;
  std_gz: number;
  std_asx: number;
  std_asy: number;
  std_asz: number;
  std_ref1: number;
  std_ref2: number;
  std_ref3: number;
  temperature: number;
  firmVersion: string;
  mqttVersion: string;
  ctime: number;
  userId: string;
};

export async function PostStationGetLast() {
  return request<ResponseData<StationItem[]>>('/api/station/getLast', {
    method: 'POST',
  });
}

export async function PostStationPublic() {
  return request<ResponseData<StationItem[]>>('/api/station/public', {
    method: 'POST',
  });
}
