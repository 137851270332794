import React from "react";
import mapboxgl from "mapbox-gl";
import {useMount} from "ahooks";

const MapViewController: React.FC<{
  map?: mapboxgl.Map;
}> = ({map}) => {
  // 判空
  if (!map) return <div/>;
  useMount(() => {

  })
  return <div/>
}


export default MapViewController
