import React from 'react';
import { useModel } from '@@/plugin-model/useModel';
import ProForm, {
  ModalForm,
  ProFormCaptcha,
  ProFormField,
} from '@ant-design/pro-form';
import {
  PostSendCaptchaEmail,
  PostUserModifyEmail,
} from '@/service/user/common';
import { message } from 'antd';

const EditEmailVIew: React.FC = () => {
  const { initialState, setInitialState } = useModel('@@initialState');
  const currentUser = initialState?.currentUser;

  return (
    <ModalForm
      title="Bind Email"
      trigger={<a>Modify</a>}
      onFinish={async (formData: Record<string, any>) => {
        const { email, captcha } = formData;
        const { data, code } = await PostUserModifyEmail(email, captcha);
        if (code === 200) {
          message.success('modify success');
          setInitialState({
            ...initialState,
            // @ts-ignore
            currentUser: {
              ...currentUser,
              email,
            },
          });
          return true;
        } else {
          message.error(data.msg);
        }
      }}
    >
      <div className="p-2 text-lg">
        The system needs to send an email with a verification code to your email
        address to verify that the email address is correct
      </div>
      <ProFormField
        width="md"
        label="New email"
        name="email"
        rules={[{ required: true, message: 'Please input your new email!' }]}
      ></ProFormField>
      <ProForm.Group>
        <ProFormCaptcha
          label="Verification code"
          name="captcha"
          phoneName="email"
          countDown={60}
          required={true}
          captchaTextRender={(timing, count) => {
            if (timing) {
              return `${count}s`;
            }
            return 'Send Code';
          }}
          onGetCaptcha={async (email) => {
            const res = await PostSendCaptchaEmail(email);
            if (res.code === 200) {
              message.success(
                'The system needs to send an email with a verification code to your email address to verify that the email address is correct',
              );
            } else {
              message.error(res.data.msg);
            }
          }}
        ></ProFormCaptcha>
      </ProForm.Group>
    </ModalForm>
  );
};

export default EditEmailVIew;
