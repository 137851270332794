import { request } from 'umi';
import { ResponseData } from '../API';
import moment, { Moment } from 'moment';
import PizZip from 'pizzip';

export type EventItem = {
  id: string;
  createdTime: number;
  updatedTime: number;
  deleted: number;

  eventId: string;
  t0Utc: number;
  t0Local: number;
  latitude: number;
  longitude: number;
  depthMsl: number;
  depthGround: number;
  uncertaintyLat: number;
  uncertaintyLog: number;
  uncertaintyDep: number;
  magnitude: number;
  mode: string;
  mxx: number;
  myy: number;
  mzz: number;
  mxz: number;
  mxy: number;
  myz: number;
  usedPhases: number;
  usedStations: number;
  MinStatDistance: number;
  MaxStatDistance: number;
  dTpRMS: number;
  dTsRMS: number;
  qcReport: string;
  sourceFileId: string;
};

export interface UsgsEventItem {
  id: string;
  createdTime: number;
  updatedTime: number;
  deleted: number;

  eventId: string;
  eventIdInternal: string;
  latitude: number;
  longitude: number;
  depthMsl: number;
  depthGround: number;
  t0Utc: number;
  magnitude: number;
  mode: number;
  sourceFileId: string;
}

export async function PostEventList() {
  return request<ResponseData<EventItem[]>>('/api/event/list', {
    method: 'POST',
  });
}

type QueryListEventListReq = {
  startTime: Moment;
  endTime: Moment;
};

export async function PostQueryListEventList(param: QueryListEventListReq) {
  return request<ResponseData<EventItem[]>>('/api/event/queryList', {
    method: 'POST',
    data: param,
  });
}

export async function PostUsgsEventList() {
  return request<ResponseData<UsgsEventItem[]>>('/api/event/usgsList', {
    method: 'POST',
  });
}

export async function PostQueryUsgsEventList(param: QueryListEventListReq): Promise<ResponseData<UsgsEventItem[]>> {
  return request<ResponseData<UsgsEventItem[]>>('/api/event/queryUsgsList', {
    method: 'POST',
    data: param,
  });
}

export function AttachmentEventReport(eventId: string) {
  window.open(`/api/event/report/${eventId}`);
}

const blobToArrayBuffer = (blob: Blob) => {
  return new Promise<ArrayBuffer>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result as ArrayBuffer);
    };
    reader.onerror = reject;
    reader.readAsArrayBuffer(blob);
  });
};

export async function genPdfZip(event: EventItem[]): Promise<Blob> {
  const zip = new PizZip();
  return new Promise((resolve, reject) => {
    Promise.all(
      event
        .map(
          (eventItem) =>
            new Promise(async (resolve2) => {
              const pdf = fetch(`/api/event/report/${eventItem.id}`)
                .then((res) => res.blob())
                .then((blob) => {
                  blobToArrayBuffer(blob).then((buffer) => {
                    zip.file(`${eventItem.eventId}.pdf`, buffer);
                    console.log('完成下载pdf', eventItem.eventId);
                    resolve2(1);
                  });
                });
            }),
        )
        .map((p) => {
          // 某一个出错
          return p.catch((err) => {
            console.log('下载出错', err);
          });
        }),
    ).then(() => {
      console.log('完成全部pdf下载');
      resolve(zip.generate({ type: 'blob' }));
    });
  });
}
