import ex from 'umi/dist';
import { Effect, ImmerReducer } from '@@/plugin-dva/connect';
import { PostStationGetLast, StationItem } from '@/service/map/station';
import { ResponseData } from '@/service/API';

export interface StationModelState {
  stationList: StationItem[];
  selectedStation: StationItem | undefined;
}

export interface StationModelType {
  namespace: 'station';
  state: StationModelState;
  reducers: {
    save: ImmerReducer<StationModelState>;
    setSelectedStation: ImmerReducer<StationModelState>;
  };
  effects: {
    fetchStationList: Effect;
    selectStation: Effect;
  };
}

const StationModel: StationModelType = {
  namespace: 'station',
  state: {
    stationList: [],
    selectedStation: undefined,
  },
  reducers: {
    save(state, { payload }) {
      state.stationList = payload;
    },
    setSelectedStation(state, { payload }) {
      state.selectedStation = payload;
    },
  },
  effects: {
    *fetchStationList({ payload }, { call, put }) {
      const response: ResponseData<StationItem[]> = yield call(PostStationGetLast, payload);
      const stations = response.data;
      const map = ['std_ax', 'std_ay', 'std_az', 'std_gx', 'std_gy', 'std_gz', 'std_asx', 'std_asy', 'std_asz', 'std_ref1', 'std_ref2', 'std_ref3'];
      // const map = ['ax', 'ay', 'az', 'gx', 'gy', 'gz', 'asx', 'asy', 'asz', 'ref1', 'ref2', 'ref3'];
      stations.forEach((station) => {
        map.forEach((key) => {
          // @ts-ignore
          if (typeof station[key] === 'string') {
            // @ts-ignore
            station[key] = NaN;
          }
        });
        if (typeof station['temeprature_cpu'] === 'string') {
          station['temeprature_cpu'] = 0;
        }
        if (typeof station['temeprature_sensor'] === 'string') {
          station['temeprature_sensor'] = 0;
        }
      });
      stations.sort((a, b) => {
        return a.stationId.localeCompare(b.stationId);
      });
      yield put({
        type: 'save',
        payload: response.data,
      });
    },
    *selectStation({ payload }, { call, put }) {
      yield put({
        type: 'setSelectedStation',
        payload: payload,
      });
    },
  },
};

export default StationModel;
