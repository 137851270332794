import React from 'react';
import { Card, Col, Row } from 'antd';
import GeneralInfo from '@/pages/user/profile/components/GeneralInfo';
import { CurrentUserInfo } from '@/service/user/common';
import { PageContainer } from '@ant-design/pro-layout';

let curUser: CurrentUserInfo | undefined = undefined;

import { Tabs } from 'antd';
import Basic from '@/pages/user/profile/components/Basic';
import Security from '@/pages/user/profile/components/Security';
import Notification from '@/pages/user/profile/components/Notification';
import { ConfigProvider } from '@ant-design/pro-table';
import { enUSIntl } from '@ant-design/pro-provider';

const { TabPane } = Tabs;

const Index: React.FC = () => {
  const [activeKey, setActiveKey] = React.useState('1');
  return (
    <ConfigProvider value={{ intl: enUSIntl, valueTypeMap: {} }}>
      <PageContainer title="User Profile">
        <Card>
          <Tabs
            defaultActiveKey="1"
            tabPosition="left"
            size="large"
            tabBarStyle={{ width: '180px' }}
          >
            <TabPane tab="Basic" key="1">
              <Basic />
            </TabPane>
            <TabPane tab="Security" key="2">
              <Security />
            </TabPane>
          </Tabs>
        </Card>
      </PageContainer>
    </ConfigProvider>
  );
};

export default Index;
