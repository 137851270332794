import { Collapse, Divider } from 'antd';
import React from 'react';
import Stations from '@/pages/components/sideView/Stations';
import Events from '@/pages/components/sideView/Events';
import { useMount, useWebSocket } from 'ahooks';
import { useDispatch } from 'umi';
import { useModel } from '@@/plugin-model/useModel';

function getCookie(cname: string) {
  const name = cname + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    const c = ca[i].trim();
    if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
  }
  return '';
}

const Sider: React.FC = () => {
  const dispatch = useDispatch();

  useMount(() => {
    dispatch!({
      type: 'station/fetchStationList',
      payload: {},
    });
    dispatch!({
      type: 'publicStation/fetchPublicStationList',
      payload: {},
    });
  });

  enum ReadyState {
    Connecting = 0,
    Open = 1,
    Closing = 2,
    Closed = 3,
  }

  // const { readyState, sendMessage, latestMessage, disconnect, connect } =
  //   useWebSocket(
  //     `ws://${window.location.hostname}/api/ws01/${getCookie('xtoken')}`,
  //   );
  //
  // if (latestMessage?.data == 'refreshStation') {
  //   console.log('refreshStation');
  // }

  return (
    <div
      style={{ height: '100%', overflowY: 'auto', backgroundColor: 'white' }}
    >
      <div className="m-4">
        <Events />
        <Divider />
        <Collapse bordered={false} ghost={true}>
          <Collapse.Panel
            header={<div className="text-xl font-medium">Stations</div>}
            key="1"
          >
            <Stations />
          </Collapse.Panel>
        </Collapse>
        <div style={{ height: 120 }} />
      </div>
    </div>
  );
};

export default Sider;
