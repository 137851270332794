import React, { useEffect, useState } from 'react';
import { PageContainer } from '@ant-design/pro-layout';
import { Button, Card, Col, Drawer, Empty, Row, Segmented, Spin, Tree } from 'antd';
import { DataNode, TreeProps } from 'antd/lib/tree';
import { useMount } from 'ahooks';
import { PostVibrationSignalsList, VibrationSignalsItem } from '@/service/mate/vibrationSignals';
import { useModel } from '@@/plugin-model/useModel';
import { Select } from 'antd';
import { PostUserGrantStation, PostUserStation } from '@/service/user/userStation';
import RcResizeObserver from 'rc-resize-observer';
import * as echarts from 'echarts/core';
import {
  TitleComponent,
  TitleComponentOption,
  TooltipComponent,
  TooltipComponentOption,
  GridComponent,
  GridComponentOption,
  LegendComponent,
  LegendComponentOption,
  VisualMapComponent,
  DataZoomComponent,
} from 'echarts/components';

import { ScatterChart, ScatterSeriesOption } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { PostAdminStationGrantListAll } from '@/service/admin/stationGrant';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  ScatterChart,
  CanvasRenderer,
  UniversalTransition,
  VisualMapComponent,
  DataZoomComponent,
]);

type EChartsOption = echarts.ComposeOption<TitleComponentOption | TooltipComponentOption | GridComponentOption | LegendComponentOption | ScatterSeriesOption>;

const { Option } = Select;

const pgaMap = [
  {
    title: 'Ⅺ+',
    desc: '(violent: >747mg)',
    color: '#ff0000',
    min: 747,
    max: Number.MAX_VALUE,
  },
  {
    title: 'Ⅷ',
    desc: '(severe: 401~747mg)',
    color: '#ee5b60',
    min: 401,
    max: 747,
  },
  {
    title: 'Ⅶ',
    desc: '(very strong: 215~401mg)',
    color: '#ffb347',
    min: 215,
    max: 401,
  },
  {
    title: 'Ⅵ',
    desc: '(strong: 115~215mg)',
    color: '#ffdf00',
    min: 115,
    max: 215,
  },
  {
    title: 'Ⅴ',
    desc: '(moderate: 27.6~115mg)',
    color: '#2196f3',
    min: 27.6,
    max: 115,
  },
  {
    title: 'Ⅳ',
    desc: '(light: 2.97~27.6mg)',
    color: '#1e8449',
    min: 2.97,
    max: 27.6,
  },
  {
    title: 'Ⅱ~Ⅲ',
    desc: '(weak: 0.464~2.97mg)',
    color: '#27b664',
    min: 0.464,
    max: 2.97,
  },
  {
    title: 'Ⅰ',
    desc: '(not felt: <0.464mg)',
    color: '#d4efdf',
    min: Number.MIN_VALUE,
    max: 0.464,
  },
];
const pga2Level = (pga: number) => {
  for (let i = 0; i < pgaMap.length; i++) {
    const element = pgaMap[i];
    if (pga < element.max && pga >= element.min) {
      return element;
    }
  }
  return;
};

const timeZoneOffset = {
  UTC: 0,
  AST: -4,
  EST: -5,
  CST: -6,
  MST: -7,
  PST: -8,
  AKST: -9,
  HST: -10,
};

const passedTimeMap = {
  All: -1,
  '4D': 96,
  '3D': 72,
  '2D': 48,
  '24H': 24,
  '12H': 12,
  '4H': 4,
  '1H': 1,
};

type ZoneAbbrName = 'UTC' | 'AST' | 'EST' | 'CST' | 'MST' | 'PST' | 'AKST' | 'HST';

type PassedTimeType = 'All' | '4D' | '3D' | '2D' | '24H' | '12H' | '4H' | '1H';

const storeZone: ZoneAbbrName = localStorage.getItem('Vibration_Signals_Time_zone') as ZoneAbbrName;

const passedTimeStore: PassedTimeType = localStorage.getItem('Vibration_Signals_Passed_time') as PassedTimeType;

let globalLoadingDeps = 0;
let scatterDom: HTMLElement | null = null;
let chartParentDom: HTMLElement | null = null;
let chartChildrenDom: HTMLElement | null = null;
let scatterChart: echarts.ECharts | null = null;
let scatterOption: EChartsOption | null = null;

const Index: React.FC = () => {
  const type: 'Admin' | 'User' = useModel('@@initialState').initialState?.currentUser?.type!!;
  const [treeData, setTreeData] = useState<DataNode[]>([]);
  const [scatterData, setScatterData] = useState<VibrationSignalsItem[]>([]);
  const [passedTimeSelect, setPassedTimeSelect] = useState<PassedTimeType>(passedTimeStore ?? '24H');
  const [timeZone, setTimeZone] = useState<ZoneAbbrName>(storeZone ?? 'UTC');

  const [loadingDeps, setLoadingDeps] = useState(0);
  const [checkedKeys, setCheckedKeys] = useState<any[]>([]);
  const [userIds, setUserIds] = useState<string[]>([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [chartWidth, setChartWidth] = useState(600);

  const addLoading = () => {
    setLoadingDeps(++globalLoadingDeps);
  };

  const removeLoading = () => {
    setLoadingDeps(--globalLoadingDeps);
  };

  const fetchData = () => {
    addLoading();
    try {
      // 右侧图
      PostVibrationSignalsList(passedTimeMap[passedTimeSelect]).then((res) => {
        removeLoading();
        res.data.forEach((item) => {
          item.amax = Number((item.amax * 1000).toPrecision(14));
        });
        setScatterData(res.data);
      });
    } catch (_) {}
  };

  useMount(() => {
    // 初始加载计数器
    globalLoadingDeps = 0;
    scatterDom = document.getElementById('container')!;
    chartParentDom = document.getElementById('chartParent');
    chartChildrenDom = document.getElementById('chartChildren');
    scatterChart = echarts.init(scatterDom);
    // 先显示左侧树
    fetchTreeData();
  });

  const fetchTreeData = async () => {
    if (type === 'Admin') {
      // Admin 用户
      return fetchTreeDataAdmin();
    } else {
      const tempCheckedKeys: React.SetStateAction<any[]> = [];
      // 普通用户
      try {
        addLoading();
        const userStations = (await PostUserGrantStation()).data;
        const treeData = userStations.map((it) => {
          tempCheckedKeys.push(it.stationId);
          return {
            key: it.stationId,
            title: it.stationId,
          };
        });
        setTreeData(treeData);
        setCheckedKeys(tempCheckedKeys);
      } catch (_) {
      } finally {
        removeLoading();
      }
    }
  };

  const fetchTreeDataAdmin = async () => {
    const tempCheckedKeys: React.SetStateAction<any[]> = [];
    try {
      addLoading();
      const { data } = await PostAdminStationGrantListAll();
      const userStations = data.filter((it) => it.stationId != '*');
      // 去重
      const users = Array.from(new Set(userStations.map((it) => it.userId)));
      setUserIds(users);
      const treeData = users.map((user) => ({
        key: user,
        title: user,
        children: userStations
          .filter((station) => station.userId === user)
          .map((station) => {
            tempCheckedKeys.push(station.stationId);
            return {
              key: station.stationId,
              title: station.stationId,
            };
          }),
      }));
      setTreeData(treeData);
      setCheckedKeys(tempCheckedKeys);
    } catch (_) {
    } finally {
      removeLoading();
    }
  };

  useEffect(() => {
    fetchData();
  }, [passedTimeSelect]);

  useEffect(() => {
    // const data = scatterData
    //   .map((it) => {
    //     const level = pga2Level(it.pga);
    //     return {
    //       Title: level?.title,
    //       stationId: it.stationId,
    //       time: moment
    //         .utc(it.time)
    //         .utcOffset(timeZoneOffset[timeZone])
    //         .format('yyyy-MM-DD_hh:mm:ss:SSS'),
    //       // pga: it.pga * 1000 + ' (mg)', // mg
    //       pga: Number((it.pga * 1000).toPrecision(14)) + ' (mg)',
    //     };
    //   })
    //   .filter((it) => {
    //     return checkedKeys.indexOf(it.stationId) !== -1;
    //   });
    const yA = checkedKeys
      .filter((key) => userIds.indexOf(key) === -1)
      .sort()
      .reverse();
    if (scatterDom === null) {
      scatterDom = document.getElementById('container')!;
      scatterChart = echarts.init(scatterDom);
    }

    let data = scatterData.map((it) => {
      return [it.tsig + timeZoneOffset[timeZone] * 3600 * 1000, it.station, it.amax, pga2Level(it.amax)?.title!];
    });

    if(yA.length==1){
      data = data.filter((it)=>{
        return it[1] == yA[0]
      })
    }

    scatterOption = {
      // legend: {
      //   data: pgaMap.map((it) => {
      //     return {
      //       name: it.title,
      //       icon: 'circle',
      //       itemStyle: {
      //         color: it.color,
      //       },
      //     };
      //   }),
      // },
      tooltip: {
        position: 'top',
      },
      dataZoom: [
        {
          type: 'slider',
          xAxisIndex: 0,
          filterMode: 'none',
        },
        {
          type: 'inside',
          xAxisIndex: 0,
          filterMode: 'none',
        },
      ],
      grid: {
        left: 2,
        bottom: 10,
        right: 10,
        containLabel: true,
      },
      useUTC: true,
      xAxis: {
        name: 'Time',
        nameLocation: 'middle',
        type: 'time',
        boundaryGap: ['5%', '5%'],
        splitLine: {
          show: true,
        },
        min:
          passedTimeSelect == 'All' ? undefined : new Date().getTime() - passedTimeMap[passedTimeSelect] * 3600 * 1000 + timeZoneOffset[timeZone] * 3600 * 1000,
        max: new Date().getTime() + timeZoneOffset[timeZone] * 3600 * 1000,
        axisLine: {
          show: true,
        },
      },
      yAxis: {
        name: 'Station ID',
        type: 'category',
        data: yA,
        axisLine: {
          show: true,
        },
        axisTick: {
          alignWithLabel: true,
          show: true,
        },
        splitLine: {
          show: true,
          // @ts-ignore
          alignWithLabel: true,
        },
      },
      visualMap: [
        {
          show: false,
          dimension: 2,
          type: 'piecewise',
          pieces: pgaMap.map((it) => {
            return {
              gte: it.min,
              lt: it.max,
              label: `${it.title}`,
              color: it.color,
            };
          }),
          outOfRange: {
            color: ['#000'],
          },
          top: 20,
          realtime: true,
        },
      ],
      series: pgaMap
        .map((it) => {
          return {
            name: it.title,
            data: data.filter((d) => d[3] === it.title),
            type: 'scatter',
            dimensions: [
              {
                name: 'time',
                type: 'time',
                displayName: 'Time',
              },
              {
                name: 'stationId',
                type: 'ordinal',
                displayName: 'Station ID',
              },
              {
                name: 'pga',
                type: 'float',
                displayName: 'PGA (mg)',
              },
              {
                name: 'level',
                type: 'ordinal',
                displayName: 'Level',
              },
            ],
            encode: {
              x: 'time',
              y: 'stationId',
              tooltip: ['time', 'stationId', 'pga', 'level'],
            },
            // symbolSize: function (val: any) {
            //   return val[2] * 10;
            // },
            symbol: 'circle',
          };
        })
        .reverse(),
    };

    scatterOption && scatterChart!.setOption(scatterOption);
  }, [scatterData, timeZone, checkedKeys]);

  const onSelect: TreeProps['onSelect'] = (selectedKeys, info) => {
    console.log('selected', selectedKeys, info);
  };

  const onCheck: TreeProps['onCheck'] = (checkedKeys, info) => {
    // @ts-ignore
    setCheckedKeys(checkedKeys);
  };

  return (
    <PageContainer>
      <Card style={{ overflow: 'hidden' }}>
        <Drawer
          title="Select stations"
          placement="left"
          onClose={() => setDrawerVisible(false)}
          visible={drawerVisible}
          closable={false}
          getContainer={false}
          style={{ position: 'absolute' }}
        >
          <div>
            {treeData.length == 0 && !(loadingDeps === 0) && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
            <Tree style={{ width: 300 }} checkable checkedKeys={checkedKeys} onSelect={onSelect} onCheck={onCheck} treeData={treeData} />
          </div>
        </Drawer>
        <Spin spinning={loadingDeps !== 0}>
          <div className="flex">
            {/*{drawerVisible && <div style={{ width: '378px' }}></div>}*/}
            <div className="flex-1">
              {/*<Row justify="space-between">*/}
              <Row>
                <Col flex="350px">
                  <Button onClick={() => setDrawerVisible(true)}>Select stations</Button>
                </Col>
                <Col flex="auto">
                  <Segmented
                    value={passedTimeSelect}
                    options={['All', '4D', '3D', '2D', '24H', '12H', '4H', '1H']}
                    onChange={(res) => {
                      localStorage.setItem('Vibration_Signals_Passed_time', `${res}`);
                      // @ts-ignore
                      setPassedTimeSelect(res);
                    }}
                  />
                </Col>
                <Col flex="300px">
                  <Select
                    defaultValue={timeZone}
                    style={{ width: 240 }}
                    onChange={(res) => {
                      localStorage.setItem('Vibration_Signals_Time_zone', res);
                      setTimeZone(res);
                    }}
                  >
                    <Option value="UTC">UTC</Option>
                    <Option value="AST">AST (UTC-4)</Option>
                    <Option value="EST">EST (UTC-5)</Option>
                    <Option value="CST">CST (UTC-6)</Option>
                    <Option value="MST">MST (UTC-7)</Option>
                    <Option value="PST">PST (UTC-8)</Option>
                    <Option value="AKST">AKST (UTC-9)</Option>
                    <Option value="HST">HST (UTC-10)</Option>
                  </Select>
                </Col>
              </Row>
              <div className="m-12"></div>
              <Row>
                <Col flex="auto">
                  <div style={{ height: '65vh' }} id="chartParent">
                    <div style={{ position: 'absolute', width: chartWidth }} id="chartChildren">
                      <RcResizeObserver
                        onResize={(res) => {
                          scatterOption!.width = chartParentDom!.offsetWidth;
                          setChartWidth(chartParentDom!.offsetWidth);
                          scatterChart!.resize();
                        }}
                      >
                        <div style={{ height: '65vh' }} id="container" />
                      </RcResizeObserver>
                    </div>
                  </div>
                </Col>
                <Col flex="300px">
                  <Level />
                </Col>
              </Row>
              <div className="m-12"></div>
            </div>
          </div>
        </Spin>
      </Card>
    </PageContainer>
  );
};

const Level: React.FC = () => {
  return (
    <div style={{ marginLeft: '10px', marginTop: '50px' }}>
      <div className="font-bold">Peak Ground Acceleration (PGA)</div>
      {pgaMap.map((it) => {
        return (
          <div key={it.title}>
            <Row>
              <Col flex="20px">
                <div
                  style={{
                    backgroundColor: it.color,
                    width: '10px',
                    height: '10px',
                    marginTop: '5px',
                    borderRadius: '50%',
                  }}
                />
              </Col>
              <Col flex="50px">{it.title}</Col>
              <Col flex="200px">{it.desc}</Col>
            </Row>
          </div>
        );
      })}
    </div>
  );
};

export default Index;
