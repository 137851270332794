import React from 'react';
import { Button, InputAdornment, SvgIcon, TextField } from '@mui/material';
import { PostUserLogin } from '@/service/user/common';
import { LoadingOutlined } from '@ant-design/icons';
import { history } from 'umi';
import { useModel } from '@@/plugin-model/useModel';

export const EmailIcon: React.FC = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 1024 1024" width="24" height="24">
      <path
        d="M554.666667 85.333333a426.666667 426.666667 0 0 0-213.333334 814.933334 433.066667 433.066667 0 0 0 170.666667 38.4 426.666667 426.666667 0 0 0 259.413333-87.893334 42.666667 42.666667 0 0 0 8.106667-59.733333 42.666667 42.666667 0 0 0-60.16-8.106667A341.333333 341.333333 0 1 1 544.853333 170.666667 348.586667 348.586667 0 0 1 853.333333 521.386667v29.013333a72.96 72.96 0 0 1-75.946666 72.533333 77.653333 77.653333 0 0 1-69.12-80.213333V358.4a42.666667 42.666667 0 0 0-42.666667-42.666667 42.666667 42.666667 0 0 0-42.666667 37.12 213.333333 213.333333 0 0 0-146.773333-58.026666A217.173333 217.173333 0 1 0 653.226667 640a153.6 153.6 0 0 0 236.8 26.026667A156.586667 156.586667 0 0 0 938.666667 550.4v-29.013333A435.2 435.2 0 0 0 554.666667 85.333333z m-77.653334 558.506667A131.84 131.84 0 1 1 608.853333 512a132.266667 132.266667 0 0 1-131.84 131.84z"
        p-id="2719"
      />
    </SvgIcon>
  );
};

export const LockIcon: React.FC = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 22 22" width="24" height="24">
      <path d="M16.5 9.5C16.8978 9.5 17.2794 9.65804 17.5607 9.93934C17.842 10.2206 18 10.6022 18 11V18C18 18.3978 17.842 18.7794 17.5607 19.0607C17.2794 19.342 16.8978 19.5 16.5 19.5H5.5C5.10217 19.5 4.72064 19.342 4.43934 19.0607C4.15804 18.7794 4 18.3978 4 18V11C4 10.6022 4.15804 10.2206 4.43934 9.93934C4.72064 9.65804 5.10217 9.5 5.5 9.5H16.5ZM16.5 8H5.5C4.70435 8 3.94129 8.31607 3.37868 8.87868C2.81607 9.44129 2.5 10.2044 2.5 11V18C2.5 18.7957 2.81607 19.5587 3.37868 20.1213C3.94129 20.6839 4.70435 21 5.5 21H16.5C17.2956 21 18.0587 20.6839 18.6213 20.1213C19.1839 19.5587 19.5 18.7957 19.5 18V11C19.5 10.2044 19.1839 9.44129 18.6213 8.87868C18.0587 8.31607 17.2956 8 16.5 8Z" />
      <path d="M11 2.5C11.9283 2.5 12.8185 2.86875 13.4749 3.52513C14.1313 4.1815 14.5 5.07174 14.5 6V8H7.5V6C7.5 5.07174 7.86875 4.1815 8.52513 3.52513C9.1815 2.86875 10.0717 2.5 11 2.5ZM11 1C9.67392 1 8.40215 1.52678 7.46447 2.46447C6.52678 3.40215 6 4.67392 6 6V9.5H16V6C16 4.67392 15.4732 3.40215 14.5355 2.46447C13.5979 1.52678 12.3261 1 11 1ZM11 16.75C10.8011 16.75 10.6103 16.671 10.4697 16.5303C10.329 16.3897 10.25 16.1989 10.25 16V13C10.25 12.8011 10.329 12.6103 10.4697 12.4697C10.6103 12.329 10.8011 12.25 11 12.25C11.1989 12.25 11.3897 12.329 11.5303 12.4697C11.671 12.6103 11.75 12.8011 11.75 13V16C11.75 16.1989 11.671 16.3897 11.5303 16.5303C11.3897 16.671 11.1989 16.75 11 16.75Z" />
    </SvgIcon>
  );
};

const goto = () => {
  if (!history) return;
  setTimeout(() => {
    const { query } = history.location;
    const { redirect } = query as {
      redirect: string;
    };
    history.push(redirect || '/');
  }, 10);
};

const Login: React.FC = () => {
  const [edit, editToggle] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const { initialState, setInitialState } = useModel('@@initialState');
  return (
    <div className="p-24">
      <h1 style={{ color: '#101727' }} className="text-3xl">
        User Login
      </h1>
      <h4 style={{ color: '#6e7480' }}>Welcome Back! please enter you detail.</h4>
      <div className="h-12" />
      <TextField
        id="outlined-basic"
        fullWidth
        label="Account"
        variant="outlined"
        onChange={(res) => {
          setEmail(res.target.value);
          editToggle(true);
        }}
        error={email == '' && edit}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <EmailIcon />
            </InputAdornment>
          ),
        }}
      />
      <div className="h-10" />
      <TextField
        id="outlined-basic"
        fullWidth
        label="Password"
        type="password"
        variant="outlined"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <LockIcon />
            </InputAdornment>
          ),
        }}
        error={password == '' && edit}
        onChange={(res) => {
          setPassword(res.target.value);
        }}
      />
      <div className="text-right">
        <Button
          sx={{ textTransform: 'capitalize' }}
          onClick={() => {
            history.push('/user/recoveryPassword');
          }}
        >
          Recovery Password
        </Button>
      </div>
      <div className="h-14" />
      <Button
        fullWidth
        variant="contained"
        size="large"
        onClick={() => {
          if (email == '' || password == '' || loading) {
            editToggle(true);
          } else {
            editToggle(false);
            setLoading(true);
            PostUserLogin({ email, password })
              .then((res) => {
                setLoading(false);
                // @ts-ignore
                setInitialState({
                  ...initialState,
                  currentUser: res.data,
                }).then((r) => {
                  if (res.data.type == 'Admin') {
                    // 这么写才能刷新路由
                    history.replace('/dashboard');
                  } else if (res.data.type == 'User') {
                    history.replace('/map');
                  }
                });
              })
              .catch((err) => {
                setLoading(false);
              });
          }
        }}
      >
        {loading ? (
          <span>
            <LoadingOutlined style={{ fontSize: 'large' }} />
            <span className="ml-4" /> Login
          </span>
        ) : (
          'Login'
        )}{' '}
      </Button>
      <div className="h-16" />
      <div className="text-center text-gray-600">
        <span>Do not have an account? </span>
        <span>
          <Button
            onClick={() => {
              window.open('https://sensorera.tech/');
            }}
          >
            contact us
          </Button>
        </span>
      </div>
    </div>
  );
};

export default Login;
