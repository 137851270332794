import { Card, Col, Row } from 'antd';
import React from 'react';
import Login from './components/Login';
import Introduce from '@/pages/user/login/components/Introduce';

const Index: React.FC = () => {
  return (
    <div
      className="w-full h-full absolute flex justify-center items-center"
      style={{ backgroundColor: '#f5f7fb' }}
    >
      <div className="transform 2xl:scale-100 xl:scale-90 lg:scale-75 md:scale-75 sm:scale-50">
        <div
          className="bg-white rounded-xl"
          style={{
            width: '1060px',
            overflow: 'hidden',
            boxShadow: '5px 5px 40px rgba(90, 166, 255, 0.15)',
          }}
        >
          <Row className="h-full">
            <Col span={12}>
              <Introduce />
            </Col>
            <Col span={12}>
              <Login />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Index;
