// @ts-nocheck
import React from 'react';
import { ApplyPluginsType } from '/root/workspace/node_modules/umi/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": require('/root/workspace/src/.umi-production/plugin-layout/Layout.tsx').default,
    "routes": [
      {
        "path": "/",
        "hideInMenu": true,
        "component": require('@/pages/index').default,
        "access": "routeAccess",
        "exact": true
      },
      {
        "path": "/user/login",
        "component": require('@/pages/user/login').default,
        "layout": false,
        "access": "routeAccess",
        "exact": true
      },
      {
        "path": "/user/recoveryPassword",
        "component": require('@/pages/user/recovery').default,
        "layout": false,
        "access": "routeAccess",
        "exact": true
      },
      {
        "path": "/error",
        "routes": [
          {
            "path": "/error/403",
            "component": require('@/pages/error/403').default,
            "access": "routeAccess",
            "exact": true
          },
          {
            "path": "/error/404",
            "component": require('@/pages/error/404').default,
            "access": "routeAccess",
            "exact": true
          }
        ],
        "layout": false,
        "access": "routeAccess"
      },
      {
        "path": "/dashboard",
        "name": "Dashboard",
        "icon": "dashboard",
        "component": require('@/pages/dashboard/index').default,
        "access": "routeAccess",
        "exact": true
      },
      {
        "path": "/station/list",
        "name": "Station",
        "icon": "DatabaseOutlined",
        "component": require('@/pages/station/list/index').default,
        "access": "routeAccess",
        "exact": true
      },
      {
        "path": "/station/grant/",
        "name": "Station Authorization",
        "icon": "PartitionOutlined",
        "userType": "Admin",
        "component": require('@/pages/station/grant/List.tsx').default,
        "access": "routeAccess",
        "exact": true
      },
      {
        "path": "/station/status",
        "name": "Station Status",
        "icon": "FundOutlined",
        "userType": "Admin",
        "routes": [
          {
            "path": "/station/status/block",
            "name": "Status",
            "component": require('@/pages/station/status/index').default,
            "access": "routeAccess",
            "exact": true
          },
          {
            "path": "/station/status/list",
            "name": "Retrieve",
            "component": require('@/pages/station/retrieve/index').default,
            "access": "routeAccess",
            "exact": true
          }
        ],
        "access": "routeAccess"
      },
      {
        "path": "/station/detail/:from/:stationId",
        "component": require('@/pages/station/status/TimeLineInfo').default,
        "name": "Station Detail",
        "userType": "Admin",
        "hideInMenu": true,
        "access": "routeAccess",
        "exact": true
      },
      {
        "path": "/network",
        "name": "Network",
        "icon": "ForkOutlined",
        "userType": "Admin",
        "component": require('@/pages/network/list/index').default,
        "access": "routeAccess",
        "exact": true
      },
      {
        "path": "/vibrationSignals",
        "name": "Vibration Signals",
        "icon": "AlertOutlined",
        "component": require('@/pages/vibrationSignals/index').default,
        "access": "routeAccess",
        "exact": true
      },
      {
        "path": "/event/list",
        "component": require('@/pages/event/list/index').default,
        "hideMenu": true,
        "access": "routeAccess",
        "exact": true
      },
      {
        "path": "/admin/manager",
        "name": "Users Manage",
        "icon": "UnorderedListOutlined",
        "component": require('@/pages/admin/userManager').default,
        "userType": "Admin",
        "access": "routeAccess",
        "exact": true
      },
      {
        "path": "/admin/boundary/:userId",
        "name": "boundary",
        "component": require('@/pages/admin/BoundaryGeoJson').default,
        "userType": "Admin",
        "hideInMenu": true,
        "access": "routeAccess",
        "exact": true
      },
      {
        "path": "/megaData",
        "name": "Mega Data",
        "icon": "InsertRowAboveOutlined",
        "component": require('@/pages/megaData/index').default,
        "userType": "Admin",
        "access": "routeAccess",
        "exact": true
      },
      {
        "path": "/experiment",
        "name": "Experiment",
        "icon": "ExperimentOutlined",
        "need": [
          "Experiment"
        ],
        "hideInMenu": true,
        "component": require('@/pages/experiment/index').default,
        "access": "routeAccess",
        "exact": true
      },
      {
        "path": "/user/profile",
        "name": "User Profile",
        "icon": "user",
        "component": require('@/pages/user/profile').default,
        "access": "routeAccess",
        "exact": true
      },
      {
        "path": "/map",
        "name": "Map",
        "icon": "global",
        "component": require('@/pages/map').default,
        "layout": {
          "hideMenu": true
        },
        "pure": true,
        "access": "routeAccess",
        "exact": true
      },
      {
        "path": "/error",
        "layout": false,
        "routes": [
          {
            "path": "/error/403",
            "component": require('@/pages/error/403').default,
            "access": "routeAccess",
            "exact": true
          },
          {
            "path": "/error/404",
            "component": require('@/pages/error/404').default,
            "access": "routeAccess",
            "exact": true
          }
        ],
        "access": "routeAccess"
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
