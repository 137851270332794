import React from 'react';
import Icon from '@ant-design/icons';

const SvgIcon = (color: string | null) => {
  const fillColor = color ? color : '#000';
  // width="128" height="128"
  return () => {
    return (
      <svg
        d="1648925799007"
        className="icon"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="5903"
        width="24"
        height="24"
      >
        <path
          d="M512.299941 419.318102c-253.250537 0-458.610428-77.384886-458.610427-172.766257V851.033782c0 95.481351 205.359891 172.766257 458.610427 172.766257S970.810389 946.415153 970.810389 851.033782V246.451865c0 95.481351-205.25991 172.866237-458.510448 172.866237z"
          fill="#7F848A"
          p-id="5904"
        ></path>
        <path
          d="M53.689514 172.766257a458.610428 172.766257 0 1 0 917.220855 0 458.610428 172.766257 0 1 0-917.220855 0Z"
          fill={fillColor}
          p-id="5905"
        ></path>
      </svg>
    );
  };
};

// const StationIcon: React.FC<{ status: number }> = ({ status }) => {
//   const list = ['#23ac4c', '#ffc000', '#ff5500', '#808080'];
//
//   return (
//     <div>
//       <Icon
//         component={SvgIcon(list[status])}
//         style={{ width: '5px', height: '5px' }}
//       />
//     </div>
//   );
// };

const StationIcon: React.FC<{ status: number }> = ({ status }) => {
  const list = ['#23ac4c', '#ffc000', '#ff5500', '#808080'];

  return (
    <div>
      <img src={`/station${status}.png`} alt={''} width={40} height={40} />
    </div>
  );
};
export default StationIcon;
