import React, { useEffect, useState } from 'react';
import { Space } from 'antd';
import { Statistic, StatisticCard } from '@ant-design/pro-card';
import { StaItem } from '@/service/core2/sta';
import { useMount } from 'ahooks';

import * as echarts from 'echarts/core';
import { TitleComponent, ToolboxComponent, TooltipComponent, GridComponent, DataZoomComponent } from 'echarts/components';
import { LineChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { useRandomDomId } from '@/utils/chart';
import { EllipsisOutlined } from '@ant-design/icons';
import { DatItem } from '@/service/core2/dat';

echarts.use([TitleComponent, ToolboxComponent, TooltipComponent, GridComponent, DataZoomComponent, LineChart, CanvasRenderer, UniversalTransition]);

export function Inclination(props: { dat: DatItem[]; width: number; min: number; max: number }) {
  const dat = props.dat;
  const width = props.width;
  const domId = useRandomDomId();

  const [globalChart, setGlobalChart] = useState<echarts.ECharts | null>(null);

  useEffect(() => {
    let chartDom: HTMLElement | null = null;
    let myChart: echarts.ECharts | null = null;

    chartDom = document.getElementById(domId);
    if (globalChart == null) {
      myChart = echarts.init(chartDom!);
      setGlobalChart(myChart);
    } else myChart = globalChart;

    let data = dat.map((item) => {
      let tpack = item.tpack < new Date('2000').getTime() ? item.tpack * 1000 : item.tpack;
      return [tpack, item.inclination];
    });

    const option = {
      title: {
        text: 'Inclination',
      },
      tooltip: {
        trigger: 'axis',
      },
      useUTC: true,
      xAxis: {
        name: 'Time',
        type: 'time',
        boundaryGap: false,
        min: props.min,
        max: props.max,
      },
      yAxis: {
        type: 'value',
        scale: true,
        name: 'deg',
      },
      series: [
        {
          name: 'inclination',
          type: 'line',
          symbol: 'none',
          sampling: 'lttb',
          itemStyle: {
            color: 'rgb(255, 70, 131)',
          },
          dimensions: [
            {
              name: 'tpack',
              type: 'time',
            },
            {
              name: 'inclination',
              type: 'value',
            },
          ],
          data: data,
        },
      ],
    };
    option && myChart.setOption(option);
  });

  useEffect(() => {
    globalChart?.resize();
  }, [width]);

  return <div id={domId} style={{ height: '300px' }}></div>;
}
