import { request } from 'umi';
import { PageData, ResponseData } from '@/service/API';

export type Network = {
  id: string;
  name: string;
  description: string;
};

export function PostAdminNetworkPage(current: number, size: number, query?: string) {
  return request<ResponseData<PageData<Network>>>('/api/admin/network/page', {
    method: 'POST',
    data: {
      current,
      size,
      query: query || '',
    },
  });
}

export function PostAdminNetworkAdd(name: string, description: string) {
  return request('/api/admin/network/add', {
    method: 'POST',
    data: {
      name,
      description,
    },
  });
}

export function PostAdminNetworkUpdate(id: string, network: Network) {
  return request('/api/admin/network/update/' + id, {
    method: 'POST',
    data: {
      ...network,
    },
  });
}

export function PostAdminNetworkDelete(id: string) {
  return request('/api/admin/network/delete/' + id, {
    method: 'POST',
  });
}

export function PostAdminNetworkList() {
  return request<ResponseData<Network[]>>('/api/admin/network/list', {
    method: 'POST',
    data: '',
  });
}

export function PostAdminNetworkChoices(keywords: string) {
  return request<ResponseData<Network[]>>('/api/admin/network/choices', {
    method: 'POST',
    data: {
      keywords: keywords,
    },
  });
}
