import { request } from 'umi';
import { StationItem } from '@/service/map/station';
import { ResponseData } from '@/service/API';
import { EventItem } from '@/service/map/event';

export async function postDashboardStationsStatistics() {
  return request<ResponseData<number[]>>('/api/user/dashboard/v1/stations/statistics', {
    method: 'POST',
  });
}

export async function postDashboardEventsGetLastMonth() {
  return request<ResponseData<EventItem[]>>('/api/user/dashboard/v1/events/lastOneMonth', {
    method: 'POST',
  });
}
