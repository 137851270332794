// AdminDeleteEvent
import { request } from 'umi';

export function AdminDeleteEvent(eventId: string) {
  return request('/api/event/delete/' + eventId, {
    method: 'POST',
  });
}

export async function AdminDeleteUsgsEvent(eventId: string) {
  return request('/api/event/deleteUsgs/' + eventId, {
    method: 'POST',
  });
}
