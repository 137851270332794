import { PostQueryUsgsEventList, UsgsEventItem } from '@/service/map/event';
import { Effect, ImmerReducer } from '@@/plugin-dva/connect';

export interface UsgsEventModelState {
  usgsEventList: UsgsEventItem[];
  magnitudeRange: number[];
}

export interface UsgsEventModelType {
  namespace: 'usgsEvent';
  state: UsgsEventModelState;
  reducers: {
    save: ImmerReducer<UsgsEventModelState>;
    level: ImmerReducer<UsgsEventModelState>;
  };
  effects: {
    queryUsgsEventList: Effect;
    changeMagnitudeRange: Effect;
  };
}

const UsgsEventModel: UsgsEventModelType = {
  namespace: 'usgsEvent',
  state: {
    usgsEventList: [],
    magnitudeRange: [1, 9],
  },
  reducers: {
    save(state, { payload }) {
      state.usgsEventList = payload;
    },
    level(state, { payload }) {
      state.magnitudeRange = payload;
    },
  },

  effects: {
    *queryUsgsEventList({ payload }, { call, put }) {
      const { data } = yield call(PostQueryUsgsEventList, payload);
      yield put({
        type: 'save',
        payload: data,
      });
    },
    *changeMagnitudeRange({ payload }, { put }) {
      yield put({
        type: 'level',
        payload: payload,
      });
    },
  },
};

export default UsgsEventModel;
