import mapboxgl from 'mapbox-gl';
import ReactDOM from 'react-dom';
import React, { useEffect } from 'react';
import StationIcon from '@/components/StationIcon';
import { Button, Descriptions } from 'antd';
import { StationItem } from '@/service/map/station';
import { connect } from 'umi';
import { CloseOutlined } from '@ant-design/icons';

// 我是真没办法写hooks了 [捂脸]，单纯函数里不能使用Hooks，这里又不能改成React函数式组件，层层传参更不优雅，只能引入全局变量了
let _UserType: String | undefined = undefined;

const stationPopup = (station: StationItem, marker: mapboxgl.Marker) => {
  const convertSignal = (num: number) => {
    if (num >= 0.85) {
      return 'Excellent';
    } else if (num >= 0.7) {
      return 'Good';
    } else if (num >= 0.55) {
      return 'Fair';
    } else if (num >= 0.25) {
      return 'Poor';
    } else return 'Very Poor';
  };
  const element = (
    <div style={{ position: 'relative' }}>
      <div style={{ position: 'absolute', right: 0, top: 0 }}>
        <Button
          type="text"
          onClick={() => {
            marker.togglePopup();
          }}
        >
          <CloseOutlined />
        </Button>
      </div>
      <Descriptions title="Station" bordered={true} size="small" column={1} style={{ width: 400 }}>
        {/*{_UserType === 'Admin' && station.user != undefined && <Descriptions.Item label="Client ID">{station.user.join(', ') || '-'}</Descriptions.Item>}*/}
        <Descriptions.Item label="Station ID">{station.stationId}</Descriptions.Item>
        <Descriptions.Item label="Network">{station.networkId}</Descriptions.Item>
        <Descriptions.Item label="Latitude">{station.latitude}°</Descriptions.Item>
        <Descriptions.Item label="Longitude">{station.longitude}°</Descriptions.Item>
        <Descriptions.Item label="DepthMsl">{station.depthMSL.toFixed(1)}m</Descriptions.Item>
        <Descriptions.Item label="Battery">{station?.battery * 100}%</Descriptions.Item>
        <Descriptions.Item label="SignalLTE">{convertSignal(station.signalLTE)}</Descriptions.Item>
        <Descriptions.Item label="Temperature">
          cpu:{station.temeprature_cpu?.toFixed(1)}℃,sensor:{station.temeprature_sensor?.toFixed(1)}℃
        </Descriptions.Item>
        <Descriptions.Item label="Noise level">
          <div>
            <span>{'A = [ '}</span>
            <span>{`${station.std_ax?.toFixed(3)}, `}</span>
            <span>{`${station.std_ay?.toFixed(3)}, `}</span>
            <span>{station.std_az?.toFixed(3)}</span>
            <span>{' ] (ug)'}</span>
          </div>
          <div>
            <span>{'G = [ '}</span>
            <span>{`${station.std_gx?.toFixed(3)}, `}</span>
            <span>{`${station.std_gy?.toFixed(3)}, `}</span>
            <span>{station.std_gz?.toFixed(3)}</span>
            <span>{' ] (mdps)'}</span>
          </div>
          <div>
            <span>{'L = [ '}</span>
            <span>{`${station.std_asx?.toFixed(3)}, `}</span>
            <span>{`${station.std_asy?.toFixed(3)}, `}</span>
            <span>{station.std_asz?.toFixed(3)}</span>
            <span>{' ] (ug)'}</span>
          </div>
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
  const container = document.createElement('div');
  ReactDOM.render(element, container);
  return container;
};

export const addStationMaker = (map: mapboxgl.Map, lenLat: mapboxgl.LngLatLike, station: StationItem) => {
  const element = (
    <div>
      <StationIcon status={station.status} />
    </div>
  );
  const container = document.createElement('div');
  ReactDOM.render(element, container);
  const marker = new mapboxgl.Marker({
    element: container,
  });
  marker
    .setLngLat([station.longitude, station.latitude])
    .setPopup(
      new mapboxgl.Popup({
        offset: 25,
        maxWidth: '450px',
        closeButton: false,
      }).setDOMContent(stationPopup(station, marker)),
    )
    .addTo(map);
  return marker;
};
type StationMarker = {
  marker: mapboxgl.Marker;
  station: StationItem;
};

export const markerList: StationMarker[] = [];

const Index: React.FC<{
  map?: mapboxgl.Map;
  userType: string | undefined;
  station?: {
    stationList: StationItem[];
  };
}> = ({ map, station, userType }) => {
  _UserType = userType;
  // 判空
  if (!map) return <div />;
  useEffect(() => {
    markerList.forEach((marker) => marker.marker.remove());
    while (markerList.length > 0) {
      markerList.pop();
    }
    station?.stationList.forEach((item) => {
      // @ts-ignore
      if ((item.latitude !== 0 && item.longitude !== 0) || item.latitude === 'NaN' || item.longitude === 'NaN') {
        try {
          const marker = addStationMaker(map, [item.longitude, item.latitude], item);
          markerList.push({
            marker: marker,
            station: item,
          });
        } catch (e) {
          console.error(e, item);
        }
      }
    });
  });
  return <div />;
};

export default connect((state) => {
  return state;
})(Index);
