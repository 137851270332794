import { request } from '@@/plugin-request/request';
import { ResponseData } from '@/service/API';

export type ReportSubscription = {
  enablePgaWarning: boolean;
  pgaLevel?: string;
  pgaEmails?: string[];

  enablePublicEvent: boolean;
  publicDistance?: number;
  publicEmails?: string[];

  enablePrivateEmail: boolean;
  enablePrivateMessage: boolean;
  privateDistance?: number;
  privateEmails?: string[];
  privateCalls?: string[];
};

export type ReportSubscriptionModel = ReportSubscription & { userId: string };

export function AdminReportGetByUserId(userId: string) {
  return request<ResponseData<ReportSubscriptionModel>>('/api/admin/report/getByUserId/' + userId, {
    method: 'POST',
  });
}

export function AdminReportUpdate(param: ReportSubscriptionModel) {
  return request<ResponseData<ReportSubscriptionModel>>('/api/admin/report/update', {
    method: 'POST',
    data: param,
  });
}
