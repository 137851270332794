import mapboxgl from 'mapbox-gl';
import ReactDOM from 'react-dom';
import React, { useEffect } from 'react';
import { Button, Descriptions } from 'antd';
import { StationItem } from '@/service/map/station';
import { connect } from 'umi';
import { CloseOutlined } from '@ant-design/icons';
import Cylindrical from '@/icons/cylindrical';

const stationPopup = (station: StationItem, marker: mapboxgl.Marker) => {
  const convertSignal = (num: number) => {
    if (num >= 0.85) {
      return 'Excellent';
    } else if (num >= 0.7) {
      return 'Good';
    } else if (num >= 0.55) {
      return 'Fair';
    } else if (num >= 0.25) {
      return 'Poor';
    } else return 'No Signal';
  };
  const element = (
    <div style={{ position: 'relative' }}>
      <div style={{ position: 'absolute', right: 0, top: 0 }}>
        <Button
          type="text"
          onClick={() => {
            marker.togglePopup();
          }}
        >
          <CloseOutlined />
        </Button>
      </div>
      <Descriptions
        title="Station"
        bordered={true}
        size="small"
        column={1}
        style={{ width: 400 }}
      >
        <Descriptions.Item label="Station ID">
          {station.stationId}
        </Descriptions.Item>
        <Descriptions.Item label="Latitude">
          {station.latitude}°
        </Descriptions.Item>
        <Descriptions.Item label="Longitude">
          {station.longitude}°
        </Descriptions.Item>
        <Descriptions.Item label="DepthMsl">
          {station.depthMsl}m
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
  const container = document.createElement('div');
  ReactDOM.render(element, container);
  return container;
};

export const addPublicStationMaker = (
  map: mapboxgl.Map,
  lenLat: mapboxgl.LngLatLike,
  station: StationItem,
) => {
  const element = (
    <div>
      <Cylindrical color="#555555" />
    </div>
  );
  const container = document.createElement('div');
  ReactDOM.render(element, container);
  const marker = new mapboxgl.Marker({
    element: container,
  });
  marker
    .setLngLat([station.longitude, station.latitude])
    .setPopup(
      new mapboxgl.Popup({
        offset: 25,
        maxWidth: '450px',
        closeButton: false,
      }).setDOMContent(stationPopup(station, marker)),
    )
    .addTo(map);
  return marker;
};
type StationMarker = {
  marker: mapboxgl.Marker;
  station: StationItem;
};

export const markerList: StationMarker[] = [];

const Index: React.FC<{
  map?: mapboxgl.Map;
  publicStation?: {
    stationList: StationItem[];
  };
}> = ({ map, publicStation }) => {
  // 判空
  if (!map) return <div />;
  useEffect(() => {
    markerList.forEach((marker) => marker.marker.remove());
    while (markerList.length > 0) {
      markerList.pop();
    }
    publicStation?.stationList.forEach((item) => {
      // @ts-ignore
      if (
        (item.latitude !== 0 && item.longitude !== 0) ||
        item.latitude === 'NaN' ||
        item.longitude === 'NaN'
      ) {
        try {
          const marker = addPublicStationMaker(
            map,
            [item.longitude, item.latitude],
            item,
          );
          markerList.push({
            marker: marker,
            station: item,
          });
        } catch (e) {
          console.warn(item);
        }
      }
    });
  });
  return <div />;
};

export default connect((state) => {
  return state;
})(Index);
