import React from 'react';
import { Button, message, Modal } from 'antd';
import { UserItem } from '@/service/user/common';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { PostUserDelete } from '@/service/user/list';

const DeleteUserModal: React.FC<{
  entity: UserItem;
  onDeleted: () => void;
}> = ({ children, entity, onDeleted }) => {
  const [loading, setLoading] = React.useState(false);
  return (
    <div>
      <Button
        type="link"
        danger
        onClick={() => {
          Modal.confirm({
            title: 'Confirm',
            icon: <ExclamationCircleOutlined />,
            content: `Are you sure to delete user ${entity.email} ?`,
            okText: 'Delete',
            closable: false,
            cancelText: 'Cancel',
            okType: 'danger',
            onOk() {
              setLoading(true);
              PostUserDelete(entity.id)
                .then(() => {
                  message.success(`User ${entity.email} deleted`).then(() => {
                    onDeleted();
                    setLoading(false);
                  });
                })
                .catch(() => {
                  setLoading(false);
                });
            },
          });
        }}
        loading={loading}
      >
        Delete
      </Button>
    </div>
  );
};

export default DeleteUserModal;
