import { CurrentUserInfo, PostUserCurrent } from '@/service/user/common';
import {
  goToLoginPage,
  goToLoginPageWithRedirect,
  isNotLoginPage,
} from '@/utils/routeUtil';
import { Button, message, Result } from 'antd';
import type { ResponseError } from 'umi-request';
import type { RunTimeLayoutConfig } from '@@/plugin-layout/layoutExports';
import RightContent from '@/components/RightContent';
import Footer from '@/components/Footer';
import enUS from 'antd/lib/locale/en_US';
import ConfigProvider from 'antd/es/config-provider';
import { RequestConfig } from '@@/plugin-request/request';
import { enUSIntl } from '@ant-design/pro-provider';
import React from 'react';
import { history } from 'umi';

const fetchUserInfo = async () => {
  try {
    const currentUser = await PostUserCurrent();
    return currentUser.data;
  } catch (error) {
    goToLoginPage();
  }
  return undefined;
};

export async function getInitialState(): Promise<{
  fetchUserInfo?: () => Promise<CurrentUserInfo | undefined>;
  currentUser?: CurrentUserInfo;
}> {
  // 如果不是登录页面，尝试获取用户信息
  if (isNotLoginPage()) {
    const currentUser = await fetchUserInfo();
    return {
      fetchUserInfo,
      currentUser,
    };
  }
  return {
    fetchUserInfo,
  };
}

const Exception404 = () => (
  <Result
    status="404"
    title="404"
    subTitle="Sorry, the page you visited does not exist."
    extra={
      <Button type="primary" onClick={() => history.push('/')}>
        Back Home
      </Button>
    }
  />
);

const Exception403 = () => (
  <Result
    status="403"
    title="403"
    subTitle="Sorry, you are not authorized to access this page."
    extra={
      <Button type="primary" onClick={() => history.push('/')}>
        Back Home
      </Button>
    }
  />
);

export const layout: RunTimeLayoutConfig = ({ initialState }) => {
  return {
    rightContentRender: () => <RightContent />,
    disableContentMargin: true,
    footerRender: () => <Footer />,
    childrenRender: (dom, props) => {
      // 添加antd全局配置
      return <ConfigProvider locale={enUS}>{dom}</ConfigProvider>;
    },
    // 如果没有登录，重定向到 login
    onPageChange: () => {
      if (!initialState?.currentUser && isNotLoginPage()) {
        goToLoginPage();
      }
    },
    unAccessible: Exception403(),
    noFound: Exception404(),
    menuHeaderRender: undefined,
  };
};

/**
 * 异常处理程序
 */
const errorHandler = (error: ResponseError) => {
  const { data, response } = error;
  if (data && response.status === 502) {
    message.error('network timeout');
    return;
  }
  if (data && data.code) {
    // 未登录的异常不展示警告，直接跳转到登录页
    if (data.code === 530) {
      goToLoginPage();
      return;
    }
    // 展示警告
    message.error(data.msg);
    return;
  }

  if (!data) {
    message.error(`Network Error!`);
  }
  console.log(error);
  throw error;
};
export const request: RequestConfig = {
  errorHandler,
  timeout: 20000,
  errorConfig: {
    adaptor: (resData: { data: any; code: number; msg: any }) => {
      return {
        data: resData.data,
        success: resData.code === 200,
        errorMessage: resData.msg,
      };
    },
  },
};
