import { PageContainer } from '@ant-design/pro-layout';
import { useAntdTable, useMount } from 'ahooks';
import { Button, Card, Form, Input, Row, Select, Table } from 'antd';
import { FilterParam, PostAdminStationSuperQuery, SortParam } from '@/service/admin/station';
import { StationItem } from '@/service/map/station';
import { PlusOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { history } from '@@/core/history';
import { PostAdminUserChoices } from '@/service/admin/user';
import { UserItem } from '@/service/user/common';
import { ColumnsType } from 'antd/lib/table';
import { Network, PostAdminNetworkChoices } from '@/service/admin/network';
import KeepAlive from 'react-activation';
import moment from 'moment-timezone';

interface Result {
  total: number;
  list: StationItem[];
}

const getClassNameByStation = (record: StationItem) => {
  const maxTime = Math.max(record?.tbeat ?? 0, record?.tpack ?? 0);
  let lightText = false;
  let color = '';
  let className = '';
  if (new Date().getTime() - maxTime < 3600000) {
    color = '#cbdfb8';
    className += 'bg-green-200';
  } else if (new Date().getTime() - maxTime < 3600000 * 5) {
    color = '#e63323';
    className += 'bg-red-400';
    lightText = true;
  } else {
    color = '#a6a6a6';
    className += 'bg-gray-400';
    lightText = true;
  }
  return className;
};

const keys = [
  { name: 'station_id', key: 'station_id' },
  { name: 'network', key: 'network_id' },
  { name: 'depth_msl', key: 'depth_msl' },
  { name: 'latitude', key: 'latitude' },
  { name: 'longitude', key: 'longitude' },
  { name: 'depthMSL', key: 'depthMSL' },
  { name: 'ipAddress', key: 'ipAddress' },
  { name: 'transmission', key: 'transmission' },
  { name: 'signalLTE', key: 'signalLTE' },
  { name: 'signelWiFi', key: 'signelWiFi' },
  { name: 'battery', key: 'battery' },
  { name: 'temeprature_cpu', key: 'temeprature_cpu' },
  { name: 'temeprature_sensor', key: 'temeprature_sensor' },
  { name: 'std_ax', key: 'std_ax' },
  { name: 'std_ay', key: 'std_ay' },
  { name: 'std_az', key: 'std_az' },
  { name: 'std_gx', key: 'std_gx' },
  { name: 'std_gy', key: 'std_gy' },
  { name: 'std_gz', key: 'std_gz' },
  { name: 'std_asx', key: 'std_asx' },
  { name: 'std_asy', key: 'std_asy' },
  { name: 'std_asz', key: 'std_asz' },
  // { name: 'std_ref1', key: 'std_ref1' },
  // { name: 'std_ref2', key: 'std_ref2' },
  // { name: 'std_ref3', key: 'std_ref3' },
];

const filters = [
  { name: 'equal', key: 'eq' },
  { name: 'not equal', key: 'ne' },
  { name: 'greater than', key: 'gt' },
  { name: 'greater or equal', key: 'ge' },
  { name: 'less than', key: 'lt' },
  { name: 'less or equal', key: 'le' },
  { name: 'include', key: 'like' },
];

const Page = () => {
  const [form] = Form.useForm();
  const [sortModel, setSortModel] = useState<SortParam[]>([{ sortByKey: '', sortByWay: 'ASC' }]);
  const [filterModel, setFilterModel] = useState<FilterParam[]>([]);
  const [updater, setUpdater] = useState(0);
  const [clientId, setClientId] = useState<string>('');
  const [users, setUsers] = useState<UserItem[]>([]);
  const [networkId, setNetworkId] = useState<string>('');
  const [networks, setNetworks] = useState<Network[]>([]);

  const getTableData = async ({ current, pageSize }: { current: number; pageSize: number }, formData: Object): Promise<Result> => {
    const sortParam: any[] = [];
    sortModel.forEach((m) => {
      if (m.sortByKey != '') sortParam.push(m);
    });
    const filterParam: FilterParam[] = [];
    filterModel.forEach((m) => {
      if (m.filterKey != '' && m.filterValue != '') filterParam.push(m);
    });
    const res = await PostAdminStationSuperQuery({
      sort: sortParam,
      filter: filterParam,
      current: current,
      pageSize: pageSize,
      clientId: clientId,
      networkId: networkId,
    });
    return {
      total: res.data.total,
      list: res.data.values,
    };
  };

  useMount(async () => {
    let { data } = await PostAdminUserChoices('');
    setUsers(data);
    let res = await PostAdminNetworkChoices('');
    let data2 = res.data;
    setNetworks(data2);
  });

  // @ts-ignore
  const { loading, tableProps, search, params } = useAntdTable(getTableData, {
    defaultPageSize: 20,
  });

  const { type, changeType, submit, reset } = search;

  const columns: ColumnsType<StationItem> = [
    { title: 'stationId', width: 200, dataIndex: 'stationId' },
    { title: 'network', dataIndex: 'networkId' },
    { title: 'status', dataIndex: 'status' },
    { title: 'latitude', dataIndex: 'latitude', width: 180 },
    { title: 'longitude', dataIndex: 'longitude', width: 180 },
    { title: 'depthMSL', dataIndex: 'depthMSL' },
    { title: 'ipAddress', dataIndex: 'ipAddress', width: 180 },
    { title: 'transmission', dataIndex: 'transmission' },
    { title: 'signalLTE', dataIndex: 'signalLTE' },
    { title: 'signelWiFi', dataIndex: 'signelWiFi' },
    { title: 'battery', dataIndex: 'battery' },
    { title: 'temeprature_cpu', dataIndex: 'temeprature_cpu' },
    { title: 'temeprature_sensor', dataIndex: 'temeprature_sensor' },
    { title: 'std_ax', dataIndex: 'std_ax' },
    { title: 'std_ay', dataIndex: 'std_ay' },
    { title: 'std_az', dataIndex: 'std_az' },
    { title: 'std_gx', dataIndex: 'std_gx' },
    { title: 'std_gy', dataIndex: 'std_gy' },
    { title: 'std_gz', dataIndex: 'std_gz' },
    { title: 'std_asx', dataIndex: 'std_asx' },
    { title: 'std_asy', dataIndex: 'std_asy' },
    { title: 'std_asz', dataIndex: 'std_asz' },
    {
      title: 'Last update',
      width: 400,
      render: (_, station, index) => {
        const maxTime = Math.max(station?.tbeat ?? 0, station?.tpack ?? 0);
        return (
          <div>
            {new Date(maxTime).toUTCString()} {` `} {moment(maxTime).startOf('seconds').fromNow()}
          </div>
        );
      },
    },
    // { title: 'std_ref1', dataIndex: 'std_ref1' },
    // { title: 'std_ref2', dataIndex: 'std_ref2' },
    // { title: 'std_ref3', dataIndex: 'std_ref3' },
  ];

  return (
    <PageContainer>
      <Card>
        <Form form={form} layout="vertical">
          <Form.Item label="sort">
            <Row className="mb-2">
              <Select
                style={{ width: 200 }}
                value={sortModel[0].sortByKey}
                onSelect={(res) => {
                  sortModel[0].sortByKey = res;
                  setSortModel(sortModel.concat());
                  submit();
                }}
              >
                {keys.map((opt) => {
                  return <Select.Option value={opt.key}>{opt.name}</Select.Option>;
                })}
              </Select>
              <Button
                type="link"
                onClick={() => {
                  sortModel[0].sortByWay == 'DESC' ? (sortModel[0].sortByWay = 'ASC') : (sortModel[0].sortByWay = 'DESC');
                  setSortModel(sortModel.concat());
                  submit();
                }}
              >
                {sortModel[0].sortByWay}
              </Button>
            </Row>
          </Form.Item>
          <Form.Item label="filter">
            {filterModel.map((model, index) => {
              return (
                <Row className="mb-2">
                  <Select
                    style={{ width: 170 }}
                    onSelect={(res) => {
                      console.log(res);
                      filterModel[index].filterKey = res;
                    }}
                  >
                    {keys.map((opt) => {
                      return <Select.Option value={opt.key}>{opt.name}</Select.Option>;
                    })}
                  </Select>
                  <Select
                    style={{ width: 180 }}
                    onSelect={(res) => {
                      filterModel[index].filterWay = res;
                    }}
                  >
                    {filters.map((opt) => {
                      return <Select.Option value={opt.key}>{opt.name}</Select.Option>;
                    })}
                  </Select>
                  <Input
                    style={{ width: 300 }}
                    onChange={(res) => {
                      filterModel[index].filterValue = res.target.value;
                      setFilterModel(filterModel.concat());
                    }}
                  ></Input>
                </Row>
              );
            })}
            <Button
              icon={<PlusOutlined />}
              onClick={() => {
                setFilterModel(filterModel.concat({ filterKey: '', filterWay: 'eq', filterValue: '' }));
              }}
            >
              Add Rule
            </Button>
          </Form.Item>
          <Form.Item label="clientId">
            <Select
              showSearch
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              onSearch={async (keyWords) => {
                if (keyWords) {
                  const { data } = await PostAdminUserChoices(keyWords);
                  setUsers(data);
                } else {
                  const { data } = await PostAdminUserChoices('');
                  setUsers(data);
                }
              }}
              value={clientId}
              onSelect={(value: string) => {
                setClientId(value);
                submit();
              }}
              onChange={(value: string) => {
                setClientId(value);
              }}
              placeholder="Select clientId"
              style={{ width: 300 }}
            >
              {users.map((d) => (
                <Option key={d.id} value={d.id}>
                  {`${d.id}`}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="network">
            <Select
              showSearch
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              onSearch={async (keyWords) => {
                if (keyWords) {
                  const { data } = await PostAdminNetworkChoices(keyWords);
                  setNetworks(data);
                } else {
                  const { data } = await PostAdminNetworkChoices('');
                  setNetworks(data);
                }
              }}
              value={networkId}
              onSelect={(value: string) => {
                setNetworkId(value);
                submit();
              }}
              onChange={(value: string) => {
                setNetworkId(value);
              }}
              placeholder="Select clientId"
              style={{ width: 300 }}
            >
              {networks.map((d) => (
                <Option key={d.id} value={d.id}>
                  {`${d.name}`}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Row gutter={24} justify="end" style={{ marginBottom: 24 }}>
            <Button type="primary" onClick={submit}>
              Search
            </Button>
            <Button
              onClick={() => {
                reset();
                setSortModel([{ sortByKey: '', sortByWay: 'ASC' }]);
                setFilterModel([]);
                setClientId('');
                setNetworkId('');
              }}
              style={{ marginLeft: 16 }}
            >
              Reset
            </Button>
          </Row>
        </Form>
      </Card>
      <div className="h-4" />
      <Card>
        <Table
          columns={columns}
          scroll={{ x: 2900 }}
          rowKey="email"
          {...tableProps}
          rowClassName={(record, index) => {
            return getClassNameByStation(record);
          }}
          onRow={(record) => {
            return {
              onClick: () => {
                history.push(`/station/detail/retrieve/${record.stationId}`);
              },
            };
          }}
        />
      </Card>
    </PageContainer>
  );
};

const Index = () => {
  return (
    <div>
      <KeepAlive saveScrollPosition={true}>
        <Page key="r-page"></Page>
      </KeepAlive>
    </div>
  );
};

export default Index;
