import { PageData, ResponseData } from '@/service/API';
import { CurrentUserInfo, UserItem } from '@/service/user/common';
import { request } from 'umi';

export async function PostUserList(pageSize: number, current: number, keyword: string) {
  return request<ResponseData<PageData<UserItem>>>('/api/admin/user/list', {
    method: 'POST',
    data: {
      pageSize,
      current,
      keyword,
    },
  });
}

export type EditUserParam = {
  id: string;
  newId: string;
  firstName: string;
  lastName: string;
  email: string;
  type: string;
  authorities: string[];
  password?: string;
};

export async function PostAdminUserEdit(data: EditUserParam) {
  return request<ResponseData<any>>('/api/admin/user/edit', {
    method: 'POST',
    data,
  });
}

export type AddUserParam = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  type: string;
  authorities: string[];
  password: string;
};

export async function PostAdminUserAdd(data: AddUserParam) {
  return request<ResponseData<any>>('/api/admin/user/add', {
    method: 'POST',
    data,
  });
}

export async function PostAdminUserQuickAdd(id: string) {
  return request<ResponseData<any>>('/api/admin/user/quickAdd/' + id, {
    method: 'POST',
  });
}

export async function NewUserEdit(data: CurrentUserInfo) {
  return request<ResponseData<any>>('/api/admin/user/add', {
    method: 'POST',
    data,
  });
}

export async function PostUserDelete(id: string) {
  return request<ResponseData<any>>('/api/admin/user/delete', {
    method: 'POST',
    data: { id },
  });
}

export async function AssociateStation(data: { userId: string; station_id: string }) {
  return request<ResponseData<any>>('/api/admin/user/associateStation', {
    method: 'POST',
    data,
  });
}

export async function UnBindStation(data: { userStationId: string }) {
  return request<ResponseData<any>>('/api/admin/user/unBind', {
    method: 'POST',
    data,
  });
}

export type AuthorityItem = {
  name: string;
  description: string;
};

export async function PostAuthoritiesOptions() {
  return request<ResponseData<AuthorityItem[]>>('/api/user/authoritiesOptions', {
    method: 'POST',
  });
}
