import { request } from 'umi';
import { ResponseData } from '../API';
import { EventItem } from '@/service/map/event';
import { Moment } from 'moment';

export type DatItem = {
  id: string;
  client: string;
  station: string;
  tpack: number;
  index: number;
  sample_rate: number;
  temeprature_sensor: number;
  std_ax: number;
  std_ay: number;
  std_az: number;
  std_gx: number;
  std_gy: number;
  std_gz: number;
  std_asx: number;
  std_asy: number;
  std_asz: number;
  std_ref1: number;
  std_ref2: number;
  std_ref3: number;
  azimuth: number;
  inclination: number;
  created_time: number;
  updated_time: number;
  deleted: number;
};

export async function PostCore2DatList(stationId: string, passedHours: number) {
  return request<ResponseData<DatItem[]>>('/api/core2/dat/list', {
    method: 'POST',
    data: {
      passedHours,
      stationId,
    },
  });
}

export async function PostCore2DatLatest(stationId: string, userId: string) {
  return request<ResponseData<DatItem>>('/api/core2/dat/latest', {
    method: 'POST',
    data: {
      stationId,
      userId,
    },
  });
}

export async function PostCore2DatListByTime(stationId: string, startTime: moment.Moment, endTime: moment.Moment) {
  return request<ResponseData<DatItem[]>>('/api/core2/dat/listByTime', {
    method: 'POST',
    data: {
      startTime,
      endTime,
      stationId,
    },
  });
}
