import React, { useState } from 'react';
import { Card, Col, DatePicker, List, Row, Spin } from 'antd';
import { useMount } from 'ahooks';
import { postDashboardStationsStatistics } from '@/service/dashboard/v1';
import { Pie } from '@antv/g2plot';

const { RangePicker } = DatePicker;

const Index: React.FC = () => {
  return (
    <Card title="Station">
      <Row>
        <Col span={16}>
          <StationStates />
        </Col>
        <Col span={8}>
          <StatesPie />
        </Col>
      </Row>
    </Card>
  );
};
const StatesPie = () => {
  useMount(() => {
    postDashboardStationsStatistics().then((res) => {
      const data = [
        {
          title: 'NORMAL',
          value: res.data[1],
        },
        {
          title: 'UNSTABLE',
          value: res.data[2],
        },
        {
          title: 'ERROR',
          value: res.data[3],
        },
        {
          title: 'STOP',
          value: res.data[4],
        },
      ];

      const piePlot = new Pie('station-pie', {
        appendPadding: 10,
        data,
        angleField: 'value',
        colorField: 'title',
        radius: 0.9,
        innerRadius: 0.6,
        color: ['#23ac4c', '#ffc000', '#f50', '#808080'],
        label: {
          type: 'inner',
          // offset: '-30%',
          content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
          style: {
            fontSize: 14,
            textAlign: 'center',
          },
        },
        interactions: [{ type: 'element-active' }],
      });

      piePlot.render();
    });
  });

  return <div id="station-pie"></div>;
};

const StationStates = () => {
  const [statistics, setStatistics] = useState<number[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useMount(() => {
    setLoading(true);
    postDashboardStationsStatistics().then((res) => {
      setLoading(false);
      setStatistics(res.data);
    });
  });

  const data = [
    {
      title: 'Total',
      value: statistics[0],
    },
    {
      title: 'Normal',
      value: statistics[1],
    },
    {
      title: 'Unstable',
      value: statistics[2],
    },
    {
      title: 'Error',
      value: statistics[3],
    },
    {
      title: 'Stop',
      value: statistics[4],
    },
  ];

  return (
    <List
      grid={{ gutter: 16, column: 3 }}
      dataSource={data}
      renderItem={(item) => (
        <List.Item>
          <Card title={item.title} style={{ fontSize: 'x-large' }} loading={loading} bordered={false}>
            {item.value}
          </Card>
        </List.Item>
      )}
    />
  );
};
//
// const StationTrend = () => {
//   const [stations, setStations] = useState<StationItem[]>([]);
//   const [loading, setLoading] = useState<boolean>(false);
//   const [dateSource, setDateSource] = useState<Moment[]>([
//     moment().subtract(1, 'day').startOf('day'),
//     moment().endOf('day'),
//   ]);
//
//   useMount(() => {
//     setLoading(true);
//     postDashboardStationsTrend().then((res) => {
//       setStations(res.data);
//       // 数据过滤
//       const dataMap = new Map<string, StationItem[]>();
//       res.data.forEach((it) => {
//         const key = moment(it.createdTime).format('MM/DD/YYYY HH:mm:00');
//         if (dataMap.get(key) == null || dataMap.get(key) === undefined) {
//           dataMap.set(key, [it]);
//         } else {
//           if (
//             dataMap.get(key)!.filter((item) => item.stationId === it.stationId)
//               .length > 0
//           )
//             return;
//           dataMap.get(key)!.push(it);
//         }
//       });
//       let data: Object[] = [];
//       dataMap.forEach((value, key) => {
//         const statusList = ['NORMAL', 'UNSTABLE', 'ERROR', 'STOP'];
//         statusList.forEach((status, index) => {
//           const count = value.filter((it) => it.status === index).length;
//           data.push({
//             date: key,
//             status: status,
//             value: count,
//           });
//         });
//       });
//       // 渲染
//       const area = new Line('station-trend', {
//         data,
//         color: ['#23ac4c', '#ffc000', '#f50', '#808080'],
//         xField: 'date',
//         yField: 'value',
//         seriesField: 'status',
//         slider: {start: 0, end: 1},
//         smooth: true,
//         area: {
//           style: {
//             fillOpacity: 0.15,
//           },
//         },
//         animation: {
//           appear: {
//             animation: 'wave-in',
//             duration: 1500,
//           },
//         },
//       });
//       area.render();
//       setLoading(false);
//     });
//   });
//
//   return (
//     <div>
//       {/* @ts-ignore */}
//       <RangePicker
//         value={dateSource}
//         format={'MM/DD/YYYY HH:mm:ss'}
//         onChange={(date) => {
//           if (date != null) {
//             // @ts-ignore
//             setDateSource(date);
//           }
//         }}
//         showTime
//       />
//       <div id="station-trend"></div>
//       {loading && <Spin style={{margin: '130px'}} size="large"/>}
//     </div>
//   );
// };

export default Index;
