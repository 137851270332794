import { request } from 'umi';
import { PageData, ResponseData } from '@/service/API';
import { EditUserParam } from '@/service/user/list';
import { RcFile } from 'antd/lib/upload';

export type CurrentUserInfo = {
  id?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  password?: string;
  passwordStrength: 'STRONG' | 'MEDIUM_STRENGTH' | 'WEAK';
  authorities: string[];
  type?: 'Admin' | 'User';
  token: string;
  lastLat: number;
  lastLng: number;
  lastZoom: number;
  stations?: Array<any>;
};

export type UserItem = {
  firstName: string;
  lastName: string;
  updatedTime: number;
  deleted: 1 | 0;
  createdTime: number;
  id: string;
  userId: string;
  description: string;
  type: 'User' | 'Admin';
  authorities: string[];
  email: string;
  boundary: UserBoundary;
};

export type UserBoundary = {
  GOCAD_Code?: string;
  lineWidth?: number;
  lineColor?: string;
  lineOpacity?: number;
};

export async function PostUserCurrent() {
  return request<ResponseData<CurrentUserInfo>>('/api/user/current', {
    method: 'POST',
  });
}

export async function PostUserLogin(data: { email: string; password: string }) {
  return request<ResponseData<CurrentUserInfo>>('/api/user/login', {
    method: 'POST',
    data,
  });
}

export async function PostUserLogout() {
  return request<ResponseData<any>>('/api/user/logout', {
    method: 'POST',
  });
}

export async function postUserSendRecoveryEmail(email: String) {
  return request<ResponseData<any>>('/api/user/sendResetPasswordEmail', {
    method: 'POST',
    data: {
      email: email,
    },
  });
}

export async function postUserResetPassword(data: { email: string; password: string; code: string }) {
  return request<ResponseData<any>>('/api/user/resetPass', {
    method: 'POST',
    data,
  });
}

export type UserNameEditParam = {
  firstName: string;
  lastName: string;
  // email: string;
  // password: string;
};

export async function PostUserNameEdit(data: UserNameEditParam) {
  return request<ResponseData<any>>('/api/user/editName', {
    method: 'POST',
    data,
  });
}

export async function PostSendCaptchaEmail(email: string) {
  return request<ResponseData<any>>('/api/user/sendCaptchaEmail', {
    method: 'POST',
    data: {
      email: email,
    },
  });
}

export async function PostUserModifyEmail(email: string, captcha: string) {
  return request<ResponseData<any>>('/api/user/modifyEmail', {
    method: 'POST',
    data: {
      email: email,
      captcha: captcha,
    },
  });
}

export async function PostUserModifyPassword(oldPassword: string, newPassword: string) {
  return request<ResponseData<any>>('/api/user/modifyPassword', {
    method: 'POST',
    data: {
      oldPassword,
      newPassword,
    },
  });
}

export async function PostUserUpdateMapStatus(params: { lat: number; lng: number; zoom: number }) {
  return request<ResponseData<any>>('/api/user/uploadMapStatus', {
    method: 'POST',
    data: params,
  });
}

export async function PostUserUploadAvatar(avatar: RcFile) {
  const formData = new FormData();
  formData.append('avatar', avatar);
  return request<ResponseData<any>>('/api/user/uploadAvatar', {
    method: 'POST',
    body: formData,
  });
}
