import ProForm, { ModalForm, ProFormDependency, ProFormDigit, ProFormField, ProFormSelect, ProFormSwitch, ProFormText } from '@ant-design/pro-form';
import React, { useState } from 'react';
import { Button, Spin } from 'antd';
import { UserItem } from '@/service/user/common';
import TagInput from '@/components/TagInput';
import { AdminReportGetByUserId, AdminReportUpdate, ReportSubscription, ReportSubscriptionModel } from '@/service/admin/report';

const EditReportModel = (props: { entity: UserItem }) => {
  const [data, setData] = useState<ReportSubscriptionModel | null>(null);

  return (
    <ModalForm
      onVisibleChange={() => {
        if (data != null) return;
        AdminReportGetByUserId(props.entity.id).then((res) => {
          setData(res.data);
        });
      }}
      title={
        <div>
          Edit User Report Settings
          <div className="text-sm text-gray-400">id:{props.entity.id}</div>
        </div>
      }
      trigger={<Button type="link">Report</Button>}
      onFinish={async (formData: ReportSubscription) => {
        console.log(formData);
        const res = await AdminReportUpdate({ ...formData, userId: props.entity.id });
        return true;
      }}
    >
      {data == null && <Spin spinning={true}>Loading...</Spin>}

      {data && (
        <>
          {/*PGA Reported*/}
          <ProForm.Group>
            <ProFormSwitch name={'enablePgaWarning'} label={'(1) Enable PGA warning email'} initialValue={data.enablePgaWarning}></ProFormSwitch>
          </ProForm.Group>
          <ProFormDependency name={['enablePgaWarning']}>
            {({ enablePgaWarning }) => {
              if (enablePgaWarning)
                return (
                  <div>
                    <ProForm.Group>
                      <ProFormSelect
                        width="md"
                        id="pgaLevel"
                        name="pgaLevel"
                        label="PGA Warning level"
                        required
                        options={[
                          { label: 'Ⅺ+ (violent: >747mg)', value: 'Violent' },
                          { label: 'Ⅷ (severe: 401~747mg)', value: 'Severe' },
                          { label: 'Ⅶ (very strong: 215~401mg)', value: 'VeryStrong' },
                          { label: 'Ⅵ (strong: 115~215mg)', value: 'Strong' },
                          { label: 'Ⅴ (moderate: 27.6~115mg)', value: 'Moderate' },
                          { label: 'Ⅳ (light: 2.97~27.6mg)', value: 'Light' },
                          { label: 'Ⅱ~Ⅲ (weak: 0.464~2.97mg)', value: 'Weak' },
                          { label: 'Ⅰ (not felt: <0.464mg)', value: 'NotFelt' },
                        ]}
                        initialValue={data?.pgaLevel}
                      ></ProFormSelect>
                    </ProForm.Group>
                    <ProForm.Group>
                      <ProForm.Item required id="pgaEmails" name="pgaEmails" label="PGA Warning Email(s)">
                        <TagInput initialValue={data?.pgaEmails}></TagInput>
                      </ProForm.Item>
                    </ProForm.Group>
                    {/*<ProForm.Group>*/}
                    {/*  <ProFormText*/}
                    {/*    width="md"*/}

                    {/*    label="PGA Warning Email"*/}
                    {/*    rules={[{required: true, pattern: /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/, message: 'Please enter a valid email address'}]}*/}
                    {/*    initialValue=''/>*/}
                    {/*</ProForm.Group>*/}
                  </div>
                );
              return <div></div>;
            }}
          </ProFormDependency>
          {/*Public Reported*/}
          <ProForm.Group>
            <ProFormSwitch
              name={'enablePublicEvent'}
              label={'(2) Enable public reported event warning email'}
              initialValue={data.enablePublicEvent}
            ></ProFormSwitch>
          </ProForm.Group>
          <ProFormDependency name={['enablePublicEvent']}>
            {({ enablePublicEvent }) => {
              if (enablePublicEvent)
                return (
                  <div>
                    <ProForm.Group>
                      <ProFormDigit
                        name={'publicDistance'}
                        label={'Event-to-station warning distance'}
                        initialValue={data?.publicDistance}
                        addonAfter="km"
                        required
                      ></ProFormDigit>
                    </ProForm.Group>
                    <ProForm.Group>
                      <ProForm.Item required id="publicEmails" name="publicEmails" label="Email(s)">
                        <TagInput initialValue={data?.publicEmails}></TagInput>
                      </ProForm.Item>
                      {/*<ProFormText*/}
                      {/*  width="md"*/}
                      {/*  id="publicReportedEmail"*/}
                      {/*  name="publicReportedEmail"*/}
                      {/*  label="PGA Warning Email(s)"*/}
                      {/*  rules={[*/}
                      {/*    { required: true, pattern: /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/, message: 'Please enter a valid email address' },*/}
                      {/*  ]}*/}
                      {/*  initialValue=""*/}
                      {/*/>*/}
                    </ProForm.Group>
                  </div>
                );
              return <div></div>;
            }}
          </ProFormDependency>
          {/*  Private reported */}

          <ProForm.Group>
            <ProFormSwitch
              name={'enablePrivateEmail'}
              label={'(3) Enable private reported event alert email'}
              initialValue={data.enablePrivateEmail}
            ></ProFormSwitch>
          </ProForm.Group>
          <ProForm.Group>
            <ProFormSwitch
              name={'enablePrivateMessage'}
              label={'Enable private reported event alert message'}
              initialValue={data.enablePrivateEmail}
            ></ProFormSwitch>
          </ProForm.Group>
          <ProFormDependency name={['enablePrivateEmail', 'enablePrivateMessage']}>
            {({ enablePrivateEmail, enablePrivateMessage }) => {
              return (
                <>
                  {/*是否显示距离*/}
                  {(enablePrivateEmail || enablePrivateMessage) && (
                    <ProForm.Group>
                      <ProFormDigit
                        name={'privateDistance'}
                        label={'Event-to-station warning distance'}
                        initialValue={data?.publicDistance}
                        addonAfter="km"
                        required
                      ></ProFormDigit>
                    </ProForm.Group>
                  )}
                  {/*  是否显示邮箱*/}
                  {enablePrivateEmail && (
                    <ProForm.Group>
                      <ProForm.Item required id="privateEmails" name="privateEmails" label="Email(s)">
                        <TagInput initialValue={data?.privateEmails}></TagInput>
                      </ProForm.Item>
                    </ProForm.Group>
                  )}
                  {enablePrivateMessage && (
                    <ProForm.Group>
                      <ProForm.Item required id="privateCalls" name="privateCalls" label="Call(s)">
                        <TagInput initialValue={data?.privateCalls}></TagInput>
                      </ProForm.Item>
                    </ProForm.Group>
                  )}
                </>
              );
            }}
          </ProFormDependency>
        </>
      )}
    </ModalForm>
  );
};

export default EditReportModel;
