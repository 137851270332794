import { PageContainer } from '@ant-design/pro-layout';
import ProList, { ProListMetas } from '@ant-design/pro-list';
import { DeleteOutlined, EllipsisOutlined, LinkOutlined, NodeExpandOutlined } from '@ant-design/icons';
import { Avatar, Button, Progress, Space, Tag } from 'antd';
import React, { useRef } from 'react';
import { PostAdminStationGrantList, StationGrant } from '@/service/admin/stationGrant';
import { enUSIntl } from '@ant-design/pro-provider';
import { ActionType, ConfigProvider } from '@ant-design/pro-table';
import NewGrantModel from '@/pages/station/grant/NewGrantModel';
import moment from 'moment';
import DeleteStationGrant from '@/pages/station/grant/DeleteStationGrant';

export default function List() {
  const ref = useRef<ActionType>();

  const listMetas: ProListMetas<any> = {
    title: {
      render: (text, record: StationGrant) => {
        return (
          <Space>
            {record.stationId}
            <LinkOutlined />
            <Avatar src={`/api/user/avatar?user=${record.user?.id}`} alt="" />
            {record.userId}
            {record.user?.firstName}
            {record.user?.lastName}
          </Space>
        );
      },
    },
    subTitle: {
      dataIndex: 'remark',
    },
    type: {},
    avatar: {
      render: (dom, entity) => {
        return <img src={'https://gw.alipayobjects.com/zos/antfincdn/UCSiy1j6jx/xingzhuang.svg'} alt={'station'} />;
      },
    },
    content: {
      render: (dom, entity) => {
        return (
          <div key="label" style={{ display: 'flex', justifyContent: 'space-around' }}>
            <div>
              <div style={{ color: '#00000073' }}>Start Time</div>
              <div style={{ color: '#000000D9', width: 160 }}>{entity.period[0] == undefined ? '-' : moment(entity.period[0]).utc().format('lll')}</div>
            </div>
            <div>
              <div style={{ color: '#00000073' }}>End Time</div>
              <div style={{ color: '#000000D9', width: 160 }}>{entity.period[1] == undefined ? '-' : moment(entity.period[1]).utc().format('lll')}</div>
            </div>
          </div>
        );
      },
    },
    actions: {
      render: (dom, entity) => {
        return (
          <Space>
            <DeleteStationGrant
              entity={entity}
              onDeleted={() => {
                ref?.current?.reload();
              }}
            ></DeleteStationGrant>
          </Space>
        );
      },
    },
  };

  return (
    <ConfigProvider value={{ intl: enUSIntl, valueTypeMap: {} }}>
      <PageContainer>
        <ProList<StationGrant>
          actionRef={ref}
          pagination={{
            defaultPageSize: 10,
            showSizeChanger: true,
          }}
          toolBarRender={() => {
            return [<NewGrantModel />];
          }}
          options={{}}
          metas={listMetas}
          headerTitle="Authorized List"
          request={async (params) => {
            const res = await PostAdminStationGrantList(params.pageSize ?? 10, params.current ?? 0, params.query ?? '');
            return {
              data: res.data.values,
              total: res.data.total,
              success: true,
            };
          }}
        />
      </PageContainer>
    </ConfigProvider>
  );
}
