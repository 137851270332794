import Icon from '@ant-design/icons';
import React from 'react';

const Torus = (color: string | null) => {
  const fillColor = color ? color : '#000';
  // width="128" height="128"
  return () => {
    return (
      <svg
        fill={fillColor}
        className="icon"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="1257"
        shapeRendering="geometricPrecision"
      >
        <path
          d="M512 1024a512.576 512.576 0 0 1-512-512 512.576 512.576 0 0 1 512-512 512.576 512.576 0 0 1 512 512 512.576 512.576 0 0 1-512 512zM512 192a320.32 320.32 0 0 0-320 320 320.384 320.384 0 0 0 320 320 320.384 320.384 0 0 0 320-320 320.384 320.384 0 0 0-320-320z"
          p-id="1258"
        />
      </svg>
    );
  };
};

const Diamond = (color: string | null) => {
  const fillColor = color ? color : '#000';
  return () => {
    return (
      <svg
        fill={fillColor}
        viewBox="0 0 1024 1024"
        xmlns="http://www.w3.org/2000/svg"
        className="icon"
        version="1.1"
        p-id="1257"
        shapeRendering="geometricPrecision"
      >
        <g id="Layer_1">
          <title>Layer 1</title>
          <path
            stroke="#000"
            id="svg_5"
            d="m70.63818,512.47205l441.55371,-441.5152l441.55371,441.5152l-441.55371,441.5152l-441.55371,-441.5152z"
            stroke-width="99"
            fill="#fff"
          />
        </g>
      </svg>
    );
  };
};

const Rect = (color: string | null) => {
  const fillColor = color ? color : '#000';
  return () => {
    return (
      <svg
        fill={fillColor}
        viewBox="0 0 1024 1024"
        xmlns="http://www.w3.org/2000/svg"
        className="icon"
        version="1.1"
        p-id="1257"
        shapeRendering="geometricPrecision"
      >
        <g stroke="null">
          <title>Layer 1</title>
          <path
            stroke="null"
            id="svg_1"
            p-id="1258"
            d="m-599.76465,1042.82353a512.576,512.576 0 0 1 -512,-512a512.576,512.576 0 0 1 512,-512a512.576,512.576 0 0 1 512,512a512.576,512.576 0 0 1 -512,512zm0,-832a320.32,320.32 0 0 0 -320,320a320.384,320.384 0 0 0 320,320a320.384,320.384 0 0 0 320,-320a320.384,320.384 0 0 0 -320,-320z"
          />
          <rect stroke="null" id="svg_6" height="1028.49997" width="196.00001" y="-4.27778" x="0" />
          <rect stroke="null" id="svg_7" height="202.33332" width="1097.50005" y="-24.33334" x="-53.00003" />
          <rect stroke="null" id="svg_8" height="1012.5" width="204.99999" y="4.66641" x="821.99996" />
          <rect stroke="null" id="svg_10" height="189.99999" width="1119.99987" y="867.16664" x="-60.50003" />
        </g>
      </svg>
    );
  };
};

/**
 * magnitude优先级更高
 * @param level
 * @param magnitude
 * @param shape
 * @constructor
 */
const Magnitude: React.FC<{ level?: number; magnitude?: number; shape?: string }> = ({ level, magnitude, shape }) => {
  let iconFun;
  if (shape == 'diamond') iconFun = Diamond;
  if (shape == 'rect') iconFun = Rect;
  else iconFun = Torus;

  if (magnitude != undefined) {
    if (magnitude <= 2) {
      level = 0;
    } else if (magnitude <= 2.5) {
      level = 1;
    } else if (magnitude <= 3) {
      level = 2;
    } else if (magnitude <= 3.5) {
      level = 3;
    } else if (magnitude <= 4) {
      level = 4;
    } else if (magnitude <= 4.5) {
      level = 5;
    } else if (magnitude <= 5) {
      level = 6;
    } else if (magnitude <= 5.5) {
      level = 7;
    } else {
      level = 8;
    }
  } else if (level == undefined) {
    level = 0;
  }

  const range1 = [['<=2'], ['2~2.5'], ['2.5~3'], ['3~3.5'], ['3.5~4'], ['4~4.5'], ['4.5~5'], ['5~5.5'], ['>5.5']];

  const list = ['#7f7f7f', '#a9d18e', '#548235', '#00b0f0', '#2e75b6', '#fbef54', '#ffc000', '#ff0000', '#c00000'];

  return (
    <div>
      <Icon component={iconFun(list[level])} style={{ width: level * 2 + 8, height: level * 2 + 8 }} />
    </div>
  );
};
export default Magnitude;
