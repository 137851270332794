import { request } from '@@/plugin-request/request';
import { ResponseData } from '@/service/API';

export type VibrationSignalsItem = {
  // 台站id
  station: string;
  // 时间
  tsig: number;
  // 强度
  amax: number;
  // 用户id
  client: string;

  createdTime: number;
  deleted: number;
  id: string;
  updatedTime: number;
};

export async function PostVibrationSignalsList(passedHours: number) {
  return request<ResponseData<VibrationSignalsItem[]>>('/api/vibration_signals/list', {
    method: 'POST',
    data: {
      passedHours,
    },
  });
}
