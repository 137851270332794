import React from 'react';
import { enUSIntl } from '@ant-design/pro-provider';
import ProTable, { ConfigProvider } from '@ant-design/pro-table';
import { PageContainer } from '@ant-design/pro-layout';
import { EventItem, PostQueryListEventList } from '@/service/map/event';

const Index: React.FC = () => {
  return (
    <ConfigProvider value={{ intl: enUSIntl, valueTypeMap: {} }}>
      <PageContainer>
        <ProTable<EventItem>
          headerTitle="Event List"
          search={false}
          rowKey="id"
        ></ProTable>
      </PageContainer>
    </ConfigProvider>
  );
};

export default Index;
