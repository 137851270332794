import ProForm, { ModalForm, ProFormText } from '@ant-design/pro-form';
import { Button, message } from 'antd';
import React from 'react';
import { PostAdminNetworkAdd } from '@/service/admin/network';

type FormDataType = {
  name: string;
  description: string;
};

const NewNetworkModel = () => {
  return (
    <ModalForm
      title="New Network"
      trigger={<Button type="primary">New Network</Button>}
      onFinish={async (formData: FormDataType) => {
        await PostAdminNetworkAdd(formData.name, formData.description || '');
        message.success('Submit successfully! Please refresh the list');
        return true;
      }}
    >
      <ProForm.Group>
        <ProFormText id="name" label="name" name="name" rules={[{ required: true, message: 'Required' }]}></ProFormText>
      </ProForm.Group>
      <ProForm.Group>
        <ProFormText id="description" label="description" name="description" width="xl"></ProFormText>
      </ProForm.Group>
    </ModalForm>
  );
};

export default NewNetworkModel;
