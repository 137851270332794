import { Card, Col, List, Row, Spin } from 'antd';
import React, { useState } from 'react';
import { useMount } from 'ahooks';
import { postDashboardEventsGetLastMonth } from '@/service/dashboard/v1';
import { EventItem } from '@/service/map/event';
import { StationItem } from '@/service/map/station';
import { Area } from '@antv/g2plot';
import moment from 'moment';

const Index = () => {
  return (
    <Card title="Event">
      <EventStatus />
      <EventTrend />
    </Card>
  );
};

const EventTrend = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [events, setEvents] = useState<EventItem[]>([]);

  useMount(() => {
    setLoading(true);
    postDashboardEventsGetLastMonth().then((res) => {
      setEvents(res.data);
      const dataMap = new Map<string, EventItem[]>();
      res.data.forEach((it) => {
        const key = moment(it.createdTime).format('MM/DD/YYYY');
        if (dataMap.get(key) == null || dataMap.get(key) === undefined) {
          dataMap.set(key, [it]);
        } else {
          dataMap.get(key)!.push(it);
        }
      });

      let data: Object[] = [];
      dataMap.forEach((value, key) => {
        const range1 = [
          '<=2',
          '2~2.5',
          '2.5~3',
          '3~3.5',
          '3.5~4',
          '4~4.5',
          '4.5~5',
          '5~5.5',
          '>5.5',
        ];

        range1.forEach((level, index) => {
          const count = value.filter(
            (it) => magnitudeToLevel(it.magnitude) === index,
          ).length;
          data.push({
            date: key,
            magnitude: level,
            value: count,
          });
        });
      });
      // 渲染
      const area = new Area('event-trend', {
        data,
        xField: 'date',
        yField: 'value',
        color: [
          '#7f7f7f',
          '#a9d18e',
          '#548235',
          '#00b0f0',
          '#2e75b6',
          '#fbef54',
          '#ffc000',
          '#ff0000',
          '#c00000',
        ],
        seriesField: 'magnitude',
        slider: { start: 0, end: 1 },
        meta: {
          date: {
            alias: 'Date',
          },
          magnitude: {
            alias: 'Magnitude',
          },
          value: {
            alias: 'Count',
          },
        },
      });
      area.render();
      setLoading(false);
    });
  });

  return (
    <div>
      <div id="event-trend"></div>
      {loading && <Spin style={{ margin: '130px' }} size="large" />}
    </div>
  );
};

const EventStatus = () => {
  const [events, setEvents] = React.useState<EventItem[]>([]);
  const [loading, setLoading] = React.useState(false);

  useMount(() => {
    setLoading(true);
    postDashboardEventsGetLastMonth().then((res) => {
      setEvents(res.data);
      setLoading(false);
    });
  });

  const data = [
    {
      title: 'Last Month',
      value: (it: EventItem[]) => it.length,
    },
    {
      title: 'Last Week',
      value: (it: EventItem[]) =>
        it.filter(
          (it) => it.t0Utc > new Date().getTime() - 1000 * 3600 * 24 * 7,
        ).length,
    },
    {
      title: 'Last Day',
      value: (it: EventItem[]) =>
        it.filter((it) => it.t0Utc > new Date().getTime() - 1000 * 3600 * 24)
          .length,
    },
  ];

  return (
    <List
      grid={{ gutter: 16, xs: 1, sm: 2, md: 5, lg: 5, xl: 5, xxl: 5 }}
      dataSource={data}
      renderItem={(item) => (
        <List.Item>
          <Card
            title={item.title}
            style={{ fontSize: 'x-large' }}
            loading={loading}
            bordered={false}
          >
            {item.value(events)}
          </Card>
        </List.Item>
      )}
    />
  );
};

const magnitudeToLevel = (magnitude: number) => {
  let level = 0;
  if (magnitude <= 2) {
    level = 0;
  } else if (magnitude <= 2.5) {
    level = 1;
  } else if (magnitude <= 3) {
    level = 2;
  } else if (magnitude <= 3.5) {
    level = 3;
  } else if (magnitude <= 4) {
    level = 4;
  } else if (magnitude <= 4.5) {
    level = 5;
  } else if (magnitude <= 5) {
    level = 6;
  } else if (magnitude <= 5.5) {
    level = 7;
  } else {
    level = 8;
  }
  return level;
};

export default Index;
