import { request } from '@@/plugin-request/request';
import { ResponseData } from '@/service/API';

export type StaItem = {
  client: string;
  station: string;
  tbeat: number;
  status: number;
  latitude: number;
  longitude: number;
  depthMSL: number;
  gps_quality: number;
  n_mqtt_sent: number;
  battery: number;
  temeprature_cpu: number;
  transmission: number;
  signalLTE: number;
  signelWiFi: number;
  ipAddress: string;
  firmVersion: string;
  mqttVersion: string;
};

export async function PostCore2StaList(stationId: string, passedHours: number) {
  return request<ResponseData<StaItem[]>>('/api/core2/sta/list', {
    method: 'POST',
    data: {
      passedHours,
      stationId,
    },
  });
}

export async function PostCore2StaLatest(stationId: string) {
  return request<ResponseData<StaItem>>('/api/core2/sta/latest', {
    method: 'POST',
    data: {
      stationId,
    },
  });
}

export async function PostCore2StaListByTime(stationId: string, startTime: moment.Moment, endTime: moment.Moment) {
  return request<ResponseData<StaItem[]>>('/api/core2/sta/listByTime', {
    method: 'POST',
    data: {
      startTime,
      endTime,
      stationId,
    },
  });
}
