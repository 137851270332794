import React, { useState } from 'react';
import { Modal, Table, Tag } from 'antd';
import { StationItem } from '@/service/map/station';
import { connect } from 'umi';
import { Dispatch } from 'redux';
import StationBoard from '@/components/StationBoard';
import { markerList } from '@/pages/components/MapStation';
import { mapInstance } from '@/pages/components/MapView';

const cylindrical = (x: number, molecular: number, denominator: number) => {
  const value = molecular / denominator;
  if (isNaN(value) || value < 0) {
    return <rect x={x} y="0" width="25" height="200" fill="#7f7f7f" />;
  }
  const fill = value > 1 ? '#ff0000' : '#00b050';
  if (value > 1.5) return <rect x={x} y="0" width="25" height="100" fill={fill} />;
  if (value < 0.5) return <rect x={x} y="100" width="25" height="100" fill={fill} />;
  if (value > 1) {
    const temp = (value - 1) * 200;
    return <rect x={x} y={100 - temp} width="25" height={temp} fill={fill} />;
  } else {
    const temp = (value - 0.5) * 200;
    return <rect x={x} y="100" width="25" height={temp} fill={fill} />;
  }
};

const Shape: React.FC<{ data: StationItem }> = ({ data }) => {
  return (
    <div>
      <svg viewBox="0 0 300.35 200" width="35px" height="35px">
        <rect x="0.35" y="0" strokeWidth="6" stroke="#000" fill="#fff" width="300" height="200" rx="0" ry="0" />
        <line x1="0" y1="100" x2="300" y2="100" strokeWidth="6" stroke="#000000" data-bx-origin="1 14.401237" />
        <line strokeDasharray="8px" x1="100" y1="0" x2="100" y2="200" strokeWidth="6" stroke="#000000" />
        <line strokeDasharray="8px" x1="200" y1="0" x2="200" y2="200" strokeWidth="6" stroke="#000000" />

        {cylindrical(7.5, data.std_ax, data.std_ref1)}
        {cylindrical(37.5, data.std_ay, data.std_ref1)}
        {cylindrical(67.5, data.std_az, data.std_ref1)}

        {cylindrical(107.5, data.std_gx, data.std_ref2)}
        {cylindrical(137.5, data.std_gy, data.std_ref2)}
        {cylindrical(167.5, data.std_gz, data.std_ref2)}

        {cylindrical(207.5, data.std_asx, data.std_ref3)}
        {cylindrical(237.5, data.std_asy, data.std_ref3)}
        {cylindrical(267.5, data.std_asz, data.std_ref3)}
      </svg>
    </div>
  );
};

const StatusRender = (state: number) => {
  switch (state) {
    case 0:
      return <Tag color="#23ac4c">Normal</Tag>;
    case 1:
      return <Tag color="#ffc000">Unstable</Tag>;
    case 2:
      return <Tag color="#f50">Error</Tag>;
    default:
      return <Tag color="#808080">Stop</Tag>;
  }
};

const BatteryRender = (battery: number) => {
  const temp = (battery * 5 + 1).toFixed(0);
  return (
    <div>
      <img src={`/battery/battery${temp}.png`} style={{ height: '23px', width: '45px' }} alt="" />
    </div>
  );
};

const SignalRender = (num: number) => {
  if (num > 0.8) num = 5;
  else if (num > 0.6) num = 4;
  else if (num > 0.4) num = 3;
  else if (num > 0.2) num = 2;
  else num = 1;
  const signalColor = num > 3 ? '#23ac4c' : num > 1 ? '#ffc000' : '#f50';
  return (
    <svg width="26" height="26" viewBox="0 0 1131 1024" xmlns="http://www.w3.org/2000/svg" version="1.1" className="icon">
      <path id="svg_1" fill={num >= 1 ? signalColor : '#cdcdcd'} d="m0,729.99998l143.36,0l0,292.8l-143.36,0l0,-292.8z" />
      <path id="svg_2" fill={num >= 2 ? signalColor : '#cdcdcd'} d="m235.52,570.00001l143.36,0l0,452.6l-143.36,0l0,-452.6z" />
      <path id="svg_3" fill={num >= 3 ? signalColor : '#cdcdcd'} d="m471.04,409.6l143.36,0l0,614.4l-143.36,0l0,-614.4z" />
      <path id="svg_4" fill={num >= 4 ? signalColor : '#cdcdcd'} d="m706.56,250l143.36,0l0,773.20002l-143.36,0l0,-773.20002z" />
      <path id="svg_5" fill={num >= 5 ? signalColor : '#cdcdcd'} d="m942.08,90l143.36,0l0,934.00004l-143.36,0l0,-934.00004z" />
    </svg>
  );
};

const SnRender = (_: any, record: StationItem) => {
  return <Shape data={record} />;
};

const Stations: React.FC<{
  dispatch?: Dispatch;
  station?: {
    stationList: StationItem[];
  };
}> = ({ dispatch, station }) => {
  const columns = [
    {
      title: 'StationID',
      dataIndex: 'stationId',
      key: 'number',
      render: (text: string) => <a title={text}>*{text.slice(-7)}</a>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: StatusRender,
    },
    {
      title: 'Battery',
      dataIndex: 'battery',
      key: 'battery',
      render: BatteryRender,
    },
    {
      title: 'Signal',
      dataIndex: 'signalLTE',
      key: 'signal',
      render: SignalRender,
    },
    {
      title: 'SN',
      dataIndex: 'sn',
      key: 'sn',
      render: SnRender,
    },
  ];

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modelRecord, setModelRecord] = useState<StationItem | undefined>(undefined);
  return (
    <div className="mr-8">
      <Table<StationItem>
        dataSource={station?.stationList}
        rowKey="id"
        columns={columns}
        pagination={false}
        onRow={(record: StationItem) => {
          return {
            onClick: () => {
              setModelRecord(record);
              // TODO  暂时先不展示
              // setIsModalVisible(true);
              markerList.forEach((item) => {
                if (item.station.stationId == record.stationId) {
                  mapInstance?.flyTo({
                    center: item.marker.getLngLat(),
                    zoom: mapInstance?.getZoom() > 14 ? mapInstance?.getZoom() : 13,
                  });
                  // 如果popup没有打开，就打开
                  if (!item.marker.getPopup().isOpen()) {
                    item.marker.togglePopup();
                  }
                } else {
                  // 如果有其他的打开了，就关闭
                  if (item.marker.getPopup().isOpen()) {
                    item.marker.togglePopup();
                  }
                }
              });
            },
          };
        }}
      />
      <Modal
        title={
          <div className="pl-4 pt-2">
            <div className="text-slate-500 font-bold text-3xl tracking-tight">Station</div>
            <div className="text-lg text-gray-400 pt-2 font-medium">{modelRecord?.stationId}</div>
          </div>
        }
        visible={isModalVisible}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
        style={{ top: 200 }}
        width={1000}
        footer={null}
      >
        <StationBoard station={modelRecord} />
      </Modal>
    </div>
  );
};

export default connect((state) => {
  return state;
})(Stations);
