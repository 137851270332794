import { Effect, ImmerReducer } from '@@/plugin-dva/connect';
import {
  EventItem,
  PostEventList,
  PostQueryListEventList,
} from '@/service/map/event';

export interface EventModelState {
  eventList: EventItem[];
  magnitudeRange: number[];
}

export interface EventModelType {
  namespace: 'event';
  state: EventModelState;
  reducers: {
    save: ImmerReducer<EventModelState>;
    level: ImmerReducer<EventModelState>;
  };
  effects: {
    fetchEventList: Effect;
    queryEventList: Effect;
    changeMagnitudeRange: Effect;
  };
}

const EventModel: EventModelType = {
  namespace: 'event',
  state: {
    eventList: [],
    magnitudeRange: [1, 9],
  },
  reducers: {
    save(state, { payload }) {
      state.eventList = payload;
    },
    level(state, { payload }) {
      state.magnitudeRange = payload;
    },
  },
  effects: {
    *fetchEventList({ payload }, { call, put }) {
      const { data } = yield call(PostEventList, payload);
      yield put({
        type: 'save',
        payload: data,
      });
    },
    *queryEventList({ payload }, { call, put }) {
      const { data } = yield call(PostQueryListEventList, payload);
      yield put({
        type: 'save',
        payload: data,
      });
    },
    *changeMagnitudeRange({ payload }, { put }) {
      yield put({
        type: 'level',
        payload: payload,
      });
    },
  },
};

export default EventModel;
