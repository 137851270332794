import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Dropdown, InputNumber, Row, Tag } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import Map, { Layer, Source } from 'react-map-gl';
import constant from '@/constant';
import { useModel } from '@@/plugin-model/useModel';
import { SketchPicker } from 'react-color';
import { useParams } from 'umi';
import { PageContainer } from '@ant-design/pro-layout';
import { PostAdminGetUser, PostAdminUpdateBoundary } from '@/service/admin/user';
import { useRequest, useUpdateEffect } from 'ahooks';
import { UserBoundary } from '@/service/user/common';
import { CheckCircleOutlined, SyncOutlined } from '@ant-design/icons';

const initGeoJson: GeoJSON.FeatureCollection<GeoJSON.Geometry> = {
  type: 'FeatureCollection',
  features: [],
};

const heredoc = (fn: Function) => {
  return fn.toString().split('\n').slice(2, -2).join('\n') + '\n';
};

const tsetJson = heredoc(() => {
  /*
{
    "type": "FeatureCollection",
    "features": [
        {
            "type": "Feature",
            "properties": {
                "GM_LAYER": "Unknown Area Type",
                "GM_TYPE": "Unknown Area Type",
                "LAYER": "Unknown Area Type",
                "OBJECTID": 1,
                "ID": 0,
                "TYPE": null,
                "UPDATED": "April 2021",
                "SHAPE_AREA": 2012974416.9400001,
                "SHAPE_LEN": 236772.276701
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -120.742082765142044,
                            56.246401170891836
                        ],
                        [
                            -120.689385331972005,
                            56.246385210193644
                        ],
                        [
                            -120.688478627805452,
                            56.159522739407961
                        ],
                        [
                            -120.349237675411786,
                            56.159531117394387
                        ],
                        [
                            -120.349225286952091,
                            56.116121412263404
                        ],
                        [
                            -120.297090113127197,
                            56.116141421211125
                        ],
                        [
                            -120.297086974693144,
                            56.043423587423185
                        ],
                        [
                            -120.114418773177846,
                            56.043423620574174
                        ],
                        [
                            -120.114411229462377,
                            55.883437159604526
                        ],
                        [
                            -120.244868495949504,
                            55.883434456689685
                        ],
                        [
                            -120.244866994778448,
                            55.825253114874208
                        ],
                        [
                            -120.688476180266619,
                            55.825243465904272
                        ],
                        [
                            -120.68847752421506,
                            55.854332755091455
                        ],
                        [
                            -120.740688787713992,
                            55.854345562363328
                        ],
                        [
                            -120.740690031448892,
                            55.897976442827527
                        ],
                        [
                            -120.766796105356605,
                            55.897976508074528
                        ],
                        [
                            -120.76679710600267,
                            55.941611322355286
                        ],
                        [
                            -120.818933627308454,
                            55.941588443079823
                        ],
                        [
                            -120.818934439887144,
                            55.970676286241684
                        ],
                        [
                            -121.027720525348514,
                            55.970694351717285
                        ],
                        [
                            -121.02772232331634,
                            56.028875837501658
                        ],
                        [
                            -121.158185830911762,
                            56.028866547084242
                        ],
                        [
                            -121.15818766315725,
                            56.145215695446737
                        ],
                        [
                            -121.032050674783079,
                            56.145230075989268
                        ],
                        [
                            -121.031936910853091,
                            56.173684500358156
                        ],
                        [
                            -121.031936631685326,
                            56.246400002941094
                        ],
                        [
                            -120.926514095928241,
                            56.246390054947298
                        ],
                        [
                            -120.926517179331711,
                            56.304566121375473
                        ],
                        [
                            -120.742088495302085,
                            56.304575512783465
                        ],
                        [
                            -120.742082765142044,
                            56.246401170891836
                        ]
                    ]
                ]
            }
        }
    ]
}
 */
});

const Index: React.FC = () => {
  const { userId } = useParams<{ userId: string }>();

  const { initialState } = useModel('@@initialState');
  const [text, setText] = useState<string>('');
  const [geojson2, setGeojson2] = useState<GeoJSON.FeatureCollection<GeoJSON.Geometry>>(initGeoJson);
  const [userLoading, setLoading] = useState<boolean>(false);
  const [syncing, setSyncing] = useState<boolean>(false);

  const commitBoundary = async (boundary: UserBoundary) => {
    PostAdminUpdateBoundary({
      userId,
      boundary,
    }).then(() => {
      setSyncing(false);
    });
  };
  // 更新数据库
  const { data, run } = useRequest(commitBoundary, {
    debounceWait: 2000,
    manual: true,
  });

  useEffect(() => {
    setLoading(true);
    PostAdminGetUser(userId).then((res) => {
      setLoading(false);
    });
  }, []);

  return (
    <PageContainer
      subTitle={<div>UserId:{userId}</div>}
      loading={userLoading}
      tabBarExtraContent={
        syncing ? (
          <Tag icon={<SyncOutlined spin />} color="processing">
            Boundary synchronizing...
          </Tag>
        ) : (
          <Tag icon={<CheckCircleOutlined />} color="success">
            Boundary has been updated
          </Tag>
        )
      }
    >
      <Card>
        <Row>
          <Col span={12}>
            <TextArea
              rows={40}
              value={''}
              onChange={(e) => {
                const text = e.target.value;
              }}
            />
          </Col>
          <Col span={12}>
            <Map
              style={{ width: '100%', height: '100%' }}
              initialViewState={{
                longitude: Number(localStorage.getItem('lastLng' + userId)) || -101.069801,
                latitude: Number(localStorage.getItem('lastLat' + userId)) || 40.5472,
                zoom: Number(localStorage.getItem('lastZoom' + userId)) || 6,
              }}
              onMove={(e) => {
                const { longitude, latitude, zoom } = e.viewState;
                localStorage.setItem('lastLng' + userId, longitude.toString());
                localStorage.setItem('lastLat' + userId, latitude.toString());
                localStorage.setItem('lastZoom' + userId, zoom.toString());
              }}
              mapboxAccessToken={constant.MAP_TOKEN}
              mapStyle="mapbox://styles/mapbox/streets-v9"
            >
              {/*<Source type="geojson" data={tsetJson}>*/}
              {/*  <Layer*/}
              {/*    type="line"*/}
              {/*    paint={{*/}
              {/*      'line-color': '#ff0000',*/}
              {/*    }}*/}
              {/*    layout={{'line-cap': 'round'}}*/}
              {/*  />*/}
              {/*</Source>*/}
            </Map>
          </Col>
        </Row>
      </Card>
    </PageContainer>
  );
};

export default Index;
