import { request } from 'umi';
import { ResponseData } from '@/service/API';

export function PostExperimentFileLl(path: string) {
  return request<ResponseData<any[]>>('/api/experiment/file/ll', {
    method: 'POST',
    params: {
      path,
    },
  });
}
