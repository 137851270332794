import { Input } from 'antd';
import React, { useRef, useState } from 'react';
import Tag from 'antd/es/tag';

const TagInput = (props: { initialValue?: string[]; onChange?: Function }) => {
  const [value, setValue] = useState<string[]>(props.initialValue ?? []);
  const [valueInput, setValueInput] = useState('');
  const inputRef = useRef(null);

  function preventDefault(str: string, e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    setValue(value.filter((item) => item !== str));
    // 回调函数
    if (props.onChange) {
      if (value.length == 0) {
        props.onChange([valueInput]);
      } else {
        props.onChange(value.filter((item) => item !== str));
      }
    }
  }

  function keyDown(e: { keyCode: number }) {
    if (e.keyCode === 8 && !valueInput) {
      setValue(
        value.filter(function (v, i, ar) {
          return i !== ar.length - 1;
        }),
      );
    }
  }

  function pressEnter(e: any) {
    if (e.target.value) {
      setValue([...value, e.target.value]);
      setValueInput('');
    } else {
      // message.warn('no data')
    }
    // 回调函数
    if (props.onChange) {
      if (value.length == 0) {
        props.onChange([valueInput]);
      } else {
        props.onChange([...value, e.target.value]);
      }
    }
  }

  function handleChange(e: { target: any }) {
    let elm = e.target;
    setValueInput(elm.value);
    // 回调函数
    if (props.onChange) {
      if (value.length == 0) {
        props.onChange([elm.value]);
      } else {
        props.onChange(elm.value);
      }
    }
  }

  return (
    <div>
      <Input
        style={{ width: 600 }}
        prefix={
          <div>
            {value &&
              value.map((item, index) => (
                <span key={index}>
                  {index % 2 == 0 && index != 0 && <br></br>}
                  <Tag closable onClose={(e) => preventDefault(item, e)}>
                    {item}
                  </Tag>
                </span>
              ))}
          </div>
        }
        ref={inputRef}
        value={valueInput}
        onKeyDown={keyDown}
        onPressEnter={pressEnter}
        onChange={handleChange}
        placeholder="please press the enter key to split values"
      ></Input>
    </div>
  );
};

export default TagInput;
