import { Button, Col, Input, Row, Space, Table } from 'antd';
import { Observer, useLocalStore } from 'mobx-react';
import { action, observable } from 'mobx';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useMount } from 'ahooks';
import { PostExperimentFileLl } from '@/service/experiment/fileEx';

class FileTabViewModel {
  stack = ['/data'];

  @observable
  path = '/data';

  inputPath = '';

  @observable
  listFiles: any[] = [];

  @observable
  loading = false;

  @action
  getListFile = () => {
    this.loading = true;
    PostExperimentFileLl(this.path)
      .then((res) => {
        console.log(res);
        this.listFiles = res.data;
        this.loading = false;
      })
      .catch((err) => {
        this.loading = false;
      });
  };

  @action
  setPath = (path: string) => {
    this.path = path;
  };
}

const ViewModel = new FileTabViewModel();

const FileTabView = () => {
  const viewModel = useLocalStore(() => ViewModel);

  useMount(() => {
    viewModel.getListFile();
  });

  return (
    <Observer>
      {() => (
        <div>
          <Row>
            <Col flex="45px">
              <Button icon={<ArrowLeftOutlined />} type="primary"></Button>
            </Col>
            <Col flex="auto">
              <Input
                defaultValue={viewModel.path}
                placeholder="Path"
                onChange={(res) => {
                  viewModel.inputPath = res.target.value;
                }}
              />
            </Col>
            <Col flex="150px">
              <Space className="ml-2">
                <Button
                  type="primary"
                  onClick={() => {
                    viewModel.path = viewModel.inputPath;
                    viewModel.getListFile();
                  }}
                >
                  Go
                </Button>
                <Button type="primary">Reload</Button>
              </Space>
            </Col>
          </Row>
          <Table loading={viewModel.loading}></Table>
        </div>
      )}
    </Observer>
  );
};

export default FileTabView;
