import React, { ReactNode, useRef, useState } from 'react';
import { PageContainer } from '@ant-design/pro-layout';
import ProList, { ProListMetas } from '@ant-design/pro-list';

import { Avatar, Button, Space, Tag } from 'antd';
import { ActionType, ConfigProvider } from '@ant-design/pro-table';
import { enUSIntl } from '@ant-design/pro-provider';
import EditUserModal from '@/pages/admin/userManager/EditUserModal';
import { UserItem } from '@/service/user/common';
import { PostUserList } from '@/service/user/list';
import NewUserModal from '@/pages/admin/userManager/NewUserModal';
import DeleteUserModal from '@/pages/admin/userManager/DeleteUserModal';
import { history, Link } from 'umi';
import { PostAdminUserSwitch } from '@/service/admin/user';
import { useCookieState } from 'ahooks';
import Cookies from 'js-cookie';
import EditReportModel from '@/pages/admin/userManager/EditReportModel';

function getCookie(cname: string) {
  const name = cname + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    const c = ca[i].trim();
    if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
  }
  return '';
}

const Index = () => {
  const ref = useRef<ActionType>();

  const listMetas: ProListMetas<UserItem> = {
    avatar: {
      render: (dom, entity) => {
        return <Avatar src={`/api/user/avatar?user=${entity.id}`} />;
      },
    },
    title: {
      dataIndex: 'email',
      render: (dom, entity) => {
        return (
          <div>
            {entity.firstName} {entity.lastName}
          </div>
        );
      },
    },
    subTitle: {
      dataIndex: 'type',
      render: (dom, entity) => {
        return <Space size={0}>{entity.type === 'Admin' ? <Tag color="orange">Admin</Tag> : <Tag color="blue">Common User</Tag>}</Space>;
      },
    },
    description: {
      dataIndex: 'email',
      render: (dom, entity) => {
        return (
          <div>
            <div>Email: {entity.email}</div>
            <div>User ID: {entity.userId}</div>
          </div>
        );
      },
    },
    actions: {
      dataIndex: 'id',
      render: (dom, entity) => {
        return (
          <Space size={0}>
            <Button
              type="link"
              onClick={() => {
                Cookies.set('otoken', Cookies.get('xtoken') as string, {
                  // 默认时长20分钟
                  expires: new Date(new Date().getTime() + 1000 * 60 * 20),
                });
                PostAdminUserSwitch(entity.id).then(() => {
                  history.replace('/');
                  window.location.reload();
                });
              }}
            >
              Switch
            </Button>
            <EditReportModel entity={entity}></EditReportModel>
            <Link to={`/admin/boundary/${entity.id}`}>Boundary</Link>
            <EditUserModal entity={entity} />
            <DeleteUserModal
              entity={entity}
              onDeleted={() => {
                ref.current?.reload();
              }}
            />
          </Space>
        );
      },
    },
  };

  const toolbarActions: ReactNode[] = [<NewUserModal />];

  return (
    <ConfigProvider value={{ intl: enUSIntl, valueTypeMap: {} }}>
      <PageContainer>
        <ProList<UserItem>
          actionRef={ref}
          headerTitle="User list"
          rowKey="id"
          metas={listMetas}
          request={async (params) => {
            const { data } = await PostUserList(params.pageSize ?? 10, params.current ?? 0, params.query ?? '');
            return {
              data: data.values,
              total: data.total,
              success: true,
            };
          }}
          options={{
            search: {
              name: 'query',
              placeholder: 'Search by email/name',
            },
            setting: false,
          }}
          toolbar={{
            actions: toolbarActions,
          }}
          pagination={{
            defaultPageSize: 10,
            showSizeChanger: true,
            showQuickJumper: true,
          }}
        />
      </PageContainer>
    </ConfigProvider>
  );
};

export default Index;
