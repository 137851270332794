/**
 * 把对象数组转为csv文件
 * @param header 表头，其中如果出现@则做表头替换，比如对象中的name属性，导出为姓名，则可以写成"name@姓名"
 * @param data 数据
 */

export function createCsv(header: string[], data: any[]) {
  const table =
    header
      .map((item) => {
        return item.includes('@') ? item.split('@')[1] : item;
      })
      .join(',') +
    '\n' +
    data
      .map((item) => {
        return header.map((key) => `${item[key.split('@')[0]]}`).join(',');
      })
      .join('\n');
  return 'data:text/csv;charset=utf-8,\ufeff' + encodeURIComponent(table);
}
