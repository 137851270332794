import mapboxgl from 'mapbox-gl';
import Magnitude from '@/components/Magnitude';
import ReactDOM from 'react-dom';
import React, { useEffect } from 'react';
import { connect } from 'umi';
import { AttachmentEventReport, EventItem } from '@/service/map/event';
import { Button, Descriptions, message } from 'antd';
import moment from 'moment';
import { CloseOutlined } from '@ant-design/icons';
import { AdminDeleteEvent } from '@/service/admin/event';

// 我是真没办法写hooks了 [捂脸]，单纯函数里不能使用Hooks，这里又不能改成React函数式组件，层层传参更不优雅，只能引入全局变量了
let _UserType: String | undefined = undefined;

const eventPopup = (event: EventItem, marker: mapboxgl.Marker) => {
  const element = (
    <div style={{ position: 'relative' }}>
      <div style={{ position: 'absolute', right: 0, top: 0 }}>
        <Button
          type="text"
          onClick={() => {
            marker.togglePopup();
          }}
        >
          <CloseOutlined />
        </Button>
      </div>
      <Descriptions title="Event" bordered={true} size="small" column={1} style={{ width: 400 }}>
        <Descriptions.Item label="Event ID ">{event.eventId}</Descriptions.Item>
        <Descriptions.Item label="Origin time (UTC) ">{moment.utc(event.t0Utc).format('YYYY-MM-DD HH:mm:ss.SSS')}</Descriptions.Item>
        <Descriptions.Item label="Latitude">{event.latitude}°</Descriptions.Item>
        <Descriptions.Item label="Longitude">{event.longitude}°</Descriptions.Item>
        <Descriptions.Item label="Depth (ground)">{event.depthGround}m</Descriptions.Item>
        <Descriptions.Item label="Magnitude">{event.magnitude}</Descriptions.Item>
        <Descriptions.Item label="Mode">{event.mode}</Descriptions.Item>
        <Descriptions.Item label="QC report">
          <Button
            type="link"
            onClick={() => {
              AttachmentEventReport(event.id);
            }}
          >
            Download
          </Button>
        </Descriptions.Item>
        {_UserType === 'Admin' && (
          <Descriptions.Item label="Operate">
            <Button
              danger={true}
              type="link"
              onClick={() => {
                AdminDeleteEvent(event.eventId).then(() => {
                  message.success('Delete event successfully, please refresh the page');
                });
              }}
            >
              Delete
            </Button>
          </Descriptions.Item>
        )}
      </Descriptions>
    </div>
  );
  const container = document.createElement('div');
  ReactDOM.render(element, container);
  return container;
};

export const addMagnitudeMaker = (map: mapboxgl.Map, lenLat: mapboxgl.LngLatLike, event: EventItem) => {
  const element = (
    <div style={{ transform: 'scale(2)' }}>
      <Magnitude magnitude={event.magnitude} />
    </div>
  );
  const container = document.createElement('div');
  ReactDOM.render(element, container);
  const marker = new mapboxgl.Marker({
    element: container,
  });
  marker
    .setLngLat(lenLat)
    .setPopup(new mapboxgl.Popup({ offset: 25, closeButton: false }).setDOMContent(eventPopup(event, marker)).setMaxWidth('800px'))
    .addTo(map);
  return marker;
};

const markerList: mapboxgl.Marker[] = [];

const Index: React.FC<{
  map?: mapboxgl.Map;
  userType: string | undefined;
  event?: {
    eventList: EventItem[];
    magnitudeRange: number[];
  };
}> = ({ map, event, userType }) => {
  _UserType = userType;
  // 判空
  if (!map) return <div />;

  useEffect(() => {
    markerList.forEach((it) => it.remove());
    while (markerList.length > 0) {
      markerList.pop();
    }

    event?.eventList.forEach((item) => {
      if (item.magnitude >= event?.magnitudeRange[0] && item.magnitude <= event?.magnitudeRange[1]) {
        const marker = addMagnitudeMaker(map, [item.longitude, item.latitude], item);
        markerList.push(marker);
      }
    });
  });
  return (
    <div>
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css" />
    </div>
  );
};

export default connect((state) => {
  return state;
})(Index);
