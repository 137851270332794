import React, { useState } from 'react';
import { Button, Card, Form, Input, message, Upload } from 'antd';
import { CurrentUserInfo, PostUserNameEdit, PostUserUploadAvatar, UserNameEditParam } from '@/service/user/common';
import { Avatar } from '@mui/material';
import { useModel } from '@@/plugin-model/useModel';
import ProForm, { ProFormField } from '@ant-design/pro-form';
import ImgCrop from 'antd-img-crop';
import { LoadingOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import usersList from '@/pages/admin/components/UsersList';

const UserAvatar: React.FC<{
  user: CurrentUserInfo | undefined;
}> = ({ user }) => {
  const [avatarLoading, setAvatarLoading] = useState(false);
  const [refreshHelper, setRefreshHelper] = useState(0);
  const refresh = () => {
    setRefreshHelper(refreshHelper + 1);
  };
  return (
    <div>
      <div>
        <Avatar
          style={{
            width: '8rem',
            height: '8rem',
          }}
          src={`/api/user/avatar?updateTimes=${refreshHelper}`}
        >
          {user?.firstName?.substring(0, 2)}
        </Avatar>
      </div>
      <div style={{ height: 20 }}></div>
      <ImgCrop aspect={1} minZoom={0.7} modalTitle="crop avatar" grid={true} shape="rect">
        <Upload
          name="avatar"
          showUploadList={false}
          beforeUpload={(file) => {
            setAvatarLoading(true);
            PostUserUploadAvatar(file).then((res) => {
              setAvatarLoading(false);
              refresh();
            });
          }}
        >
          <Button loading={avatarLoading} icon={<UploadOutlined />}>
            Upload avatar
          </Button>
        </Upload>
      </ImgCrop>
      <div>
        <div className="text-xl font-bold pt-2">{user?.firstName + ' ' + user?.lastName}</div>
      </div>
    </div>
  );
};

const Index: React.FC = () => {
  const { initialState, setInitialState } = useModel('@@initialState');
  const currentUser = initialState?.currentUser;

  return (
    <Card title={<div className="text-xl">Basic Setting</div>} bordered={false}>
      <UserAvatar user={currentUser}></UserAvatar>
      <div className="p-4"></div>
      <ProForm
        submitter={{ resetButtonProps: { style: { display: 'none' } } }}
        onReset={undefined}
        onFinish={async (values: UserNameEditParam) => {
          const res = await PostUserNameEdit(values);
          if (res.code === 200) {
            message.success('submit successfully');
            await setInitialState({
              ...initialState,
              // @ts-ignore
              currentUser: {
                ...currentUser,
                firstName: values.firstName,
                lastName: values.lastName,
              },
            });
          }
        }}
      >
        <ProFormField
          name="firstName"
          width="md"
          label="First Name"
          placeholder="First Name"
          rules={[{ required: true, message: 'First Name required' }]}
          initialValue={currentUser?.firstName}
        />
        <ProFormField
          name="lastName"
          width="md"
          label="Last Name"
          placeholder="Last Name"
          rules={[{ required: true, message: 'Last Name required' }]}
          initialValue={currentUser?.lastName}
        />
      </ProForm>
    </Card>
  );
};

export default Index;
