import { useEffect } from 'react';

type ComponentProps = {
  map?: mapboxgl.Map;
  userType?: string;
  clientId?: string;
};

const Index = ({ map, userType, clientId }: ComponentProps) => {
  useEffect(() => {
    if (userType == 'Admin' || clientId == 'Shell_groundbirch') {
      map?.on('load', (e) => {
        // Line
        map?.addSource('detectionLine', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                properties: { shape: 'Line', name: 'Detection Layer', category: 'default' },
                geometry: {
                  type: 'LineString',
                  coordinates: [
                    [-120.76814400000002, 55.92451435383447],
                    [-120.76479896501502, 55.92435600006484],
                    [-120.76149376500231, 55.924061781076176],
                    [-120.758244581423, 55.92364296282909],
                    [-120.75506721988702, 55.923102501167165],
                    [-120.75196702113203, 55.922461075820245],
                    [-120.7489054025099, 55.921788477118376],
                    [-120.7458342841644, 55.921123051796435],
                    [-120.74273522344025, 55.920449447241964],
                    [-120.73957814411494, 55.919766345689325],
                    [-120.73634155824247, 55.919061982702736],
                    [-120.7329967343813, 55.91832954009615],
                    [-120.72949324328577, 55.917571941526504],
                    [-120.72581147062574, 55.91676726802897],
                    [-120.72188732820614, 55.91591438566017],
                    [-120.71766557351673, 55.91499814183555],
                    [-120.71307782633895, 55.91400071443799],
                    [-120.70802790696617, 55.91290325118039],
                    [-120.70240451260558, 55.91167686359967],
                    [-120.69611303328142, 55.91027330221176],
                    [-120.6900455494549, 55.90845947008263],
                    [-120.68458342529746, 55.90620954684623],
                    [-120.67977401320294, 55.90358004538234],
                    [-120.67569960359558, 55.90062389770636],
                    [-120.67239933716462, 55.897400347325],
                    [-120.66994805275881, 55.893969313305],
                    [-120.66834408370471, 55.89039424644866],
                    [-120.66763687487389, 55.88674027298811],
                    [-120.66759533282331, 55.88304614082788],
                    [-120.66760342324942, 55.87925713099686],
                    [-120.66791856897065, 55.87539276804537],
                    [-120.66916782284613, 55.871596939192855],
                    [-120.67137927258045, 55.86795235385467],
                    [-120.67449089880937, 55.86452485916096],
                    [-120.67844228855556, 55.86137974569965],
                    [-120.68316019397682, 55.85857806431594],
                    [-120.6885593515966, 55.85617592046768],
                    [-120.69454341493044, 55.854223813784024],
                    [-120.70099531139124, 55.852759333450535],
                    [-120.70783179061259, 55.851840078293414],
                    [-120.71489782819944, 55.85147621481622],
                    [-120.72209944278937, 55.85172011861785],
                    [-120.72928694566237, 55.852580804974906],
                    [-120.73626525854107, 55.85399978160581],
                    [-120.74251098684361, 55.855354119403486],
                    [-120.7481035363455, 55.85657343217641],
                    [-120.75317152200982, 55.85767275131745],
                    [-120.7578263009084, 55.85868759827008],
                    [-120.76214687358329, 55.8596275278211],
                    [-120.76620021856577, 55.86050871809876],
                    [-120.7700405085043, 55.861343730703794],
                    [-120.7737131953417, 55.86214036369563],
                    [-120.77725678625158, 55.86290986795216],
                    [-120.78070577490783, 55.863657494323846],
                    [-120.7840860985734, 55.86439973118112],
                    [-120.78743725339393, 55.86512082202],
                    [-120.79076679416778, 55.86585855204746],
                    [-120.79406583238607, 55.86664957271575],
                    [-120.79727674933014, 55.86755881767617],
                    [-120.80037696665339, 55.86859059361389],
                    [-120.80333107388411, 55.86975443171696],
                    [-120.80613011783699, 55.87103620422583],
                    [-120.80875791267728, 55.87242872053987],
                    [-120.81118727173363, 55.87392995150858],
                    [-120.81341515139762, 55.87552455016094],
                    [-120.81541580154673, 55.877208238180565],
                    [-120.81720435049006, 55.87896078577676],
                    [-120.81874415786108, 55.880781358163105],
                    [-120.82004073546892, 55.8826542622387],
                    [-120.82107271704662, 55.88457139714005],
                    [-120.82186549693984, 55.88651505678544],
                    [-120.82240139058773, 55.8884772587722],
                    [-120.82267918512571, 55.890446458787736],
                    [-120.82271534164411, 55.8924103882348],
                    [-120.82249617764282, 55.89435940800172],
                    [-120.82202537719907, 55.89628217574414],
                    [-120.82132420736218, 55.89816855948075],
                    [-120.82039920375898, 55.900009415691464],
                    [-120.81925809852343, 55.90179592962162],
                    [-120.81803215811495, 55.903541003710764],
                    [-120.81682813353942, 55.90527199918717],
                    [-120.81563790144776, 55.90700472942718],
                    [-120.81436433651392, 55.90872864767037],
                    [-120.8128776085711, 55.91040597890562],
                    [-120.81118377521345, 55.912028020110746],
                    [-120.80929005563291, 55.91358633739879],
                    [-120.80719302208712, 55.91506672014063],
                    [-120.80491528627819, 55.916466701239074],
                    [-120.80245712306125, 55.91777213905562],
                    [-120.79984195345179, 55.918982410177414],
                    [-120.79707364179458, 55.920083744275566],
                    [-120.79417592252261, 55.921077441041625],
                    [-120.79116294445727, 55.92195849788352],
                    [-120.78803757914223, 55.92270584092657],
                    [-120.78483588270876, 55.92334014312736],
                    [-120.78156278820985, 55.9238415958074],
                    [-120.77823746536272, 55.92420749501975],
                    [-120.77488144455278, 55.9244449489895],
                    [-120.77151037329547, 55.9245442664668],
                    [-120.76814400000002, 55.92451435383447],
                  ],
                },
                id: '8a22aade-dfa5-45f4-8e7e-565dcbbf8306',
              },
            ],
          },
        });
        map?.addSource('detectionLine2', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                properties: { shape: 'Line', name: 'Detection Layer', category: 'default' },
                geometry: {
                  type: 'LineString',
                  coordinates: [
                    [-120.76814400000002, 55.894843],
                    [-120.76814400000002, 55.894843],
                    [-120.76814400000002, 55.894843],
                    [-120.76814416020935, 55.894843269494594],
                    [-120.76814736439559, 55.89484677292419],
                    [-120.76815409318674, 55.894854498435635],
                    [-120.76816466700141, 55.89486635619739],
                    [-120.76817908583958, 55.8948825258725],
                    [-120.76819062091013, 55.89489564127565],
                    [-120.76819686907335, 55.89490264813487],
                    [-120.76821545335365, 55.894923668712515],
                    [-120.76823403763399, 55.89494468929016],
                    [-120.76825278212362, 55.89496570986781],
                    [-120.76827136640395, 55.89498682027698],
                    [-120.76828995068425, 55.89500784085463],
                    [-120.76830853496459, 55.89502886143228],
                    [-120.76832727945421, 55.89504988200993],
                    [-120.76834586373455, 55.89507090258758],
                    [-120.76836444801486, 55.895091923165225],
                    [-120.7683769443413, 55.89510602671519],
                    [-120.76838351292314, 55.89511348273204],
                    [-120.76840834536667, 55.895141510168905],
                    [-120.7684405474386, 55.89517789193792],
                    [-120.76848011913893, 55.89522253820754],
                    [-120.76852706046769, 55.89527544897778],
                    [-120.76858121121553, 55.89533653441709],
                    [-120.76864241117312, 55.8954057945255],
                    [-120.76871082054984, 55.89548304963993],
                    [-120.76872299645764, 55.895496704032254],
                    [-120.76878403620593, 55.89556542515149],
                    [-120.76885709165273, 55.8956479803261],
                    [-120.76893030730882, 55.89573053550071],
                    [-120.76900336275558, 55.895813090675325],
                    [-120.76907641820239, 55.89589564584994],
                    [-120.76914963385849, 55.89597820102454],
                    [-120.76922268930525, 55.896060756199155],
                    [-120.76929590496134, 55.89614331137376],
                    [-120.76936896040814, 55.89622586654838],
                    [-120.76944217606423, 55.896308421722985],
                    [-120.76951523151101, 55.8963909768976],
                    [-120.76958844716714, 55.896473532072214],
                    [-120.7696615026139, 55.896556087246815],
                    [-120.76973471826999, 55.89663864242142],
                    [-120.76980777371679, 55.896721107764506],
                    [-120.7698809893729, 55.89680366293912],
                    [-120.76995404481966, 55.89688621811373],
                    [-120.77002726047574, 55.89696877328834],
                    [-120.77010031592255, 55.89705132846296],
                    [-120.77017353157864, 55.897133883637565],
                    [-120.77021326348829, 55.897178799401765],
                    [-120.77024578597884, 55.89721563032842],
                    [-120.77031227284378, 55.897290549823104],
                    [-120.77037155028965, 55.89735738448025],
                    [-120.7704234581071, 55.89741613429984],
                    [-120.77046831671478, 55.89746670945033],
                    [-120.77050580569404, 55.89750902010021],
                    [-120.77053576483561, 55.89754297641795],
                    [-120.77055851455808, 55.89756848857201],
                    [-120.77057373444282, 55.89758573622547],
                    [-120.77058158469916, 55.89759453971526],
                    [-120.77058270616433, 55.89759579735666],
                    [-120.77058270616433, 55.89759579735666],
                    [-120.77058270616433, 55.89759579735666],
                    [-120.77058270616433, 55.89759579735666],
                    [-120.77058270616433, 55.89759579735666],
                    [-120.77058270616433, 55.89759579735666],
                    [-120.77058270616433, 55.89759579735666],
                    [-120.77058270616433, 55.89759579735666],
                    [-120.77058270616433, 55.89759579735666],
                    [-120.77058270616433, 55.89759579735666],
                    [-120.77058270616433, 55.89759579735666],
                    [-120.77058270616433, 55.89759579735666],
                    [-120.77058270616433, 55.89759579735666],
                    [-120.77058270616433, 55.89759579735666],
                    [-120.77058270616433, 55.89759579735666],
                    [-120.77058270616433, 55.89759579735666],
                    [-120.77057581716389, 55.897594270220665],
                    [-120.77052711353268, 55.89758367010032],
                    [-120.77043226961932, 55.897563098680315],
                    [-120.77029208647035, 55.89753255596065],
                    [-120.77010816617887, 55.8974925809305],
                    [-120.76988275167533, 55.89744353291599],
                    [-120.76961792568072, 55.89738595090628],
                    [-120.76931689238137, 55.8973205535536],
                    [-120.7689828559635, 55.89724787984712],
                    [-120.76861934103205, 55.89716882810211],
                    [-120.7682305130291, 55.89708429663388],
                    [-120.76782053739683, 55.896995093926165],
                    [-120.76739406020528, 55.896902387788835],
                    [-120.76695556731528, 55.89680698670567],
                    [-120.76650986500617, 55.89671014831804],
                    [-120.76630351541085, 55.896665232553836],
                    [-120.7660619197667, 55.896612681109715],
                    [-120.76561381431787, 55.89651521390139],
                    [-120.76516570886906, 55.89641783652459],
                    [-120.76471760342028, 55.896320369316264],
                    [-120.76426949797145, 55.89622290210794],
                    [-120.76382139252267, 55.89612543489961],
                    [-120.76337328707386, 55.89602796769128],
                    [-120.76292518162506, 55.895930590314485],
                    [-120.76247707617627, 55.89583312310615],
                    [-120.76202897072744, 55.89573565589782],
                    [-120.76158086527866, 55.89563818868951],
                    [-120.76113275982985, 55.8955408113127],
                    [-120.76068465438107, 55.89544334410437],
                    [-120.76023654893224, 55.895345876896045],
                    [-120.75978844348344, 55.895248409687724],
                    [-120.75934033803466, 55.8951509424794],
                    [-120.75889223258584, 55.89505356510259],
                    [-120.75844412713705, 55.894956097894266],
                    [-120.75799602168823, 55.89485863068594],
                    [-120.75754791623945, 55.89476116347762],
                    [-120.75709981079063, 55.89466378610082],
                    [-120.75665170534182, 55.894566318892494],
                    [-120.75620359989303, 55.89446885168416],
                    [-120.75575549444423, 55.89437138447584],
                    [-120.75530738899545, 55.89427400709904],
                    [-120.75485928354662, 55.894176539890715],
                    [-120.75441117809784, 55.89407907268239],
                    [-120.75396307264903, 55.89398160547406],
                    [-120.75351496720022, 55.89388413826573],
                    [-120.75306686175142, 55.89378676088893],
                    [-120.75261875630262, 55.8936892936806],
                    [-120.75217065085381, 55.893591826472274],
                    [-120.75172254540502, 55.89349435926395],
                    [-120.75127443995619, 55.89339698188715],
                    [-120.75082633450741, 55.89329951467882],
                    [-120.7503782290586, 55.893202047470496],
                    [-120.74993012360981, 55.89310458026217],
                    [-120.749482018161, 55.89300720288537],
                    [-120.7490339127122, 55.892909735677044],
                    [-120.74858564705409, 55.892812268468724],
                    [-120.7481375416053, 55.89271480126039],
                    [-120.74768943615648, 55.89261733405206],
                    [-120.7472413307077, 55.892519956675265],
                    [-120.74679322525888, 55.89242248946694],
                    [-120.74634511981007, 55.89232502225862],
                    [-120.74589701436128, 55.89222755505029],
                    [-120.74544890891246, 55.89213017767348],
                    [-120.74500080346367, 55.89203271046515],
                    [-120.74455269801487, 55.89193524325684],
                    [-120.74410459256609, 55.89183777604851],
                    [-120.74365648711726, 55.891740398671715],
                    [-120.74320838166845, 55.89164293146337],
                    [-120.74276027621967, 55.891545464255046],
                    [-120.74231217077086, 55.891447997046726],
                    [-120.74186406532206, 55.8913505298384],
                    [-120.74141595987327, 55.8912531524616],
                    [-120.74096785442444, 55.891155685253274],
                    [-120.74051974897566, 55.89105821804495],
                    [-120.74007164352683, 55.89096075083662],
                    [-120.73962353807805, 55.89086337345982],
                    [-120.73917543262924, 55.890765906251495],
                    [-120.73872732718046, 55.89066843904317],
                    [-120.73827922173164, 55.89057097183484],
                    [-120.73783111628283, 55.890473594458044],
                    [-120.73738301083405, 55.890376127249716],
                    [-120.73693490538523, 55.89027866004139],
                    [-120.73648679993644, 55.89018119283306],
                    [-120.73603869448763, 55.890083725624734],
                    [-120.73559058903884, 55.88998634824793],
                    [-120.73514248359002, 55.8898888810396],
                    [-120.73469437814121, 55.889791413831276],
                    [-120.73424627269242, 55.88969394662295],
                    [-120.73379816724362, 55.88959656924615],
                    [-120.73335006179484, 55.88949910203783],
                    [-120.73290195634601, 55.889401634829504],
                    [-120.73245385089722, 55.88930416762117],
                    [-120.73200574544842, 55.88920679024437],
                    [-120.73155763999961, 55.889109323036045],
                    [-120.73110953455081, 55.889011855827725],
                    [-120.73066142910201, 55.8889143886194],
                    [-120.73021332365323, 55.88881692141107],
                    [-120.7297652182044, 55.88871954403427],
                    [-120.72931711275558, 55.888622076825946],
                    [-120.7288690073068, 55.88852460961762],
                    [-120.728420901858, 55.88842714240929],
                    [-120.7279727964092, 55.888329765032495],
                    [-120.7275246909604, 55.88823229782417],
                    [-120.7270765855116, 55.88813483061584],
                    [-120.7266284800628, 55.88803736340751],
                    [-120.72618037461397, 55.887939986030716],
                    [-120.72573226916519, 55.88784251882238],
                    [-120.72528416371638, 55.887745051614054],
                    [-120.7248360582676, 55.88764758440573],
                    [-120.72438795281879, 55.8875501171974],
                    [-120.72393984736998, 55.8874527398206],
                    [-120.72349174192118, 55.887355272612275],
                    [-120.72304363647237, 55.88725780540395],
                    [-120.72259553102359, 55.88716033819562],
                    [-120.72214742557476, 55.887062960818824],
                    [-120.72169932012598, 55.8869654936105],
                    [-120.72125121467717, 55.88686802640217],
                    [-120.72080310922836, 55.88677055919384],
                    [-120.72035500377957, 55.886673181817045],
                    [-120.71990689833076, 55.886575714608725],
                    [-120.71945879288198, 55.88647824740039],
                    [-120.71901068743315, 55.88638078019206],
                    [-120.71856258198436, 55.886283312983736],
                    [-120.71811447653555, 55.886185935606946],
                    [-120.71766637108675, 55.88608846839862],
                    [-120.71721826563795, 55.88599100119029],
                    [-120.71677016018914, 55.88589353398195],
                    [-120.71632205474035, 55.88579615660515],
                    [-120.71587394929155, 55.88569868939683],
                    [-120.71566631802173, 55.885653234643456],
                  ],
                },
                id: '87d10d85-bcc2-4197-8466-36b0f36bf165',
              },
            ],
          },
        });
        map?.addSource('detectionLine3', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                properties: { shape: 'Line', name: 'Detection Layer', category: 'default' },
                geometry: {
                  type: 'LineString',
                  coordinates: [
                    [-120.7681762222423, 55.894815777786],
                    [-120.7681762222423, 55.894815777786],
                    [-120.7681762222423, 55.894815777786],
                    [-120.7681762222423, 55.894815777786],
                    [-120.7681762222423, 55.894815777786],
                    [-120.7681762222423, 55.894815777786],
                    [-120.7681762222423, 55.894815777786],
                    [-120.76817942642857, 55.894817304921986],
                    [-120.76818919919667, 55.894821796498405],
                    [-120.76820522012795, 55.894829252515265],
                    [-120.76822780964112, 55.89483976280409],
                    [-120.7682568075268, 55.89485332736488],
                    [-120.76828916980804, 55.89486832923012],
                    [-120.76832137188, 55.89488333109537],
                    [-120.76835357395193, 55.894898243129084],
                    [-120.76838577602386, 55.894913244994335],
                    [-120.76841813830512, 55.89492824685958],
                    [-120.76845034037706, 55.89494324872482],
                    [-120.768471808425, 55.89495330985601],
                    [-120.76848318328624, 55.89495860991618],
                    [-120.76852627959147, 55.89497855251549],
                    [-120.76858187222314, 55.8950045138272],
                    [-120.76865044180914, 55.89503631418826],
                    [-120.76873150772157, 55.89507404343019],
                    [-120.76879254746987, 55.89510252002469],
                    [-120.76882442912319, 55.89511734222688],
                    [-120.76892023429244, 55.89516189866498],
                    [-120.76901587925238, 55.895206455103065],
                    [-120.76911168442162, 55.89525092170963],
                    [-120.76920732938154, 55.89529547814773],
                    [-120.76930313455081, 55.89534003458583],
                    [-120.76939877951074, 55.89538459102391],
                    [-120.76949458467998, 55.895429147462],
                    [-120.76959038984921, 55.89547361406857],
                    [-120.76968603480917, 55.895518170506655],
                    [-120.76978183997839, 55.89556272694475],
                    [-120.76987748493835, 55.89560728338284],
                    [-120.76997329010759, 55.895651839820935],
                    [-120.77006893506751, 55.8956963064275],
                    [-120.77016474023675, 55.895740862865594],
                    [-120.77026038519669, 55.895785419303685],
                    [-120.77035619036594, 55.895829975741776],
                    [-120.7704519955352, 55.89587453217986],
                    [-120.77054764049514, 55.89591899878643],
                    [-120.77064344566438, 55.895963555224526],
                    [-120.7707390906243, 55.89600811166262],
                    [-120.77083489579357, 55.89605266810071],
                    [-120.7709305407535, 55.896097224538806],
                    [-120.77102634592273, 55.89614169114537],
                    [-120.77109058985731, 55.89617160504433],
                    [-120.77112135004542, 55.89618597808887],
                    [-120.7712067416093, 55.896225683624436],
                    [-120.77127947663747, 55.89625946027912],
                    [-120.77133955512988, 55.89628739788445],
                    [-120.77138681687727, 55.896309406608914],
                    [-120.77142126187958, 55.896325396620966],
                    [-120.77144272992756, 55.89633545775215],
                    [-120.77145138123045, 55.89633950017093],
                    [-120.77145154143977, 55.89633959000246],
                    [-120.77145154143977, 55.89633959000246],
                    [-120.77145154143977, 55.89633959000246],
                    [-120.77145154143977, 55.89633959000246],
                    [-120.77145154143977, 55.89633959000246],
                    [-120.77145154143977, 55.89633959000246],
                    [-120.77145154143977, 55.89633959000246],
                    [-120.77145154143977, 55.89633959000246],
                    [-120.77145154143977, 55.89633959000246],
                    [-120.77145154143977, 55.89633959000246],
                    [-120.77145154143977, 55.89633959000246],
                    [-120.77145154143977, 55.89633959000246],
                    [-120.77145154143977, 55.89633959000246],
                    [-120.77145154143977, 55.89633959000246],
                    [-120.77145154143977, 55.89633959000246],
                    [-120.77145154143977, 55.89633959000246],
                    [-120.77145154143977, 55.89633959000246],
                    [-120.77145154143977, 55.89633959000246],
                    [-120.77144721578831, 55.89633860185564],
                    [-120.77140347864582, 55.89632907971364],
                    [-120.77131344101183, 55.89630949644044],
                    [-120.77117806414228, 55.89628012153065],
                    [-120.77099878992088, 55.89624113464731],
                    [-120.77077754085947, 55.89619298494809],
                    [-120.770516880307, 55.89613630125366],
                    [-120.77021953182187, 55.89607162255321],
                    [-120.76988869959025, 55.89599966749895],
                    [-120.76952822863575, 55.89592133440617],
                    [-120.76914196398181, 55.89583725209558],
                    [-120.7687340710706, 55.89574858837703],
                    [-120.76830903576287, 55.8956561517343],
                    [-120.76787166433805, 55.89556102014571],
                    [-120.7674266028662, 55.89546427158962],
                    [-120.76717251089559, 55.89540902519964],
                    [-120.76697849741741, 55.89536680438129],
                    [-120.76653039196859, 55.89526933717296],
                    [-120.76608228651979, 55.89517195979615],
                    [-120.76563418107101, 55.89507449258783],
                    [-120.76518607562218, 55.894977025379504],
                    [-120.7647379701734, 55.894879558171176],
                    [-120.76428986472459, 55.89478209096285],
                    [-120.76384175927579, 55.89468471358605],
                    [-120.76339365382698, 55.894587246377725],
                    [-120.76294554837817, 55.8944897791694],
                    [-120.76249744292937, 55.89439231196107],
                    [-120.76204933748058, 55.89429484475274],
                    [-120.7616012320318, 55.894197467375946],
                    [-120.76115312658297, 55.894100000167626],
                    [-120.76070502113419, 55.89400253295929],
                    [-120.76025691568537, 55.893905065750964],
                    [-120.75980881023656, 55.89380768837417],
                    [-120.75936070478777, 55.89371022116584],
                    [-120.75891259933897, 55.89361275395752],
                    [-120.75846449389016, 55.89351528674919],
                    [-120.75801638844136, 55.893417819540865],
                    [-120.75756828299254, 55.893320442164054],
                    [-120.75712017754375, 55.89322297495573],
                    [-120.75667207209494, 55.89312550774741],
                    [-120.75622396664616, 55.89302804053908],
                    [-120.75577586119735, 55.89293057333075],
                    [-120.75532775574855, 55.89283319595395],
                    [-120.75487965029976, 55.89273572874563],
                    [-120.75443154485093, 55.8926382615373],
                    [-120.75398343940215, 55.89254079432897],
                    [-120.75353533395334, 55.892443327120645],
                    [-120.75308722850454, 55.89234594974385],
                    [-120.75263912305573, 55.89224848253552],
                    [-120.75219101760695, 55.892151015327194],
                    [-120.75174291215812, 55.89205354811887],
                    [-120.75129480670932, 55.89195608091054],
                    [-120.75084670126054, 55.89185870353374],
                    [-120.75039859581172, 55.891761236325415],
                    [-120.74995049036293, 55.89166376911709],
                    [-120.74950238491412, 55.89156630190876],
                    [-120.74905427946531, 55.89146883470043],
                    [-120.74860617401652, 55.89137145732363],
                    [-120.74815806856772, 55.891273990115316],
                    [-120.74770996311894, 55.89117652290699],
                    [-120.74726185767011, 55.89107905569865],
                    [-120.74681375222133, 55.89098158849032],
                    [-120.7463656467725, 55.89088421111352],
                    [-120.7459175413237, 55.8907867439052],
                    [-120.74546943587491, 55.890689276696875],
                    [-120.7450213304261, 55.89059180948855],
                    [-120.7445732249773, 55.89049434228021],
                    [-120.7441251195285, 55.89039696490342],
                    [-120.74367701407968, 55.890299497695096],
                    [-120.7432289086309, 55.89020203048677],
                    [-120.74278080318209, 55.89010456327844],
                    [-120.7423326977333, 55.890007096070114],
                    [-120.74188459228449, 55.88990971869332],
                    [-120.7414364868357, 55.88981225148499],
                    [-120.74098838138688, 55.88971478427666],
                    [-120.74054027593807, 55.889617317068335],
                    [-120.74009217048929, 55.88951993969154],
                    [-120.73964406504047, 55.88942247248321],
                    [-120.73919595959168, 55.889325005274884],
                    [-120.73874785414287, 55.88922753806656],
                    [-120.73829974869409, 55.88913007085823],
                    [-120.73785164324528, 55.889032693481425],
                    [-120.73740353779647, 55.8889352262731],
                    [-120.73695543234768, 55.888837759064785],
                    [-120.73650732689886, 55.88874029185644],
                    [-120.73605922145008, 55.888642824648116],
                    [-120.73561111600125, 55.88854544727132],
                    [-120.73516301055245, 55.888447980063],
                    [-120.73471490510366, 55.88835051285467],
                    [-120.73426679965485, 55.888253045646344],
                    [-120.73381869420605, 55.88815557843801],
                    [-120.73337058875725, 55.88805820106121],
                    [-120.73292248330847, 55.88796073385289],
                    [-120.73247437785965, 55.887863266644565],
                    [-120.73202627241085, 55.88776579943624],
                    [-120.73157816696205, 55.88766833222791],
                    [-120.73113006151324, 55.88757095485111],
                    [-120.73068195606444, 55.88747348764279],
                    [-120.73023385061563, 55.88737602043446],
                    [-120.72978574516684, 55.88727855322613],
                    [-120.72933763971804, 55.887181086017804],
                    [-120.72888953426921, 55.88708370864101],
                    [-120.72844142882043, 55.88698624143268],
                    [-120.72799332337163, 55.886888774224346],
                    [-120.72754521792284, 55.88679130701602],
                    [-120.72709711247403, 55.88669383980769],
                    [-120.72664900702522, 55.886596462430894],
                    [-120.72620090157643, 55.88649899522258],
                    [-120.72575279612761, 55.88640152801424],
                    [-120.72530469067883, 55.88630406080591],
                    [-120.72485658523001, 55.886206593597585],
                    [-120.72440847978122, 55.88610921622079],
                    [-120.72396037433242, 55.88601174901247],
                    [-120.7235122688836, 55.88591428180414],
                    [-120.72306416343481, 55.885816814595806],
                    [-120.722616057986, 55.88571943721901],
                    [-120.72216795253722, 55.88562197001069],
                    [-120.7217198470884, 55.88552450280236],
                    [-120.7212717416396, 55.885427035594034],
                    [-120.7208236361908, 55.88532956838571],
                    [-120.72037553074199, 55.885232191008896],
                    [-120.7199274252932, 55.88513472380058],
                    [-120.7194793198444, 55.885037256592256],
                    [-120.7190312143956, 55.88493978938393],
                    [-120.71858310894679, 55.8848423221756],
                    [-120.71813500349796, 55.88474494479879],
                    [-120.71768689804918, 55.88464747759047],
                    [-120.71723879260038, 55.88455001038214],
                    [-120.71679068715159, 55.884452543173815],
                    [-120.71634258170278, 55.88435507596549],
                    [-120.715894476254, 55.88425769858869],
                    [-120.7156650565176, 55.884207033606664],
                  ],
                },
                id: 'c6d30e15-0ae5-4bf3-a679-9778cfde8a03',
              },
            ],
          },
        });
        map?.addSource('detectionLine4', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                properties: { shape: 'Line', name: 'Detection Layer', category: 'default' },
                geometry: {
                  type: 'LineString',
                  coordinates: [
                    [-120.76820844453266, 55.89478827779496],
                    [-120.76820844453266, 55.89478827779496],
                    [-120.76820844453266, 55.89478827779496],
                    [-120.76820844453266, 55.89478827779496],
                    [-120.76820844453266, 55.89478827779496],
                    [-120.76820844453266, 55.89478827779496],
                    [-120.76820844453266, 55.89478827779496],
                    [-120.76820844453266, 55.89478827779496],
                    [-120.76820844453266, 55.89478827779496],
                    [-120.76820844453266, 55.89478827779496],
                    [-120.76820844453266, 55.89478827779496],
                    [-120.76820844453266, 55.89478827779496],
                    [-120.76820844453266, 55.89478827779496],
                    [-120.76820844453266, 55.89478827779496],
                    [-120.76820844453266, 55.89478827779496],
                    [-120.76820844453266, 55.89478827779496],
                    [-120.76820844453266, 55.89478827779496],
                    [-120.76820844453266, 55.89478827779496],
                    [-120.76820844453266, 55.89478827779496],
                    [-120.76820940578853, 55.89478836762649],
                    [-120.76822318378947, 55.8947893557733],
                    [-120.76825378376827, 55.894791511729984],
                    [-120.76830072509702, 55.894794925328064],
                    [-120.76836432819434, 55.89479941690448],
                    [-120.76844443285087, 55.894805166122296],
                    [-120.76854087885738, 55.89481208314999],
                    [-120.7686535060045, 55.89482016798755],
                    [-120.76878215408294, 55.89482942063498],
                    [-120.76892682309268, 55.89483975126074],
                    [-120.76903208061142, 55.89484729710912],
                    [-120.76908639156856, 55.89485124969637],
                    [-120.76924948464931, 55.89486292779507],
                    [-120.76941257773008, 55.89487460589376],
                    [-120.76957567081082, 55.894886283992456],
                    [-120.76973876389157, 55.89489796209115],
                    [-120.76990169676303, 55.894909730021375],
                    [-120.7700647898438, 55.89492140812006],
                    [-120.77022788292454, 55.89493308621876],
                    [-120.77039097600532, 55.89494476431745],
                    [-120.77055390887674, 55.894956442416145],
                    [-120.77071700195752, 55.89496812051484],
                    [-120.77088009503828, 55.894979888445064],
                    [-120.77104318811904, 55.89499156654376],
                    [-120.77120612099047, 55.895003244642446],
                    [-120.77136921407123, 55.89501492274115],
                    [-120.77149690089381, 55.89502408555704],
                    [-120.77153198673336, 55.895026600839834],
                    [-120.77168370495288, 55.895037470454774],
                    [-120.77181956245043, 55.895047262091374],
                    [-120.77193955922594, 55.89505579608657],
                    [-120.77204321465153, 55.89506325210343],
                    [-120.7721306889365, 55.895069540310416],
                    [-120.77220166166222, 55.89507457087601],
                    [-120.77225613282869, 55.89507852346326],
                    [-120.77229410243588, 55.89508121840911],
                    [-120.77231541027453, 55.895082745545096],
                    [-120.77232053697254, 55.895083104871205],
                    [-120.77232053697254, 55.895083104871205],
                    [-120.77232053697254, 55.895083104871205],
                    [-120.77232053697254, 55.895083104871205],
                    [-120.77232053697254, 55.895083104871205],
                    [-120.77232053697254, 55.895083104871205],
                    [-120.77232053697254, 55.895083104871205],
                    [-120.77232053697254, 55.895083104871205],
                    [-120.77232053697254, 55.895083104871205],
                    [-120.77232053697254, 55.895083104871205],
                    [-120.77232053697254, 55.895083104871205],
                    [-120.77232053697254, 55.895083104871205],
                    [-120.77232053697254, 55.895083104871205],
                    [-120.77232053697254, 55.895083104871205],
                    [-120.77232053697254, 55.895083104871205],
                    [-120.77232053697254, 55.895083104871205],
                    [-120.77232053697254, 55.895083104871205],
                    [-120.77232053697254, 55.895083104871205],
                    [-120.77232053697254, 55.895083104871205],
                    [-120.77230739980888, 55.8950802302623],
                    [-120.77224892340958, 55.8950675640168],
                    [-120.77214430672812, 55.8950448366401],
                    [-120.77199483143897, 55.895012317626815],
                    [-120.77180193942598, 55.89497036630305],
                    [-120.77156803382883, 55.894919431826445],
                    [-120.77129535757788, 55.89486014301769],
                    [-120.77098695465013, 55.89479312869749],
                    [-120.77064634965042, 55.894719017686555],
                    [-120.770277227393, 55.89463879813168],
                    [-120.76988359311068, 55.89455318868511],
                    [-120.76946977245487, 55.894463177493634],
                    [-120.76904041149571, 55.89436984253562],
                    [-120.76859999609393, 55.8942739922948],
                    [-120.76815349273824, 55.89417688441259],
                    [-120.76804150642839, 55.8941525400684],
                    [-120.76770538728945, 55.89407950703579],
                    [-120.76725728184064, 55.893982039827456],
                    [-120.76680917639186, 55.89388457261914],
                    [-120.76636107094303, 55.893787105410816],
                    [-120.76591296549424, 55.893689728034005],
                    [-120.76546486004545, 55.89359226082568],
                    [-120.76501675459663, 55.89349479361736],
                    [-120.76456864914785, 55.89339732640903],
                    [-120.76412054369902, 55.8932998592007],
                    [-120.76367243825024, 55.893202481823906],
                    [-120.76322433280143, 55.89310501461557],
                    [-120.76277622735262, 55.89300754740725],
                    [-120.76232812190382, 55.892910080198924],
                    [-120.76188001645502, 55.892812612990596],
                    [-120.76143191100624, 55.8927152356138],
                    [-120.76098380555742, 55.89261776840547],
                    [-120.76053570010862, 55.892520301197145],
                    [-120.76008759465982, 55.89242283398882],
                    [-120.75963948921101, 55.89232536678049],
                    [-120.75919138376221, 55.89222798940369],
                    [-120.7587432783134, 55.892130522195366],
                    [-120.7582951728646, 55.89203305498704],
                    [-120.75784706741581, 55.89193558777871],
                    [-120.75739896196698, 55.891838120570384],
                    [-120.7569508565182, 55.89174074319358],
                    [-120.7565027510694, 55.89164327598525],
                    [-120.75605464562061, 55.89154580877694],
                    [-120.7556065401718, 55.89144834156861],
                    [-120.755158434723, 55.89135087436027],
                    [-120.7547103292742, 55.891253496983474],
                    [-120.75426222382538, 55.891156029775146],
                    [-120.7538141183766, 55.891058562566826],
                    [-120.75336601292777, 55.8909610953585],
                    [-120.75291790747899, 55.8908637179817],
                    [-120.75246980203019, 55.89076625077337],
                    [-120.75202169658137, 55.89066878356505],
                    [-120.75157359113258, 55.89057131635672],
                    [-120.75112548568377, 55.89047384914839],
                    [-120.75067738023499, 55.890376471771596],
                    [-120.75022927478616, 55.89027900456327],
                    [-120.74978116933738, 55.89018153735494],
                    [-120.74933306388857, 55.890084070146614],
                    [-120.74888495843976, 55.889986602938286],
                    [-120.74843685299096, 55.88988922556149],
                    [-120.74798874754215, 55.88979175835316],
                    [-120.74754064209337, 55.889694291144835],
                    [-120.74709253664456, 55.88959682393651],
                    [-120.74664443119573, 55.88949935672818],
                    [-120.74619632574695, 55.889401979351376],
                    [-120.74574822029815, 55.88930451214305],
                    [-120.74530011484936, 55.88920704493472],
                    [-120.74485200940055, 55.88910957772641],
                    [-120.74440390395175, 55.88901211051807],
                    [-120.74395579850295, 55.88891473314127],
                    [-120.74350769305413, 55.88881726593294],
                    [-120.74305958760534, 55.88871979872462],
                    [-120.74261148215655, 55.888622331516295],
                    [-120.74216337670774, 55.88852486430797],
                    [-120.74171527125894, 55.888427486931164],
                    [-120.74126716581014, 55.88833001972284],
                    [-120.74081906036133, 55.888232552514516],
                    [-120.74037095491252, 55.88813508530619],
                    [-120.73992284946374, 55.88803761809786],
                    [-120.73947474401491, 55.88794024072106],
                    [-120.73902663856613, 55.88784277351274],
                    [-120.73857853311733, 55.88774530630441],
                    [-120.73813042766851, 55.88764783909608],
                    [-120.73768232221973, 55.887550461719286],
                    [-120.73723421677091, 55.88745299451096],
                    [-120.73678611132212, 55.88735552730263],
                    [-120.73633800587331, 55.8872580600943],
                    [-120.73588990042452, 55.88716059288597],
                    [-120.7354417949757, 55.88706321550917],
                    [-120.7349936895269, 55.886965748300845],
                    [-120.73454558407812, 55.88686828109252],
                    [-120.7340974786293, 55.8867708138842],
                    [-120.73364937318051, 55.88667334667586],
                    [-120.7332012677317, 55.88657596929907],
                    [-120.73275316228292, 55.88647850209074],
                    [-120.7323050568341, 55.88638103488241],
                    [-120.7318569513853, 55.88628356767409],
                    [-120.73140884593649, 55.886186100465764],
                    [-120.73096074048769, 55.88608872308896],
                    [-120.73051263503889, 55.88599125588063],
                    [-120.73006452959008, 55.88589378867231],
                    [-120.72961642414127, 55.885796321463985],
                    [-120.72916831869249, 55.88569885425566],
                    [-120.72872021324366, 55.88560147687885],
                    [-120.72827210779488, 55.88550400967052],
                    [-120.72782400234608, 55.885406542462206],
                    [-120.72737589689729, 55.88530907525388],
                    [-120.72692779144847, 55.88521160804555],
                    [-120.72647968599966, 55.88511423066875],
                    [-120.72603158055088, 55.88501676346042],
                    [-120.72558347510206, 55.88491929625209],
                    [-120.72513536965327, 55.884821829043766],
                    [-120.72468726420445, 55.88472445166697],
                    [-120.72423915875565, 55.88462698445864],
                    [-120.72379105330687, 55.884529517250314],
                    [-120.72334294785804, 55.88443205004199],
                    [-120.72289484240926, 55.88433458283366],
                    [-120.72244673696045, 55.88423720545686],
                    [-120.72199863151167, 55.884139738248535],
                    [-120.72155052606284, 55.88404227104021],
                    [-120.72110242061405, 55.88394480383189],
                    [-120.72065431516526, 55.88384733662356],
                    [-120.72020620971644, 55.88374995924676],
                    [-120.71975810426765, 55.88365249203843],
                    [-120.71930999881883, 55.8835550248301],
                    [-120.71886189337005, 55.88345755762178],
                    [-120.71841378792124, 55.883360090413454],
                    [-120.71796568247241, 55.88326271303664],
                    [-120.71751757702363, 55.883165245828316],
                    [-120.71706947157483, 55.88306777862],
                    [-120.71662136612605, 55.882970311411675],
                    [-120.71617326067722, 55.88287284420335],
                    [-120.71572515522841, 55.882775466826544],
                    [-120.71566315422425, 55.88276073445588],
                  ],
                },
                id: '310f04e5-f2d3-4182-a0b0-5a4bad5e69fe',
              },
            ],
          },
        });
        map?.addSource('detectionLine5', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                properties: { shape: 'Line', name: 'Detection Layer', category: 'default' },
                geometry: {
                  type: 'LineString',
                  coordinates: [
                    [-120.76824066687108, 55.894761055589946],
                    [-120.76824066687108, 55.894761055589946],
                    [-120.76824066687108, 55.894761055589946],
                    [-120.76824066687108, 55.894761055589946],
                    [-120.76824066687108, 55.894761055589946],
                    [-120.76824066687108, 55.894761055589946],
                    [-120.76824066687108, 55.894761055589946],
                    [-120.76824467210389, 55.89476033693773],
                    [-120.76825652759307, 55.89475809114951],
                    [-120.7682763935479, 55.894754318225324],
                    [-120.76830426996838, 55.89474901816514],
                    [-120.7683399966452, 55.89474228080051],
                    [-120.76837972855485, 55.894734824783654],
                    [-120.7684194604645, 55.894727368766794],
                    [-120.76845903216486, 55.89471982291841],
                    [-120.76849876407452, 55.89471236690155],
                    [-120.76853849598415, 55.89470482105317],
                    [-120.7685782278938, 55.8946973650363],
                    [-120.76860466243048, 55.89469233447072],
                    [-120.76861876085002, 55.89468972935639],
                    [-120.76867162992336, 55.89467975805674],
                    [-120.7687403597187, 55.89466673248512],
                    [-120.7688246298174, 55.89465083230459],
                    [-120.76892444021946, 55.89463196768363],
                    [-120.76903979092494, 55.89461022845375],
                    [-120.76917036151512, 55.89458552478344],
                    [-120.76919375207483, 55.894581212870065],
                    [-120.76931102529205, 55.89455902448255],
                    [-120.76945168906896, 55.89453243435015],
                    [-120.76959251305517, 55.894505934049256],
                    [-120.76973333704143, 55.89447934391685],
                    [-120.76987400081836, 55.894452753784435],
                    [-120.77001482480458, 55.89442616365203],
                    [-120.77015548858148, 55.894399663351145],
                    [-120.77029631256774, 55.89437307321874],
                    [-120.77043713655397, 55.89434648308633],
                    [-120.7705778003309, 55.89431989295392],
                    [-120.77071862431711, 55.89429339265303],
                    [-120.77085928809404, 55.894266802520626],
                    [-120.77100011208027, 55.89424021238822],
                    [-120.77114093606652, 55.894213622255805],
                    [-120.77128159984342, 55.89418712195493],
                    [-120.77142242382968, 55.894160531822514],
                    [-120.77156308760658, 55.89413394169011],
                    [-120.77170391159284, 55.89410735155769],
                    [-120.77184473557905, 55.89408085125682],
                    [-120.77198539935598, 55.8940542611244],
                    [-120.77212622334221, 55.894027670991996],
                    [-120.77226688711914, 55.894001080859596],
                    [-120.77240771110536, 55.893974490727174],
                    [-120.77254853509162, 55.89394799042629],
                    [-120.772550778022, 55.89394754126865],
                    [-120.77268182924013, 55.893922747766815],
                    [-120.77280006371319, 55.893900469547766],
                    [-120.77290275788292, 55.89388106593763],
                    [-120.77298991174926, 55.89386462676792],
                    [-120.77306152531223, 55.893851152038664],
                    [-120.77311727815318, 55.89384064174984],
                    [-120.77315717027216, 55.89383309590145],
                    [-120.77318136187841, 55.89382851449351],
                    [-120.77318953255342, 55.893826987357514],
                    [-120.77318953255342, 55.893826987357514],
                    [-120.77318953255342, 55.893826987357514],
                    [-120.77318953255342, 55.893826987357514],
                    [-120.77318953255342, 55.893826987357514],
                    [-120.77318953255342, 55.893826987357514],
                    [-120.77318953255342, 55.893826987357514],
                    [-120.77318953255342, 55.893826987357514],
                    [-120.77318953255342, 55.893826987357514],
                    [-120.77318953255342, 55.893826987357514],
                    [-120.77318953255342, 55.893826987357514],
                    [-120.77318953255342, 55.893826987357514],
                    [-120.77318953255342, 55.893826987357514],
                    [-120.77318953255342, 55.893826987357514],
                    [-120.77318953255342, 55.893826987357514],
                    [-120.77318953255342, 55.893826987357514],
                    [-120.77318953255342, 55.893826987357514],
                    [-120.77317399225004, 55.89382366359097],
                    [-120.77311279229242, 55.89381027869324],
                    [-120.77300545205263, 55.89378692249585],
                    [-120.77285325320516, 55.8937538644934],
                    [-120.77265779784315, 55.893711374180455],
                    [-120.772421328897, 55.893659900714674],
                    [-120.77214640971567, 55.893600162748285],
                    [-120.77183592406683, 55.89353260943892],
                    [-120.7714935567647, 55.89345813910187],
                    [-120.77112283241415, 55.89337756022087],
                    [-120.77072775624796, 55.89329168127972],
                    [-120.77031297433629, 55.89320140059366],
                    [-120.76988265212125, 55.89310788597259],
                    [-120.76944175609152, 55.89301194590024],
                    [-120.76899493231726, 55.89291483801803],
                    [-120.76891034179992, 55.892896422554706],
                    [-120.76854682686844, 55.8928173708097],
                    [-120.76809872141965, 55.89271990360137],
                    [-120.76765061597085, 55.89262243639304],
                    [-120.76720251052203, 55.89252505901625],
                    [-120.76675440507324, 55.89242759180792],
                    [-120.76630629962442, 55.892330124599596],
                    [-120.76585819417564, 55.89223265739127],
                    [-120.76541008872682, 55.892135280014465],
                    [-120.76496198327804, 55.892037812806144],
                    [-120.76451387782923, 55.89194034559782],
                    [-120.76406577238042, 55.89184287838949],
                    [-120.76361766693164, 55.89174541118116],
                    [-120.76316956148281, 55.891648033804366],
                    [-120.76272145603403, 55.89155056659604],
                    [-120.76227335058522, 55.89145309938771],
                    [-120.76182524513641, 55.89135563217938],
                    [-120.76137713968761, 55.891258164971056],
                    [-120.7609290342388, 55.89116078759425],
                    [-120.76048092879, 55.891063320385925],
                    [-120.7600328233412, 55.8909658531776],
                    [-120.75958471789242, 55.89086838596927],
                    [-120.7591366124436, 55.89077100859247],
                    [-120.7586885069948, 55.890673541384146],
                    [-120.758240401546, 55.890576074175826],
                    [-120.7577922960972, 55.8904786069675],
                    [-120.7573441906484, 55.890381139759164],
                    [-120.7568960851996, 55.89028376238237],
                    [-120.75644797975079, 55.89018629517404],
                    [-120.75599987430199, 55.89008882796572],
                    [-120.75555176885317, 55.88999136075739],
                    [-120.75510366340438, 55.88989398338059],
                    [-120.75465555795559, 55.88979651617226],
                    [-120.75420745250679, 55.88969904896394],
                    [-120.75375934705798, 55.88960158175561],
                    [-120.75331124160917, 55.889504114547286],
                    [-120.75286313616039, 55.88940673717049],
                    [-120.75241503071156, 55.88930926996215],
                    [-120.75196692526278, 55.889211802753834],
                    [-120.75151881981397, 55.88911433554551],
                    [-120.75107071436518, 55.88901686833718],
                    [-120.75062260891637, 55.888919490960376],
                    [-120.75017450346755, 55.88882202375205],
                    [-120.74972639801877, 55.88872455654372],
                    [-120.74927829256995, 55.888627089335394],
                    [-120.74883018712117, 55.8885297119586],
                    [-120.74838208167235, 55.88843224475027],
                    [-120.74793397622355, 55.88833477754194],
                    [-120.74748587077475, 55.888237310333615],
                    [-120.74703776532594, 55.88813984312529],
                    [-120.74658965987716, 55.88804246574849],
                    [-120.74614155442835, 55.88794499854016],
                    [-120.74569344897957, 55.887847531331836],
                    [-120.74524534353074, 55.887750064123516],
                    [-120.74479723808194, 55.88765259691519],
                    [-120.74434913263313, 55.887555219538385],
                    [-120.74390102718434, 55.88745775233006],
                    [-120.74345292173554, 55.88736028512173],
                    [-120.74300481628673, 55.88726281791341],
                    [-120.74255671083793, 55.88716544053661],
                    [-120.74210860538913, 55.887067973328286],
                    [-120.74166049994031, 55.886970506119944],
                    [-120.74121239449153, 55.88687303891163],
                    [-120.74076428904273, 55.8867755717033],
                    [-120.74031618359393, 55.8866781943265],
                    [-120.73986807814512, 55.88658072711817],
                    [-120.73941997269631, 55.886483259909845],
                    [-120.73897186724751, 55.88638579270152],
                    [-120.7385237617987, 55.88628841532472],
                    [-120.73807565634992, 55.88619094811639],
                    [-120.7376275509011, 55.886093480908066],
                    [-120.73717944545231, 55.88599601369974],
                    [-120.73673134000352, 55.88589854649141],
                    [-120.73628323455469, 55.885801169114615],
                    [-120.73583512910591, 55.88570370190629],
                    [-120.7353870236571, 55.88560623469796],
                    [-120.73493891820831, 55.88550876748963],
                    [-120.73449081275949, 55.885411300281305],
                    [-120.73404270731069, 55.88531392290451],
                    [-120.73359460186188, 55.88521645569618],
                    [-120.73314649641308, 55.885118988487854],
                    [-120.7326983909643, 55.88502152127952],
                    [-120.73225028551548, 55.88492414390272],
                    [-120.7318021800667, 55.88482667669441],
                    [-120.73135407461788, 55.88472920948608],
                    [-120.73090596916906, 55.88463174227774],
                    [-120.73045786372028, 55.88453427506941],
                    [-120.73000975827148, 55.88443689769262],
                    [-120.72956165282268, 55.884339430484296],
                    [-120.72911354737387, 55.88424196327597],
                    [-120.72866544192509, 55.88414449606764],
                    [-120.72821733647628, 55.88404711869084],
                    [-120.72776923102745, 55.88394965148252],
                    [-120.72732112557867, 55.88385218427419],
                    [-120.72687302012984, 55.88375471706586],
                    [-120.72642491468106, 55.883657249857535],
                    [-120.72597680923226, 55.88355987248074],
                    [-120.72552870378344, 55.88346240527241],
                    [-120.72508059833466, 55.883364938064084],
                    [-120.72463249288585, 55.883267470855756],
                    [-120.72418438743706, 55.88317000364743],
                    [-120.72373628198824, 55.88307262627063],
                    [-120.72328817653946, 55.882975159062305],
                    [-120.72284007109066, 55.88287769185397],
                    [-120.72239196564183, 55.88278022464564],
                    [-120.72194386019305, 55.882682847268846],
                    [-120.72149575474423, 55.88258538006052],
                    [-120.72104764929544, 55.882487912852206],
                    [-120.72059954384663, 55.882390445643864],
                    [-120.72015143839785, 55.88229297843554],
                    [-120.71970333294902, 55.88219560105874],
                    [-120.71925522750023, 55.88209813385041],
                    [-120.71880712205144, 55.88200066664209],
                    [-120.71835901660262, 55.881903199433765],
                    [-120.71791091115384, 55.88180582205696],
                    [-120.71746280570503, 55.881708354848634],
                    [-120.7170147002562, 55.88161088764031],
                    [-120.71656659480742, 55.881513420431986],
                    [-120.71611848935862, 55.88141595322366],
                    [-120.71567038390981, 55.88131857584686],
                    [-120.71566205302554, 55.8813177673631],
                  ],
                },
                id: '331a1f38-44aa-4776-8966-711fc3dc5102',
              },
            ],
          },
        });
        map?.addSource('detectionLine6', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                properties: { shape: 'Line', name: 'Detection Layer', category: 'default' },
                geometry: {
                  type: 'LineString',
                  coordinates: [
                    [-120.76827288925755, 55.89473383340291],
                    [-120.76827288925755, 55.89473383340291],
                    [-120.76827288925755, 55.89473383340291],
                    [-120.76827320967618, 55.89473365373985],
                    [-120.76827913742076, 55.89473149778317],
                    [-120.7682919541658, 55.89472673671216],
                    [-120.7683116599113, 55.894719370526836],
                    [-120.7683384148666, 55.89470930939565],
                    [-120.76836004312389, 55.89470122455809],
                    [-120.76837173840376, 55.894696912644726],
                    [-120.76840650382469, 55.894683887073114],
                    [-120.76844142945495, 55.89467086150149],
                    [-120.76847619487589, 55.89465783592987],
                    [-120.76851112050616, 55.894644810358244],
                    [-120.76854604613641, 55.89463178478663],
                    [-120.76858081155736, 55.894618759215014],
                    [-120.76861573718763, 55.89460573364339],
                    [-120.76865050260857, 55.89459270807178],
                    [-120.76868542823883, 55.89457968250015],
                    [-120.76870865858923, 55.8945709688419],
                    [-120.76872099470633, 55.89456629760242],
                    [-120.76876745540714, 55.89454896011744],
                    [-120.7688276941089, 55.8945264124038],
                    [-120.76890171081156, 55.89449874429305],
                    [-120.76898934530581, 55.89446595578518],
                    [-120.76909059759174, 55.894428136711724],
                    [-120.76920530745993, 55.894385287072666],
                    [-120.7693333147011, 55.894337406868026],
                    [-120.76935590421427, 55.89432896270436],
                    [-120.7694698130359, 55.89428638255989],
                    [-120.7696066317893, 55.89423517858869],
                    [-120.76974345054269, 55.89418406444903],
                    [-120.7698801090868, 55.894132950309356],
                    [-120.77001692784019, 55.89408183616969],
                    [-120.77015374659359, 55.8940306321985],
                    [-120.770290565347, 55.893979518058835],
                    [-120.7704272238911, 55.89392840391917],
                    [-120.77056404264451, 55.893877289779496],
                    [-120.77070086139791, 55.89382617563983],
                    [-120.770837519942, 55.89377497166864],
                    [-120.77097433869542, 55.89372385752897],
                    [-120.77111115744883, 55.8936727433893],
                    [-120.7712478159929, 55.89362162924963],
                    [-120.77138463474633, 55.89357042527845],
                    [-120.77152145349972, 55.89351931113878],
                    [-120.7716581120438, 55.89346819699911],
                    [-120.77179493079723, 55.89341708285944],
                    [-120.77193174955063, 55.893365968719785],
                    [-120.77206840809471, 55.89331476474858],
                    [-120.77220522684811, 55.893263650608915],
                    [-120.77234204560153, 55.89321253646924],
                    [-120.77247886435495, 55.893161422329584],
                    [-120.77261552289903, 55.893110218358395],
                    [-120.77275234165245, 55.89305910421872],
                    [-120.77288916040585, 55.893007990079056],
                    [-120.77302581894992, 55.89295687593939],
                    [-120.77316263770332, 55.892905761799724],
                    [-120.77329945645675, 55.89285455782852],
                    [-120.77336754541483, 55.892829135505984],
                    [-120.77343451290771, 55.89280407250956],
                    [-120.77355803428811, 55.892757899103955],
                    [-120.77366825829557, 55.89271666643241],
                    [-120.77376502472067, 55.892680554158],
                    [-120.77384801314487, 55.892649472449165],
                    [-120.77391754398677, 55.89262351113746],
                    [-120.77397313661842, 55.89260267022286],
                    [-120.77401495124914, 55.89258703953692],
                    [-120.77404282766963, 55.89257661907963],
                    [-120.77405676587988, 55.892571408850976],
                    [-120.77405836797301, 55.892570780030276],
                    [-120.77405836797301, 55.892570780030276],
                    [-120.77405836797301, 55.892570780030276],
                    [-120.77405836797301, 55.892570780030276],
                    [-120.77405836797301, 55.892570780030276],
                    [-120.77405836797301, 55.892570780030276],
                    [-120.77405836797301, 55.892570780030276],
                    [-120.77405836797301, 55.892570780030276],
                    [-120.7740473135304, 55.89256835457901],
                    [-120.77399156068941, 55.8925563171542],
                    [-120.77388982777559, 55.892534128766684],
                    [-120.7737430760448, 55.892502238574096],
                    [-120.7735527473808, 55.8924608262395],
                    [-120.77332108471403, 55.892410430752065],
                    [-120.77305065139348, 55.89235168093248],
                    [-120.77274449139608, 55.89228502593839],
                    [-120.77240580890815, 55.89221136408509],
                    [-120.77203828874384, 55.89213150385634],
                    [-120.77164593613601, 55.89204616390434],
                    [-120.7712332369454, 55.891956422207464],
                    [-120.77080467703281, 55.89186317708097],
                    [-120.77036474225898, 55.891767596334745],
                    [-120.76991839911261, 55.891670488452526],
                    [-120.76977933742882, 55.891640215227454],
                    [-120.76947029366383, 55.891573021244206],
                    [-120.76902218821502, 55.8914756438674],
                    [-120.76857408276621, 55.891378176659074],
                    [-120.76812597731741, 55.89128070945075],
                    [-120.76767787186861, 55.89118324224243],
                    [-120.76722976641983, 55.8910857750341],
                    [-120.766781660971, 55.8909883976573],
                    [-120.76633355552218, 55.890890930448975],
                    [-120.7658854500734, 55.89079346324064],
                    [-120.7654373446246, 55.89069599603232],
                    [-120.7649892391758, 55.89059852882399],
                    [-120.764541133727, 55.8905011514472],
                    [-120.7640930282782, 55.89040368423887],
                    [-120.7636449228294, 55.89030621703054],
                    [-120.76319681738057, 55.890208749822214],
                    [-120.76274871193179, 55.89011128261389],
                    [-120.76230060648298, 55.89001390523708],
                    [-120.7618525010342, 55.889916438028756],
                    [-120.76140439558539, 55.88981897082043],
                    [-120.76095629013659, 55.8897215036121],
                    [-120.7605083448971, 55.889624036403774],
                    [-120.7600602394483, 55.889526569195446],
                    [-120.75961213399947, 55.88942919181865],
                    [-120.75916402855069, 55.88933172461032],
                    [-120.75871592310189, 55.889234257402],
                    [-120.75826781765309, 55.889136790193675],
                    [-120.75781971220428, 55.88903932298534],
                    [-120.75737160675548, 55.88894185577701],
                    [-120.75692350130669, 55.888844478400216],
                    [-120.75647539585786, 55.888747011191896],
                    [-120.75602729040908, 55.88864954398357],
                    [-120.75557918496025, 55.88855207677524],
                    [-120.75513107951147, 55.88845460956691],
                    [-120.75468297406267, 55.888357142358586],
                    [-120.75423486861385, 55.88825967515026],
                    [-120.75378676316507, 55.88816229777346],
                    [-120.75333865771626, 55.888064830565135],
                    [-120.75289055226747, 55.88796736335681],
                    [-120.75244244681865, 55.88786989614848],
                    [-120.75199434136987, 55.88777242894015],
                    [-120.75154623592107, 55.887674961731825],
                    [-120.75109813047224, 55.88757749452349],
                    [-120.75065002502346, 55.88748002731516],
                    [-120.75020191957465, 55.88738264993838],
                    [-120.74975381412585, 55.88728518273004],
                    [-120.74930570867704, 55.88718771552171],
                    [-120.74885760322822, 55.887090248313385],
                    [-120.74840949777943, 55.88699278110506],
                    [-120.74796139233064, 55.88689531389674],
                    [-120.74751328688184, 55.88679784668841],
                    [-120.74706518143303, 55.88670037948008],
                    [-120.74661707598425, 55.88660291227175],
                    [-120.74616897053544, 55.88650544506343],
                    [-120.74572086508661, 55.88640806768663],
                    [-120.74527275963783, 55.8863106004783],
                    [-120.74482481439833, 55.886213133269976],
                    [-120.74437670894953, 55.88611566606165],
                    [-120.74392860350075, 55.88601819885332],
                    [-120.74348049805192, 55.885920731644994],
                    [-120.74303239260314, 55.88582326443667],
                    [-120.74258428715433, 55.88572579722834],
                    [-120.7421361817055, 55.88562833002001],
                    [-120.74168807625672, 55.88553086281169],
                    [-120.74123997080792, 55.885433395603364],
                    [-120.74079186535914, 55.88533592839503],
                    [-120.74034375991032, 55.8852384611867],
                    [-120.7398956544615, 55.885141083809906],
                    [-120.73944754901272, 55.885043616601585],
                    [-120.7389994435639, 55.88494614939326],
                    [-120.73855133811512, 55.88484868218493],
                    [-120.73810323266629, 55.8847512149766],
                    [-120.73765512721751, 55.884653747768276],
                    [-120.73720702176871, 55.88455628055995],
                    [-120.73675891631989, 55.88445881335162],
                    [-120.7363109710804, 55.884361346143294],
                    [-120.73586286563162, 55.884263878934966],
                    [-120.73541476018279, 55.88416641172664],
                    [-120.73496665473401, 55.88406894451832],
                    [-120.73451854928521, 55.88397147730998],
                    [-120.7340704438364, 55.88387401010165],
                    [-120.7336223383876, 55.88377654289332],
                    [-120.7331742329388, 55.88367907568501],
                    [-120.73272612749, 55.88358160847668],
                    [-120.73227802204119, 55.883484141268355],
                    [-120.7318299165924, 55.88338667406003],
                    [-120.73138181114358, 55.8832892068517],
                    [-120.7309337056948, 55.883191739643365],
                    [-120.730485600246, 55.88309427243504],
                    [-120.73003749479717, 55.88299680522671],
                    [-120.72958954955769, 55.88289933801838],
                    [-120.7291414441089, 55.88280187081007],
                    [-120.7286933386601, 55.88270440360173],
                    [-120.7282452332113, 55.8826069363934],
                    [-120.72779712776249, 55.882509469185074],
                    [-120.72734902231367, 55.882412001976746],
                    [-120.72690091686489, 55.882314534768426],
                    [-120.72645281141607, 55.8822170675601],
                    [-120.72600470596728, 55.88211960035177],
                    [-120.72555660051847, 55.88202213314344],
                    [-120.72510849506969, 55.88192466593512],
                    [-120.72466038962088, 55.88182719872679],
                    [-120.7242124443814, 55.88172973151846],
                    [-120.72376433893257, 55.881632264310134],
                    [-120.72331623348379, 55.88153479710181],
                    [-120.72286812803499, 55.88143732989348],
                    [-120.72242002258618, 55.88133986268515],
                    [-120.72197191713738, 55.881242395476825],
                    [-120.72152381168858, 55.8811449282685],
                    [-120.72107570623977, 55.88104746106017],
                    [-120.72062760079096, 55.88094999385185],
                    [-120.72017949534218, 55.88085252664352],
                    [-120.71973138989335, 55.880755059435195],
                    [-120.71928344465388, 55.88065759222686],
                    [-120.71883533920509, 55.88056012501854],
                    [-120.71838723375627, 55.88046265781021],
                    [-120.71793912830748, 55.880365190601886],
                    [-120.71749102285868, 55.88026763356203],
                    [-120.71704291740986, 55.8801701663537],
                    [-120.71659481196107, 55.88007269914538],
                    [-120.71614670651225, 55.87997523193705],
                    [-120.71569860106347, 55.87987776472872],
                    [-120.7156601508283, 55.87986941039659],
                  ],
                },
                id: '280bff51-9c2f-4d42-bef1-b42eba1252e3',
              },
            ],
          },
        });
        map?.addSource('detectionLine7', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                properties: { shape: 'Line', name: 'Detection Layer', category: 'default' },
                geometry: {
                  type: 'LineString',
                  coordinates: [
                    [-120.76830483390516, 55.89470633343881],
                    [-120.76830483390516, 55.89470633343881],
                    [-120.76830483390516, 55.89470633343881],
                    [-120.76830483390516, 55.89470606394422],
                    [-120.76830499411449, 55.89470211135698],
                    [-120.76830515432377, 55.89469357736177],
                    [-120.76830563495173, 55.89468019246405],
                    [-120.76830627578899, 55.89466222615836],
                    [-120.76830659620761, 55.894647583619225],
                    [-120.76830691662623, 55.894639768276264],
                    [-120.76830755746347, 55.894616232415814],
                    [-120.76830835851005, 55.894592786386895],
                    [-120.76830915955662, 55.894569340357975],
                    [-120.76830980039387, 55.89454580449754],
                    [-120.76831060144042, 55.89452235846862],
                    [-120.76831124227769, 55.89449882260818],
                    [-120.76831204332426, 55.894475376579265],
                    [-120.76831268416149, 55.89445184071882],
                    [-120.76831348520805, 55.8944283946899],
                    [-120.768313965836, 55.89441276400395],
                    [-120.76831508730119, 55.89440476899793],
                    [-120.76832886530212, 55.894378448360115],
                    [-120.76835834381573, 55.89434880395573],
                    [-120.76840336263274, 55.894315925616326],
                    [-120.7684639217531, 55.89427981334191],
                    [-120.76853986096751, 55.89424046713247],
                    [-120.7686313404853, 55.89419797681952],
                    [-120.76873787967854, 55.894152342403096],
                    [-120.7688597989658, 55.894103743546225],
                    [-120.76892147955137, 55.894080117854244],
                    [-120.76899437478885, 55.8940524497435],
                    [-120.76913071291429, 55.894000976277724],
                    [-120.76926705103976, 55.893949412980405],
                    [-120.76940322895591, 55.89389784968311],
                    [-120.7695395670814, 55.893846286385795],
                    [-120.76967590520687, 55.893794812920014],
                    [-120.76981208312301, 55.89374324962271],
                    [-120.76994842124849, 55.8936916863254],
                    [-120.77008475937393, 55.893640123028085],
                    [-120.7702209372901, 55.89358855973078],
                    [-120.77035727541555, 55.893537086265006],
                    [-120.77049361354103, 55.89348552296769],
                    [-120.77062979145718, 55.89343395967039],
                    [-120.77076612958265, 55.89338239637308],
                    [-120.7709023074988, 55.89333092290729],
                    [-120.77103864562429, 55.893279359609984],
                    [-120.77117498374975, 55.89322779631268],
                    [-120.7713111616659, 55.893176233015375],
                    [-120.77144749979135, 55.893124759549586],
                    [-120.77158383791682, 55.89307319625229],
                    [-120.77172001583297, 55.89302163295498],
                    [-120.77185635395844, 55.892970069657665],
                    [-120.77199269208393, 55.89291859619189],
                    [-120.77212887000006, 55.89286703289457],
                    [-120.77226520812555, 55.89281546959727],
                    [-120.772401546251, 55.892763906299955],
                    [-120.77253772416717, 55.89271234300266],
                    [-120.77267406229261, 55.89266086953687],
                    [-120.77281024020878, 55.89260930623956],
                    [-120.77294657833424, 55.89255774294226],
                    [-120.7730829164597, 55.89250617964494],
                    [-120.77321909437586, 55.892454706179166],
                    [-120.77335543250132, 55.89240314288186],
                    [-120.7734917706268, 55.89235157958455],
                    [-120.77362794854294, 55.89230001628724],
                    [-120.77376428666842, 55.892248542821456],
                    [-120.7739006247939, 55.89219697952415],
                    [-120.77403680271003, 55.89214541622684],
                    [-120.7741731408355, 55.89209385292954],
                    [-120.7742657418185, 55.89205890846498],
                    [-120.77430851770511, 55.89204166081153],
                    [-120.77442931552717, 55.89197931773081],
                    [-120.77452800446406, 55.89190574570904],
                    [-120.77460122012015, 55.891823280365955],
                    [-120.77464655935579, 55.89173479631047],
                    [-120.7746625802871, 55.891643078319966],
                    [-120.77464848186753, 55.89155136032945],
                    [-120.77460490493438, 55.891462516947854],
                    [-120.77453345158071, 55.8913795126156],
                    [-120.77443620452766, 55.89130522194161],
                    [-120.77431652817074, 55.89124198054561],
                    [-120.77417826753353, 55.89119203421581],
                    [-120.77414302148463, 55.891182242579205],
                    [-120.77401517445276, 55.89114954390287],
                    [-120.77380449920602, 55.89109762127944],
                    [-120.77354800409569, 55.89103617651401],
                    [-120.77324953414534, 55.890966107921855],
                    [-120.77291341500643, 55.890888403649775],
                    [-120.77254429274899, 55.89080432133918],
                    [-120.77214761448968, 55.890714849136884],
                    [-120.77172882734521, 55.8906213345158],
                    [-120.7712941794787, 55.89052521478041],
                    [-120.7708495986348, 55.890427747572076],
                    [-120.77064805531889, 55.890383819954685],
                    [-120.7704016533953, 55.89033019053222],
                    [-120.76995354794651, 55.89023281315542],
                    [-120.76950544249769, 55.8901353459471],
                    [-120.76905733704888, 55.890037878738774],
                    [-120.7686092316001, 55.889940411530446],
                    [-120.76816112615127, 55.88984294432212],
                    [-120.7677131809118, 55.88974547711379],
                    [-120.76726507546302, 55.889648099736995],
                    [-120.76681697001419, 55.88955063252867],
                    [-120.76636886456541, 55.88945316532034],
                    [-120.7659207591166, 55.88935569811201],
                    [-120.76547265366777, 55.889258230903685],
                    [-120.76502454821899, 55.88916076369536],
                    [-120.76457644277019, 55.889063386318554],
                    [-120.76412833732138, 55.88896591911023],
                    [-120.76368023187258, 55.8888684519019],
                    [-120.7632321264238, 55.88877098469357],
                    [-120.76278402097498, 55.88867351748526],
                    [-120.76233591552617, 55.88857605027692],
                    [-120.76188781007738, 55.88847858306859],
                    [-120.76143970462856, 55.88838120569179],
                    [-120.76099159917977, 55.888283738483466],
                    [-120.76054349373098, 55.888186271275146],
                    [-120.76009538828215, 55.88808880406682],
                    [-120.75964728283337, 55.887991336858484],
                    [-120.75919917738456, 55.887893869650156],
                    [-120.75875107193576, 55.88779649227337],
                    [-120.75830296648695, 55.88769902506504],
                    [-120.75785486103817, 55.88760155785671],
                    [-120.75740675558934, 55.887504090648385],
                    [-120.75695865014055, 55.88740662344006],
                    [-120.75651054469176, 55.88730915623173],
                    [-120.75606243924294, 55.88721177885493],
                    [-120.75561433379416, 55.887114311646606],
                    [-120.75516622834535, 55.88701684443828],
                    [-120.75471812289653, 55.88691937722995],
                    [-120.75427001744774, 55.886821910021624],
                    [-120.75382191199894, 55.886724442813296],
                    [-120.75337380655013, 55.88662706543649],
                    [-120.75292570110133, 55.886529598228165],
                    [-120.75247759565255, 55.88643213101984],
                    [-120.75202949020373, 55.886334663811525],
                    [-120.75158138475491, 55.88623719660318],
                    [-120.75113327930613, 55.886139729394856],
                    [-120.75068517385732, 55.88604235201806],
                    [-120.75023706840852, 55.88594488480973],
                    [-120.74978896295973, 55.88584741760141],
                    [-120.74934085751094, 55.885749950393084],
                    [-120.74889275206212, 55.88565248318475],
                    [-120.74844464661331, 55.88555501597642],
                    [-120.74799654116453, 55.8854575487681],
                    [-120.7475484357157, 55.885360171391305],
                    [-120.74710033026692, 55.88526270418298],
                    [-120.74665222481809, 55.88516523697465],
                    [-120.7462041193693, 55.88506776976632],
                    [-120.74575601392051, 55.884970302557996],
                    [-120.74530790847169, 55.88487283534967],
                    [-120.7448598030229, 55.88477545797287],
                    [-120.7444116975741, 55.884677990764544],
                    [-120.74396359212531, 55.88458052355622],
                    [-120.74351548667649, 55.88448305634789],
                    [-120.74306738122769, 55.88438558913956],
                    [-120.7426192757789, 55.88428821176276],
                    [-120.74217117033008, 55.88419074455443],
                    [-120.7417230648813, 55.8840932773461],
                    [-120.74127495943247, 55.88399581013779],
                    [-120.74082685398369, 55.88389834292946],
                    [-120.74037874853488, 55.88380087572112],
                    [-120.73993064308607, 55.883703498344325],
                    [-120.73948253763727, 55.883606031136],
                    [-120.73903443218848, 55.88350856392768],
                    [-120.73858632673969, 55.88341109671935],
                    [-120.73813822129087, 55.88331362951102],
                    [-120.73769011584206, 55.88321616230269],
                    [-120.73724201039327, 55.8831187849259],
                    [-120.73679390494446, 55.88302131771757],
                    [-120.73634579949567, 55.88292385050924],
                    [-120.73589769404687, 55.882826383300916],
                    [-120.73544958859806, 55.88272891609259],
                    [-120.73500164335859, 55.88263144888426],
                    [-120.73455353790976, 55.882534071507465],
                    [-120.73410543246096, 55.88243660429914],
                    [-120.73365732701217, 55.88233913709081],
                    [-120.73320922156336, 55.88224166988248],
                    [-120.73276111611456, 55.882144202674155],
                    [-120.73231301066576, 55.88204673546583],
                    [-120.73186490521698, 55.88194935808903],
                    [-120.73141679976816, 55.8818518908807],
                    [-120.73096869431936, 55.88175442367237],
                    [-120.73052058887055, 55.881656956464056],
                    [-120.73007248342175, 55.88155948925573],
                    [-120.72962437797295, 55.88146202204739],
                    [-120.72917627252414, 55.88136464467059],
                    [-120.72872816707533, 55.88126717746226],
                    [-120.72828006162655, 55.88116971025394],
                    [-120.72783195617772, 55.881072243045615],
                    [-120.72738385072894, 55.88097477583729],
                    [-120.72693574528014, 55.880877398460484],
                    [-120.72648763983135, 55.880779931252164],
                    [-120.72603953438254, 55.880682464043836],
                    [-120.72559142893373, 55.88058499683551],
                    [-120.72514332348494, 55.88048752962718],
                    [-120.72469521803612, 55.880390062418854],
                    [-120.72424711258734, 55.88029268504206],
                    [-120.72379900713851, 55.88019521783373],
                    [-120.72335090168971, 55.8800977506254],
                    [-120.72290279624093, 55.880000283417075],
                    [-120.7224546907921, 55.87990281620875],
                    [-120.72200658534332, 55.87980543883195],
                    [-120.72155847989451, 55.879707971623624],
                    [-120.72111037444573, 55.8796105044153],
                    [-120.7206622689969, 55.87951303720697],
                    [-120.7202141635481, 55.879415569998635],
                    [-120.71976605809931, 55.87931810279032],
                    [-120.7193179526505, 55.879220725413525],
                    [-120.7188698472017, 55.87912325820518],
                    [-120.71842174175289, 55.879025790996856],
                    [-120.71797363630411, 55.87892832378853],
                    [-120.7175255308553, 55.87883085658021],
                    [-120.71707742540647, 55.87873338937188],
                    [-120.71662931995769, 55.878636011995084],
                    [-120.7161812145089, 55.87853854478675],
                    [-120.7157331090601, 55.87844107757843],
                    [-120.71567671538186, 55.878428770659035],
                  ],
                },
                id: '50725a7f-b17d-4848-88ca-5807e8fff632',
              },
            ],
          },
        });
        map?.addLayer({
          id: 'detectionLine',
          type: 'line',
          source: 'detectionLine',
          // 虚线
          layout: { 'line-join': 'round', 'line-cap': 'round' },
          paint: { 'line-color': '#080808', 'line-width': 2, 'line-dasharray': [5, 5] },
        });
        map?.addLayer({
          id: 'detectionLine2',
          type: 'line',
          source: 'detectionLine2',
          layout: { 'line-join': 'round', 'line-cap': 'round' },
          paint: { 'line-color': '#080808', 'line-width': 2 },
        });
        map?.addLayer({
          id: 'detectionLine3',
          type: 'line',
          source: 'detectionLine3',
          layout: { 'line-join': 'round', 'line-cap': 'round' },
          paint: { 'line-color': 'rgb(0,0,255)', 'line-width': 2 },
        });
        map?.addLayer({
          id: 'detectionLine4',
          type: 'line',
          source: 'detectionLine4',
          layout: { 'line-join': 'round', 'line-cap': 'round' },
          paint: { 'line-color': 'rgb(255,0,0)', 'line-width': 2 },
        });
        map?.addLayer({
          id: 'detectionLine5',
          type: 'line',
          source: 'detectionLine5',
          layout: { 'line-join': 'round', 'line-cap': 'round' },
          paint: { 'line-color': 'rgb(255,0,255)', 'line-width': 2 },
        });
        map?.addLayer({
          id: 'detectionLine6',
          type: 'line',
          source: 'detectionLine6',
          layout: { 'line-join': 'round', 'line-cap': 'round' },
          paint: { 'line-color': 'rgb(0,255,0)', 'line-width': 2 },
        });
        map?.addLayer({
          id: 'detectionLine7',
          type: 'line',
          source: 'detectionLine7',
          layout: { 'line-join': 'round', 'line-cap': 'round' },
          paint: { 'line-color': 'rgb(0,255,255)', 'line-width': 2 },
        });
      });
    }
  });
  return <div></div>;
};

export default Index;
