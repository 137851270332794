import React from 'react';
import { history } from 'umi';
import List from '@mui/material/List';
import {
  Avatar,
  Card,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  SvgIcon,
} from '@mui/material';
import { RightOutlined } from '@ant-design/icons';

const userIcon = (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.24998 10.9999C5.68331 10.9999 3.66665 8.98325 3.66665 6.41658C3.66665 3.84992 5.68331 1.83325 8.24998 1.83325C10.8166 1.83325 12.8333 3.84992 12.8333 6.41658C12.8333 8.98325 10.8166 10.9999 8.24998 10.9999ZM8.24998 3.66659C6.69165 3.66659 5.49998 4.85825 5.49998 6.41658C5.49998 7.97492 6.69165 9.16658 8.24998 9.16658C9.80831 9.16658 11 7.97492 11 6.41658C11 4.85825 9.80831 3.66659 8.24998 3.66659ZM13.75 20.1666C13.2 20.1666 12.8333 19.7999 12.8333 19.2499V17.4166C12.8333 15.8583 11.6416 14.6666 10.0833 14.6666H6.41665C4.85831 14.6666 3.66665 15.8583 3.66665 17.4166V19.2499C3.66665 19.7999 3.29998 20.1666 2.74998 20.1666C2.19998 20.1666 1.83331 19.7999 1.83331 19.2499V17.4166C1.83331 14.8499 3.84998 12.8333 6.41665 12.8333H10.0833C12.65 12.8333 14.6666 14.8499 14.6666 17.4166V19.2499C14.6666 19.7999 14.3 20.1666 13.75 20.1666V20.1666ZM14.6666 10.9083C14.3 10.9083 13.8416 10.6333 13.75 10.1749C13.6583 9.71659 13.9333 9.16658 14.3916 9.07492C15.4 8.79992 16.1333 8.06658 16.4083 7.05825C16.775 5.59158 15.8583 4.12492 14.3916 3.66659C13.9333 3.66659 13.6583 3.11659 13.75 2.65825C13.8416 2.19992 14.3916 1.83325 14.85 2.01659C17.325 2.65825 18.7916 5.13325 18.15 7.60825C17.6916 9.25825 16.5 10.5416 14.85 10.9083H14.6666ZM19.25 20.1666C18.7 20.1666 18.3333 19.7999 18.3333 19.2499V17.4166C18.3333 16.1333 17.5083 15.1249 16.225 14.7583C15.7666 14.6666 15.4 14.1166 15.5833 13.6583C15.7666 13.1999 16.225 12.8333 16.6833 13.0166C18.7 13.5666 20.075 15.3083 20.1666 17.4166V19.2499C20.1666 19.7999 19.8 20.1666 19.25 20.1666V20.1666Z"
      fill="white"
    />
  </svg>
);
const stationIcon = (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.3071 2.10645H2.69692C1.98128 2.10645 1.44159 2.65473 1.44159 3.38133V18.6238C1.44159 19.3509 1.98128 19.8989 2.69692 19.8989H19.3071C20.0228 19.8989 20.5625 19.3507 20.5625 18.6238V3.38133C20.5627 2.65451 20.023 2.10645 19.3071 2.10645ZM19.0289 3.64022V13.7896H2.97536V3.64022H19.0289ZM2.97536 18.3652V15.3234H19.0289V18.3652H2.97536Z"
      fill="white"
    />
    <path
      d="M4.11553 9.74899H6.44509C6.80946 9.74899 7.14827 9.53565 7.30811 9.20522L8.42745 6.88877L11.1433 12.0091C11.3117 12.326 11.634 12.5198 11.9891 12.5198C11.9982 12.5198 12.0072 12.5196 12.016 12.5194C12.3816 12.5095 12.705 12.2955 12.8592 11.9617L14.3636 8.71451L14.7288 9.36033C14.899 9.66154 15.2187 9.84889 15.5628 9.84889H18.0748C18.4985 9.84889 18.8418 9.50557 18.8418 9.0819C18.8418 8.65822 18.4985 8.3149 18.0748 8.3149H15.8999L15.1471 6.98416C14.9692 6.66877 14.6405 6.48487 14.2753 6.49561C13.915 6.50979 13.5962 6.72313 13.443 7.05313L11.955 10.2655L9.25009 5.16616C9.07907 4.84389 8.74907 4.64666 8.38534 4.65504C8.0229 4.66213 7.69934 4.87032 7.54079 5.1986L6.08307 8.215H4.11532C3.69165 8.215 3.34833 8.55832 3.34833 8.98199C3.34854 9.40567 3.69186 9.74899 4.11553 9.74899ZM16.4418 16.1221H14.2723C13.8488 16.1221 13.5053 16.4654 13.5053 16.8891C13.5053 17.3126 13.8486 17.6561 14.2723 17.6561H16.4418C16.8652 17.6561 17.2088 17.3128 17.2088 16.8891C17.2088 16.4654 16.8654 16.1221 16.4418 16.1221ZM10.925 16.1221H4.41911C3.99565 16.1221 3.65212 16.4654 3.65212 16.8891C3.65212 17.3126 3.99544 17.6561 4.41911 17.6561H10.925C11.3485 17.6561 11.692 17.3128 11.692 16.8891C11.6918 16.4654 11.3485 16.1221 10.925 16.1221Z"
      fill="white"
    />
  </svg>
);
const eventIcon = (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.78293 0.913305C5.75904 0.883346 1.98405 3.93383 1.13741 7.86354C0.320834 11.3154 1.78289 15.1658 4.71113 17.1782C7.55338 19.2139 11.6041 19.3734 14.5607 17.4828C17.5336 15.6622 19.2843 12.0268 18.7296 8.56742C18.2216 4.9814 15.357 1.86257 11.7998 1.12546C11.1384 0.977906 10.4604 0.908815 9.78293 0.913305ZM9.99659 3.71841C12.9534 3.70904 15.6695 6.15611 15.9708 9.09977C16.3555 11.9134 14.5416 14.8566 11.8143 15.7025C9.15249 16.6083 5.94277 15.4523 4.55607 12.9832C3.0217 10.4216 3.67453 6.77113 6.10594 4.99263C7.20857 4.14186 8.60448 3.68638 9.99659 3.71841Z"
      fill="white"
    />
  </svg>
);
const mapIcon = (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.6461 4.0193L14.5538 0.97315C14.2577 0.846227 13.9192 0.846227 13.6654 0.97315L8.03844 3.80777L2.36921 0.97315C2.03075 0.80392 1.60767 0.80392 1.31151 1.01546C1.01536 1.18469 0.84613 1.52315 0.84613 1.86161V17.0924C0.84613 17.4732 1.05767 17.8116 1.39613 17.9808L7.48844 21.027C7.78459 21.1539 8.12305 21.1539 8.3769 21.027L14.0461 18.1924L19.6731 21.027C19.8 21.1116 19.9692 21.1539 20.1384 21.1539C20.3077 21.1539 20.5192 21.1116 20.6884 20.9847C20.9846 20.8155 21.1538 20.477 21.1538 20.1385V4.90777C21.1538 4.527 20.9846 4.18853 20.6461 4.0193ZM19.0384 5.96546V14.0039C19.0384 14.4693 18.6154 14.8078 18.1923 14.6385C16.6269 14.0462 17.8961 11.4232 16.7538 9.98469C15.6961 8.67315 14.3423 10.027 13.0307 7.95392C11.8038 5.96546 13.4538 4.527 14.9769 3.76546C15.1884 3.68084 15.4 3.68084 15.5692 3.76546L18.7 5.33084C18.9538 5.45777 19.0384 5.71161 19.0384 5.96546ZM10.5346 17.727C10.2807 17.8539 9.98459 17.8116 9.77305 17.6424C9.34998 17.2616 9.01151 16.6693 9.01151 16.077C9.01151 15.0616 7.31921 15.4001 7.31921 13.3693C7.31921 11.7193 5.37305 11.2962 3.72305 11.4655C3.29998 11.5078 3.00382 11.2116 3.00382 10.7885V4.61161C3.00382 4.10392 3.51151 3.76546 3.93459 4.0193L7.57305 5.83853C7.61536 5.83853 7.65767 5.88084 7.65767 5.88084L7.78459 5.96546C9.30767 6.85392 9.01151 7.57315 8.3769 8.67315C7.65767 9.90007 7.36151 8.67315 6.34613 8.33469C5.33075 7.99623 4.31536 8.67315 4.65382 9.35007C4.99228 10.027 6.00767 9.35007 6.68459 10.027C7.36151 10.7039 7.36151 11.7193 9.39228 11.0424C11.4231 10.3655 11.7615 10.7039 12.4384 11.3808C13.1154 12.0578 13.4538 13.4116 12.4384 14.427C11.8461 15.0193 11.5923 16.2885 11.3384 17.1347C11.2961 17.3039 11.1692 17.4732 11 17.5578L10.5346 17.727Z"
      fill="white"
    />
  </svg>
);

const RightArray = () => {
  return (
    <SvgIcon>
      <svg
        width="13"
        height="13"
        viewBox="0 0 13 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.66184 6.75561C9.66835 6.74301 9.6789 6.73246 9.6846 6.71944C9.79632 6.48706 9.75974 6.2027 9.56191 6.01581L5.07731 1.77417C4.83274 1.54301 4.44723 1.55397 4.21566 1.79815C3.9845 2.04272 3.99507 2.42823 4.23963 2.65981L8.26355 6.46636L4.25547 10.3294C4.01334 10.563 4.00603 10.9485 4.23963 11.191C4.35907 11.3153 4.51873 11.3775 4.67839 11.3775C4.83074 11.3775 4.98308 11.3207 5.10129 11.2069L9.54527 6.92381C9.55381 6.91527 9.55704 6.9035 9.56558 6.89457C9.57208 6.88847 9.57899 6.884 9.58547 6.87751C9.62 6.84133 9.63828 6.79704 9.66184 6.75561Z"
          fill="black"
        />
      </svg>
    </SvgIcon>
  );
};

const OptionItem: React.FC<{
  icon: JSX.Element;
  primary: string;
  secondary: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}> = ({ icon, primary, secondary, onClick }) => {
  return (
    <ListItemButton onClick={onClick}>
      <ListItemAvatar>
        <Avatar>{icon}</Avatar>
      </ListItemAvatar>
      <ListItemText primary={primary} secondary={secondary} />
      <RightArray />
    </ListItemButton>
  );
};

const Options = () => {
  return (
    <Card
      className="shadow h-full bg-white"
      style={{
        boxShadow: '5px 5px 40px rgba(90, 166, 255, 0.15',
        borderRadius: '20px',
        overflow: 'hidden',
      }}
    >
      {/*随便占位一下1*/}
      <div className="p-2" />
      <List>
        <OptionItem
          icon={userIcon}
          primary="Users"
          secondary="manage user information"
          onClick={() => {
            history.push('/admin/manager');
          }}
        />
        <OptionItem
          icon={stationIcon}
          primary="Station"
          secondary="station data set"
        />
        <OptionItem
          icon={eventIcon}
          primary="Event"
          secondary="event data set"
        />
        <OptionItem
          icon={mapIcon}
          primary="Watch Map"
          secondary="check the map"
          onClick={() => {
            history.push('/map');
          }}
        />
      </List>
    </Card>
  );
};

export default Options;
