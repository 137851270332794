import React from 'react';
import ProForm, { ModalForm, ProFormDependency, ProFormSelect, ProFormSwitch, ProFormText } from '@ant-design/pro-form';
import { UserItem } from '@/service/user/common';
import { Button, message, Switch } from 'antd';
import { PostAdminUserEdit, PostAuthoritiesOptions } from '@/service/user/list';

type FormDataType = {
  newId: string;
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  type: string;
  authorities: string[];
};

const EditUserModal: React.FC<{ entity: UserItem }> = ({ entity }) => {
  return (
    <ModalForm
      title={
        <>
          Edit User
          <div className="text-sm text-gray-400">id:{entity.id}</div>
        </>
      }
      trigger={<Button type="link">Edit</Button>}
      onFinish={async (formData: FormDataType) => {
        const res = await PostAdminUserEdit({
          id: entity.id,
          ...formData,
        });
        message.success('submit successfully');
        return true;
      }}
    >
      <ProForm.Group>
        <ProFormText width="md" id="newId" name="newId" label="Id" required initialValue={entity.id} />
      </ProForm.Group>
      <ProForm.Group>
        <ProFormText width="md" id="email" name="email" label="Email" required initialValue={entity.email} />
      </ProForm.Group>
      <ProForm.Group>
        <ProFormText width="md" id="firstName" name="firstName" label="First Name" required initialValue={entity.firstName} />
        <ProFormText width="md" id="lastName" name="lastName" label="Last Name" required initialValue={entity.lastName} />
      </ProForm.Group>
      <ProForm.Group>
        <ProFormSelect
          width="md"
          required
          initialValue={entity.type}
          options={[
            {
              value: 'Admin',
              label: 'Admin',
            },
            {
              value: 'User',
              label: 'Common User',
            },
          ]}
          name="type"
          label="User Type"
        />
      </ProForm.Group>
      <ProForm.Group>
        <ProFormSelect
          request={async () => {
            const res = await PostAuthoritiesOptions();
            return res.data.map((item) => {
              return {
                value: item.name,
                label: item.description,
              };
            });
          }}
          mode="tags"
          width="xl"
          name="authorities"
          label="Authorities"
          initialValue={entity.authorities}
        />
      </ProForm.Group>
      <ProForm.Group>
        <ProFormSwitch name="editPassword" width="lg" label="Edit Password" initialValue={false} />
      </ProForm.Group>
      <ProForm.Group>
        <ProFormDependency name={['editPassword']}>
          {({ editPassword }) => {
            if (editPassword) {
              return (
                <ProForm.Group>
                  <ProFormText.Password
                    rules={[{ required: true, message: 'new password is required' }]}
                    width="md"
                    id="newPassword"
                    name="password"
                    label="New Password"
                  />
                  <ProFormText.Password
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (`${value}`.indexOf(' ') !== -1) {
                            return Promise.reject(new Error("The password can't contain Spaces"));
                          }
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('password not match'));
                        },
                      }),
                    ]}
                    width="md"
                    id="confirmPassword"
                    name="rePassword"
                    required
                    label="Confirm Password"
                    placeholder="confirm password"
                  />
                </ProForm.Group>
              );
            }
            return <></>;
          }}
        </ProFormDependency>
      </ProForm.Group>
    </ModalForm>
  );
};

export default EditUserModal;
