import React, { useRef, useState } from 'react';
import { PostAdminMegaDataNetwork, PostAdminMegaDataStation, Station } from '@/service/admin/megaData';
import { ActionType, EditableProTable, ProColumns, ProTable } from '@ant-design/pro-table';
import { AddStation, DeleteStation, UpdateStation } from '@/service/admin/station';
import { Button, message, Select, Space } from 'antd';
import { PostAdminNetworkList } from '@/service/admin/network';
import { useMount } from 'ahooks';
import Options from '@/pages/admin/components/Options';
import ProForm, { ModalForm, ProFormSelect, ProFormText } from '@ant-design/pro-form';

type FormDataType = {
  stationId: string;
  networkId: string;
};

const NewStationModel = () => {
  return (
    <ModalForm
      title="New Station"
      trigger={<Button type="primary">New Station</Button>}
      onFinish={async (formData: FormDataType) => {
        await AddStation(formData.stationId, formData.networkId);
        message.success('Submit successfully! Please refresh the list');
        return true;
      }}
    >
      <ProForm.Group>
        <ProFormText id="stationId" label="stationId" name="stationId" rules={[{ required: true, message: 'Required' }]} />
      </ProForm.Group>
      <ProForm.Group>
        <ProFormSelect
          label="networkId"
          name="networkId"
          mode="single"
          rules={[{ required: true, message: 'Required' }]}
          request={async () => {
            const { data } = await PostAdminNetworkList();
            // @ts-ignore
            let res = [];
            data.map((it) => {
              let temp = {};
              // @ts-ignore
              temp['label'] = it.name;
              // @ts-ignore
              temp['value'] = it.name;
              res.push(temp);
            });
            // @ts-ignore
            return res;
          }}
        />
      </ProForm.Group>
    </ModalForm>
  );
};

async function networkList() {
  return await PostAdminNetworkList();
}

const Stations = () => {
  const ref = useRef<ActionType>();
  const [netlist, setNetlist] = useState([{ name: 'nodata' }]);
  useMount(async () => {
    networkList().then((res) => setNetlist(res.data));
  });
  const columns: ProColumns<Station>[] = [
    {
      title: 'StationId',
      width: 240,
      dataIndex: 'stationId',
      render: (_) => <a>{_}</a>,
      readonly: true,
    },
    {
      title: 'NetworkId',
      width: 240,
      dataIndex: 'networkId',
      renderFormItem: (_) => (
        <Select>
          {netlist.map((it) => (
            // @ts-ignore
            <Options key={it.name}>{it.name}</Options>
          ))}
        </Select>
      ),
    },
    {
      title: 'operation',
      valueType: 'option',
      readonly: true,
      render: (dom, entity, index, action) => {
        return (
          <Space>
            <Button
              onClick={() => {
                action?.startEditable?.(entity.stationId);
              }}
              type="link"
            >
              Edit
            </Button>
          </Space>
        );
      },
    },
  ];
  return (
    <EditableProTable<Station>
      columns={columns}
      rowKey="stationId"
      // @ts-ignore
      request={async (params) => {
        const { data } = await PostAdminMegaDataStation(params.pageSize ?? 10, params.current ?? 0, params.query ?? '');
        return {
          data: data.values,
          total: data.total,
          success: true,
        };
      }}
      search={false}
      options={{
        search: {
          name: 'query',
          placeholder: 'Search by stationId/networkId',
        },
        setting: false,
      }}
      pagination={{
        defaultPageSize: 10,
        showSizeChanger: true,
        showQuickJumper: true,
      }}
      editable={{
        type: 'multiple',
        onSave: async (rowKey, data: Station) => {
          await UpdateStation(data);
        },
        onDelete: async (rowKey, data: Station) => {
          await DeleteStation(data.id);
          ref.current?.reload();
        },
      }}
      // @ts-ignore
      recordCreatorProps={{
        style: {
          display: 'none',
        },
      }}
      toolbar={{
        actions: [<NewStationModel />],
      }}
    />
  );
};

export default Stations;
