import React from 'react';
import { PageContainer } from '@ant-design/pro-layout';
import StationOverview from './components/StationOverview';
import EventOverView from '@/pages/dashboard/components/EventOverView';
import { Result, Space } from 'antd';

const Index: React.FC = () => {
  return (
    <PageContainer>
      <Space direction="vertical" size="middle" className="w-full">
        <StationOverview />
        <EventOverView />
      </Space>
    </PageContainer>
  );
};

export default Index;
