import { request } from 'umi';
import { PageData, ResponseData } from '@/service/API';
import { Moment } from 'moment';
import { UserItem } from '@/service/user/common';

export type StationGrant = {
  id: string;
  stationId: string;
  userId: string;
  period: number[];
  user: UserItem;
};

export type StationGrantAddParam = {
  stationId: string;
  userId: string;
  description: string;
  period: Moment[];
};

export function PostAdminStationGrantList(current: number, size: number, query: string) {
  return request<ResponseData<PageData<StationGrant>>>('/api/admin/grant/station/list', {
    method: 'POST',
    data: {
      pageSize: size,
      current: current,
      keyword: query,
    },
  });
}

export function PostAdminStationGrantAdd(param: StationGrantAddParam) {
  return request<ResponseData<StationGrant>>('/api/admin/grant/station/add', {
    method: 'POST',
    data: {
      ...param,
    },
  });
}

export function PostAdminStationGrantDelete(id: string) {
  return request<ResponseData<StationGrant>>('/api/admin/grant/station/delete/' + id, {
    method: 'POST',
  });
}

type StationGrantListAllItem = {
  id: string;
  stationId: string;
  userId: string;
};

export function PostAdminStationGrantListAll() {
  return request<ResponseData<StationGrantListAllItem[]>>('/api/admin/grant/station/listAll', {
    method: 'POST',
  });
}
