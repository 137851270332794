import { Button, Checkbox, Col, DatePicker, Divider, Form, Row, Slider, Space } from 'antd';
import React, { useState } from 'react';
import Magnitude from '@/components/Magnitude';
import moment, { Moment } from 'moment';
import { useDispatch } from 'umi';
import { useMount, useRequest } from 'ahooks';
import { useSelector } from '@@/plugin-dva/exports';
import { EventItem, genPdfZip } from '@/service/map/event';
import { createCsv } from '@/utils/GenCsvUtil';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';

const { RangePicker } = DatePicker;

let _setUsgs: ((p: boolean) => void) | undefined = undefined;

let setUsgs = (p: (b: boolean) => void) => {
  _setUsgs = p;
};

// 导出按钮
const ExportComponent: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);

  let { eventList, magnitudeRange }: { eventList: EventItem[]; magnitudeRange: number[] } = useSelector((state: any) => state.event);

  const header = [
    'eventId@eventID',
    'latitude@latidue(˚)',
    'longitude@longitude(˚)',
    'depthGround@depthGround(m)',
    'uncertaintyLat@uncertaintyLat(m)',
    'uncertaintyLog@uncertaintyLog(m)',
    'uncertaintyDep@uncertaintyDep(m)',
    't0Utc@t0UTC',
    'magnitude',
    'mode',
    'mxx',
    'myy',
    'mzz',
    'mxy',
    'myz',
    'mxz',
    'mxy',
    'usedPhases',
    'usedStations',
    'minStatDistance@MinStatDistance',
    'maxStatDistance@MaxStatDistance',
    'dtpRMS@dTpRMS',
    'dtsRMS@dTsRMS',
    'qcReport',
  ];

  const handleClick = () => {
    const link = document.createElement('a', {});
    const temp = eventList.filter((item: EventItem) => {
      return item.magnitude >= magnitudeRange[0] && item.magnitude <= magnitudeRange[1];
    });
    link.href = createCsv(header, temp);
    //对下载的文件命名
    link.download = 'event_data.txt';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    if (checked) {
      // 下载pdf
      setLoading(true);
      genPdfZip(temp).then((res) => {
        const link = document.createElement('a', {});
        link.href = window.URL.createObjectURL(res);
        link.download = 'event_data.zip';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoading(false);
      });
    }
  };

  const handleCheck = (e: any) => {
    setChecked(e.target.checked);
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Button type="primary" block size="large" loading={loading} onClick={handleClick}>
        Export event data
      </Button>
      <Checkbox checked={checked} onChange={handleCheck}>
        Include QC reports
      </Checkbox>
    </Space>
  );
};

type LegendItem = {
  key: number;
  preliminary: number;
  final: number;
  range: string;
};

const range1 = [['<=2'], ['2~2.5'], ['2.5~3'], ['3~3.5'], ['3.5~4'], ['4~4.5'], ['4.5~5'], ['5~5.5'], ['>5.5']];

const UsgsCheckbox = () => {
  const [checked, setChecked] = useState(false);
  const onChange = (e: CheckboxChangeEvent) => {
    if (_setUsgs) {
      _setUsgs(e.target.checked);
      setChecked(e.target.checked);
    }
  };
  const data: Array<LegendItem> = [];
  range1.forEach((item, index) => {
    data.push({
      key: index,
      preliminary: index,
      final: index,
      range: item[0],
    });
  });
  return (
    <div>
      <Checkbox defaultChecked={false} onChange={onChange}>
        USGS Events
      </Checkbox>
      {checked && (
        <div>
          <Form layout="vertical">
            <Form.Item>
              <Row>
                <Col span={2} />
                <Col span={20}>
                  {data.map((item) => {
                    return (
                      <Row className="mb-1" key={item.key}>
                        <Col span={6} className="text-center">
                          <Magnitude level={item.preliminary} shape="rect" />
                        </Col>

                        <Col span={8}>
                          <div>{item.range}</div>
                        </Col>
                      </Row>
                    );
                  })}
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </div>
      )}
    </div>
  );
};

// 图例
const LegendComponent: React.FC = () => {
  const data: Array<LegendItem> = [];

  range1.forEach((item, index) => {
    data.push({
      key: index,
      preliminary: index,
      final: index,
      range: item[0],
    });
  });

  return (
    <div>
      <Divider />
      <Row align="top" justify="space-between">
        <Col span="10">
          <Form layout="vertical">
            <Form.Item label="Magnitude">
              <Row>
                <Col span={2} />
                <Col span={20}>
                  {data.map((item) => {
                    return (
                      <Row className="mb-1" key={item.key}>
                        <Col span={6} className="text-center">
                          <Magnitude level={item.preliminary} />
                        </Col>

                        <Col span={8}>
                          <div>{item.range}</div>
                        </Col>
                      </Row>
                    );
                  })}
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Col>
        <Col span="10">
          <UsgsCheckbox />
        </Col>
      </Row>
    </div>
  );
};

// main
const Events: React.FC = () => {
  const [dateSource, setDateSource] = useState<Moment[]>([moment().subtract(1, 'month').startOf('day'), moment().endOf('day')]);

  const dispatch = useDispatch();
  useMount(() => {
    dispatch({
      type: 'event/queryEventList',
      payload: {
        startTime: dateSource[0],
        endTime: dateSource[1],
      },
    });
    dispatch({
      type: 'usgsEvent/queryUsgsEventList',
      payload: {
        startTime: dateSource[0],
        endTime: dateSource[1],
      },
    });
  });

  const emitChangeRange = async (value: number[]) => {
    dispatch({
      type: 'event/changeMagnitudeRange',
      payload: value,
    });
    dispatch({
      type: 'usgsEvent/changeMagnitudeRange',
      payload: value,
    });
  };

  const { run } = useRequest(emitChangeRange, {
    debounceWait: 100,
    manual: true,
  });

  return (
    <div className="events ml-4" style={{ width: '410px' }}>
      <div className="text-xl font-medium">Events filters</div>
      <div className="mt-4" />
      <Form layout="vertical">
        <Form.Item label="Time window (past 6 months):">
          {/*@ts-ignore*/}
          <RangePicker
            value={dateSource}
            format={'MM/DD/YYYY HH:mm:ss'}
            onChange={(date) => {
              if (date != null) {
                dispatch({
                  type: 'event/queryEventList',
                  payload: {
                    startTime: date[0],
                    endTime: date[1],
                  },
                });
                dispatch({
                  type: 'usgsEvent/queryUsgsEventList',
                  payload: {
                    startTime: date[0],
                    endTime: date[1],
                  },
                });
              }
              // @ts-ignore
              setDateSource(date);
            }}
            showTime
          />
        </Form.Item>
        <Form.Item label={'Magnitude range'}>
          <div className="mx-4">
            <Slider
              range
              defaultValue={[1, 9]}
              min={1}
              max={9}
              step={0.1}
              marks={{ 1: '1', 9: '9' }}
              onChange={(value) => {
                run(value);
              }}
            />
          </div>
        </Form.Item>
      </Form>
      <ExportComponent />
      <LegendComponent />
    </div>
  );
};

export default Events;
export { setUsgs };
