import React, { useEffect, useState } from 'react';
import { Space } from 'antd';
import { Statistic, StatisticCard } from '@ant-design/pro-card';
import { StaItem } from '@/service/core2/sta';
import { useMount, useRequest, useUpdateEffect } from 'ahooks';

import * as echarts from 'echarts/core';
import { TitleComponent, ToolboxComponent, TooltipComponent, GridComponent, DataZoomComponent } from 'echarts/components';
import { LineChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { useRandomDomId } from '@/utils/chart';
import { EllipsisOutlined } from '@ant-design/icons';

echarts.use([TitleComponent, ToolboxComponent, TooltipComponent, GridComponent, DataZoomComponent, LineChart, CanvasRenderer, UniversalTransition]);

export function TimeSlider(props: { width: number; min: number; max: number; onChange: (min: number, max: number) => void }) {
  const width = props.width;
  const domId = useRandomDomId();

  const callback = async (min: number, max: number) => {
    props.onChange(min, max);
  };

  const { run } = useRequest(callback, {
    debounceWait: 300,
    manual: true,
  });

  const [globalChart, setGlobalChart] = useState<echarts.ECharts | null>(null);

  useEffect(() => {
    let chartDom: HTMLElement | null = null;
    let myChart: echarts.ECharts | null = null;

    chartDom = document.getElementById(domId);
    if (globalChart == null) {
      myChart = echarts.init(chartDom!);
      setGlobalChart(myChart);
    } else myChart = globalChart;

    let data = [];

    const option = {
      useUTC: true,
      xAxis: {
        name: 'Time',
        type: 'time',
        boundaryGap: false,
        min: props.min,
        max: props.max,
        axisLine: {
          show: false,
        },
      },
      dataZoom: {
        min: 0,
        max: 100,
      },
      yAxis: {
        type: 'value',
        scale: true,
      },
      series: [
        {
          name: 'timslider',
          type: 'line',
          symbol: 'none',
          sampling: 'lttb',
          itemStyle: {
            color: 'rgb(255, 70, 131)',
          },
          data: [],
        },
      ],
    };

    myChart?.on('dataZoom', (params) => {
      const tick = (props.max - props.min) / 100;
      // @ts-ignore
      run(new Date(params?.start * tick + props.min).getTime(), new Date(params.end * tick + props.min).getTime());
    });

    option && myChart.setOption(option);
  }, [props.min, props.max]);

  useEffect(() => {
    globalChart?.resize();
  }, [width]);

  return <div id={domId} style={{ height: '90px' }}></div>;
}
