import React, { useState, FC } from 'react';
import { Button, InputAdornment, TextField } from '@mui/material';
import { EmailIcon, LockIcon } from '@/pages/user/login/components/Login';
import { LoadingOutlined } from '@ant-design/icons';
import {
  postUserResetPassword,
  postUserSendRecoveryEmail,
} from '@/service/user/common';
import { message } from 'antd';
import { history } from '@@/core/history';
import { useModel } from '@@/plugin-model/useModel';

const back = (
  <svg
    className="icon"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    data-spm-anchor-id="a313x.7781069.0.i0"
    width="36"
    height="36"
  >
    <path
      d="M419.2 865l-353-353 353-353 43.2 43.2L152.5 512l309.9 309.8z"
      fill="#2c2c2c"
      data-spm-anchor-id="a313x.7781069.0.i1"
      className="selected"
    />
    <path d="M128.8 481.5h829v61h-829z" fill="#2c2c2c" />
  </svg>
);

const Index: FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [captcha, setCaptcha] = useState('');
  const [emailToggle, editEmailToggle] = React.useState(false);
  const [passwordToggle, editPasswordToggle] = React.useState(false);
  const [captchaToggle, editCaptchaToggle] = React.useState(false);
  const [countDown, setCountDown] = React.useState<number>(0);
  const [loading, setLoading] = React.useState(false);
  const { initialState, setInitialState } = useModel('@@initialState');

  const sendAndCountDown = async () => {
    let second = 60;
    const timer = setInterval(() => {
      second--;
      setCountDown(second);
      if (second <= 0) {
        clearInterval(timer);
      }
    }, 1000);
    postUserSendRecoveryEmail(email).then((res) => {
      message.success(
        'The email containing the verification code has been sent to your email',
      );
    });
  };

  return (
    <div>
      <div className="py-16 px-24">
        <Button
          onClick={() => {
            history.goBack();
          }}
          className="mb-2"
        >
          {back}
        </Button>
        <h1 style={{ color: '#101727' }} className="text-3xl">
          Recovery Password
        </h1>
        <h4 style={{ color: '#6e7480' }}>
          Please complete email verification to retrieve your password.
        </h4>
        <div className="h-12" />
        <TextField
          id="outlined-basic"
          fullWidth
          label="Account"
          variant="outlined"
          onChange={(res) => {
            setEmail(res.target.value);
            editEmailToggle(false);
          }}
          error={email == '' && emailToggle}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <EmailIcon />
              </InputAdornment>
            ),
          }}
        />
        <div className="h-10" />
        <TextField
          id="outlined-basic"
          fullWidth
          label="verification code"
          type="text"
          variant="outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  disabled={countDown > 0}
                  onClick={() => {
                    if (email != null && email != '') {
                      // This regular expression can validate all email addresses in RFC822 format
                      const isEmail =
                        /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
                          email,
                        );
                      if (!isEmail) {
                        editEmailToggle(true);
                        message.error(
                          `${email} is not legitimate email address`,
                        );
                        return;
                      }
                      setCountDown(60);
                      sendAndCountDown();
                    } else {
                      message.error('Email is a required field');
                      editEmailToggle(true);
                    }
                  }}
                >
                  {countDown > 0 ? `${countDown}s..` : 'Send code'}
                </Button>
              </InputAdornment>
            ),
          }}
          error={captcha == '' && captchaToggle}
          onChange={(res) => {
            setCaptcha(res.target.value);
            editCaptchaToggle(false);
          }}
        />
        <div className="h-10" />
        <TextField
          id="outlined-basic"
          fullWidth
          label="New Password"
          type="password"
          variant="outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <LockIcon />
              </InputAdornment>
            ),
          }}
          error={password == '' && passwordToggle}
          onChange={(res) => {
            setPassword(res.target.value);
          }}
        />
        <div className="h-14" />
        <Button
          fullWidth
          variant="contained"
          size="large"
          onClick={() => {
            if (captcha == '') {
              editCaptchaToggle(true);
            }
            if (email == '') {
              editEmailToggle(true);
            }
            if (password == '') {
              editPasswordToggle(true);
            }
            if (loading) return;
            if (captcha != '' && email != '' && password != '') {
              setLoading(true);
              postUserResetPassword({
                email,
                code: captcha,
                password,
              })
                .then((res) => {
                  message.success(
                    'Password reset successfully, will automatically login after 3 seconds',
                  );
                  setLoading(false);
                  setTimeout(() => {
                    setInitialState({
                      ...initialState,
                      currentUser: res.data,
                    }).then((r) => {
                      if (res.data.type == 'Admin') {
                        // 这么写才能刷新路由
                        history.replace('/dashboard');
                      } else if (res.data.type == 'User') {
                        history.replace('/map');
                      }
                    });
                  }, 3000);
                })
                .catch((err) => {
                  console.log(err);
                  message.error(err.message);
                  setLoading(false);
                });
            }
          }}
        >
          {loading ? (
            <span>
              <LoadingOutlined style={{ fontSize: 'large' }} />
              <span className="ml-4" /> Login
            </span>
          ) : (
            'Confirm'
          )}
        </Button>
      </div>
    </div>
  );
};

export default Index;
