import { request } from 'umi';
import { PageData, ResponseData } from '@/service/API';
import { StationItem } from '@/service/map/station';
import { Station } from '@/service/admin/megaData';

type StationChoices = {
  id: string;
  stationId: string;
};

export function PostAdminStationChoices(keywords: string) {
  return request<ResponseData<StationChoices[]>>('/api/admin/station/choices', {
    method: 'POST',
    data: {
      keywords,
    },
  });
}

export function PostAdminStationStatus(userId: string) {
  return request<ResponseData<StationItem[]>>('/api/admin/station/status', {
    method: 'POST',
    data: {
      userId,
    },
  });
}

export type SortParam = {
  sortByKey: string;
  sortByWay: 'ASC' | 'DESC';
};

export type FilterParam = {
  filterKey: string;
  filterWay: 'eq' | 'ne' | 'le' | 'lt' | 'ge' | 'gt' | 'like';
  filterValue: string;
};

export type SuperQueryParam = {
  sort: SortParam[];
  filter: FilterParam[];
  current: number;
  pageSize: number;
  clientId: string;
  networkId: string;
};

export function PostAdminStationSuperQuery(param: SuperQueryParam) {
  return request<ResponseData<PageData<StationItem>>>('/api/admin/station/superQuery', {
    method: 'POST',
    data: param,
  });
}

type Station = {
  id: string;
  stationId: string;
  networkId: string;
};

export function UpdateStation(param: Station) {
  return request<ResponseData<any>>('/api/station/update', {
    method: 'POST',
    data: param,
  });
}

export function AddStation(stationId: string, networkId: string) {
  return request<ResponseData<any>>('/api/station/add', {
    method: 'POST',
    data: {
      stationId,
      networkId,
    },
  });
}

export function DeleteStation(param: String) {
  return request<ResponseData<any>>('/api/station/delete', {
    method: 'POST',
    data: param,
  });
}
