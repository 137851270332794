import React from 'react';
import ProForm, { ModalForm, ProFormDateTimePicker, ProFormDateTimeRangePicker, ProFormField, ProFormSelect } from '@ant-design/pro-form';
import { Button, message } from 'antd';
import { PostAdminStationChoices } from '@/service/admin/station';
import { PostAdminUserChoices } from '@/service/admin/user';
import { PostAdminStationGrantAdd } from '@/service/admin/stationGrant';
import moment, { Moment } from 'moment';

type FormDataType = {
  station: string;
  user: string;
  description?: string;
  t1?: Date;
  t2?: Date;
};

function NewGrantModel() {
  return (
    <ModalForm
      title={<>New Auth</>}
      trigger={<Button type="primary">New Auth</Button>}
      onFinish={async (formData: FormDataType) => {
        console.log(formData.t1, formData.t2);
        // get timestamp
        let period: Moment[] = [];
        if (formData.t1 == undefined) {
        } else if (formData.t2 == undefined) {
          period = [moment(formData.t1)];
        } else {
          period = [moment(formData.t1), moment(formData.t2)];
        }
        await PostAdminStationGrantAdd({
          stationId: formData.station,
          userId: formData.user,
          period: period,
          description: formData.description ?? '',
        });
        message.success('submit successfully');
        return true;
      }}
    >
      <ProForm.Group>
        <ProFormSelect
          label="Station"
          name="station"
          showSearch
          width="md"
          request={async ({ keyWords = '' }) => {
            const { data } = await PostAdminStationChoices(keyWords);
            return data.map((it) => ({ label: it.stationId, value: it.stationId }));
          }}
          placeholder="Please select station"
          rules={[{ required: true, message: 'Required' }]}
        />
      </ProForm.Group>
      <ProForm.Group>
        <ProFormSelect
          label="User"
          name="user"
          showSearch
          width="md"
          request={async ({ keyWords = '' }) => {
            const { data } = await PostAdminUserChoices(keyWords);
            return data.map((it) => ({ label: `${it.id} (${it.firstName} ${it.lastName})`, value: it.id }));
          }}
          placeholder="Select user id/name/email"
          rules={[{ required: true, message: 'Required' }]}
        />
      </ProForm.Group>
      <ProForm.Group>
        <ProFormDateTimePicker label="Start Time" width="md" name="t1" placeholder="If not filled, it means no authorization"></ProFormDateTimePicker>
        <ProFormDateTimePicker label="End Time" width="md" name="t2" placeholder="If not filled, it means so far"></ProFormDateTimePicker>
      </ProForm.Group>
      <ProForm.Group>
        <ProFormField name="description" label="Description" width="md" placeholder="Description" />
      </ProForm.Group>
    </ModalForm>
  );
}

export default NewGrantModel;
